//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    address: {
      type: Object,
      default: () => {}
    },

    onSubmit: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    addressValue () {
      return `${this.address.detail_address},
      ${this.address.village.subdistrict ? this.address.village.subdistrict.name + ', ' : null}
      ${this.address.village.city}, ${this.address.village.province}, ${this.address.village.postal_code}`
    }
  },

  methods: {
    ...mapActions('User/address', ['editUserAddress']),

    onSubmitChange (value) {
      this.$emit('on-submit-changed', value)
    },

    onAddressUpdated () {
      this.$emit('on-address-updated')
    },

    async handleChangeMainAddress (addressId) {
      try {
        await this.editUserAddress({
          id: addressId,
          payload: {
            data: {
              main: true
            }
          }
        })

        this.$Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Alamat berhasil dijadikan alamat utama !',
          heightAuto: false
        })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error.message

        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: errorMessage,
          heightAuto: false
        })
      }

      this.$emit('on-address-updated')
    }
  }
}
