//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import panstoreMixin from '~/mixins';

export default {
  mixins: [panstoreMixin],

  props: {
    flashSaleSessions: {
      type: Array,
      default: () => []
    },

    activeFlashSaleWeekIndex: {
      type: Number,
      default: 0
    },

    activeFlashSaleWeek: {
      type: Object,
      default: () => {}
    },

    activeFlashSaleSessionIndex: {
      type: Number,
      default: 0
    },

    activeFlashSaleSession: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      //
    }
  },

  computed: {
    onFlashSale () {
      return this.getFlashTime(this.activeFlashSaleSession.date_start)
    }
  },

  watch: {
    activeFlashSaleWeekIndex () {
      this.resetOrCompleteInfiniteLoading()
    },

    activeFlashSaleSessionIndex () {
      this.resetOrCompleteInfiniteLoading()
    }
  },

  methods: {
    ...mapActions('Flashsale', ['getProductSession']),

    resetOrCompleteInfiniteLoading () {
      if (
        this.activeFlashSaleSession && this.activeFlashSaleSession.metaPagination &&
        (
          this.activeFlashSaleSession.metaPagination.page <= this.activeFlashSaleSession.metaPagination.totalPage ||
          this.activeFlashSaleSession.metaPagination.page === 1
        ) &&
        this.$refs.InfiniteLoading
      ) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      } else if (
        this.activeFlashSaleSession && this.activeFlashSaleSession.metaPagination &&
        this.activeFlashSaleSession.metaPagination.page > this.activeFlashSaleSession.metaPagination.totalPage &&
        this.$refs.InfiniteLoading
      ) {
        this.$refs.InfiniteLoading.stateChanger.complete()
      }
    },

    handleInfiniteLoading () {
      if (
        this.activeFlashSaleSession && this.activeFlashSaleSession.metaPagination &&
        (
          this.activeFlashSaleSession.metaPagination.page <= this.activeFlashSaleSession.metaPagination.totalPage ||
          this.activeFlashSaleSession.metaPagination.page === 1
        ) &&
        this.$refs.InfiniteLoading
      ) {
        this.handleGetProducts()
      } else if (
        this.activeFlashSaleSession && this.activeFlashSaleSession.metaPagination &&
        this.activeFlashSaleSession.metaPagination.page > this.activeFlashSaleSession.metaPagination.totalPage &&
        this.$refs.InfiniteLoading
      ) {
        this.$refs.InfiniteLoading.stateChanger.complete()
      }
    },

    getFlashTime (start) {
      const startTime = new Date(this.formatYMD(start))

      if (startTime.getTime() < new Date().getTime()) {
        return true
      }

      return false
    },

    async handleGetProducts () {
      this.$emit('flashsale-session-product-loading-change', true)

      try {
        const resp = await this.getProductSession({
          payload: {
            data: {
              where: {
                event_sale_session_id: this.activeFlashSaleSession.id
              },
              per_page: this.activeFlashSaleSession.metaPagination.perPage,
              page: this.activeFlashSaleSession.metaPagination.page
            }
          }
        })

        this.updateFlashSaleSessionProducts(resp.data.data.event_sale_products)
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk flash sale')
      }

      this.$emit('flashsale-session-product-loading-change', false)
    },

    updateFlashSaleSessionProducts (eventSaleSessionProducts) {
      this.$emit('flashsale-session-product-updated', eventSaleSessionProducts)

      if (
        this.activeFlashSaleSession.metaPagination.page >
        this.activeFlashSaleSession.metaPagination.totalPage
      ) {
        if (
          Array.isArray(this.$refs.InfiniteLoading) &&
          this.$refs.InfiniteLoading.length > 0
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.complete()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.complete()
        }
      } else if (
        Array.isArray(this.$refs['InfiniteLoading' + this.activeTabName]) &&
        this.$refs.InfiniteLoading.length > 0
      ) {
        this.$refs.InfiniteLoading[0].stateChanger.loaded()
      } else if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.loaded()
      }
    }
  }
}
