//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      checked: false,
      loading: false
    }
  },
  computed: {
    ...mapState('Contents', ['privacyPolicy'])
  },
  async mounted () {
    await this.fetchPrivacyPolicy()
  },
  methods: {
    ...mapActions('Contents', ['getPrivacyPolicy']),
    ...mapActions('Affiliate', ['createAffiliateRegistration']),
    async fetchPrivacyPolicy () {
      try {
        const payload = {
          data: {}
        }

        await this.getPrivacyPolicy(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
    },
    handleChange (value) {
      this.checked = value
    },
    async createRegistration () {
      try {
        await this.createAffiliateRegistration()
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Pengajuan Berhasil Disimpan',
            text: 'Anda telah mendaftar untuk mengikuti program Afiliasi Paninti. Pengajuan akan diproses maksimal 7 hari kerja. Anda akan mendapat notifikasi saat pengajuan sudah selesai'
          }).then((confirm) => {
            window.location.href = '/'
          })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: errorMessage
          })
      }
    }
  }
}
