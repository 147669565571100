//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import mixins from '~/mixins'
export default {
  mixins: [mixins],
  props: {
    modal: {
      type: String,
      default: 'hide'
    }
  },
  data () {
    return {
      activeNames: ['1', '2', '3', '4', '5', '6', '7'],
      activeNamesTwo: ['1', '2', '3', '4', '5', '6', '7'],
      valueRange: [0, 10000000],
      selected_category: [],
      selected_merk: [],
      selected_color: [],
      selected_location: [],
      selected_size: [],
      selected_rating: [5],
      defaultProps: {
        children: 'sub_categories',
        label: 'name'
      },
      showBrand: 10,
      showColor: 10,
      showSize: 10,
      search: '',
      sort: '',
      slug: ''
    }
  },
  computed: {
    ...mapState('Product/filter', [
      'filterCategories',
      'filterBrands',
      'filterColor',
      'filterPrice',
      'filterSize',
      'filterLocation',
      'loadData'
    ]),
    ...mapState('Category', ['category'])
  },
  watch: {
    '$route.query' () {
      this.getData()
    }
  },
  async created () {
    await this.initComponent()
    this.getData()
  },
  methods: {
    async initComponent () {
      this.slug = this.$route.params.slug
      try {
        await this.$store.dispatch(
          'Product/filter/getFilterCategoryPage',
          this.slug || null
        )
      } catch (error) {
        console.log(error)
      }
      this.valueRange = this.filterPrice
    },
    getData () {
      const dataQuery = this.initFilter(
        this.$route.query.brands,
        this.$route.query.search,
        this.$route.query.categories,
        this.$route.query.colors,
        this.$route.query.sizes,
        this.$route.query.priceLimit,
        this.$route.query.rating,
        this.$route.query.sort,
        this.$route.query.city
      )
      this.selected_merk = dataQuery.brands
      this.selected_category = dataQuery.category
      this.selected_color = dataQuery.colors
      this.selected_size = dataQuery.sizes
      this.selected_location = dataQuery.location
      if (dataQuery.priceLimit) {
        this.valueRange[0] = Number(dataQuery.priceLimit[0])
        this.valueRange[1] = Number(dataQuery.priceLimit[1])
      } else {
        this.valueRange = [0, 10000000]
      }
      if (this.selected_category === {}) {
        this.$refs.categoryTree.setCheckedKeys([])
      }
      this.search = dataQuery.search
      this.sort = dataQuery.sort
    },
    handleShowAll (target) {
      if (target === 'color') {
        this.showColor = this.showColor === 10 ? this.filterColor.length : 10
      } else if (target === 'brand') {
        this.showBrand = this.showBrand === 10 ? this.filterBrands.length : 10
      } else if (target === 'size') {
        this.showSize = this.showSize === 10 ? this.filterSize.length : 10
      }
    },
    handleClickCategory (data) {
      const slug = data.slug
      window.location = `/c${data.level}/${slug}`
      // if (data.level && data.level === 2) {
      //   window.location = '/category/' + this.$route.params.slug + '/' + slug
      // } else {
      //   const parent = this.filterCategories.filter((cat) => {
      //     const temp = cat.sub_categories.filter((sub) => {
      //       return sub.slug === slug
      //     })
      //     return temp.length
      //   })[0].slug
      //   window.location =
      //     '/category/' + this.$route.params.slug + '/' + parent + '/' + slug
      // }
      // if (this.category.level === 1) {
      //   if (data.level === 3) {
      //     const parent = this.category.sub_categories.filter((sub) => {
      //       const temp = sub.sub_categories.filter((child) => {
      //         return child.slug === slug
      //       })
      //       return temp.length
      //     })[0].slug
      //     window.location =
      //       '/category/' + this.$route.params.slug + '/' + parent + '/' + slug
      //   } else {
      //     window.location = '/category/' + this.$route.params.slug + '/' + slug
      //   }
      // } else if (this.category.level === 2) {
      //   if (data.level && data.level === 2) {
      //     window.location = '/category/' + this.$route.params.slug + '/' + slug
      //   } else {
      //     window.location =
      //       '/category/' +
      //       this.$route.params.slug +
      //       '/' +
      //       this.$route.params.subCategory +
      //       '/' +
      //       slug
      //   }
      // window.location =
      //   '/category/' +
      //   this.$route.params.slug +
      //   '/' +
      //   this.category.slug +
      //   '/' +
      //   slug
      // this.selected_category = this.$refs.categoryTree.getCheckedKeys()
      // this.applyFilter()
    },
    applyFilter () {
      const brand = this.selected_merk.join('::')
      const priceLimit = this.valueRange.join('::')
      const category = this.selected_category.join('::')
      const color = this.selected_color.join('::')
      const size = this.selected_size.join('::')
      const location = this.selected_location.join('::')
      const rating =
        this.selected_rating.length > 1 ? this.selected_rating.join('::') : null
      this.$router.push({
        query: this.modelPayload(
          brand,
          this.search,
          category,
          color,
          size,
          priceLimit,
          rating,
          this.sort,
          location
        )
      })
    }
  }
}
