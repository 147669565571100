import { render, staticRenderFns } from "./index.vue?vue&type=template&id=17a0bdeb&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=17a0bdeb&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a0bdeb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,OrderStepper: require('/usr/src/app/components/account/shop/my-order/detail-order/OrderStepper.vue').default,DetailOrderUser: require('/usr/src/app/components/account/shop/my-order/detail-order/DetailOrderUser.vue').default,DetailOrderStuff: require('/usr/src/app/components/account/shop/my-order/detail-order/DetailOrderStuff.vue').default,DetailOrderTime: require('/usr/src/app/components/account/shop/my-order/detail-order/DetailOrderTime.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,ModalTrackingOrder: require('/usr/src/app/components/account/shop/my-order/detail-order/ModalTrackingOrder.vue').default,ModalCancelOrder: require('/usr/src/app/components/account/shop/my-order/detail-order/ModalCancelOrder.vue').default})
