//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      nearestStores: {
        data: [],
        total: 0
      }
    }
  },

  computed: {
    ...mapState('User/address', ['location'])
  },

  watch: {
    location () {
      this.getNearestStores()
    }
  },

  mounted () {
    this.getNearestStores()
  },

  methods: {
    ...mapActions('Partner', ['getNearestPartners']),

    async getNearestStores () {
      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155

        const resp = await this.getNearestPartners({
          data: {
            city_id: cityId,
            paginated: true,
            per_page: 5,
            where: {
              'stores.type': 'brand_partner'
            }
          }
        })

        this.nearestStores.data = resp.data.data.stores.data
        this.nearestStores.total = resp.data.data.stores.total
      } catch (error) {
        this.$message.error('Gagal mendapatkan data official store partner')
      }
    }
  }
}
