import { render, staticRenderFns } from "./FormPaymentConfirmation.vue?vue&type=template&id=b39d8fa0&scoped=true&"
import script from "./FormPaymentConfirmation.vue?vue&type=script&lang=js&"
export * from "./FormPaymentConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./FormPaymentConfirmation.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b39d8fa0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,ModalCropImage: require('/usr/src/app/components/base/ModalCropImage.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default})
