//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions } from 'vuex'

export default {
  mixins: [mixins],

  computed: {
    ...mapState('Contents', ['bannerPromotion']),

    bannerImage () {
      return this.isPhone ? this.bannerPhone : this.bannerDesktop
    },

    bannerDesktop () {
      return this.bannerPromotion.filter((banner) => {
        return banner.display_type === 'normal desktop'
      })[0]
    },

    bannerPhone () {
      return this.bannerPromotion.filter((banner) => {
        return banner.display_type === 'normal phone'
      })[0]
    },

    isPhone () {
      return (
        this.$mq === 'md' ||
        this.$mq === 'sm' ||
        this.$mq === 'xs' ||
        this.$mq === 'xxs'
      )
    }
  },

  mounted () {
    this.getHomeBannerPromotion()
  },

  methods: {
    ...mapActions('Contents', ['getBannerPromotion']),

    async getHomeBannerPromotion () {
      try {
        await this.getBannerPromotion()
      } catch (error) {
        this.$message.error('Gagal mendapatkan data home banner promotion')
      }
    }
  }
}
