//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapGetters } from 'vuex'
import panstoreMixin from '@/mixins/index'
import VClamp from 'vue-clamp'
import ModalDeleteAccount from './ModalDeleteAccount.vue'
export default {
  components: {
    VClamp,
    ModalDeleteAccount
  },
  mixins: [panstoreMixin],
  data () {
    return {
      listMenu: [
        {
          id: 1,
          name: 'Beranda',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-home.svg'),
          link: '/account/home',
          havechild: false
        },
        {
          id: 2,
          name: 'Akun Saya',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-user.svg'),
          link: '/account',
          havechild: true,
          isOpen: true,
          child: [
            {
              id: 21,
              name: 'Profil',
              link: '/account/profile'
            },
            {
              id: 22,
              name: 'Alamat',
              link: '/account/address'
            },
            {
              id: 23,
              name: 'Kartu Kredit/Debit',
              link: '/account/payment'
            },
            {
              id: 24,
              name: 'Akun Bank',
              link: '/account/bank-account'
            },
            {
              id: 25,
              name: 'Ubah Password',
              link: '/account/change-password'
            }
          ]
        },
        {
          id: 3,
          name: 'Afiliasi',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-affiliate.svg'),
          link: '/account/affiliate',
          havechild: true,
          isOpen: true,
          isNewMenu: false,
          child: [
            {
              id: 29,
              name: 'Daftar Produk',
              link: '/account/affiliate/product-list'
            },
            {
              id: 30,
              name: 'Komisi',
              link: '/account/affiliate/commission'
            }
          ]
        },
        {
          id: 4,
          name: 'Belanja Saya',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-order.svg'),
          link: '/account/shop',
          havechild: true,
          isOpen: true,

          child: [
            {
              id: 31,
              name: 'Pesanan Saya',
              link: '/account/shop/my-order'
            },
            {
              id: 32,
              name: 'Ulasan Saya',
              link: '/account/shop/order-review'
            }
          ]
        },
        {
          id: 5,
          name: 'Favorit Saya',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-heart.svg'),
          link: '/account/wishlist',
          havechild: false
        },
        {
          id: 6,
          name: 'Notifikasi',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-notifications.svg'),
          link: '/account/notification',
          havechild: false
        },
        {
          id: 7,
          name: 'Poin Saya',
          img: require('@/static/img/icon/account/sidebaracc/sidebar-point.svg'),
          link: '/account/point',
          havechild: false
        }
      ],
      isItemActive: 0
    }
  },
  computed: {
    ...mapState('Sidebar', ['accSidebar']),
    ...mapGetters('Account', ['isOpen']),
    isOpenMenu () {
      return this.isOpen
    },
    isRouteActive () {
      if (this.$route.path === '/') {
        return true
      } else {
        return false
      }
    },
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  mounted () {
    this.setMenu()
  },
  methods: {
    ...mapMutations('Sidebar', ['setMainSidebar']),
    ...mapMutations('User', ['setUser']),
    ...mapMutations('Cart', ['setCart']),
    ...mapMutations('Product', ['setProductWishlist']),
    setMenu () {
      if (!this.checkObject(this.$auth.user.affiliate)) {
        const affiliateMenu = this.listMenu[2]
        affiliateMenu.link = '/affiliate'
        affiliateMenu.havechild = false
        affiliateMenu.isOpen = false
        affiliateMenu.isNewMenu = true
        delete affiliateMenu.child
        this.listMenu[2] = affiliateMenu
      } else {
        this.listMenu[2].isNewMenu = this.$auth.user.affiliate.is_new_menu
      }
    },
    async logout () {
      await this.$auth.logout('local')
      this.setUser([])
      this.setCart([])
      this.setProductWishlist([])
      this.$cookies.remove('session_id')
      const path = this.$route.path
      if (
        path === '/cart' ||
        path === '/checkout' ||
        this.$route.name === 'payment-detail-order_id' ||
        this.$route.name === 'payment-confirmation-order_id'
      ) {
        window.location.replace('/')
      } else {
        window.location.reload()
      }
    },
    menuClick (num) {
      if (this.listMenu[num].name === 'Afiliasi') {
        this.$router.push('/account/affiliate');
      } else {
        this.listMenu[num].isOpen = !this.listMenu[num].isOpen;
      }
    }
  }
}
