//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'AllProduct',
  mixins: [panstoreMixin],
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('Product', ['popularAffiliateMonthly'])
  },
  async mounted () {
    await this.handleGetPopularAffiliateMonthly()
  },
  methods: {
    ...mapActions('Product', ['getPopularAffiliateMonthly']),

    async handleGetPopularAffiliateMonthly (paginated = true) {
      this.loading = true
      try {
        const payload = {
          data: {
            paginated: true,
            per_page: 5
          }
        }

        await this.getPopularAffiliateMonthly(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    }
  }
}
