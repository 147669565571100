var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-section mb-section mt-4 featured"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-12 col-12 mb-lg-0 mb-md-3 mb-3"},[_c('flicking',{attrs:{"plugins":_vm.plugins}},[_vm._l((_vm.dataBanner),function(item,index){return _c('a',{key:index,attrs:{"target":item.link_type === 'internal' ? '_self' : '_blank',"href":item.link === '' ? '/' : item.link,"aria-label":("" + (item.title)),"alt":("" + (item.title))}},[_c('img',{staticClass:"featured-pict mx-2",attrs:{"src":item.files[0].value + '.webp',"alt":("Gambar " + (item.title))},on:{"error":_vm.getDefaultImgBanner}})])}),_vm._v(" "),_c('div',{staticClass:"flicking-pagination",attrs:{"slot":"viewport"},slot:"viewport"}),_vm._v(" "),_c('span',{staticClass:"flicking-arrow-prev",attrs:{"slot":"viewport"},slot:"viewport"}),_vm._v(" "),_c('span',{staticClass:"flicking-arrow-next",attrs:{"slot":"viewport"},slot:"viewport"})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8 col-md-12 col-12"},[_c('div',{staticClass:"d-flex overflow-x"},_vm._l((_vm.brands.slice(0, 4)),function(i){return _c('div',{key:'brands-' + i.slug,staticClass:"col-lg-3 px-2 mb-4"},[_c('div',{staticClass:"text-center card-featured cursor-pointer h-100",on:{"click":function($event){return _vm.$router.push(("/partner/" + (i.slug)))}}},[_c('img',{staticClass:"img-logo img-fluid",attrs:{"src":_vm.getThumbnailImage(i.most_popular_product.files || [], 'image'),"alt":'Gambar ' + i.most_popular_product.name},on:{"error":_vm.getDefaultImgProduct}}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('img',{staticClass:"img-logo-thumbnail rounded-circle me-2",attrs:{"src":_vm.getThumbnailImage(i.files, 'brand'),"alt":'Logo Thumbnail' + i.name},on:{"error":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.getDefaultImgProfile.apply(void 0, args.concat( ['brand'] ));
}}}),_vm._v(" "),_c('div',{staticClass:"my-auto"},[_vm._v("\n                "+_vm._s(i.name)+"\n              ")])])])])}),0),_vm._v(" "),_c('div',{staticClass:"d-flex mt-3 overflow-x"},_vm._l((_vm.partners.slice(0, 4)),function(i){return _c('div',{key:'partner-' + i.slug,staticClass:"col-lg-3 px-2 mb-4"},[_c('div',{staticClass:"text-center card-featured cursor-pointer h-100",on:{"click":function($event){return _vm.$router.push(("/partner/" + (i.slug)))}}},[_c('img',{staticClass:"img-logo img-fluid",attrs:{"src":_vm.getThumbnailImage(i.files || [], 'image'),"alt":'Gambar ' + i.name},on:{"error":_vm.getDefaultImgProduct}}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('img',{staticClass:"img-logo-thumbnail rounded-circle me-2",attrs:{"src":_vm.getThumbnailImage(i.files, 'store'),"alt":'Logo Thumbnail' + i.name},on:{"error":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.getDefaultImgProfile.apply(void 0, args.concat( ['store'] ));
}}}),_vm._v(" "),_c('div',{staticClass:"my-auto"},[_vm._v("\n                "+_vm._s(i.name)+"\n              ")])])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }