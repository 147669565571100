var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-comp order-status border rounded h-100 mb-5 mb-lg-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("\n      Informasi Pesanan\n    ")]),_vm._v(" "),_c('div',{staticClass:"badge-wrapper ms-auto"},[_c('div',{staticClass:"badge-status",class:_vm.status[1]},[_vm._v("\n        "+_vm._s(_vm.status[0])+"\n      ")])])]),_vm._v(" "),(_vm.status[0] === 'Dikirim' || _vm.status[0] === 'Selesai')?[(_vm.method === 'shipping')?[_c('div',{staticClass:"text-uppercase mb-3"},[_vm._v("\n        "+_vm._s(_vm.shipping)+"\n      ")]),_vm._v(" "),(
          Object.keys(_vm.orderTracking).length &&
            _vm.orderTracking.waybill.manifest.length
        )?_c('div',{staticClass:"status-date mb-3"},[_vm._v("\n        "+_vm._s(_vm.orderTracking.waybill.manifest[0].manifest_description)+"\n      ")]):_c('div',{staticClass:"mb-3 status-date"},[_vm._v("\n        "+_vm._s(_vm.formatDate(_vm.date))+" - "+_vm._s(_vm.time)+" WIB\n      ")])]:[(_vm.status[0] === 'Selesai')?[_c('div',{staticClass:"mb-3 status-date"},[_vm._v("\n          Pesanan telah diambil pada "+_vm._s(_vm.formatDate(_vm.date))+" - "+_vm._s(_vm.time)+" WIB\n        ")])]:_vm._e()]]:_vm._e(),_vm._v(" "),(_vm.status[0] === 'Belum Bayar')?_c('div',{staticClass:"row mt-3 align-items-center"},[_c('div',{staticClass:"col-lg-4 mb-3 mb-lg-0"},[_c('button',{staticClass:"btn btn-lg btn-outline-danger w-100 px-0",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modalCancelOrder","disabled":_vm.order.cancellation_request}},[_vm._v("\n        Batalkan Pesanan\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('Button',{staticClass:"btn btn-main w-100",attrs:{"text":_vm.isBankTransfer ? 'Konfirmasi Pembayaran' : 'Detail Pembayaran'},on:{"click":function($event){return _vm.toPaymentDetail()}}})],1)]):_vm._e(),_vm._v(" "),(
      (_vm.status[0] === 'Diproses' || _vm.status[0] === 'Selesai') &&
        _vm.method === 'shipping'
    )?_c('div',{staticClass:"row align-items-center mt-3"},[_c('div',{staticClass:"col-lg-4 mb-3 mb-lg-0"},[(_vm.status[0] === 'Diproses')?_c('button',{staticClass:"btn btn-lg btn-outline-danger w-100 px-0",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modalCancelOrder","disabled":_vm.order.cancellation_request}},[_vm._v("\n        Batalkan Pesanan\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('Button',{staticClass:"btn w-100",class:_vm.status[0] === 'Diproses' ? 'btn-grey' : 'btn-main',attrs:{"text":_vm.status[0] === 'Selesai'
            ? 'Lihat Detail Pengiriman'
            : 'Lacak Pesanan',"data-bs-toggle":"modal","data-bs-target":"#modalTracking","disabled":_vm.status[0] !== 'Selesai'}})],1)]):_vm._e(),_vm._v(" "),(_vm.status[0] === 'Dikirim')?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 mb-3 mb-lg-0"},[_c('Button',{staticClass:"btn btn-outline-main w-100",attrs:{"text":"Pesanan Diterima"},on:{"click":function($event){return _vm.handleConfirmOrder()}}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-6"},[_c('Button',{staticClass:"btn btn-main w-100",attrs:{"text":"Lacak Pesanan","data-bs-toggle":"modal","data-bs-target":"#modalTracking"}})],1)]):_vm._e(),_vm._v(" "),(
      _vm.status[0] === 'Siap Diambil' ||
        (_vm.status[0] === 'Diproses' && _vm.method !== 'shipping')
    )?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"font-16 mt-2"},[_vm._v("\n      Anda bisa mengambil barang di\n      "),_c('strong',{staticClass:"color-main"},[_vm._v(_vm._s(_vm.branchName)+" - "+_vm._s(_vm.branchCity))]),_vm._v("\n      mulai dari\n      "),_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.getTimeStore(_vm.schedules)))])]),_vm._v(" "),_c('div',{staticClass:"font-11 text-danger"},[_vm._v("\n      *Batas waktu pengambilan barang maksimal 7 hari dari waktu pembayaran.\n    ")]),_vm._v(" "),_c('client-only',[_c('el-collapse',{staticClass:"mb-2"},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"title-jadwal"},[_vm._v("Lihat Jadwal Toko")])]),_vm._v(" "),_vm._l((_vm.schedules),function(schedule){return _c('div',{key:'Schedule-' + schedule.day,staticClass:"d-flex"},[_c('div',{staticClass:"col-6 text-schedule text-capitalize"},[_vm._v("\n              "+_vm._s(schedule.day)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"col-6 text-end ms-auto pe-2 text-schedule"},[_vm._v("\n              "+_vm._s(schedule.is_open
                  ? ((schedule.open_time.substr(
                    10,
                    6
                  )) + " - " + (schedule.close_time.substr(10, 6)) + " " + (schedule.timezone ? schedule.timezone.toUpperCase() : ""))
                  : "Libur")+"\n            ")])])})],2)],1)],1),_vm._v(" "),(_vm.status[0] === 'Siap Diambil')?_c('div',{staticClass:"col-lg-6 mb-3 mb-lg-0"},[_c('Button',{staticClass:"btn btn-outline-main w-100",attrs:{"text":"Pesanan Diterima"},on:{"click":function($event){return _vm.handleConfirmOrder()}}})],1):_vm._e(),_vm._v(" "),(_vm.status[0] === 'Diproses')?_c('div',{staticClass:"col-lg-4 mb-3 mb-lg-0"},[_c('button',{staticClass:"btn btn-lg btn-outline-danger w-100 px-0",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modalCancelOrder","disabled":_vm.order.cancellation_request}},[_vm._v("\n        Batalkan Pesanan\n      ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-3 mb-lg-0",class:_vm.status[0] === 'Diproses' ? 'col-lg-8' : 'col-lg-6'},[_c('button',{staticClass:"btn btn-green w-100",on:{"click":_vm.handleRedirectWhatsapp}},[_c('img',{staticClass:"me-2 pb-1",style:({ height: '18px' }),attrs:{"src":require("@/static/img/icon/icon-whatsapp-outline.svg")}}),_vm._v("Konfirmasi Pengambilan\n      ")])])],1):_vm._e(),_vm._v(" "),(_vm.status[0] === 'Dibatalkan')?_c('div',{staticClass:"row mt-2 align-items-center"},[_c('div',{staticClass:"col-12 text-capitalize"},[_vm._v("\n      Dibatalkan Oleh: "+_vm._s(_vm.cancelBy[_vm.cancellation.by])+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_vm._v("\n      Alasan: "+_vm._s(_vm.cancellation.reason)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('ModalTrackingOrder'),_vm._v(" "),(
      _vm.status[0] === 'Diproses' ||
        _vm.status[0] === 'Belum Bayar' ||
        !_vm.order.cancellation_request
    )?_c('ModalCancelOrder',{attrs:{"data":_vm.order}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }