import { render, staticRenderFns } from "./AccountLastPurchasedProducts.vue?vue&type=template&id=3dc7937b&scoped=true&"
import script from "./AccountLastPurchasedProducts.vue?vue&type=script&lang=js&"
export * from "./AccountLastPurchasedProducts.vue?vue&type=script&lang=js&"
import style0 from "./AccountLastPurchasedProducts.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AccountLastPurchasedProducts.vue?vue&type=style&index=1&id=3dc7937b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc7937b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardProductNotReviewed: require('/usr/src/app/components/home/base/CardProductNotReviewed.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default})
