//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  layout: 'account',
  middleware: ['user'],

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Beranda',
          path: '/account/home'
        },
        {
          name: '<b>Akun Saya</b>'
        },
        {
          name: 'Profil'
        }
      ]
    }
  },

  computed: {
    ...mapState('Account', ['isEdit']),
    ...mapState('Base', ['dataBreadCrumbs'])
  },

  mounted () {
    this.setBreadCrumbs(this.breadCrumb)
  },

  methods: {
    ...mapMutations('Account', ['setIsEdit']),
    ...mapMutations('Base', ['setBreadCrumbs'])
  },

  beforeRouteLeave (to, from, next) {
    if (this.isEdit) {
      this.$Swal
        .fire({
          html: '<h1 class="font-heading-1 pb-2">Konfirmasi!</h1><div class="font-website-small mb-3">Anda belum menyimpan perubahan.<br/>Apakah anda yakin ingin meninggalkan halaman ini?</div>',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-main px-5 me-auto',
            cancelButton: 'btn btn-outline-main color-main ms-auto me-3 px-5'
          },
          confirmButtonText: 'Keluar',
          cancelButtonText: 'Kembali',
          buttonsStyling: false,
          showCloseButton: true,
          reverseButtons: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.setBreadCrumbs(this.breadCrumb)
            this.setIsEdit()
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  }
}
