//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [mixins],

  data () {
    return {
      catalogListPartner: {
        isLoading: true,
        perPage: 10,
        page: 1,
        totalPage: 0,
        total: 0,
        data: []
      }
    }
  },

  computed: {
    ...mapState('User/address', ['location']),

    isRouteNearbyPartner () {
      return this.$route.name === 'nearby-partner'
    },

    partnerType () {
      return this.$route.query.type || 'store'
    }
  },

  watch: {
    location (newVal, oldVal) {
      if (!oldVal.city_id) {
        return
      }

      this.catalogListPartner.data = []
      this.catalogListPartner.page = 1
      this.catalogListPartner.totalPage = 0
      this.catalogListPartner.total = 0

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },

    '$route.query' (newVal, oldVal) {
      if ((newVal.search === oldVal.search)) {
        return
      }

      this.catalogListPartner.data = []
      this.catalogListPartner.totalPage = 0
      this.catalogListPartner.page = 1
      this.catalogListPartner.total = 0

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },

  methods: {
    handleInfiniteLoading () {
      this.handleGetPartners()
    },

    async handleGetPartners () {
      if (this.isRouteNearbyPartner) {
        await this.getNearestPartners()
      } else {
        await this.getSearchedPartners()
      }
    },

    async getSearchedPartners () {
      this.catalogListPartner.isLoading = true

      const cityId = localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location')).city_id
        : 155

      const payload = {
        per_page: this.catalogListPartner.perPage,
        city_id: cityId,
        page: this.catalogListPartner.page,
        search: this.$route.query.search
      }

      try {
        const resp = await this.$store.dispatch('Partner/getPartners', {
          data: { ...payload },
          setValue: false
        })

        this.catalogListPartner.data = this.catalogListPartner.data.concat(resp.data.data.stores.data)
        this.catalogListPartner.totalPage = resp.data.data.stores.last_page
        this.catalogListPartner.total = resp.data.data.stores.total
        this.catalogListPartner.page++

        if (this.catalogListPartner.page > this.catalogListPartner.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (err) {
        this.$message.error('Gagal mendapatkan data partner')
      }

      this.catalogListPartner.isLoading = false
    },

    async getNearestPartners () {
      this.catalogListPartner.isLoading = true

      const cityId = localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location')).city_id
        : 155

      try {
        const resp = await this.$store.dispatch('Partner/getNearestPartners', {
          data: {
            city_id: cityId,
            where: {
              'stores.type':
                this.partnerType === 'store'
                  ? 'store_partner'
                  : 'brand_partner'
            },
            per_page: this.catalogListPartner.perPage,
            page: this.catalogListPartner.page
          }
        })

        this.catalogListPartner.data = this.catalogListPartner.data.concat(resp.data.data.stores.data)

        this.catalogListPartner.totalPage = resp.data.data.stores.last_page
        this.catalogListPartner.total = resp.data.data.stores.total
        this.catalogListPartner.page++

        if (this.catalogListPartner.page > this.catalogListPartner.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (
          Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (err) {
        this.$message.error('Gagal mendapatkan data partner')
      }

      this.catalogListPartner.isLoading = false
    }
  }
}
