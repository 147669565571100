//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import panstoreMixins from '@/mixins'
import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  mixins: [panstoreMixins],

  data () {
    return {
      datas: [],
      dataLimit: 6,
      loading: true
    }
  },

  async mounted () {
    await this.getPopularSearchProducts()
  },

  methods: {
    ...mapActions('Product', ['getProductSection']),

    async getPopularSearchProducts () {
      try {
        const resp = await this.getProductSection({
          section: 'popular-search',
          payload: {
            data: {
              session_id: this.$cookies.get('session_id')
            }
          }
        })

        this.datas = resp.data.data.products
      } catch (error) {
        this.$message.error('Gagal mendapatkan data pencarian populer produk')
      }

      this.loading = false
    }
  }
}
