import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6feb7bb0&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=6feb7bb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6feb7bb0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,CardCommissionStats: require('/usr/src/app/components/account/affiliate/commission/CardCommissionStats.vue').default,AllCommissionHistory: require('/usr/src/app/components/account/affiliate/commission/AllCommissionHistory.vue').default,ModalChooseWithdrawCommission: require('/usr/src/app/components/account/affiliate/commission/ModalChooseWithdrawCommission.vue').default,ModalSendWithdraw: require('/usr/src/app/components/account/affiliate/commission/ModalSendWithdraw.vue').default,ModalCreateBankAccount: require('/usr/src/app/components/base/ModalCreateBankAccount.vue').default})
