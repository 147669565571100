//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  async asyncData ({ store, params, error }) {
    try {
      const resp = await store.dispatch('Contents/getPages', params.section)
      return {
        breadCrumb: [
          {
            path: '/'
          },
          {
            name: 'Halaman',
            path: '/page'
          },
          {
            name: resp.data.data.page.title
          }
        ],
        dataPage: resp.data.data.page
      }
    } catch (exerror) {
      console.log(exerror)
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
  },
  head () {
    return {
      title: this.dataPage.title + ' | Paninti Store',
      meta: [
        {
          hid: 'og:' + this.dataPage.meta_title + ' | Paninti Store',
          name: 'og:' + this.dataPage.meta_title + ' | Paninti Store',
          content: 'Page ' + this.dataPage.meta_title + ' | Paninti Store'
        },
        {
          hid: 'description',
          name: 'description',
          content: this.dataPage.meta_description
        }
      ]
    }
  }
}
