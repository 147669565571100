//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  layout: 'account',
  middleware: ['user'],
  mixins: [mixins],
  async asyncData ({ store, params, error }) {
    let resp = []
    let breadcrumbs = []
    try {
      store.commit('Order/setDetailOrder', {})
      resp = await store.dispatch('Order/getDetailOrder', params.order_id)
      if (resp.status === 200) {
        breadcrumbs = [
          {
            path: '/'
          },
          {
            name: 'Beranda',
            path: '/account/home'
          },
          {
            name: '<b>Belanja Saya</b>'
          },
          {
            name: 'Pesanan Saya',
            path: '/account/shop/my-order'
          },
          {
            name: '<b>Detail Pesanan</b>'
          },
          {
            name: resp.data.data.order.reference
          }
        ]
      }
    } catch (exerror) {
      console.log(exerror)
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
    await store.commit('Order/resetTracking')
    // if (resp.data.data.order.shipping.recipient_number) {
    //   try {
    //     await store.dispatch('Order/getTrackingOrder', { data: { order_id: resp.data.data.order.id } })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
    return {
      breadCrumb: breadcrumbs
    }
  },
  computed: {
    ...mapState('Order', ['order']),
    orderExist () {
      return this.order && Object.keys(this.order).length
    },
    shippingExist () {
      return this.orderExist && this.order.shipping && Object.keys(this.order.shipping).length
    },
    branchExist () {
      return this.orderExist && this.order.store && Object.keys(this.order.store).length && this.order.store.branch
    },
    status () {
      if (this.checkObject(this.order)) {
        return this.getStatusPesanan(
          this.order.status.slug,
          this.order.order_method
        )
      }

      return null
    },
    noOrder () {
      if (this.orderExist) {
        return this.order.reference
      }

      return undefined
    },
    recipientName () {
      if (this.shippingExist) {
        return this.order.shipping.recipient_name
      }
      return undefined
    },
    branchName () {
      if (this.branchExist) {
        return this.order.store.branch.name
      }

      return undefined
    },
    branchWA () {
      if (this.branchExist) {
        const socmedExist = this.order.store.branch.social_medias && this.order.store.branch.social_medias.length
        const whatsapp = socmedExist ? this.order.store.branch.social_medias.filter((socmed) => {
            return socmed.name === 'whatsapp'
          })
          : []

        return whatsapp && whatsapp.length ? whatsapp[0].value : undefined
      }
      return undefined
    }
  },
  methods: {
    ...mapActions('Order', ['editStatus']),
    ...mapMutations('Order', ['setOrder']),
    handleGoto (id) {
      this.$router.push('/partner/' + id)
    },
    getFormatDate (date, time) {
      const newDate = new Date(date)
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'Nopember',
        'Desember'
      ]
      const dayNames = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu'
      ]
      return `${dayNames[newDate.getDay()]}, ${newDate.getDate()} ${
        monthNames[newDate.getMonth()]
      } ${newDate.getFullYear()} (${time})`
    },
    handleConfirmOrder () {
      this.$Swal
        .fire({
          title: 'Apakah Anda yakin?',
          text: '',
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ya, saya yakin',
          cancelButtonText: 'Batal'
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const resp = await this.editStatus({
                id: this.$route.params.order_id,
                payload: {
                  data: {
                    updated_status: 'transaksi-selesai'
                  }
                }
              })
              if (resp.status === 200) {
                this.$message({
                  showClose: true,
                  message: 'Status berhasil diperbaharui',
                  type: 'success'
                })
                window.location.reload()
              }
            } catch (error) {
              const errorMessage = error.response ? error.response.data.message : error
              this.$Swal
                .fire({
                  icon: 'error',
                  title: 'Gagal memperbaharui status pesanan',
                  text: errorMessage,
                  heightAuto: false,
                  confirmButtonText: 'Tutup'
                })
            }
          }
        })
    },
    handleRedirectWhatsapp () {
      const confirmMessage = this.order.delivery_via_wa_confirmation_message
      const pickupMessage = this.order.pickup_confirmation_message

      const message = this.order.shipping.name === 'Konfirmasi Whatsapp' ? confirmMessage : pickupMessage
      window.open(`https://api.whatsapp.com/send?phone=${this.branchWA}?text=${message}`)
    },
    toPaymentDetail () {
      const orderId = this.order.payment.id
      this.$router.push(`/payment-detail/${orderId}`)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.setOrder({})
    next()
  }
}
