//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixins from '@/mixins'
export default {
  mixins: [panstoreMixins],
  props: {
    section: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputSearch: '',
      sort: 'most_popular',
      perPage: 20,
      onLoading: false,
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapState('Product', ['productAffiliate']),
    listProduct () {
      return this.productAffiliate.data
    }
  },
  async mounted () {
    await this.fetchProductAffiliate()
  },
  methods: {
    ...mapMutations('Product', ['resetProductAffiliate']),
    ...mapActions('Product', ['getProductAffiliate']),
    async handleInfiniteLoading ($state) {
      await this.fetchProductAffiliate()

      if (this.productAffiliate.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async fetchProductAffiliate () {
      this.onLoading = true
      const payload = Object.assign({
        data: {
          paginate: true,
          per_page: this.perPage
        },
        params: {
          page: this.productAffiliate.pagination.nextPage || 1
        }
      });

      if (this.sort || this.inputSearch) {
        payload.data = Object.assign(payload.data, {
          order_by: this.sort,
          search: this.inputSearch
        })
      }

      try {
          await this.getProductAffiliate(payload)
      } catch (error) {
          console.log(error)
      } finally {
          this.onLoading = false
      }
    },
    handleSearch () {
      const value = this.inputSearch
      const input = value.charAt(value.length - 1)
      const letters = /^[A-Za-z\d\-_\s]+$/i
      if (value === ' ') {
        this.inputSearch = ''
      }

      if (!input.match(letters)) {
        this.inputSearch = value.substring(0, value.length - 1)
      }
    },
    handleChangeFilter: _.debounce(function () {
      this.resetProductAffiliate()
      this.onLoading = true
      this.infiniteId++
      this.fetchProductAffiliate()
    }, 500),
    handleChangeSort () {
      this.resetProductAffiliate()
      this.onLoading = true
      this.infiniteId++
      this.fetchProductAffiliate()
    },
    handleDeleteAffiliateItem (id) {
      this.resetProductAffiliate()
      this.onLoading = true
      this.infiniteId++
      this.fetchProductAffiliate()
    }
  }
}
