var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-wrapper position-relative"},[(_vm.phone)?[_c('div',{staticClass:"banner",staticStyle:{"position":"absolute","background":"rgba(0, 0, 0, 0.3)","z-index":"1"}}),_vm._v(" "),_c('img',{staticClass:"img-fluid banner position-absolute",attrs:{"src":_vm.banner.length
          ? _vm.banner[0].value + '.webp'
          : function () {
                      var args = [], len = arguments.length;
                      while ( len-- ) args[ len ] = arguments[ len ];

                      return _vm.getDefaultCoverProfile.apply(
              void 0, args.concat( [_vm.$route.name === 'partner-id' ? 'store' : 'brand'] )
            );
},"alt":"Banner"},on:{"error":function () {
                    var args = [], len = arguments.length;
                    while ( len-- ) args[ len ] = arguments[ len ];

                    return _vm.getDefaultCoverProfile.apply(
            void 0, args.concat( [_vm.$route.name === 'partner-id' ? 'store' : 'brand'] )
          );
}}})]:_vm._e(),_vm._v(" "),(_vm.desktop)?[_c('img',{staticClass:"img-fluid banner",attrs:{"src":_vm.banner.length
          ? _vm.banner[0].value + '.webp'
          : function () {
                      var args = [], len = arguments.length;
                      while ( len-- ) args[ len ] = arguments[ len ];

                      return _vm.getDefaultCoverProfile.apply(
              void 0, args.concat( [_vm.$route.name === 'partner-id' ? 'store' : 'brand'] )
            );
},"alt":"Banner"},on:{"error":function () {
                    var args = [], len = arguments.length;
                    while ( len-- ) args[ len ] = arguments[ len ];

                    return _vm.getDefaultCoverProfile.apply(
            void 0, args.concat( [_vm.$route.name === 'partner-id' ? 'store' : 'brand'] )
          );
}}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }