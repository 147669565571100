//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapState } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  props: {
    branch: {
      type: Array,
      default: null
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('Checkout', [
      'storeBranch',
      'storeBranchPAS',
      'selectedAddress',
      'branchSelected',
      'pageTakeInStore',
      'usePoint'
    ]),
    ...mapState('Voucher', ['voucherUsed', 'voucher']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    branchMoreThanThree () {
      return this.branch.length > 3
    }
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBranch', 'setUnshiftBranch']),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    handleBranchSelected (index) {
      this.setSelectedBranch(index)
      this.handleSubmitBranch()
    },
    async handleSubmitBranch () {
      if (this.branchMoreThanThree) {
        this.setUnshiftBranch(this.branch[this.branchSelected])
        this.setSelectedBranch(0)
      }
      await this.checkAvailibility(
        this.selectedAddress.id,
        this.getDataBranch()[this.branchSelected].id
      )
      this.$refs.closeEditBranch.click()
    },
    async checkAvailibility (userAddressId, storeId) {
      try {
        const resp = await this.checkAvailibilities(
          JSON.parse(localStorage.cartItem),
          storeId,
          userAddressId,
          null,
          null,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.usePoint
        )

        const vouchers = resp.data.data.checkout.vouchers
        this.checkActiveOnlyVoucher(vouchers)
      } catch (error) {
        console.log(error)
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    }
  }
}
