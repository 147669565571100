//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    },
    homepage: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      let cls = ''

      if (this.variant) {
        switch (this.variant) {
          case 'red':
            cls += 'badge-red'
            break
          case 'blue':
            cls += 'badge-blue'
            break
          case 'pink':
            cls += 'badge-pink'
            break
          case 'green':
            cls += 'badge-green'
            break
        }

        if (this.filled) {
          cls += '-filled'
        }

        if (this.size) {
          cls += ` badge--${this.size}`
        }

        if (!this.border) {
          cls += ' border-none'
        }
      } else {
        switch (this.type) {
          case 'brand':
            cls = 'badge-partner badge-brand'
            break
          case 'store':
            cls = 'badge-partner badge-store'
            break
          default:
            break
        }

        if (this.homepage) {
          cls += ' homepage'
        }

        if (this.icon) {
          cls += ' icon'
        }
      }

      return cls
    }
  }
}
