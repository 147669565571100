//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions } from 'vuex'

export default {
  mixins: [mixins],

  props: {
    articleLoaded: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      request_sectionValues: false,
      windowWidth: process.client && window ? window.innerWidth : 0,
      windowHeight: process.client && window ? window.innerHeight : 0,
      loadingBanner: true,
      loadingArticle: true
    }
  },

  computed: {
    ...mapState('Contents', ['parentingHubBanner', 'articleParentingHub']),

    active_link () {
      let link = '#'

      if (this.normalDesktop) {
        link = this.ph_n_desktop ? this.ph_n_desktop.link : '#'
      } else if (this.normalPhone) {
        link = this.ph_n_phone ? this.ph_n_phone.link : '#'
      }

      return link
    },

    ph_n_desktop () {
      return this.parentingHubBanner.filter((sv) => {
        return sv.display_type === 'normal desktop'
      })[0]
    },

    ph_n_phone () {
      return this.parentingHubBanner.filter((sv) => {
        return sv.display_type === 'normal phone'
      })[0]
    },

    parentingHubArticleCount () {
      let articleCount = 4

      if (this.windowWidth < 755) {
        articleCount = 4
      } else if (this.windowWidth < 1170) {
        articleCount = 3
      }

      return articleCount
    },

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },

    normalDesktop () {
      return this.windowWidth >= 992
    }
  },

  watch: {
    articleParentingHub () {
      setTimeout(() => (this.loadingArticle = false), 1000)
    },

    articleLoaded () {
      if (this.articleLoaded) {
        this.loadingArticle = false
      }
    }
  },

  mounted () {
    this.getParentingHubBanners()

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    if (this.articleLoaded) {
      this.loadingArticle = false
    }
  },

  methods: {
    ...mapActions('Contents', ['getParentingHubBanner']),

    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },

    async getParentingHubBanners () {
      this.loadingBanner = true

      try {
        await this.getParentingHubBanner()
      } catch (error) {
        this.$message.error('Gagal mendapatkan data banner parenting hub')
      }

      this.loadingBanner = false
    },

    handleOnClickBannerParentingHub () {
      this.$gtag('event', 'click', {
        event_category: 'Banner',
        event_label: 'Banner Parenting Hub Homepage Clicked',
        value: this.active_link || ''
      })
    }
  }
}
