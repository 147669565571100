//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      page: 1,
      totalPage: 0,
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Checkout', [
      'pageTakeInStore',
      'selectedAddress',
      'selectedCourier',
      'branchSelected',
      'storeBranch',
      'storeBranchPAS',
      'selectedBank',
      'listPaymentMethods',
      'usePoint'
    ]),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    ...mapState('User', ['accounts'])
  },
  mounted () {
    this.initComponent()
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBank']),
    ...mapMutations('User', ['setCreateCC']),
    ...mapActions('User', ['getPayment']),
    async handleSubmit (item) {
      try {
        const resp = await this.checkAvailibilities(
          JSON.parse(localStorage.cartItem),
          this.getDataBranch()[this.branchSelected].id,
          this.selectedAddress.id,
          this.selectedCourier.code,
          this.selectedCourier.service,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.usePoint,
          {
            payment_method_id:
              JSON.parse(JSON.stringify(this.listPaymentMethods)).filter(
                (bank) => {
                  return bank.type.code === 'credit_card'
                }
              )[0].id || '',
            account_id: item.id
          }
        )
        if (resp.status === 200) {
          this.handleNext(item)
        }
      } catch (error) {
        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: error,
          heightAuto: false
        })
      }
    },
    handleNext (item) {
      const dataCC = JSON.parse(JSON.stringify(this.listPaymentMethods)).filter(
        (bank) => {
          return bank.type.code === 'credit_card'
        }
      )
      dataCC[0].create_new = false
      dataCC[0].accounts.push(item)
      dataCC[0].name = `${dataCC[0].name} (${item.value.account_number})`
      this.setSelectedBank(dataCC)
      this.$refs.closeModal.click()
    },
    async initComponent () {
      try {
        const resp = await this.getPayment({
          data: {
            paginated: true,
            per_page: 10,
            page: this.page,
            where: {
              type: 'Credit Card'
            }
          }
        })
        if (resp.status === 200) {
          if (this.page === 1) {
            this.totalPage = resp.data.data.accounts.last_page
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    showLabel (value) {
      let str = ''
      const count = value.length
      if (value) {
        str = '**** ' + value.substring(count - 4, count)
      } else {
        str = value
      }
      return str
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    },

    handleAddNew () {
      this.setCreateCC(true)
      setTimeout(() => this.setCreateCC(false), 300)
    }
  }
}
