//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      videoPlayed: false,
      indexActiveVideo: 0
    }
  },
  computed: {
    ...mapState('Files', ['video'])
  },
  methods: {
    changeVideo (i) {
      i = this.indexActiveVideo = i
    }

  }
}
