//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('Product', ['mainProducts']),
    haveWishlist () {
      return this.mainProducts.length > 0
    }
  },
  created () {
    this.getHomeProducts()
  },
  methods: {
    ...mapActions('Product', ['getHomeProducts'])
  }
}
