//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [mixins],
  computed: {
    ...mapState('Contents', ['popup']),
    voucher () {
      return (
        Object.keys(this.popup).length &&
        this.popup.promotion &&
        this.popup.promotion.type === 'voucher'
      )
    }
  },
  watch: {
    popup () {
      if (
        Object.keys(this.popup).length &&
        this.popup.promotion &&
        this.popup.promotion.show_popup &&
        this.getImage(this.popup.promotion.files)
      ) {
        this.$refs.buttonOpenPopup.click()
        this.handleClosePopup()
      }
    }
  },
  mounted () {
    this.getPopup()
  },
  methods: {
    ...mapActions('Contents', ['getPopup']),
    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),
    async handleClosePopup () {
      try {
        await this.getPopup({
          data: {
            closed: true
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    handleClickPopup () {
      this.$refs.closePopup.click()
      if (this.popup.promotion.type !== 'voucher') {
        const type = {
          sale: 'sale',
          clearance_sale: 'clearance-sale',
          event_sale: this.popup.promotion.event_sales ? `sale/${this.popup.promotion.event_sales.slug}` : '#',
          flash_sale: 'flashsale'
        }
        if (this.popup.promotion.type !== 'external') {
          this.$router.push(`/${type[this.popup.promotion.type]}`)
        } else {
          window.open(this.popup.promotion.link, '_blank')
        }
      } else {
        this.setVoucher(this.popup.promotion.vouchers)
        this.setToggleDetail(true)
        this.$refs.showModalVoucher.click()
      }
    },
    getImage (files) {
      let img = ''
      const temp = files.filter((file) => {
        return file.name === 'banner_popup'
      })
      if (temp.length) {
        img = temp[0].value + '.webp'
      }
      return img
    }
  }
}
