//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  name: 'CardPartnerAffiliate',
  mixins: [panstoreMixin],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    showTotalSold: {
      type: Boolean,
      default: false
    }
  }
}
