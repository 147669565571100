//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    title: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      listVoucher: {
        perPage: 3,
        page: 1,
        total: 0,
        data: []
      },

      listVoucherAll: {
        perPage: 1,
        page: 1,
        total: 0,
        data: []
      }
    }
  },

  computed: {
    ...mapState('Partner', ['partner']),

    userLoggedIn () {
      return this.$store.state.auth.user
    }
  },

  mounted () {
    if (this.userLoggedIn) {
      this.getListVouchers()
    }
  },

  methods: {
    ...mapActions('Voucher', ['getVouchers']),

    showModalListVoucherProfile () {
      if (this.userLoggedIn) {
        this.getListAllVouchers()
      }
    },

    async getListVouchers () {
      const resp = await this.handleGetVouchers({
        data: {
          paginated: true,
          partner_id: [this.partner.id],
          page: this.listVoucher.page,
          per_page: this.listVoucher.perPage
        }
      })

      if (resp) {
        this.listVoucher.data = resp.data.data.vouchers.data
        this.listVoucher.total = resp.data.data.vouchers.total
      }
    },

    async getListAllVouchers () {
      const resp = await this.handleGetVouchers({
        data: {
          paginated: true,
          partner_id: [this.partner.id],
          page: this.listVoucherAll.page,
          per_page: this.listVoucher.total
        }
      })

      if (resp) {
        this.listVoucherAll.data = resp.data.data.vouchers.data
        this.listVoucherAll.total = resp.data.data.vouchers.total
      }
    },

    async handleGetVouchers (payload) {
      let resp

      try {
        resp = await this.getVouchers(payload)
      } catch (error) {
        this.$message.error('Gagal mendapatkan data voucher partner')
      }

      return resp
    }
  }
}
