//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  name: 'CategoryLevel1',
  mixins: [panstoreMixin],
  async asyncData ({ store, params, query, error }) {
    const breadCrumbs = [
      {
        path: '/'
      }
    ]
    let metaData = {
      title: 'Paninti Store',
      meta_description: 'Paninti Store'
    }
    let category = {}

    try {
      const respCat = await store.dispatch('Category/getCategory', params.slug)

      metaData = Object.assign({
        title: respCat.data.data.category.meta_title,
        meta_description: respCat.data.data.category.meta_description
      })
      breadCrumbs.push({
        name: respCat.data.data.category.name
      })
      category = respCat.data.data.category
    } catch (err) {
      if (err.response && err.response.status === 404) {
        error({
          statusCode: 404,
          image: require('@/static/img/error/404.svg'),
          message: 'Kategori tidak ditemukan',
          subMessage: 'Maaf kategori yang dimaksud tidak ada. Mohon untuk kembali ke beranda',
          type: 'category-not-found'
        })
      } else {
        error({
          statusCode: err.response ? err.response.status : 500
        })
      }
    }

    let metaScript = []

    try {
      const respMetaData = await store.dispatch(
        'Category/getMetaCategory',
        params.slug
      )

      if (respMetaData.status === 200) {
        if (respMetaData.data.data.meta_scripts) {
          metaScript = respMetaData.data.data.meta_scripts
        }
      }
    } catch (error) {
      console.log(error)
    }

    return {
      category,
      metaScript,
      breadCrumbs,
      metaData
    }
  },

  data () {
    return {
      isLeave: false,
      totalProduct: 0
    }
  },

  methods: {
    onTotalProductChanged (value) {
      this.totalProduct = value
    }
  },

  head () {
    return {
      title: this.metaData.title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.metaData.title
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.metaData.title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.metaData.meta_description
        },
        {
          hid: 'description',
          name: 'description',
          content: this.metaData.meta_description
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.category ? this.categorySEOLogoImage(this.category.files) : ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.category ? this.categoryMetaKeyword(this.category.meta_keywords, this.category.name) : ''
        }
      ],
      script: this.metaScript || []
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.name !== from.name) {
      this.isLeave = true
    }
    next()
  }
}
