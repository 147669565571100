//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  name: 'CardCommissionStats',
  mixins: [panstoreMixin],
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    indicatorImage () {
      let image = null
      switch (this.data.trendStatus) {
        case 'constant':
          image = '/img/icon/icon-constant-blue.svg'
          break
        case 'up':
          image = '/img/icon/icon-up-green.svg'
          break
        case 'down':
          image = '/img/icon/icon-down-red.svg'
          break
      }

      return image
    },
    classCard () {
      let classes = 'general'

      if (!this.data.general) {
        switch (this.data.trendStatus) {
          case 'constant':
            classes = 'constant'
            break
          case 'up':
            classes = 'green'
            break
          case 'down':
            classes = 'red'
            break
        }
      }

      return classes
    }
  }
}
