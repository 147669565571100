import { render, staticRenderFns } from "./PopupBankList.vue?vue&type=template&id=60fc3059&scoped=true&"
import script from "./PopupBankList.vue?vue&type=script&lang=js&"
export * from "./PopupBankList.vue?vue&type=script&lang=js&"
import style0 from "./PopupBankList.vue?vue&type=style&index=0&id=60fc3059&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fc3059",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default})
