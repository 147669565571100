import { render, staticRenderFns } from "./ModalDetailWithdrawCommission.vue?vue&type=template&id=16023c12&"
import script from "./ModalDetailWithdrawCommission.vue?vue&type=script&lang=js&"
export * from "./ModalDetailWithdrawCommission.vue?vue&type=script&lang=js&"
import style0 from "./ModalDetailWithdrawCommission.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,Badge: require('/usr/src/app/components/base/Badge.vue').default,Stepper: require('/usr/src/app/components/base/Stepper.vue').default})
