var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.haveBrands)?_c('div',{staticClass:"brand-sale-section-container"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"brand-sale-carousel-container"},[_c('client-only',[_c('carousel',{staticClass:"brand-sale-carousel",attrs:{"navigation-enabled":_vm.brands && _vm.brands.length > 8,"pagination-enabled":false,"navigation-prev-label":_vm.sliderArrow.left,"navigation-next-label":_vm.sliderArrow.right,"per-page":8,"per-page-custom":[
          [320, 3],
          [576, 4],
          [768, 6],
          [992, 8] ]}},_vm._l((_vm.brands),function(item,index){return _c('slide',{key:index},[_c('div',{staticClass:"brand-sale-carousel-item d-flex flex-column"},[_c('div',{staticClass:"brand-sale-carousel-item-logo-container mx-auto"},[_c('img',{staticClass:"brand-sale-carousel-item-logo",attrs:{"src":_vm.getThumbnailImage(item.files || [], 'brand'),"alt":'Brand-' + item.name},on:{"click":function($event){return _vm.handleGoToBrandPage(item.slug)},"error":function () {
                var args = [], len = arguments.length;
                while ( len-- ) args[ len ] = arguments[ len ];

                return _vm.getDefaultImgProfile.apply(void 0, args.concat( ['brand'] ));
        }}})]),_vm._v(" "),_c('div',{staticClass:"brand-sale-carousel-item-title"},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])])])}),1)],1)],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-sale-title-container"},[_c('h2',{staticClass:"brand-sale-title"},[_vm._v("\n      Brand Sedang Diskon\n    ")])])}]

export { render, staticRenderFns }