//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    dataBanner: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0,
      loading: false
    }
  },
  computed: {
    banner_desktop () {
      return this.dataBanner.length
        ? this.dataBanner[0].files.filter((file) => {
          return file.name === 'banner'
        })[0]
        : {}
    },
    banner_phone () {
      return this.dataBanner.length
        ? this.dataBanner[0].files.filter((file) => {
          return file.name === 'banner_mobile'
        })[0]
        : {}
    },

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    normalDesktop () {
      return this.windowWidth >= 992
    }
  },
  watch: {
    banner_desktop () {
      this.setLoading()
    },
    banner_phone () {
      this.setLoading()
    }
  },
  mounted () {
    this.setLoading()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },
    setLoading () {
      this.loading =
        Object.keys(this.banner_desktop).length ||
        Object.keys(this.banner_phone).length
      if (!this.dataBanner.length) {
        this.loading = true
      }
    }
  }
}
