//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    return {
      onSubmit: false,
      formChangePassword: {
        token: this.$route.params.token,
        password: '',
        password_confirmation: ''
      },
      errorPassword: '',
      errorCPassword: '',
      toggleShowHide: true,
      toggleShowHideCP: true,
      iconEye: '/img/icon/eye-solid.svg',
      iconEye2: '/img/icon/eye-solid.svg'
    }
  },
  methods: {
    ...mapActions('Auth', ['resetPassword']),
    showHidePassword () {
      this.toggleShowHide = !this.toggleShowHide
      if (this.toggleShowHide === true) {
        this.iconEye = '/img/icon/eye-solid.svg'
        document.getElementById('inputPassword').type = 'password'
      } else {
        this.iconEye = '/img/icon/eye-slash-solid.svg'
        document.getElementById('inputPassword').type = 'text'
      }
    },
    showHideCPassword () {
      this.toggleShowHideCP = !this.toggleShowHideCP
      if (this.toggleShowHideCP === true) {
        this.iconEye2 = '/img/icon/eye-solid.svg'
        document.getElementById('inputCPassword').type = 'password'
      } else {
        this.iconEye2 = '/img/icon/eye-slash-solid.svg'
        document.getElementById('inputCPassword').type = 'text'
      }
    },
    handleSubmitChangePasswordForm () {
      const _this = this
      const valid = !(this.errorPassword || this.errorCPassword)
      if (valid) {
        _this.submitChangePasswordForm()
      } else {
        /* eslint-disable */
        if (!this.formChangePassword.password) {
          this.errorPassword = "Password wajib diisi";
        }
        if (!this.formChangePassword.password_confirmation) {
          this.errorPassword = "Konfirmasi Password wajib diisi";
        }
      }
    },
    async submitChangePasswordForm() {
      this.onSubmit = true;
      try {
        const resp = await this.resetPassword({
          data: this.formChangePassword,
        });
        if (resp.status === 200) {
          await this.$Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Password berhasil diubah!",
          });
          window.location = this.$route.query.redirect;
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error;
        this.$Swal.fire({
          icon: "error",
          title: "Oops, ada kesahalan.",
          text: errorMessage,
          heightAuto: false,
        });
      }
      this.onSubmit = false;
    },

    handleChangePassword() {
      const value = this.formChangePassword.password;
      const input = value.charAt(value.length - 1);
      if (value === " ") {
        this.formChangePassword.password = "";
      }
      if (input === " ") {
        this.formChangePassword.password = value.substring(0, value.length - 1);
      }
      if (value.length >= 100) {
        this.errorPassword = "Maksimal 100 karakter";
        this.formChangePassword.password = value.substring(0, value.length - 1);
      } else {
        this.errorPassword = "";
      }
    },

    handleChangeCPassword() {
      const value = this.formChangePassword.password_confirmation;
      const input = value.charAt(value.length - 1);
      if (value === " ") {
        this.formChangePassword.password_confirmation = "";
      }
      if (input === " ") {
        this.formChangePassword.password_confirmation = value.substring(
          0,
          value.length - 1
        );
      }
      if (value.length >= 100) {
        this.errorCPassword = "Maksimal 100 karakter";
        this.formChangePassword.password_confirmation = value.substring(
          0,
          value.length - 1
        );
      } else {
        this.errorCPassword = "";
      }
    },

    checkPassword() {
      this.errorPassword = "";
      if (this.formChangePassword.password === "") {
        this.errorPassword = "Password wajib diisi";
      }
      if (this.formChangePassword.password.length >= 100) {
        this.errorPassword = "Maksimal 100 karakter";
        this.formChangePassword.password =
          this.formChangePassword.password.substring(0, 100);
      } else {
        this.errorPassword = "";
      }
    },
    checkCPassword() {
      this.errorCPassword = "";
      if (this.formChangePassword.password_confirmation === "") {
        this.errorCPassword = "Password wajib diisi";
      }
      if (this.formChangePassword.password_confirmation.length >= 100) {
        this.errorCPassword = "Maksimal 100 karakter";
        this.formChangePassword.password_confirmation =
          this.formChangePassword.password_confirmation.substring(0, 100);
      } else {
        this.errorCPassword = "";
      }
    },
  },
};
