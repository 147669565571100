//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('Cart', ['cart', 'isLoading']),
    getTotalProductCart () {
      let qtyCart = 0
      if (this.$route.name === 'cart') {
        this.cart.map((store) => {
          store.cart_items.map((cart) => {
            qtyCart += Number(cart.quantity)
          })
        })
      }
      return qtyCart
    }
  },
  mounted () {
    setTimeout(() => (this.setLoading(false)), 2000)
  },
  methods: {
    ...mapMutations('Cart', ['setLoading'])
  }
}
