//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapState, mapActions } from 'vuex'
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  async fetch () {
    try {
      // review
      try {
        const respRat = await this.$store.dispatch(
          'Product/getProductReviews',
          {
            id: this.$route.params.slug,
            payload: {
              data: {
                per_page: 5,
                page: 1,
                order_by: {
                  created_at: 'desc'
                }
              }
            }
          }
        )
        if (respRat.status === 200) {
          const data = respRat.data.data.rating_accumulation
          const rating = []
          for (let i = Object.keys(data).length; i > 0; i--) {
            rating.push({
              count: data[`${i}`],
              progress:
                  (data[`${i}`] / respRat.data.data.reviews.total) * 100 || 0
            })
          }
          this.$store.dispatch('Product/setRatingAccumulation', rating)
        }
      } catch (error) {
        console.log(error)
      }
      try {
        await this.$store.dispatch('Product/getProductReviewFiles', {
          id: this.$route.params.slug,
          payload: {
            data: {
              per_page: 6,
              page: 1,
              order_by: {
                created_at: 'desc'
              }
            }
          }
        })
      } catch (error) {
        console.log(error)
      }

      if (this.$auth.loggedIn) {
        const resp = await this.getReviewSuggestions()
        if (resp.status === 200) {
          resp.data.data.review_suggestions.map((suggestion) => {
            this.optFilter.push({
              title: suggestion.title,
              value: suggestion.id,
              icon: false,
              payload: {
                where_has: [
                  {
                    relation: 'suggestions',
                    where_in: [['id', [suggestion.id]]]
                  }
                ]
              }
            })
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  data () {
    return {
      selectedFilter: 0,
      customColors: '#FFCF44',
      optFilter: [
        {
          title: 'Semua',
          value: 'all',
          icon: false,
          payload: null
        },
        {
          title: 'Dengan Foto',
          value: 'with_medias',
          icon: false,
          payload: {
            condition: 'with_medias'
          }
        },
        {
          title: 'Dengan Komentar',
          value: 'with_comments',
          icon: false,
          payload: {
            condition: 'with_comments'
          }
        },
        {
          title: '5',
          value: '5',
          icon: true,
          payload: {
            where: {
              rating: 5
            }
          }
        },
        {
          title: '4',
          value: '4',
          icon: true,
          payload: {
            where: {
              rating: 4
            }
          }
        },
        {
          title: '3',
          value: '3',
          icon: true,
          payload: {
            where: {
              rating: 3
            }
          }
        },
        {
          title: '2',
          value: '2',
          icon: true,
          payload: {
            where: {
              rating: 2
            }
          }
        },
        {
          title: '1',
          value: '1',
          icon: true,
          payload: {
            where: {
              rating: 1
            }
          }
        }
      ],
      page: 1,
      visible: false,
      indexImg: 0,
      indexReviews: 0,
      type: 'gallery'
    }
  },
  computed: {
    ...mapState('Product', [
      'productReviews',
      'productReviewGallery',
      'product',
      'ratingAccumulation'
    ]),
    imgReview () {
      const img = []
      if (this.type === 'gallery') {
        if (Object.keys(this.productReviewGallery).length) {
          this.productReviewGallery.data.map((data) => {
            data.variant === 'thumbnail' && img.push(data.value)
          })
        }
      } else {
        const review = this.productReviews.reviews.data[this.indexReviews];
          if (review && review.files) {
            review.files.filter((file) => {
              file.variant === 'original' && img.push(file.value);
            });
          }
      }
      return img
    }
  },
  methods: {
    ...mapActions('Product', ['getProductReviews', 'getReviewsByPage']),
    ...mapActions('Order', ['getReviewSuggestions']),
    getAvatarImage (files, gender) {
      if (files.length) {
        const img = files.filter((file) => {
          return file.name === 'avatar' && file.variant === 'thumbnail'
        })
        if (img.length) {
          return img[0].value + '.webp'
        } else {
          return this.getDefaultAvatar(gender || 'male')
        }
      } else {
        return this.getDefaultAvatar(gender || 'male')
      }
    },
    getThumbnail (files) {
      return files.filter((file) => {
        return file.variant === 'thumbnail'
      })
    },
    expandedToggle (index) {
      this.$refs['desc-' + index][0]._props.expanded =
          !this.$refs['desc-' + index][0]._props.expanded
    },
    async handleFilter (index, filter, newPage) {
      this.selectedFilter = index
      const payload = Object.assign({
        ...filter,
        page: newPage,
        per_page: 5,
        order_by: {
          created_at: 'desc'
        }
      })
      try {
        await this.getProductReviews({
          id: this.$route.params.slug,
          payload: {
            data: payload
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    handleNavigation (type) {
      this.page = type
      this.handleFilter(
        this.selectedFilter,
        this.optFilter[this.selectedFilter].payload,
        type
      )
    },
    showImage (indexImg, type, indexData) {
      this.indexImg = indexImg
      this.visible = true
      this.type = type
      if (type !== 'gallery') {
        this.indexReviews = indexData
      }
    }
  }
}
