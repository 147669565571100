//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions } from 'vuex'

export default {
  mixins: [mixins],

  props: {
    slider: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      lastPurchaseProducts: {
        isLoading: true,
        page: 1,
        perPage: 10,
        data: []
      },

      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      }
    }
  },

  async mounted () {
    await this.handleGetProduct()
  },

  methods: {
    ...mapActions('Product', ['getProductNotReviewed']),

    async handleGetProduct () {
      this.lastPurchaseProducts.isLoading = true

      try {
        const resp = await this.getProductNotReviewed({
          data: {
            page: this.lastPurchaseProducts.page,
            per_page: this.lastPurchaseProducts.perPage,
            order_by: {
              'order_products.created_at': 'desc'
            }
          }
        })

        this.lastPurchaseProducts.data = resp.data.data.order_products.data
        this.lastPurchaseProducts.page++
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk terakhir dibeli')
      } finally {
        this.lastPurchaseProducts.isLoading = false
      }
    }
  }
}
