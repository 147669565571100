//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    section: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      page: 1,
      onLoading: false,
      total: 0,
      totalPage: 0,
      perPage: 20
    }
  },

  computed: {
    ...mapState('Product', ['mainProducts']),
    ...mapState('User/address', ['location']),

    otherSection () {
      return (
        [
          'today-promotion',
          'on-sale-brand-partner',
          'on-sale-store-partner'
        ].includes(this.$route.params.section) || false
      )
    }
  },

  watch: {
    location (newVal, oldVal) {
      if (newVal.cityId !== oldVal.cityId || !oldVal.cityId) {
        return
      }

      this.$refs.InfiniteLoading.stateChanger.reset()
      this.page = 1
      this.totalPage = 0

      this.handleGetProducts()
    },

    '$route.query' (newVal, oldVal) {
      if (this.$route.query.show_all) {
        return
      }

      this.$refs.InfiniteLoading.stateChanger.reset()
      this.page = 1
      this.totalPage = 0

      this.handleGetProducts()
    },

    total () {
      this.$emit('total-product-changed', this.total)
    }
  },

  mounted () {
    this.handleGetProducts()
  },

  methods: {
    ...mapMutations('Product', ['setTotalProducts']),

    handleInfiniteLoading () {
      this.handleGetProducts()
    },

    getPayload () {
      // initial query
      let brands = []
      let category = []
      let colors = []
      let sizes = []
      let priceLimit = []
      let rating = []
      let search = []
      let partner = ''
      let sort = ''
      let location = []

      if (this.$route.query.brands) {
        brands = this.$route.query.brands.split('::')
      }

      if (this.$route.query.categories) {
        category = this.$route.query.categories.split('::')
      }

      if (this.$route.query.colors) {
        colors = this.$route.query.colors.split('::')
      }

      if (this.$route.query.sizes) {
        sizes = this.$route.query.sizes.split('::')
      }

      if (this.$route.query.priceLimit) {
        priceLimit = this.$route.query.priceLimit.split('::')
      }

      if (this.$route.query.city) {
        location = this.$route.query.city.split('::')
      }

      rating = this.$route.query.rating
        ? this.$route.query.rating.split('::').reverse()
        : []
      search = this.$route.query.search ? this.$route.query.search : ''
      partner = this.$route.query.partner ? this.$route.query.partner : ''
      sort = this.$route.query.sort ? this.$route.query.sort : ''

      // payload
      let payload = {
        page: this.page,
        per_page: this.perPage
      }

      const orWhereHas = []
      if (brands.length) {
        orWhereHas.push({
          relation: 'brand',
          where_in: [['slug', brands]]
        })
      }

      if (category.length) {
        payload = Object.assign({
          ...payload,
          category_slug: category
        })
      }

      let variants = []
      if (colors.length && sizes.length) {
        variants = colors.concat(sizes)
      } else {
        if (colors.length) {
          variants = colors
        }
        if (sizes.length) {
          variants = sizes
        }
      }

      if (
        this.$route.query.priceLimit &&
        this.$route.query.priceLimit !== '0::10000000'
      ) {
        payload = Object.assign({
          ...payload,
          where_greater_than: {
            price: priceLimit[0]
          },
          where_lower_than: {
            price: priceLimit[1]
          }
        })
      }

      if (location.length) {
        payload = Object.assign({
          ...payload,
          city: location
        })
      }

      if (rating.length) {
        payload = Object.assign({
          ...payload,
          where_rating: rating
        })
      }

      if (partner.length) {
        payload = Object.assign({
          ...payload,
          where_has: [
            {
              relation: 'store',
              where: {
                'stores.slug': partner
              }
            }
          ]
        })
      }

      if (sort) {
        if (sort === 'most_expensive') {
          payload = Object.assign({
            ...payload,
            order_by: {
              price: 'desc'
            }
          })
        } else if (sort === 'cheapest') {
          payload = Object.assign({
            ...payload,
            order_by: {
              price: 'asc'
            }
          })
        } else if (sort === 'best_seller') {
          payload = Object.assign({
            ...payload,
            order_by: {
              total_sold_last_month: 'desc'
            }
          })
        } else if (sort === 'lastest') {
          payload = Object.assign({
            ...payload,
            order_by: {
              created_at: 'desc'
            }
          })
        }
      }

      if (search) {
        payload = Object.assign({
          ...payload,
          search
        })
      }

      if (variants.length) {
        orWhereHas.push({
          relation: 'details',
          where_has: [
            {
              relation: 'variants',
              where_in: [['variants.name', variants]]
            }
          ]
        })
      }

      if (orWhereHas.length) {
        payload = Object.assign({
          ...payload,
          or_where_has: orWhereHas
        })
      }

      if (this.$route.query.event_sale) {
        payload = Object.assign({
          ...payload,
          event_sale: this.$route.query.event_sale
        })
      }

      return payload
    },

    async handleGetProducts () {
      this.onLoading = true
      let tempPayload = this.getPayload()

      if (!this.$route.query.city) {
        delete tempPayload.location

        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155
        tempPayload = Object.assign({
          ...tempPayload,
          city_id: cityId
        })
      }

      let payload = {
        data: {
          ...tempPayload,
          page: this.page
        }
      }

      if (this.otherSection) {
        payload = {
          payload,
          section: this.$route.params.section
        }
      }

      try {
        const resp = await this.$store.dispatch(
          'Product/' + this.section,
          payload
        )

        this.totalPage = resp.data.data.products.last_page
        this.total = resp.data.data.products.total
        this.setTotalProducts(this.total)
        this.page++

        if (this.page > this.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (error) {
        this.$message.error('Gagal mendapatkan data catalog produk')
      }

      this.onLoading = false
    },

    handleResetQuery () {
      this.$router.replace({})
    }
  }
}
