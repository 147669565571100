//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import marketplaceMixin from '@/mixins'
export default {
  mixins: [marketplaceMixin],
  props: {
    share: {
      type: Boolean,
      default () {
        return false
      }
    },
    quote: {
      type: String,
      default () {
        return ''
      }
    },
    partnerData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      medias: [
        {
          name: 'line',
          icon: '/img/icon/icon-share-line.svg'
        },
        {
          name: 'facebook',
          icon: '/img/icon/icon-share-facebook.svg'
        },
        {
          name: 'twitter',
          icon: '/img/icon/icon-share-twitter.svg'
        }
      ]
    }
  },
  computed: {
    productUrl () {
      return this.checkObject(this.partnerData) ? this.partnerData.share_link : ''
    },
    shareText () {
      return this.checkObject(this.partnerData) ? this.partnerData.share_text : ''
    }
  },
  methods: {
    handleAlert () {
      this.$message({
        message: 'Tautan berhasil disalin ke clipboard',
        type: 'success'
      })
    }
  }
}
