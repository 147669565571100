//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Order', ['order']),
    isAvailable () {
      let valid = false
      if (Object.keys(this.order).length) {
        this.order.products.filter((product) => {
          if (product.reorder_message.type === 'available') {
            valid = true
          }
        })
      }
      return valid
    }
  },
  methods: {
    ...mapMutations('Order', ['setOrder']),
    ...mapActions('Cart', ['addBulkCart', 'getCarts', 'getCartsNavbar']),
    async handleAddToCart () {
      if (Object.keys(this.order).length) {
        const productsId = []
        this.order.products.map((product) => {
          if (product.reorder_message.type === 'available') {
            productsId.push({
              product_detail_id: product.detail_id,
              quantity: 1
            })
          }
        })
        if (productsId.length) {
          try {
            const resp = await this.addBulkCart({
              data: {
                carts: productsId
              }
            })
            if (resp.status === 200) {
              this.$Swal
                .fire({
                  icon: 'success',
                  title: 'Berhasil',
                  text: 'Berhasil ditambahkan ke keranjang !',
                  heightAuto: false
                })
                .then(async (confirm) => {
                  if (confirm) {
                    await this.handleGetCart()
                    this.$refs.closeButton.click()
                    this.setOrder({})
                  }
                })
            }
          } catch (error) {
            this.$message.error(error.response.data.message)
            console.log(error)
          }
        }
      }
    },
    async handleGetCart () {
      const payload = {
        session_id: this.$cookies.get('session_id')
      }
      try {
        await this.getCartsNavbar({ params: payload })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
