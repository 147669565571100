//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    const validateNumberOnly = (rule, value, callback) => {
      const letters = /^[0-9]+$/
      const field = rule.field
      const input = value.charAt(value.length - 1)
      if (value === ' ') {
        this.formData[field] = ''
      }
      if (!input.match(letters)) {
        this.formData[field] = value.substring(0, value.length - 1)
        callback(new Error('CVV hanya berisi number'))
      }

      callback()
    }
    return {
      formData: {
        cvv: ''
      },
      rules: {
        cvv: [
          {
            required: true,
            message: 'Masukan CVV',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Masukan 3 digit CVV',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ]
      },
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Checkout', [
      'pageTakeInStore',
      'storeBranch',
      'storeBranchPAS',
      'paymentDetail',
      'orderPayload',
      'selectedBank'
    ]),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapState('User', ['dataAccount'])
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBank', 'setOrderPayload']),
    ...mapActions('User', ['createTokenCC']),
    ...mapActions('Order', ['addOrder']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    async handleSubmit (data) {
      const payload = JSON.parse(JSON.stringify(this.orderPayload))
      payload.data.credit_card = Object.assign({
        ...payload.data.credit_card,
        card_token_id: data.credit_card_token.id,
        card_authentication_id: data.credit_card_token.authentication_id,
        card_cvv: this.formData.cvv,
        save_card: false,
        installment_interval: 'month'
      })
      try {
        const resp = await this.addOrder(payload)
        if (resp.status === 201) {
          this.$refs.popupLoading.$refs.buttonClose.click()
        }
      } catch (error) {
        this.$refs.popupLoading.$refs.buttonClose.click()
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Tidak dapat memproses pesanan',
            text: error.response.data.message,
            heightAuto: false,
            confirmButtonText: 'Kembali'
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location = '/cart'
            }
          })
      }
    },
    handleNext () {
      this.onSubmit = true
      this.$refs.formDataCVV.validate(async (valid) => {
        if (valid) {
          const payload = {
            amount: this.paymentDetail.total_net_price || 5000,
            card_number: this.selectedBank[0].accounts[0].value.account_number,
            card_exp_month: this.selectedBank[0].accounts[0].value.card_exp_month,
            card_exp_year: this.selectedBank[0].accounts[0].value.card_exp_year,
            card_cvv: this.formData.cvv,
            is_multiple_use: false,
            should_authenticate: true
          }
          try {
            const resp = await this.createTokenCC(payload)

            if (resp.status === 'IN_REVIEW') {
              this.setDataAccount({
                ...this.dataAccount,
                card_token_id: resp.id,
                card_authentication_id:
                  resp.authentication_id,
                card_cvv: this.formData.cvv
              })
              this.setLink3DS(
                resp.payer_authentication_url
              )
              if (resp.status === 'IN_REVIEW') {
                const payload = JSON.parse(JSON.stringify(this.orderPayload))
                payload.data.credit_card = Object.assign({
                  ...payload.data.credit_card,
                  card_token_id: resp.id,
                  card_authentication_id:
                    resp.authentication_id,
                  card_exp_month: this.selectedBank[0].accounts[0].value.card_exp_month,
                  card_exp_year: this.selectedBank[0].accounts[0].value.card_exp_year,
                  card_cvv: this.formData.cvv,
                  save_card: false,
                  installment_interval: 'month'
                })
                this.setOrderPayload(payload)
                this.$refs.showModal3DS.click()
              } else if (
                resp.status === 'VERIFIED'
              ) {
                this.$refs.buttonShowLoading.click()
                this.handleSubmit(resp.data)
              } else {
                this.$Swal.fire({
                  icon: 'error',
                  title: 'Pemberitahuan',
                  text: 'Kartu Kredit tidak dapat digunakan.',
                  heightAuto: false
                })
              }
            }
          } catch (error) {
            if (error.error_code === 'VALIDATION_ERROR') {
              this.$Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: error.message,
                heightAuto: false
              })
            }
            console.log(error)
          } finally {
            this.onSubmit = false
          }
        }
      })
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    },
    handleClose () {
      this.setSelectedBank([])
      this.$refs.closeModal.click()
    }
  }
}
