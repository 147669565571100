//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  data () {
    return {
      search: '',
      sort: ''
    }
  },

  watch: {
    '$route.query' () {
      this.getData()
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    getData () {
      const dataQuery = this.initFilter(
        this.$route.query.search,
        this.$route.query.sort
      )

      this.search = dataQuery.search
      this.sort = dataQuery.sort
    },

    changeFilter (sort) {
      this.$router.push({
        query: {
          ...this.modelPayload(
            this.search,
            sort
          )
        }
      })
    }
  }
}
