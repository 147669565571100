//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'

export default {
  mixins: [panstoreMixin],
  props: {
    isPartner: {
      type: Boolean,
      default () {
        return false
      }
    },

    vouchers: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),

    getImage (type) {
      if (type === 'discount') {
        return require('@/static/img/home/cart/voucher-tag.svg')
      } else {
        return require('@/static/img/home/cart/voucher-truck.svg')
      }
    },

    getPeriode (voucher) {
      const dateStart = this.$dayjs(this.formatYMD(voucher.date_start))
      const dateFinish = this.$dayjs(this.formatYMD(voucher.date_end))
      let result = '-'

      if (voucher.date_start && voucher.date_end) {
        if (
          dateStart.get('M') === dateFinish.get('M') &&
          dateStart.get('y') === dateFinish.get('y')
        ) {
          result =
            dateStart.format('DD') + '-' + dateFinish.format('DD MMMM YYYY')
          dateFinish.get('y')
        } else if (dateStart.get('M') !== dateFinish.get('M')) {
          result =
            dateStart.format('DD MMMM') +
            ' - ' +
            dateFinish.format('DD MMMM YYYY')
        } else {
          result =
            dateStart.format('DD MMMM YYYY') +
            ' - ' +
            dateFinish.format('DD MMMM YYYY')
        }
      }

      return result
    },

    onClickVoucher (data) {
      this.$refs.closeModalVouchers.click()
      this.setVoucher(data)
      this.setToggleDetail(true)
    }
  }
}
