//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      onLoading: false,
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },
  computed: {
    ...mapState('Contents', ['littleBanner']),
    isMobile () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    isDesktop () {
      return this.windowWidth >= 992
    },
    dataLittleBanner () {
      return this.littleBanner.filter((sv) => {
        return (
          sv.display_type === `normal ${this.isMobile ? 'phone' : 'desktop'}`
        )
      })
    },
    banner () {
      if (this.dataLittleBanner && this.dataLittleBanner.length) {
        return this.dataLittleBanner[0]
      }

      return null
    }
  },
  created () {
    this.initComponent()
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    ...mapActions('Contents', ['getLittleBanner']),
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async initComponent () {
      try {
        await this.getLittleBanner()
      } catch (error) {
        console.log(error)
      }
    },
    getImage (img) {
      if (img.includes('.gif')) {
        return img
      }

      return img + '.webp'
    }
  }
}
