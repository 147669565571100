import { render, staticRenderFns } from "./index.vue?vue&type=template&id=12099ed3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12099ed3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,FilterSection: require('/usr/src/app/components/home/products/catalogue/FilterSection.vue').default,SortBySection: require('/usr/src/app/components/home/products/catalogue/SortBySection.vue').default,CatalogForPartner: require('/usr/src/app/components/base/CatalogForPartner.vue').default,RelatedSection: require('/usr/src/app/components/home/products/catalogue/RelatedSection.vue').default,CatalogAffiliateProduct: require('/usr/src/app/components/affiliate/products/CatalogAffiliateProduct.vue').default})
