//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'Index',
  mixins: [panstoreMixin],
  async asyncData ({ store, error, params }) {
    const metaData = {
      title: 'Paninti Store',
      meta_description: 'Paninti Store'
    }

    try {
      const payload = {
        username: params.slug
      }
      await store.dispatch('User/showProfileUser', payload)
    } catch (err) {
      if (err.response && err.response.status === 404) {
        error({
          statusCode: 404,
          image: require('@/static/img/error/404.svg'),
          message: 'Pengguna tidak ditemukan',
          subMessage: 'Maaf pengguna yang dimaksud tidak ada. Mohon untuk kembali ke beranda',
          type: 'user-not-found'
        })
      } else {
        error({
          statusCode: err.response ? err.response.status : 500
        })
      }
    }

    return {
      metaData
    }
  },
  data () {
    return {
      loading: {
        topProducts: true,
        topBrands: true,
        allProduct: false
      }
    }
  },
  computed: {
    ...mapState('Product', ['affiliateUserProducts']),
    ...mapState('Brand', ['affiliateUserBrand']),
    checkLoading () {
      return Object.keys(this.loading).some((key) => {
        return this.loading[key]
      })
    },
    showEmptyState () {
      return !this.checkArray(this.affiliateUserProducts.data) && !this.checkArray(this.affiliateUserBrand.data) && !this.checkLoading
    }
  },
  async mounted () {
    await this.handleFetchData()
  },
  methods: {
    ...mapActions('Brand', ['getAffiliateTopBrands']),
    ...mapActions('Product', ['getAffiliateTopProducts']),
    ...mapActions('User', ['showProfileUser']),
    async handleFetchData () {
      const getApis = [
        this.handleGetTopProducts(),
        this.handleGetTopBrands()
      ]

      await Promise.all(getApis)
    },
    async handleGetTopBrands () {
      this.loading.topBrands = true
      try {
        const payload = {
          username: this.$route.params.slug,
          params: {
            paginated: true,
            per_page: 3
          }
        }

        await this.getAffiliateTopBrands(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading.topBrands = false
      }
    },
    async handleGetTopProducts () {
      this.loading.topProducts = true
      try {
        const payload = {
          username: this.$route.params.slug,
          params: {
            paginated: true,
            per_page: 10
          }
        }

        await this.getAffiliateTopProducts(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading.topProducts = false
      }
    }
  }
}
