//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imageMixins from '@/mixins/images.js'

export default {
  mixins: [imageMixins],
  props: {
    partner: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'Cabang'
    }
  },
  methods: {
    handleGoto (id) {
      this.$router.push('/partner/' + id)
    }
  }
}
