import { render, staticRenderFns } from "./point.vue?vue&type=template&id=e709e734&scoped=true&"
import script from "./point.vue?vue&type=script&lang=js&"
export * from "./point.vue?vue&type=script&lang=js&"
import style0 from "./point.vue?vue&type=style&index=0&id=e709e734&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e709e734",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,PoinSection: require('/usr/src/app/components/account/PoinSection.vue').default})
