//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  data () {
    return {
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Checkout', ['selectedBank']),
    ...mapState('Order', ['orderID'])
  },
  methods: {
    handleRedirect () {
      this.$refs.closeButton.click()
      if (this.selectedBank[0].type.code === 'cash') {
        this.$router.push('/account/shop/my-order')
      } else {
        this.$router.push(`/payment-detail/${this.orderID}`)
      }
    }
  }
}
