//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'

export default {
  data () {
    return {
      breadCrumb: [
        {
          path: '/affiliate/home'
        },
        {
          path: '/affiliate/products/best-selling-this-month',
          name: 'Produk Terlaris'
        },
        {
          name: 'Semua Partner'
        }
      ]
    }
  },
  computed: {
    ...mapState('Brand', ['bestSellingBrandAffiliate'])
  }
}
