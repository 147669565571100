import { render, staticRenderFns } from "./affiliatePages.vue?vue&type=template&id=e2606db0&scoped=true&"
import script from "./affiliatePages.vue?vue&type=script&lang=js&"
export * from "./affiliatePages.vue?vue&type=script&lang=js&"
import style0 from "./affiliatePages.vue?vue&type=style&index=0&id=e2606db0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2606db0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/usr/src/app/components/home/base/Header.vue').default,MainSidebar: require('/usr/src/app/components/home/base/MainSidebar.vue').default,Navbar: require('/usr/src/app/components/home/base/Navbar.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default,ApplicationDescriptionSection: require('/usr/src/app/components/home/ApplicationDescriptionSection.vue').default,Footer: require('/usr/src/app/components/home/base/Footer.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default})
