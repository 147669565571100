//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import marketplaceMixin from '@/mixins'
import VClamp from 'vue-clamp'
export default {
  name: 'ModalDetailWithdrawCommission',
  components: {
    VClamp
  },
  mixins: [marketplaceMixin],
  props: {
    modalId: {
      type: String,
      default: 'modalDetailWithdrawCommission'
    },
    detailWithdraw: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: ['1'],
      badgeStatus: {
        rejected: {
          color: 'red',
          text: 'Ditolak'
        },
        new: {
          color: 'pink',
          text: 'Menunggu Persetujuan'
        },
        done: {
          color: 'green',
          text: 'Selesai'
        },
        approved: {
          color: 'blue',
          text: 'Diterima'
        }
      }
    }
  },
  computed: {
    ...mapState('Affiliate', ['detailHistory', 'detailAffiliateWithdrawal']),
    detailExist () {
      return this.checkObject(this.detailHistory.data)
    },
    type () {
      if (this.detailExist && !this.detailWithdraw) {
        return this.detailHistory.data.balance_type
      }

      return null
    },
    code () {
      if (this.detailExist && !this.detailWithdraw) {
        if (this.type === 'in') {
          return this.detailHistory.data.order.invoice_number
        } else {
          return this.detailHistory.data.affiliate_balance_withdrawal.code
        }
      }

      return null
    },
    createdDate () {
      if (this.detailExist && !this.detailWithdraw) {
        return this.type === 'in' ? this.detailHistory.data.order.created_at
          : this.detailHistory.data.affiliate_balance_withdrawal.created_at
      }

      return null
    }
  }
}
