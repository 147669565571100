//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixins from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [panstoreMixins],

  data () {
    return {
      valueRange: [0, 10000000],
      selected_category: [],
      selected_merk: [],
      selected_color: [],
      selected_size: [],
      selected_rating: [5],
      search: '',
      sort: '',
      options: [{
        value: '',
        label: 'Semua Produk'
      }, {
        value: 'best_seller',
        label: 'Terlaris'
      }, {
        value: 'lastest',
        label: 'Terbaru'
      }, {
        value: 'most_expensive',
        label: 'Termahal'
      }, {
        value: 'cheapest',
        label: 'Termurah'
      }],
      value: ''
    }
  },
  computed: {
    ...mapState('Product', ['totalProducts'])
  },
  watch: {
    '$route.query' () {
      this.getData()
    }
  },

  mounted () {
    this.setSort()
    this.getData()
  },

  methods: {
    setSort () {
      if (this.$route.query.sort) {
        this.value = this.$route.query.sort
      }
    },
    getData () {
      const dataQuery = this.initFilter(
        this.$route.query.brands,
        this.$route.query.search,
        this.$route.query.categories,
        this.$route.query.colors,
        this.$route.query.sizes,
        this.$route.query.priceLimit,
        this.$route.query.rating,
        this.$route.query.sort,
        this.$route.query.city
      )

      this.selected_merk = dataQuery.brands
      this.selected_category = dataQuery.category
      this.selected_color = dataQuery.colors
      this.selected_size = dataQuery.sizes
      this.selected_location = dataQuery.location

      if (dataQuery.priceLimit) {
        this.valueRange[0] = dataQuery.priceLimit[0]
        this.valueRange[1] = dataQuery.priceLimit[1]
      }

      this.search = dataQuery.search
      this.sort = dataQuery.sort
    },

    changeFilter (sort) {
      const brand = this.selected_merk.join('::')
      const priceLimit = this.valueRange.join('::')
      const category = this.selected_category.join('::')
      const color = this.selected_color.join('::')
      const size = this.selected_size.join('::')
      const location = this.selected_location.join('::')
      const rating =
        this.selected_rating.length > 1
          ? this.selected_rating.join('::')
          : null

      this.$router.push({
        query: {
          ...this.modelPayload(
            brand,
            this.search,
            category,
            color,
            size,
            priceLimit,
            rating,
            sort,
            location
          ),
          section: this.$route.query.section,
          event_sale: this.$route.query.event_sale
        }
      })
    }
  }
}
