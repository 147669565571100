//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: {
    sourceMedia: {
      type: String,
      default: ''
    },
    valueChange: {
      type: Object,
      default: () => ({})
    },
    cropRatio: {
      type: Number,
      default: 1
    },
    cropText: {
      type: String,
      default: 'Crop'
    }
  },
  data () {
    return {
      fileList: [],
      imgSrc: 'https://placehold.co/600x400?text=Pilih+Gambar',
      uploaded: {
        name: '',
        url: ''
      },
      imgCropped: '',
      preview: '',
      cropperOptions: {
        viewMode: 2,
        dragMode: 'crop',
        moveable: false,
        zoomable: true,
        cropBoxResizable: true
      }
    }
  },
  watch: {
    valueChange: {
      handler () {
        if (Object.keys(this.valueChange).length) {
          this.uploaded = this.valueChange
          setTimeout(() => {
            this.$refs.cropper.replace(this.uploaded.original)
          }, 500)
        }
      },
      deep: true
    }
  },
  methods: {
    resetCropper (notReset) {
      if (this.$refs.cropper) {
        this.$refs.cropper.reset()
      }
      if (!notReset) {
        this.$emit('reset', {})
      }
      setTimeout(() => {
        this.imgSrc = 'https://placehold.co/600x400?text=Pilih+Gambar'
        this.uploaded = {
          name: '',
          url: ''
        }
      }, 200)
    },
    cropImage () {
      if (this.uploaded.original.includes('base64')) {
        const cropped = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL('image/png', 0.5)
        if (cropped) {
          this.uploaded.cropped = cropped
          this.$emit('cropped', this.uploaded)
          this.resetCropper(true)
          this.$refs.btnCloseNoReset.click()
        }
      }
    }
  }
}
