//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixins from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [panstoreMixins],

  data () {
    return {
      accountPromotion: {
        isLoading: true,
        page: 1,
        perPage: 3,
        data: []
      }
    }
  },

  computed: {
    ...mapState('Contents', ['promotion'])
  },

  mounted () {
    this.getAccountPromotions()
  },

  methods: {
    ...mapActions('Contents', ['getPromotion']),
    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),

    async getAccountPromotions () {
      this.accountPromotion.isLoading = true

      try {
        const resp = await this.getPromotion({
          data: {
            page: this.accountPromotion.page,
            per_page: this.accountPromotion.perPage
          }
        })

        this.accountPromotion.data = resp.data.data.promotions.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data banner promo')
      }

      this.accountPromotion.isLoading = false
    },

    handleClick (item) {
      if (this.promotion.type !== 'voucher') {
        const type = {
          clearance_sale: 'clearance-sale',
          flash_sale: 'flashsale',
          event_sale: this.promotion.event_sales && this.promotion.event_sales.slug
            ? `sale/${this.promotion.event_sales.slug}`
            : 'sale',
          external: this.promotion.link
        }

        if (this.promotion.type === 'external') {
          window.location.href = this.promotion.link
        } else {
          this.$router.push(`/${type[this.promotion.type]}`)
        }
      } else {
        this.setVoucher(this.promotion.vouchers)
        this.setToggleDetail(true)
        this.$refs.showModalVoucher.click()
      }
    },

    getImage (files) {
      return (
        files.filter((file) => {
          return file.name === 'banner'
        })[0].value + '.webp'
      )
    }
  }
}
