//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex'

import panstoreMixin from '@/mixins/index'

export default {
  mixins: [panstoreMixin],
  layout: 'affiliatePages',

  async asyncData ({ store, params, error, app }) {
    store.commit('Product/setSelectedDetails', {})

    let product = {}
    try {
      const resGetProduct = await store.dispatch('Product/getDetailProduct', {
        slug:
          params.slug +
          (!app.$auth.loggedIn
            ? '?session_id=' + app.$cookies.get('session_id')
            : '')
      })

      product = resGetProduct.data.data.product
    } catch (err) {
      console.log(error)
      if (err.response && err.response.status === 404) {
        error({
          statusCode: 404,
          image: require('@/static/img/error/empty-cart.svg'),
          message: 'Produk tidak ditemukan',
          subMessage: 'Produk yang dimaksud tidak ada pada data kami. Mohon untuk cari dengan kata kunci yang sesuai',
          type: 'product-not-found'
        })
      } else {
        error({
          statusCode: err.response ? err.response.status : 500
        })
      }
    }

    let metaData = {}
    try {
      const respMetaData = await store.dispatch(
        'Product/getMetaProduct',
        params.slug
      )
      if (respMetaData.status === 200) {
        if (respMetaData.data.data.meta_scripts) {
          metaData = respMetaData.data.data.meta_scripts
        }
      }
    } catch (error) {
      console.log(error)
    }

    return {
      metaData,
      data: product || {},
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: product && product.brand ? product.brand.name : '-',
          path:
            product && product.brand
              ? `/partner/${product.brand.slug}`
              : '#'
        },
        {
          name: product ? product.name : '-'
        }
      ]
    }
  },

  data () {
    return {
      showBrand: false
    }
  },

  computed: {
    productSEOImage () {
      let urlProductSEOImg = process.env.URL + '/default-og-image.jpg'

      if (Array.isArray(this.metaData)) {
        const metaScriptProduct = this.metaData.filter((script) => {
          return script.json && script.json['@type'] === 'Product'
        })

        if (metaScriptProduct.length > 0) {
          urlProductSEOImg = metaScriptProduct[0].json.image
        }
      }

      return urlProductSEOImg
    },

    productMetaKeyword () {
      let productMetaKeyword = ''

      if (this.data && Array.isArray(this.data.meta_keywords)) {
        if (this.data.meta_keywords.length === 1 && this.data.meta_keywords[0].trim() !== '') {
          productMetaKeyword = this.data.meta_keywords.map(keyword => keyword.trim()).join(',')
        } else {
          const productNameArray = this.data.name.trim().split(' ')

          for (let i = 1; i < productNameArray.length; i++) {
            for (let j = 0; j <= i; j++) {
              productMetaKeyword += productNameArray[j]
              if (j < i) { productMetaKeyword += ' ' }
            }

            if (i < productNameArray.length - 1) { productMetaKeyword += ',' }
          }
        }
      }

      return productMetaKeyword.toLowerCase()
    }
  },
  async mounted () {
    await this.handleIncreaseAffiliate()
  },
  methods: {
    ...mapMutations('Product', ['setSelectedDetails']),
    ...mapActions('Affiliate', ['increaseClickAffiliateProduct']),
    async handleIncreaseAffiliate () {
      if (this.$route.query && this.$route.query.affiliate_code) {
        try {
          const payload = {
            code: this.$route.query.affiliate_code
          }

          await this.increaseClickAffiliateProduct(payload)
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error
          console.log(errorMessage)
        }
      }
    },
    handleShowBrand (value) {
      this.showBrand = value
    },

    getVideos (files) {
      return files.filter((file) => {
        return file.type === 'youtube_video'
      })
    }
  },

  head () {
    return {
      title: this.data.meta_title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.data.meta_title
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.data.meta_title
        },
        {
          hid: 'description',
          name: 'description',
          content: this.data.meta_description
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.data.meta_description
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.productSEOImage
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.productMetaKeyword
        }
      ],
      script: this.metaData || []
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.setSelectedDetails({})
    next()
  },

  beforeRouteLeave (to, from, next) {
    this.setSelectedDetails({})
    next()
  }
}
