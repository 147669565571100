//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      onSubmit: false,
      productId: 'b4726177-1887-413c-b20d-45b057d2d99e',
      branchId: '83ab274c-debf-4415-af07-3ce00ae15bc8',
      perPage: 9
    }
  },
  computed: {
    ...mapState('User/address', ['userAddress']),
    ...mapState('Voucher', ['voucherUsedId']),
    ...mapState('Checkout', ['selectedAddress', 'usePoint', 'pageTakeInStore'])
  },
  created () {
    this.getUserAddress()
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedAddress', 'setOrderPayload']),
    ...mapActions('User/address', ['editUserAddress', 'getUserAddress']),
    ...mapActions('Checkout', ['getStoreBranch']),
    handleAddAddress () {
      this.$refs.closeButtonEdit.click()
    },
    async selectAddress (address) {
      this.$refs.closeButtonEdit.click()
      this.setSelectedAddress(address)
      await this.handleGetStoreBranch(address.id)
    },
    async changeMainAddress (id) {
      this.onSubmit = true
      try {
        const resp = await this.editUserAddress({
          id,
          payload: {
            data: {
              main: true
            }
          }
        })
        if (resp.status === 200) {
          this.getUserAddress()
        }
      } catch (error) {
        console.log(error)
      }
      this.onSubmit = false
    },
    async handleGetStoreBranch (userAddressId) {
      try {
        const payload = {
          data: {
            cart_id: JSON.parse(localStorage.cartItem),
            user_address_id: userAddressId,
            pickup_at_store: this.pageTakeInStore
          }
        }
        const resp = await this.getStoreBranch({
          payload
        })
        if (resp.status === 200) {
          const respAvailability = await this.checkAvailibilities(
            JSON.parse(localStorage.cartItem),
            resp.data.data.store_branches[0].id,
            userAddressId,
            null,
            null,
            this.pageTakeInStore,
            this.voucherUsedId,
            this.usePoint
          )

          const vouchers = respAvailability.data.data.checkout.vouchers
          this.checkActiveOnlyVoucher(vouchers)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
