//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  mixins: [mixins],
  props: {
    onAccountPage: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const validateNumberOnly = (rule, value, callback) => {
      const letters = /^[0-9]+$/
      const field = rule.field
      const input = value.charAt(value.length - 1)
      if (value === ' ') {
        this.formData[field] = ''
      }
      if (!input.match(letters)) {
        callback(new Error('Inputan hanya berupa angka'))
        this.formData[field] = value.substring(0, value.length - 1)
      } else {
        callback()
      }
    }
    return {
      formData: {
        account_number: '',
        expired_date: '',
        cvv: '',
        save: true
      },
      rules: {
        account_number: [
          {
            required: true,
            message: 'Masukan nomor kartu kredit',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ],
        expired_date: [
          {
            required: true,
            message: 'Masukan masa berlaku kartu',
            trigger: 'change'
          }
        ],
        cvv: [
          {
            required: true,
            message: 'Masukan CVV',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Masukan 3 digit CVV',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ]
      },
      onSubmit: false,
      onDraw: false,
      datePickerOpt: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      }
    }
  },
  computed: {
    ...mapState('Checkout', [
      'pageTakeInStore',
      'selectedAddress',
      'selectedCourier',
      'branchSelected',
      'storeBranch',
      'storeBranchPAS',
      'selectedBank',
      'listPaymentMethods',
      'usePoint'
    ]),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    ...mapState('User', ['accounts', 'createCC'])
  },
  watch: {
    createCC () {
      if (this.createCC) {
        this.$refs.formDataCC.resetFields()
        this.resetForm()
      }
    }
  },
  mounted () {
    window.Xendit.setPublishableKey(process.env.XENDIT_PUBLIC_KEY)

    setTimeout(() => {
      this.onDraw = true
      if (this.$refs.expiredDate) {
        this.$refs.expiredDate.picker.visible = false
      }
    }, 500)
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBank']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    ...mapActions('User', [
      'createPayment',
      'getPayment',
      'createTokenCC',
      'checkValidCC'
    ]),
    getMonthFormat () {
      let tempDate = new Date(this.formData.expired_date).getMonth() + 1
      if (tempDate < 10) {
        tempDate = `0${tempDate}`
      }
      return tempDate.toString()
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.onSubmit = true
          try {
            const check = await this.checkValidCC({
              data: {
                credit_card: {
                  card_number: this.formData.account_number
                }
              }
            })
            if (
              check.status === 200 &&
              check.data.data.available_for_new_account
            ) {
              const expYear = new Date(this.formData.expired_date)
                .getFullYear()
                .toString()
              const resAccountNumber = window.Xendit.card.validateCardNumber(this.formData.account_number)
              const resExpiry = window.Xendit.card.validateExpiry(this.getMonthFormat(), expYear)
              const resCvn = window.Xendit.card.validateCvn(this.formData.cvv)

              if (!resAccountNumber || !resExpiry || !resCvn) {
                this.$Swal.fire({
                  icon: 'error',
                  title: 'Validasi Credit Card Gagal',
                  text: 'Periksa kembali credit card yang dimasukan'
                })

                return
              }

              const payload = {
                credit_card: {
                  amount: 5000,
                  card_number: this.formData.account_number,
                  card_exp_month: this.getMonthFormat(),
                  card_exp_year: expYear,
                  card_cvv: this.formData.cvv,
                  is_multiple_use: false,
                  should_authenticate: true
                }
              }
              if (!this.onAccountPage) {
                this.handleCheck(payload)
              } else {
                try {
                  const resp = await this.createTokenCC(payload.credit_card)
                  if (resp.status === 'IN_REVIEW') {
                    this.setDataAccount({
                      account_number: this.formData.account_number,
                      card_charge_amount: 5000,
                      card_token_id: resp.id,
                      card_authentication_id: resp.authentication_id || null,
                      card_exp_month: this.getMonthFormat(),
                      card_exp_year: expYear,
                      card_cvv: this.formData.cvv
                    })
                    this.setLink3DS(
                      resp.payer_authentication_url
                    )
                    if (
                      resp.payer_authentication_url
                    ) {
                      this.$refs.showModal3DS.click()
                    } else if (this.formData.save) {
                      try {
                        const resp = await this.createPayment({ data: payload })
                        if (resp.status === 200) {
                          this.getDataAccounts()
                        }
                      } catch (error) {
                        console.log(error)
                      } finally {
                        this.onSubmit = false
                      }
                    }
                  }
                } catch (error) {
                  console.error(error)
                  const errorMessage = error.response ? error.response.data.message : error
                  this.$Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: errorMessage,
                    heightAuto: false
                  })
                } finally {
                  this.onSubmit = false
                }
              }
            } else {
              this.$Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: 'Kartu kredit tidak dapat digunakan / telah terdaftar !',
                heightAuto: false
              })
            }
          } catch (error) {
            console.log(error)
            this.$Swal.fire({
              icon: 'error',
              title: 'Gagal',
              text: error.response.data.message,
              heightAuto: false
            })
          } finally {
            this.onSubmit = false
          }
        }
      })
    },
    async getDataAccounts () {
      try {
        await this.getPayment({
          data: {
            paginated: true,
            per_page: 10,
            page: this.page,
            where: {
              type: 'Credit Card'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async handleCheck (item) {
      try {
        this.onSubmit = true
        const resp = await this.checkAvailibilities(
          JSON.parse(localStorage.cartItem),
          this.getDataBranch()[this.branchSelected].id,
          this.selectedAddress.id,
          this.selectedCourier.code,
          this.selectedCourier.service,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.usePoint,
          {
            credit_card: {
              card_number: item.credit_card.card_number
            },
            payment_method_id:
              JSON.parse(JSON.stringify(this.listPaymentMethods)).filter(
                (bank) => {
                  return bank.type.code === 'credit_card'
                }
              )[0].id || ''
          }
        )
        if (resp.status === 200) {
          this.handleNext(item)
        }
      } catch (error) {
        console.log(error)
        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: error,
          heightAuto: false
        })
      } finally {
        this.onSubmit = false
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    },
    handleNext (item) {
      const dataCC = JSON.parse(JSON.stringify(this.listPaymentMethods)).filter(
        (bank) => {
          return bank.type.code === 'credit_card'
        }
      )
      dataCC[0].create_new = true
      dataCC[0].save = this.formData.save
      dataCC[0].accounts.push(item)

      const count = this.formData.account_number.length
      dataCC[0].name = `${dataCC[0].name} (${
        '**** ' + this.formData.account_number.substring(count - 4, count)
      })`
      this.setSelectedBank(dataCC)
      this.$refs.closeModal.click()
      this.resetForm()
    },
    resetForm () {
      this.formData = Object.assign({
        account_number: '',
        expired_date: '',
        cvv: '',
        save: true
      })
    }
  }
}
