//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    return {
      value: 5,
      showUpload: true,
      imgExpression: '',
      expressionText: '',
      expressionList: [
        '1-colored.svg',
        '2-colored.svg',
        '3-colored.svg',
        '4-colored.svg',
        '5-colored.svg'
      ],
      expressionTextList: [
        'Buruk',
        'Biasa Saja',
        'Cukup',
        'Baik',
        'Sangat Baik'
      ],
      formRating: {
        rating: 0,
        reviewable_type: 'order_products',
        reviewable_id: this.$route.params.id,
        review_suggestions: [],
        description: '',
        medias: [],
        show_identity: true
      },
      customHeaders: {
        'Application-Key': 'Q5nGL97wRBFlgQ2WOG3XDnHhV33jFIYWdHKArumKS78YaSQA3U',
        Authorization: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : null
      },
      isImage: false,
      fileList: 0,
      fileUploaded: 0,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      isBtnHover: false,
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Order', ['productOrder', 'suggestions']),
    baseApi () {
      return process.env.BASE_API
    }
  },
  methods: {
    ...mapActions('Order', ['addReview']),
    ...mapActions('Files', ['uploadFiles']),
    handleSuggestions (id) {
      if (this.formRating.review_suggestions.includes(id)) {
        const idx = this.formRating.review_suggestions.findIndex((sug) => {
          return sug.id === id
        })
        this.formRating.review_suggestions.splice(idx, 1)
      } else {
        this.formRating.review_suggestions.push(id)
      }
    },
    handleRateChange (value) {
      const idx = value - 1
      this.imgExpression = this.expressionList[idx]
      this.expressionText = this.expressionTextList[idx]
    },
    handleBtnHover (type) {
      if (type === 'in') {
        this.isBtnHover = true
      } else {
        this.isBtnHover = false
      }
    },
    handleChange () {
      this.checkUpload()
    },
    handleError (err) {
      this.$refs.uploadFiles.uploadFiles.pop()
      this.checkUpload()
      const errorMessage = err ? err.message : err
      this.$Swal.fire({
        icon: 'error',
        title: 'Gagal upload gambar',
        text: errorMessage
      })
    },
    checkUpload () {
      if (this.$refs.uploadFiles.uploadFiles.length === 5) {
        this.showUpload = false
      } else {
        this.showUpload = true
      }
    },
    handleRemove (file) {
      const idx = this.$refs.uploadFiles.uploadFiles.findIndex((uf) => {
        return file.uid === uf.uid
      })
      this.$refs.uploadFiles.uploadFiles.splice(idx, 1)
      this.checkUpload()
      this.$message({
        showClose: true,
        message: 'Gambar berhasil dihapus dari list',
        type: 'success'
      })
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeUpload (file) {
      const isImage = file.type.substr(0, 5) === 'image'
      const isLimit5M = file.size / 1024 / 1024 < 6

      if (!isImage) {
        this.$message.error('File tidak sesuai, harap masukan .png atau .jpg!')
        this.$refs.uploadFiles.uploadFiles =
          this.$refs.uploadFiles.uploadFiles.pop()
      }
      if (!isLimit5M) {
        this.$message.error('Ukuran file tidak boleh lebih dari 5Mb!')
        this.$refs.uploadFiles.uploadFiles =
          this.$refs.uploadFiles.uploadFiles.pop()
      }
      return isImage && isLimit5M
    },
    handleLimit () {
      this.$message.error('Upload maksimal 5 gambar!')
      this.checkUpload()
    },
    handleAddUlasan () {
      if (this.formRating.rating === 0) {
        this.$message.error('Rating wajib diisi')
        return
      }

      if (this.isImage && this.$refs.uploadFiles.uploadFiles.length) {
        this.fileList = this.$refs.uploadFiles.uploadFiles.length
        this.$refs.uploadFiles.submit()
      } else {
        this.addUlasan()
      }
    },
    async addUlasan (gotPoint) {
      try {
        this.onSubmit = true
        const payload = {
          data: this.formRating
        }
        const resp = await this.addReview(payload)
        if (resp.status === 201) {
          let text = 'Review berhasil ditambahkan!'
          let title = 'Berhasil'
          let confirmButtonText = 'OK'
          if (gotPoint) {
            title = 'Review ditambahkan!'
            text = `Anda mendapatkan ${resp.data.data.review.paninti_point_earned} poin!`
            confirmButtonText = 'Kumpulkan Poin'
          }
          this.$Swal
            .fire({
              icon: 'success',
              title,
              text,
              heightAuto: false,
              confirmButtonText,
              allowOutsideClick: false
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push(
                  '/account/shop/my-order/detail/' + this.productOrder.order.id
                )
              }
            })
        }
      } catch (error) {
        console.log(error)
        this.$Swal.fire({
          icon: 'error',
          title: 'Oops, Terjadi kesalahan!',
          text: 'Gagal menambahkan review',
          heightAuto: false
        })
      } finally {
        this.onSubmit = false
      }
    },
    handleSuccess (response) {
      if (response.status_code === 201) {
        this.fileUploaded++
        this.formRating.medias.push({
          name: 'review',
          value: response.data.files[0].filename,
          type: 'image'
        })
        if (this.fileList === this.fileUploaded) {
          this.addUlasan(true)
        }
      }
    }
  }
}
