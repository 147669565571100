var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-profile-section"},[_c('div',{staticClass:"col-lg-3 pe-4 d-none d-lg-block"},[_c('div',{staticClass:"filter-section"},_vm._l((_vm.visibleSections),function(i,index){return _c('div',{key:'filter-section-' + index,staticClass:"filter-item font-website-small font-weight-600 mb-2",class:{
          active:
            i.value === _vm.$route.query.section ||
            (!_vm.$route.query.section && i.value === 'all'),
        },on:{"click":function($event){return _vm.handleFilterSectionChange(i)}}},[_vm._v("\n        "+_vm._s(i.name)+"\n      ")])}),0)]),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-9 col-md-12"},[_c('SortBySection',{staticClass:"mt-0 d-none d-lg-block",attrs:{"total-product":_vm.totalProductInActiveSection}}),_vm._v(" "),_c('FilterProfileMobile'),_vm._v(" "),(_vm.activeSectionIndex >= 0)?_vm._l((_vm.sections),function(section,index){return _c('div',{key:'list-product-section-' + index,staticClass:"section-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSectionActive(section.value)),expression:"isSectionActive(section.value)"}],staticClass:"listProduct-wrapper"},[(_vm.$route.query.partnerSearch)?_c('div',{staticClass:"section-search-result"},[_vm._v("\n            "+_vm._s(_vm.totalProductInActiveSection)+" produk hasil pencarian untuk \""),_c('b',[_vm._v(_vm._s(_vm.$route.query.partnerSearch))]),_vm._v("\"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"products-container"},[[(_vm.$route.query.section !== 'flash_sale')?_vm._l((_vm.sections[index].product.data),function(i,idx){return _c('CardProduct',{key:'item-' + i.id + idx,attrs:{"item":i,"using-row":true}})}):_vm._l((_vm.sections[index].product.data),function(item,index2){return _c('CardProductFlashsale',{key:'product-card-flashsale-section-' + item.id + '-' + index2,attrs:{"item":item,"on-flashsale":_vm.getFlashTime(
                      item.event_ongoing.flash_sale && item.event_ongoing.flash_sale.length
                        ? item.event_ongoing.flash_sale[0].session.date_start
                        : null
                    ),"progress":item.sold_percentage,"total-sold":item.total_sold}})})],_vm._v(" "),(_vm.sections[index].product.isLoading)?_vm._l((_vm.sections[index].product.perPage),function(i){return _c('ProductCardSkeletonLoading',{key:'card-' + _vm.generateRandomString(4, 'alphanumeric') + '-' + i,attrs:{"is-animated":true}})}):_vm._e()],2),_vm._v(" "),_c('infinite-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.sections[index].product.page > 1),expression:"sections[index].product.page > 1"}],ref:'InfiniteLoading' + index,refInFor:true,attrs:{"indentifier":_vm.infiniteId},on:{"infinite":_vm.handleInfiniteLoading}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"}),_vm._v(" "),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_vm._v(" "),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]),_vm._v(" "),(_vm.sections[index].product.data.length <= 0 && !_vm.sections[index].product.isLoading)?_c('div',{staticClass:"product-empty text-center mx-auto py-5"},[_c('img',{staticClass:"img-fluid img-empty mb-3",attrs:{"src":require("@/static/img/error/empty-cart.svg"),"alt":"Product is Empty"}}),_vm._v(" "),_c('div',{staticClass:"text-empty-bold"},[_vm._v("\n              Yahh, produk tidak ditemukan...\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-empty mb-3"},[_vm._v("\n              Atur ulang pengaturan produk\n            ")]),_vm._v(" "),_c('a',{staticClass:"btn btn-primary text-decoration-none",attrs:{"href":"javascript:void(0)","text":"Atur Ulang"},on:{"click":_vm.handleResetQuery}},[_vm._v("Atur Ulang")])]):_vm._e()],1)])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }