//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    return {
      options: [{
        value: 'male',
        label: 'Laki - Laki'
      }, {
        value: 'female',
        label: 'Perempuan'
      }],
      onSubmit: false,
      formRegister: {
        full_name: '',
        date_birth: '',
        gender: '',
        username: '',
        email: '',
        phone_number: '',
        password: '',
        password_confirmation: '',
        remember: false
      },
      errorName: {
        full_name: ''
      },
      errorBirthdate: '',
      errorGender: '',
      errorUsername: '',
      errorEmail: '',
      errorNumberPhone: '',
      errorPassword: '',
      errorCPassword: '',
      toggleShowHide: {
        ipassword: false,
        icpassword: false
      },
      iconEye: {
        ipassword: '/img/icon/eye-solid.svg',
        icpassword: '/img/icon/eye-solid.svg'
      }
    }
  },
  methods: {
    ...mapActions('Auth', ['register']),
    showHidePassword (id) {
      this.toggleShowHide[id] = !this.toggleShowHide[id]
      if (this.toggleShowHide[id] === true) {
        this.iconEye[id] = '/img/icon/eye-solid.svg'
        document.getElementById(id).type = 'password'
      } else {
        this.iconEye[id] = '/img/icon/eye-slash-solid.svg'
        document.getElementById(id).type = 'text'
      }
    },
    handleSubmitRegisterForm () {
      const _this = this
      _this.onSubmit = true
      _this.checkForm()
      const valid = !(
        this.errorName.full_name ||
          this.errorBirthdate ||
          this.errorGender ||
          this.errorUsername ||
          this.errorEmail ||
          this.errorPassword ||
          this.errorCPassword ||
          this.errorNumberPhone
      )

      const payload = {
        data: {
          ..._this.formRegister
        }
      }

      const arrFullnames = this.formRegister.full_name.split(' ')

      payload.data.first_name = arrFullnames[0]
      payload.data.last_name = arrFullnames.length > 1 ? arrFullnames[1] : null

      delete payload.data.full_name

      if (valid) {
        this.register(payload)
          .then((response) => {
            if (response.status === 201) {
              _this.handleLogin(
                _this.formRegister.username,
                _this.formRegister.email,
                _this.formRegister.password
              )
            } else {
              this.onSubmit = false
              this.$Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: response.data.message,
                heightAuto: false
              })
            }
          })
          .catch((error) => {
            this.onSubmit = false
            if (error.response) {
              this.$Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: error.response.data.message,
                heightAuto: false
              })
            } else {
              this.$Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: error,
                heightAuto: false
              })
            }
          })
      } else {
        this.onSubmit = false
        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: 'Lengkapi data dengan benar!',
          heightAuto: false
        })
      }
    },

    validatorName (name) {
      const value = this.formRegister[name]
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[A-Za-z. ]+$/
      if (value.length === 1) {
        if (input === ' ') {
          this.formRegister[name] = ''
        } else {
          this.formRegister[name] = input.toUpperCase()
        }
      }
      if (!input.match(letters)) {
        // hanya huruf, . dan spasi (tidak bisa double spasi)
        this.formRegister[name] = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formRegister[name] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formRegister[name] =
            value.substr(0, value.length - 1) + input.toUpperCase()
      }
      if (value.length > 100) {
        this.formRegister[name] = value.substr(0, value.length - 1)
      }
      if (value.length < 2) {
        this.errorName[name] = 'Nama Lengkap Minimal 2 karakter'
      } else {
        this.errorName[name] = ''
      }
    },

    validatorBirthdate (value) {
      if (value) {
        this.errorBirthdate = ''
      } else {
        this.errorBirthdate = 'Tanggal lahir wajib diiisi'
      }
    },

    validatorGender (value) {
      if (value) {
        this.errorGender = ''
      } else {
        this.errorGender = 'Jenis kelamin wajib diisi wajib diiisi'
      }
    },

    checkName (name) {
      const value = this.formRegister[name]
      if (value === '' && name === 'full_name') {
        this.errorName[name] = 'Nama Lengkap Wajib Diiisi'
      } else {
        this.errorName[name] = ''
      }
      if (value.length > 100) {
        this.formRegister[name] = value.substring(0, 100)
      }
    },

    validatorUsername () {
      const value = this.formRegister.username
      const input = value.charAt(value.length - 1)
      const letters = /^[a-zA-z._0-9]+$/
      if (value === ' ') {
        this.formRegister.username = ''
      }
      if (input === ' ') {
        this.formRegister.username = value.substring(0, value.length - 1)
      }
      if (value.length > 30) {
        this.formRegister.username = value.substring(0, value.length - 1)
      }
      if (value.length < 6) {
        this.errorUsername = 'Username minimal 6 karakter'
      } else {
        this.errorUsername = ''
      }
      if (!input.match(letters)) {
        this.formRegister.username = value.substr(0, value.length - 1)
      }
    },

    validatorNumberPhone (e) {
      const input = this.formRegister.phone_number.charAt(
        this.formRegister.phone_number.length - 1
      )
      if (
        this.formRegister.phone_number.length === 1 &&
          (input === '0' || input === 0)
      ) {
        this.formRegister.phone_number = '62'
      }
      const letters = /[^0-9]/g
      if (letters.test(input)) {
        this.formRegister.phone_number = this.formRegister.phone_number.substr(
          0,
          this.formRegister.phone_number.length - 1
        )
      }
      if (this.formRegister.phone_number.split('').length >= 15) {
        this.formRegister.phone_number = this.formRegister.phone_number.substr(
          0,
          this.formRegister.phone_number.length - 1
        )
      }
      if (this.formRegister.phone_number.split('').length < 8) {
        this.errorNumberPhone = 'Nomor telepon minimal 8 karakter'
      } else {
        this.errorNumberPhone = ''
      }
    },

    checkNumberPhone () {
      if (this.formRegister.phone_number === '') {
        this.errorNumberPhone = 'Nomor telepon wajib diisi'
      } else if (this.formRegister.phone_number.length < 7) {
        this.errorNumberPhone = 'Nomor telepon minimal 6 karakter'
      } else {
        this.validatorNumberPhone()
      }
    },

    checkUsername () {
      if (this.formRegister.username === '') {
        this.errorUsername = 'Username wajib diisi'
      } else {
        this.errorUsername = ''
      }

      if (this.formRegister.username.length < 5) {
        this.errorUsername = 'Username minimal 6 karakter'
      } else {
        this.errorUsername = ''
      }

      if (this.formRegister.username.length > 30) {
        this.formRegister.username = this.formRegister.username.substring(0, 30)
      }
    },

    validatorEmail () {
      const value = this.formRegister.email
      const input = value.charAt(value.length - 1)

      /* eslint-disable */
        const letters = /^[A-Za-z0-9._+@]+$/
        if (value === ' ') {
          this.formRegister.email = ''
        }

        if (value.length > 100) {
          this.formRegister.email = value.substring(0, value.length - 1)
        }

        if (!input.match(letters)) {
          this.formRegister.email = value.substring(0, value.length - 1)
        }
      },

    checkEmail() {
      const value = this.formRegister.email
      /* eslint-disable */
      const format =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((-\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]{2,})+[a-zA-Z]{2,}))/
      this.errorEmail = ''
      if (value === '') {
        this.errorEmail = 'Email wajib diisi'
      } else if (!format.test(value)) {
        this.errorEmail = 'Format email tidak valid'
      } else {
        this.errorEmail = ''
      }
      if (value.length > 100) {
        this.formRegister.email = value.substring(0, 100)
      }
    },

    validatorPassword() {
      const value = this.formRegister.password
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/
      if (value === '') {
        this.errorPassword = 'Password wajib diisi'
      } else {
        this.errorPassword = ''
      }

      if (!value.match(regex)) {
        this.errorPassword =
          'Password minimal berisi 1 huruf kapital dan 1 angka'
      } else {
        this.errorPassword = ''
      }

      if (value === ' ') {
        this.formRegister.password = ''
      }
      if (input === ' ') {
        this.formRegister.password = value.substr(0, value.length - 1)
      }
    },

    checkPassword() {
      if (this.formRegister.password === '') {
        this.errorPassword = 'Password wajib diisi'
      } else {
        if (this.formRegister.password.length < 7) {
          this.errorPassword = 'Password minimal 6 karakter'
        } else {
          this.validatorPassword()
        }
      }
    },

    validatorCPassword() {
      const value = this.formRegister.password_confirmation
      const input = value.charAt(value.length - 1)
      if (value === '') {
        this.errorCPassword = 'Konfirmasi Password wajib diisi'
      } else {
        this.errorCPassword = ''
      }
      if (value === ' ') {
        this.formRegister.password_confirmation = ''
      }
      if (input === ' ') {
        this.formRegister.password_confirmation = value.substr(
          0,
          value.length - 1
        )
      }
      if (
        this.formRegister.password_confirmation !== this.formRegister.password
      ) {
        this.errorCPassword = 'Konfirmasi Password tidak sesuai'
      }
    },

    checkCPassword() {
        if (
          this.formRegister.password_confirmation === this.formRegister.password
        ) {
          this.errorCPassword = ''
        } else {
          this.errorCPassword = 'Password tidak sesuai'
        }
        if (this.formRegister.password_confirmation === '') {
          this.errorCPassword = 'Konfirmasi password wajib diisi'
        } else {
          this.validatorCPassword()
        }
      },

    checkGender () {
      if (this.formRegister.gender) {
        this.errorGender = ''
      } else {
        this.errorGender = 'Jenis kelamin wajib diisi'
      }
    },

    checkBirthdate () {
      if (this.formRegister.date_birth) {
        this.errorBirthdate = ''
      } else {
        this.errorBirthdate = 'Tanggal lahir wajib diisi'
      }
    },
    checkForm() {
        this.checkName('full_name')
        this.checkGender()
        this.checkBirthdate()
        this.checkUsername()
        this.checkEmail()
        this.checkPassword()
        this.checkCPassword()
        this.checkNumberPhone()
      },

    async handleLogin(username, email, password) {
        try {
          await this.$auth.loginWith('local', {
            data: {
              email,
              username,
              password,
              session_id: this.$cookies.get('session_id')
            }
          })

          if (this.$route.query.redirect_from) {
            if (this.$route.query.redirect_from === '/cart') {
              this.$Swal.fire({
                icon: 'success',
                title: 'Berhasil Masuk',
                text: 'Selamat datang kembali di Paninti App'
              }).then(() => {
                this.$router.push(this.$route.query.redirect_from)
              })
            } else {
              this.$router.push(this.$route.query.redirect_from)
            }
          } else {``
            window.location.reload()
          }
        } catch (error) {
          console.log(error)
          const errorMessage = error.response ? error.response.data.message : error
          this.$Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: errorMessage
          })
        }
      }
  }
}
