//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixins from '@/mixins'
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixins],
  data () {
    return {
      value: 5,
      reviewCardData: [
        {
          isImage: true
        },
        {
          isImage: false
        },
        {
          isImage: true
        }
      ]
    }
  },
  computed: {
    ...mapState('User/order', ['reviews'])
  },
  methods: {
    getImageFiles (files) {
      return files.filter((file) => {
        return file.variant === 'thumbnail'
      })
    }
  }
}
