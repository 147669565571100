//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {}
  },
  computed: {
    ...mapState('Cart', ['cartEmpty']),
    ...mapGetters('Cart', ['getTotalProductCartEmpty'])
  },
  methods: {
    ...mapMutations('Cart', ['setCartItemId', 'setDeleteItem']),
    deleteModal (item, id) {
      this.setCartItemId(id)
      this.setDeleteItem(item)
    }
  }
}
