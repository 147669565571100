import { render, staticRenderFns } from "./index.vue?vue&type=template&id=78f74c4b&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=78f74c4b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f74c4b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,CatalogListPartner: require('/usr/src/app/components/home/products/catalogue/CatalogListPartner.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
