//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'ModalChooseWithdrawCommission',
  mixins: [panstoreMixin],
  props: {
    modalId: {
      type: String,
      default: 'modalChooseWithdraw'
    }
  },
  data () {
    return {
      formFilter: {
        range: 'all',
        sort: 'desc'
      },
      optionsRange: [
        {
          label: 'Sepanjang Waktu',
          value: 'all'
        },
        {
          label: 'Minggu Lalu',
          value: 'last-week'
        },
        {
          label: '30 Hari yang Lalu',
          value: '30-days'
        },
        {
          label: '60 Hari yang Lalu',
          value: '60-days'
        },
        {
          label: '90 Hari yang Lalu',
          value: '90-days'
        }
      ],
      checkAll: false,
      optionsSort: [
        {
          label: 'Terbaru',
          value: 'desc'
        }, {
          label: 'Terlama',
          value: 'asc'
        }
      ],
      checkedWithdrawals: [],
      perPage: 5,
      infiniteId: +new Date(),
      loading: false,
      showAlertAmount: false
    }
  },
  computed: {
    ...mapState('Affiliate', ['withdrawables']),
    listWithdrawables () {
      return this.withdrawables.data || []
    },
    totalAmount () {
      if (this.checkArray(this.checkedWithdrawals)) {
        let sumAmount = 0
        this.checkedWithdrawals.forEach((check) => {
          sumAmount += check.amount
        })

        return sumAmount
      }
      return 0
    }
  },
  watch: {
    formFilter: {
      async handler () {
        this.resetWithdrawables()
        await this.handleGetWithdrawables()
        this.infiniteId++
      },
      deep: true
    }
  },
  async mounted () {
    await this.handleGetWithdrawables()
  },
  methods: {
    ...mapMutations('Affiliate', ['resetWithdrawables', 'setSelectedWithdrawables']),
    ...mapActions('Affiliate', ['getWithdrawablesAffiliate', 'getWithdrawalCheckAmount']),
    async handleInfiniteLoading ($state) {
      await this.handleGetWithdrawables()
      if (this.withdrawables.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async handleGetWithdrawables (paginated = true) {
      this.loading = true
      try {
        const payload = {
          data: {
            paginated,
            per_page: this.perPage,
            page: this.withdrawables.pagination.nextPage || 1,
            period_date: this.formFilter.range || null
          }
        }

        if (this.formFilter.sort) {
          payload.data = Object.assign(payload.data, {
            order_by: {
              created_at: this.formFilter.sort
            }
          })
        }

        await this.getWithdrawablesAffiliate(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    },
    handleChecked (value) {
      const { id, amount, type, checked } = value

      if (checked) {
        this.checkedWithdrawals.push({
          id,
          amount,
          type
        })
      } else {
        const indexDelete = this.checkedWithdrawals.findIndex((dt) => {
          return dt.id === id
        })

        this.checkedWithdrawals.splice(indexDelete, 1)
      }
    },
    async handleCheckedAll (value) {
      if (value) {
        this.resetWithdrawables()
        await this.handleGetWithdrawables(false)
        this.checkedWithdrawals = this.listWithdrawables.map((withdrawal) => {
          return {
            id: withdrawal.id,
            amount: withdrawal.amount,
            type: withdrawal.withdrawable_type
          }
        })
      } else {
        this.checkedWithdrawals = []
      }
    },
    modalClose () {
      this.checkedWithdrawals = []
    },
    alertClose () {
      this.showAlertAmount = false
    },
    drawCommission () {
      this.handleMinimalAmount().then((response) => {
        if (response && response.data.status_code === 200) {
          this.setSelectedWithdrawables(this.checkedWithdrawals)
          const temp = this.checkedWithdrawals
          this.checkedWithdrawals = JSON.parse(JSON.stringify(temp))
          this.$refs.hideModal.$el.click()
          this.$emit('draw-commission')
        }
      })
    },
    async handleMinimalAmount () {
      try {
        const payload = {
          data: {
            amount: this.totalAmount
          }
        }
        const response = await this.getWithdrawalCheckAmount(payload)
        return response
      } catch (error) {
        if (error.response.data.status_code === 400) {
          this.showAlertAmount = true
          setTimeout(() => {
            this.showAlertAmount = false
          }, 5000)
        }
      }
    }
  }
}
