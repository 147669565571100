//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'TopBrands',
  mixins: [panstoreMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('User', ['profileUser']),
    ...mapState('Brand', ['affiliateUserBrand'])
  }
}
