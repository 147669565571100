//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixins from '@/mixins'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  mixins: [panstoreMixins],

  data () {
    return {
      activeTabName: 'wishlist',

      wishlistTabs: [
        {
          name: 'wishlist',
          label: 'Favorit Saya',
          product: {
            isLoading: false,
            page: 1,
            perPage: 8,
            totalPage: 0,
            total: 0,
            data: []
          }
        },
        {
          name: 'lastSeen',
          label: 'Terakhir Dilihat',
          product: {
            isLoading: false,
            page: 1,
            perPage: 8,
            totalPage: 0,
            total: 0,
            data: []
          }
        }
      ]
    }
  },

  computed: {
    ...mapState('User/address', ['location']),
    ...mapState('Base', ['dataBreadCrumbs']),

    activeWishlistTabIndex () {
      return this.wishlistTabs.findIndex(tab => tab.name === this.activeTabName)
    }
  },

  watch: {
    activeTabName () {
      if (
        this.activeWishlistTabIndex >= 0 &&
        this.wishlistTabs[this.activeWishlistTabIndex].product.data <= 0
      ) {
        this.getTabData()
      }
    }
  },

  mounted () {
    this.getTabData()
  },

  methods: {
    ...mapActions('Product', ['getProductWishlist']),
    ...mapActions('Contents', ['getLastSeenProducts']),
    ...mapMutations('Base', ['setBreadCrumbs']),

    handleInfiniteLoading () {
      this.getTabData()
    },

    async getTabData () {
      if (this.activeWishlistTabIndex >= 0) {
        this.wishlistTabs[this.activeWishlistTabIndex].product.isLoading = true

        try {
          let resp
          const requestPayload = {
            data: {
              page: this.wishlistTabs[this.activeWishlistTabIndex].product.page,
              per_page: this.wishlistTabs[this.activeWishlistTabIndex].product.perPage
            }
          }

          switch (this.activeTabName) {
            case 'wishlist':
              resp = await this.getProductWishlist(requestPayload)
              break;
            case 'lastSeen':
              resp = await this.getLastSeenProducts(requestPayload)
              break;
          }

          if (resp) {
            this.wishlistTabs[this.activeWishlistTabIndex].product.total = resp.data.data.products.total
            this.wishlistTabs[this.activeWishlistTabIndex].product.totalPage = resp.data.data.products.last_page
            this.wishlistTabs[this.activeWishlistTabIndex].product.data = [
              ...this.wishlistTabs[this.activeWishlistTabIndex].product.data,
              ...resp.data.data.products.data
            ]
            this.wishlistTabs[this.activeWishlistTabIndex].product.page++

            if (
              this.wishlistTabs[this.activeWishlistTabIndex].product.page >
              this.wishlistTabs[this.activeWishlistTabIndex].product.totalPage
            ) {
              if (
                Array.isArray(this.$refs['InfiniteLoading' + this.activeTabName]) &&
                this.$refs['InfiniteLoading' + this.activeTabName].length > 0
              ) {
                this.$refs['InfiniteLoading' + this.activeTabName][0].stateChanger.complete()
              } else if (this.$refs['InfiniteLoading' + this.activeTabName]) {
                this.$refs['InfiniteLoading' + this.activeTabName].stateChanger.complete()
              }
            } else if (
              Array.isArray(this.$refs['InfiniteLoading' + this.activeTabName]) &&
              this.$refs['InfiniteLoading' + this.activeTabName].length > 0
            ) {
              this.$refs['InfiniteLoading' + this.activeTabName][0].stateChanger.loaded()
            } else if (this.$refs['InfiniteLoading' + this.activeTabName]) {
              this.$refs['InfiniteLoading' + this.activeTabName].stateChanger.loaded()
            }
          }
        } catch (error) {
          const tabName = this.wishlistTabs[this.activeWishlistTabIndex].label

          this.$message.error(`Gagal mendapatkan data ${tabName.toLowerCase()} produk`)
        }

        this.wishlistTabs[this.activeWishlistTabIndex].product.isLoading = false
      }
    },

    setTab (tab) {
      this.activeTabName = tab.name

      const temp = JSON.parse(JSON.stringify(this.dataBreadCrumbs))
      temp[temp.length - 1].name = tab.label

      this.setBreadCrumbs(temp)
    }
  }
}
