var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-order-review"},_vm._l((_vm.orderReviews.data),function(list,index){return _c('div',{key:index,staticClass:"card-comp card-order-review rounded border"},[_c('div',{staticClass:"\n        card-header-review\n        d-flex\n        flex-column flex-md-row\n        border-bottom\n        pb-4\n      "},[_c('div',{staticClass:"profile-wrapper d-flex align-items-center"},[_c('div',{staticClass:"img-wrapper me-3"},[_c('img',{staticClass:"img-profile",attrs:{"src":_vm.getAvatar(list.user.files || []),"alt":"3s"},on:{"error":function () {
                            var args = [], len = arguments.length;
                            while ( len-- ) args[ len ] = arguments[ len ];

                            return _vm.getDefaultImgAvatar.apply(
                  void 0, args.concat( [list.user.detail ? list.user.detail.gender : 'male'] )
                );
}}})]),_vm._v(" "),_c('div',{staticClass:"\n            profile-detail\n            d-flex\n            flex-column flex-md-row\n            align-items-xl-center\n          "},[_c('div',{staticClass:"profile-name me-0 me-md-3 my-auto"},[_vm._v("\n            "+_vm._s(list.user.first_name)+" "+_vm._s(list.user.last_name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"profile-date me-0 me-md-3 d-none d-md-block"},[_vm._v("\n            |\n          ")]),_vm._v(" "),_c('div',{staticClass:"profile-date mb-2 mb-md-0"},[_vm._v("\n            "+_vm._s(list.timestamp)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"datetime-detail d-block d-md-none"},[_c('client-only',[_c('el-rate',{staticClass:"custom-rating",attrs:{"value":Number(list.rating),"disabled":"","colors":['#FFCF44', '#FFCF44', '#FFCF44'],"score-template":"{value}"}})],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"datetime-detail ms-0 ms-md-auto my-auto d-none d-md-block"},[_c('client-only',[_c('el-rate',{staticClass:"custom-rating",attrs:{"value":Number(list.rating),"disabled":"","colors":['#FFCF44', '#FFCF44', '#FFCF44'],"score-template":"{value}"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[(Object.keys(list.reviewable.order_products).length)?_c('div',{staticClass:"col-lg-5 mb-3 mb-lg-0 border-right pe-4"},[_c('div',{staticClass:"card-comp product-info p-0"},[_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"img-product",attrs:{"src":list.reviewable.order_products.files.length
                    ? list.reviewable.order_products.files[0].value +
                      '.webp'
                    : _vm.getDefaultImgProduct,"alt":"Gambar Produk"},on:{"error":_vm.getDefaultImgProduct}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('div',{staticClass:"font-heading-4 pb-1"},[_vm._v("\n                  "+_vm._s(list.reviewable.order_products.brand_name)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"font-website-small pb-1"},[_vm._v("\n                  "+_vm._s(list.reviewable.order_products.product_name)+"\n                ")]),_vm._v(" "),(list.reviewable.order_products.detail_name)?_c('div',{staticClass:"product-variant pb-1"},[_vm._v("\n                  Varian: "+_vm._s(list.reviewable.order_products.detail_name)+"\n                ")]):_vm._e()])]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":"/"}},[_c('Button',{staticClass:"btn btn-main w-100",attrs:{"text":"Beli Lagi"}})],1)],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-lg-7 product-review ps-4"},[(list.suggestions.length)?_c('div',{staticClass:"product-excess d-block d-lg-flex align-items-center row"},[_c('div',{staticClass:"excess-title me-2 mb-3 col-12"},[_vm._v("\n              YANG DISUKAI\n            ")]),_vm._v(" "),_c('div',{staticClass:"excess-wrapper col-12"},_vm._l((list.suggestions),function(item,idx){return _c('div',{key:idx,staticClass:"excess-type mb-2 mb-lg-0 me-2"},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])}),0)]):_vm._e(),_vm._v(" "),(list.description)?_c('div',{staticClass:"product-text-review"},[_c('v-clamp',{ref:'desc-' + index,refInFor:true,attrs:{"autoresize":"","max-lines":3,"expanded":false}},[_vm._v("\n              "+_vm._s(list.description)+"\n              "),(list.description.length > 300)?_c('a',{staticClass:"toggle",attrs:{"slot":"after","href":"javascript:void(0)"},on:{"click":function($event){return _vm.expandedToggle(index)}},slot:"after"},[_vm._v("Lihat selengkapnya\n              ")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
              _vm.getImagesReview(list.files, 'thumbnail') ||
                _vm.getImagesReview(list.files, 'original')
            )?_c('div',{staticClass:"product-pict-wrapper"},_vm._l((_vm.getImagesReview(
                list.files,
                'thumbnail'
              ).slice(0, 3)),function(item,idx){return _c('div',{key:idx,staticClass:"picture-item me-2",on:{"click":function($event){return _vm.showImage(idx, index)}}},[_c('img',{staticClass:"product-pict",attrs:{"src":item.value + '.webp',"alt":"Product Img"},on:{"error":_vm.getDefaultImgProduct}}),_vm._v(" "),(
                  _vm.getImagesReview(list.files, 'thumbnail').length > 3 &&
                    idx === 2
                )?[_c('div',{staticClass:"qty-wrapper"}),_vm._v(" "),_c('div',{staticClass:"qty-image"},[_vm._v("\n                  +"+_vm._s(_vm.getImagesReview(list.files, "thumbnail").length - 3)+"\n                ")])]:_vm._e()],2)}),0):_vm._e()])])]),_vm._v(" "),_c('client-only',[_c('vue-easy-lightbox',{attrs:{"visible":_vm.visible,"index":_vm.indexImg,"imgs":_vm.imgReview},on:{"hide":function($event){_vm.visible = false}}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }