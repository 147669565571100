//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'TopBrands',
  mixins: [panstoreMixin],
  // props: {
  //   loading: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    // ...mapState('User', ['profileUser']),
    ...mapState('Brand', ['bestSellingBrandAffiliate'])
  },
  async mounted () {
    await this.handleTopBrandAffiliate()
  },
  methods: {
    ...mapActions('Brand', ['getBestSellingBrandAffiliate']),

    async handleTopBrandAffiliate () {
      this.loading = true
      try {
        const payload = {
          params: {
            paginated: true,
            per_page: 4
          }
        }

        await this.getBestSellingBrandAffiliate(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    }
  }
}
