//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: Array,
      default: () => ([])
    },
    inputValue: {
      type: Object,
      default: () => ({})
    },
    voucher: {
      type: Object,
      default: () => ({})
    },
    description: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checked: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    getImage (type) {
      if (type === 'discount') {
        return require('@/static/img/home/cart/voucher-tag.svg')
      } else {
        return require('@/static/img/home/cart/voucher-truck.svg')
      }
    }
  }
}
