import { render, staticRenderFns } from "./CardStatsBank.vue?vue&type=template&id=46789445&scoped=true&"
import script from "./CardStatsBank.vue?vue&type=script&lang=js&"
export * from "./CardStatsBank.vue?vue&type=script&lang=js&"
import style0 from "./CardStatsBank.vue?vue&type=style&index=0&id=46789445&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46789445",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalDeleteAccountBank: require('/usr/src/app/components/account/bank-account/ModalDeleteAccountBank.vue').default})
