//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions } from 'vuex'
export default {
  mixins: [mixins],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions('User', ['deletePayment', 'getPayment']),
    showLabel (value) {
      let str = ''
      const count = value.length
      if (value) {
        str = '**** ' + value.substring(count - 4, count)
      } else {
        str = value
      }
      return str
    },
    handleDeletePayment () {
      this.$Swal
        .fire({
          title: 'Apakah Anda yakin?',
          text: '',
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ya, saya yakin',
          cancelButtonText: 'Batal'
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const resp = await this.deletePayment({
                data: {
                  id: [this.item.id]
                }
              })
              if (resp.status === 200) {
                this.$Swal.fire({
                  icon: 'success',
                  title: 'Berhasil',
                  text: 'Kartu Kredit berhasil dihapus.',
                  heightAuto: false
                })
                this.handleGetPayment()
                this.$refs.closeModal.click()
              }
            } catch (error) {
              console.log(error)
            }
          }
        })
    },
    async handleGetPayment () {
      try {
        await this.getPayment({
          data: {
            paginated: true,
            per_page: 10,
            page: 1,
            where: {
              type: 'Credit Card'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
