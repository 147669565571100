//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      onLoading: true
    }
  },
  computed: {
    ...mapState('Brand', ['brands'])
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Brand', ['getListBrands']),
    async initComponent () {
      try {
        const resp = await this.getListBrands({
          data: {
            where_has: [
              {
                relation: 'products.details.store_branches.store',
                where: {
                  'stores.id': this.$route.params.id
                }
              }
            ],
            paginated: false
          }
        })
        if (resp.status === 200) {
          this.onLoading = false
        }
      } catch (err) {
        this.onLoading = false
        console.log(err)
      }
    },
    handleClickImage (id) {
      this.$router.push('/partner/' + id)
    }
  }
}
