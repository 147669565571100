//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions } from 'vuex';

export default {
  mixins: [mixins],

  props: {
    eventSaleSectionId: {
      type: String,
      default: null
    },

    eventSaleSessionId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      eventSaleSection: null,
      eventSaleProducts: {
        isLoading: false,
        page: 1,
        perPage: 10,
        totalPage: 0,
        total: 0,
        data: []
      }
    }
  },

  computed: {
    bannerHeader () {
      if (!this.eventSaleSection || !this.eventSaleSection.files) {
        return null
      }

      const bannerHeaders = this.eventSaleSection.files.filter((file) => {
        return file.name === 'header'
      })

      if (bannerHeaders.length > 0) {
        return bannerHeaders[0].value
      }

      return null
    }
  },

  methods: {
    ...mapActions('Contents', [
      'getEventSaleSection',
      'getEventSaleOtherSection'
    ]),

    handleInfiniteLoading () {
      this.getEventSaleSectionProducts()
    },

    async getEventSaleSectionProducts () {
      this.eventSaleProducts.isLoading = true

      const wherePayload = {}
      if (this.eventSaleSessionId) {
        wherePayload.event_sale_session_id = this.eventSaleSessionId
      }

      try {
        let resp

        if (this.eventSaleSectionId) {
          resp = await this.getEventSaleSection({
            id: this.eventSaleSectionId,
            payload: {
              data: {
                page: this.eventSaleProducts.page,
                per_page: this.eventSaleProducts.perPage,
                where: wherePayload
              }
            }
          })
        } else {
          resp = await this.getEventSaleOtherSection({
            data: {
              page: this.eventSaleProducts.page,
              per_page: this.eventSaleProducts.perPage,
              where: wherePayload
            }
          })
        }

        if (resp.data.data.event_sale_section) {
          this.eventSaleProducts.data = [
            ...this.eventSaleProducts.data,
            ...resp.data.data.event_sale_section.event_sale_products.data
          ]
          this.eventSaleProducts.totalPage = resp.data.data.event_sale_section.event_sale_products.last_page
          this.eventSaleProducts.total = resp.data.data.event_sale_section.event_sale_products.total

          this.eventSaleSection = resp.data.data.event_sale_section
          delete this.eventSaleSection.event_sale_products
          this.eventSaleProducts.page++
        }

        if (this.eventSaleProducts.page > this.eventSaleProducts.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) &&
            this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (
          Array.isArray(this.$refs.InfiniteLoading) &&
          this.$refs.InfiniteLoading.length > 0
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (e) {
        this.$message.error('Gagal mendapatkan data sale section produk')
      }

      this.eventSaleProducts.isLoading = false
    }
  }
}
