//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

export default {
  name: 'ModalCreateBankAccount',
  props: {
    modalId: {
      type: String,
      default: 'modalCreateBankAccount'
    }
  },
  data () {
    const validateNumeric = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[0-9]+$/
      const field = rule.field

      if (value.length === 1) {
        if (value === ' ') {
          this.formBankAccount[field] = ''
        }
      }

      if (value === ' ') {
        this.formBankAccount[field] = ''
      }

      if (!input.match(letters)) {
        this.formBankAccount[field] = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formBankAccount[field] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formBankAccount[field] =
          value.substr(0, value.length - 1) + input.toUpperCase()
      }

      callback()
    }

    const validateAlphaCapitalize = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[A-Za-z'. ]+$/
      const firstLetter = /^['\\. ]+$/
      const field = rule.field

      if (value.length === 1) {
        if (value === ' ') {
          this.formBankAccount[field] = ''
        } else if (value.match(firstLetter)) {
          this.formBankAccount[field] = ''
        } else {
          this.formBankAccount[field] = value.substr(0, 1).toUpperCase()
        }
      }

      if (value === ' ') {
        this.formBankAccount[field] = ''
      }

      if (!input.match(letters)) {
        this.formBankAccount[field] = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formBankAccount[field] = value.substr(0, value.length - 1)
      } else if (input.match(firstLetter) && input.match(inputBefore)) {
        this.formBankAccount[field] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formBankAccount[field] =
          value.substr(0, value.length - 1) + input.toUpperCase()
      }

      callback()
    }

    return {
      formBankAccount: {
        bank: '',
        accountNumber: '',
        onBehalfOf: ''
      },
      formBankAccountRules: {
        bank: [
          {
            required: true,
            message: 'Bank wajib dipilih',
            trigger: ['change', 'blur']
          }
        ],
        accountNumber: [
          {
            required: true,
            message: 'Nomor rekening wajib diisi',
            trigger: ['change', 'blur']
          },
          {
            validator: validateNumeric
          }
        ],
        onBehalfOf: [
          {
            required: true,
            message: 'Nama pemegang wajib diisi',
            trigger: ['change', 'blur']
          },
          {
            validator: validateAlphaCapitalize
          }
        ]
      },
      onSubmit: false
    }
  },
  computed: {
    ...mapState('BankAccount', ['masterBanks']),
    listMasterBank () {
      return this.masterBanks.data.map((bank) => {
        return {
          value: bank.id,
          label: bank.name
        }
      })
    }
  },
  methods: {
    ...mapActions('BankAccount', ['createBankAccount']),
    resetForm (clearCart) {
      this.$refs[clearCart].resetFields();
    },
    async submitForm (formBank) {
      let result = false
      this.$refs[formBank].validate((valid) => {
        result = valid
      });
      if (!result) {
        return
      }
      this.onSubmit = true

      try {
        const payload = {
          data: {
            name: null,
            owner_name: this.formBankAccount.onBehalfOf,
            value: {
              account_number: this.formBankAccount.accountNumber
            },
            master_bank_id: this.formBankAccount.bank,
            type: 'Bank'
          }
        }

        await this.createBankAccount(payload)
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Berhasil menambahkan akun bank!'
          })
          .then((confirm) => {
            this.$emit('create-bank-success')
            this.resetForm('formBankAccount')
            this.$refs.btnClose.click()
          })
      } catch (error) {
        console.log(error)
      }
      this.$emit('on-bank-updated')
      this.onSubmit = false
    },
    modalClose () {
      this.$emit('modal-close')
    }
  }
}
