//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
export default {
  layout: 'account',
  middleware: ['user'],
  data () {
    return {
      inputSearch: '',
      isMobileSearch: false,
      disable: true,
      date: [],
      sort: '',
      type: '',
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Beranda',
          path: '/account/home'
        },
        {
          name: '<b>Belanja Saya</b>'
        },
        {
          name: 'Pesanan Saya'
        }
      ],
      onLoad: false,
      tabsList: [
        {
          id: 0,
          name: 'SEMUA',
          slug: '',
          active: true,
          data: 'allOrder',
          loadMore: false,
          firstLoad: false,
          payload: {
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        },
        {
          id: 1,
          name: 'Belum Bayar',
          active: false,
          data: 'orderNotPaid',
          loadMore: false,
          firstLoad: false,
          payload: {
            where_has: [
              {
                relation: 'status',
                where_in: [['slug', ['menunggu-pembayaran', 'pre-order']]]
              }
            ],
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        },
        {
          id: 2,
          name: 'Diproses',
          active: false,
          data: 'orderProcess',
          loadMore: false,
          firstLoad: false,
          payload: {
            where_has: [
              {
                relation: 'status',
                where_in: [
                  [
                    'slug',
                    [
                      'pembayaran-berhasil',
                      'menunggu-konfirmasi-pembayaran',
                      'pesanan-sedang-dikemas'
                    ]
                  ]
                ]
              }
            ],
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        },
        {
          id: 3,
          name: 'Dikirim',
          active: false,
          data: 'orderSent',
          loadMore: false,
          firstLoad: false,
          payload: {
            where: {
              order_method: 'shipping'
            },
            where_has: [
              {
                relation: 'status',
                where: {
                  slug: 'pesanan-telah-dikirim'
                }
              }
            ],
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        },
        {
          id: 4,
          name: 'Siap Diambil',
          active: false,
          data: 'orderReadyPickUp',
          loadMore: false,
          firstLoad: false,
          payload: {
            where: {
              order_method: 'pickup'
            },
            where_has: [
              {
                relation: 'status',
                where: {
                  slug: 'pesanan-telah-dikirim'
                }
              }
            ],
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        },
        {
          id: 5,
          name: 'Selesai',
          active: false,
          data: 'orderDone',
          loadMore: false,
          firstLoad: false,
          payload: {
            where_has: [
              {
                relation: 'status',
                where: {
                  slug: 'transaksi-selesai'
                }
              }
            ],
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        },
        {
          id: 6,
          name: 'Dibatalkan',
          active: false,
          data: 'orderCanceled',
          loadMore: false,
          firstLoad: false,
          payload: {
            where_has: [
              {
                relation: 'status',
                where_in: [
                  [
                    'slug',
                    ['bukti-pembayaran-ditolak', 'transaksi-dibatalkan']
                  ]
                ]
              }
            ],
            page: 1,
            per_page: 10,
            order_by: {
              created_at: 'desc'
            }
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('Order', [
      'allOrder',
      'orderNotPaid',
      'orderProcess',
      'orderSent',
      'orderReadyPickUp',
      'orderDone',
      'orderCanceled'
    ]),
    tabActive () {
      return this.tabsList.findIndex((tab) => {
        return tab.active
      })
    }
  },
  async mounted () {
    await this.handleGetOrder()
  },
  methods: {
    ...mapActions('Order', ['getMyOrders']),
    ...mapMutations('Order', ['resetOrder']),
    async handleGetOrder () {
      console.log('here')
      this.onLoad = true
      const payload = {
        payload: {
          data: {
            ...this.tabsList[this.tabActive].payload,
            search: this.inputSearch
          }
        },
        type: this.tabsList[this.tabActive].data
      }
      try {
        const resp = await this.getMyOrders(payload)
        if (resp.status === 200) {
          this.tabsList[this.tabActive].loadMore =
            resp.data.data.orders.current_page <
            resp.data.data.orders.last_page
          this.tabsList[this.tabActive].payload.page =
            resp.data.data.orders.current_page + 1
          this.tabsList[this.tabActive].firstLoad = true
        }
      } catch (error) {
        this.tabsList[this.tabActive].loadMore = false
        this.tabsList[this.tabActive].firstLoad = true
        console.log(error)
      }
      this.onLoad = false
    },
    handleTabs (id, idx) {
      const tab = this.tabActive
      this.tabsList.map((item) => {
        if (item.id === id) {
          item.active = true
          if (tab !== idx && !this.getData().length) {
            this.handleGetOrder()
          }
        } else {
          item.active = false
        }
      })
    },
    handleSearch: _.debounce(function () {
      this.resetOrder(this.tabsList[this.tabActive].data)
      this.tabsList[this.tabActive].firstLoad = false
      this.tabsList[this.tabActive].loadMore = false
      this.tabsList[this.tabActive].payload.page = 1
      this.handleGetOrder()
    }, 2000),
    handleSearchMobile () {
      this.isMobileSearch = !this.isMobileSearch
    },
    getData () {
      const name = this.tabsList[this.tabActive].data
      return this[name]
    },
    handleChangeSort () {
      this.resetOrder(this.tabsList[this.tabActive].data)
      this.tabsList[this.tabActive].firstLoad = false
      this.tabsList[this.tabActive].loadMore = false
      this.tabsList.map((data, index) => {
        this.tabsList[index].payload.order_by.created_at = this.sort || 'desc'
        this.tabsList[index].payload.page = 1
      })
      this.handleGetOrder()
    },
    handleChangeType () {
      this.resetOrder(this.tabsList[this.tabActive].data)
      this.tabsList[this.tabActive].firstLoad = false

      this.tabsList[this.tabActive].loadMore = false
      this.tabsList.map((data, index) => {
        this.tabsList[index].payload = Object.assign({
          ...this.tabsList[index].payload,
          where: {
            ...this.tabsList[index].payload.where,
            order_method: this.type
          },
          page: 1
        })
        if (!this.type) {
          delete this.tabsList[index].payload.where.order_method
        }
      })
      this.handleGetOrder()
    },
    handleChangeDate () {
      this.resetOrder(this.tabsList[this.tabActive].data)
      this.tabsList[this.tabActive].firstLoad = false
      this.tabsList[this.tabActive].loadMore = false
      this.tabsList.map((data, index) => {
        this.tabsList[index].payload = Object.assign({
          ...this.tabsList[index].payload,
          where_greater_than: {
            created_at: this.date ? this.date[0] : null
          },
          where_lower_than: {
            created_at: this.date ? this.date[1] : null
          },
          page: 1
        })
        if (!this.date) {
          delete this.tabsList[index].payload.where_greater_than
          delete this.tabsList[index].payload.where_lower_than
        }
      })
      this.handleGetOrder()
    }
  },
  head: () => {
    return {
      title: 'Pesanan Saya | Paninti Store'
    }
  }
}
