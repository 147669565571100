//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import VClamp from 'vue-clamp';

export default {
  components: { VClamp },
  mixins: [mixins],

  props: {
    product: {
      type: Object,
      default: () => {}
    }
  }
}
