//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Cart', ['selectedProduct', 'selectedVariant']),
    preOrderShow () {
      if (this.indexVariant === -1) {
        return this.selectedProduct.is_preorder
      }

      return !!this.selectedProduct.details[this.indexVariant].is_preorder
    },
    indexVariant () {
      if (this.selectedVariant) {
        return this.selectedProduct.details.findIndex((detail) => {
          return detail.id === this.selectedVariant
        })
      }
      return -1
    },
    preOrderPeriode () {
      if (this.indexVariant === -1) {
        return this.selectedProduct.preorder_period
      }

      return this.selectedProduct.details[this.indexVariant].preorder_period
    }
  },
  methods: {
    getImage () {
      if (this.selectedProduct.type === 'simple') {
        return this.getThumbnailImage(this.selectedProduct.files || [], 'image')
      } else {
        const objVariant = this.selectedProduct.details.filter((variant) => {
          return variant.id === this.selectedVariant
        })[0]
        return this.getThumbnailImage(
          objVariant ? objVariant.files : this.selectedProduct.files,
          'image'
        )
      }
    },
    getVariant () {
      if (this.selectedProduct) {
        const variant = this.selectedProduct.details.filter((variant) => {
          return variant.id === this.selectedVariant
        })[0]
        return variant && variant.name && variant.name !== undefined
          ? variant.name
          : '-'
      }
    },
    getPrice () {
      let price = this.selectedProduct.discounted_price || this.selectedProduct.current_price
      if (this.selectedVariant) {
        this.selectedProduct.details.map((detail) => {
          if (detail.id === this.selectedVariant) {
            price =
                detail.discounted_price || this.selectedProduct.discounted_price || this.selectedProduct.current_price
          }
        })
      }
      return price
    }
  }
}
