//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    onSubmit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions('BankAccount', ['deleteBankAccount']),

    async handleDeleteBank (id) {
      this.$emit('on-submit-change', true)

      try {
        await this.deleteBankAccount({
          data: {
            id: [id]
          }
        })

        this.$refs.closeButton.click()
      } catch (error) {
        console.log(error)
      }

      this.$emit('on-bank-deleted')
      this.$emit('on-submit-change', false)
    }
  }
}
