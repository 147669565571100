//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [mixins],

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },

      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0,

      similarProducts: []
    }
  },

  computed: {
    ...mapState('Product', ['product']),
    ...mapState('User/address', ['location']),

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 427
    }
  },

  watch: {
    location () {
      this.getSimilarProducts()
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.getSimilarProducts()
  },

  methods: {
    async getSimilarProducts () {
      const cityId = localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location')).city_id
        : 155

      try {
        const respSimilar = await this.$store.dispatch('Product/getHomeProducts', {
          data: {
            city_id: cityId,
            where_has: [
              {
                relation: 'categorizable',
                where: {
                  'categories.id': this.product.categories.length
                    ? this.product.categories[0].id
                    : null
                }
              }
            ],
            or_where: {
              sku: this.product.sku
            },
            where_not_in: [['products.slug', [this.$route.params.slug]]],
            order_by: {
              created_at: 'desc'
            },
            per_page: 10
          }
        })

        this.similarProducts = respSimilar.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk serupa')
      }
    },

    onResize () {
      this.windowWidth = process.client && window ? window.innerWidth : 0
    }
  }
}
