//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'CommissionHistory',
  mixins: [panstoreMixin],
  props: {
    totalCommission: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('Affiliate', ['commissionHistory']),
    listHistory () {
      return this.commissionHistory.data
    }
  },
  async mounted () {
    this.resetCommissionHistory()
    await this.fetchAffiliateCommission()
  },
  methods: {
    ...mapMutations('Affiliate', ['resetCommissionHistory']),
    ...mapActions('Affiliate', ['getCommissionHistory', 'getCommissionHistoryDetail']),
    async handleInfiniteLoading ($state) {
      await this.fetchAffiliateCommission()
      if (this.commissionHistory.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async fetchAffiliateCommission () {
      const payload = {
        params: {
          paginated: true,
          per_page: 5,
          page: this.commissionHistory.pagination.nextPage || 1,
          period_date: 'last-week'
        }
      }

      try {
        await this.getCommissionHistory(payload)
      } catch (error) {
        console.log(error)
      }
    },
    async openDetailHistory (id) {
      try {
        const payload = {
          id
        }

        await this.getCommissionHistoryDetail(payload)
        setTimeout(() => { this.$refs.btnOpenDetail.click() })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    }
  }
}
