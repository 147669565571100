//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    dataPayment: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
