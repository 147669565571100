import { render, staticRenderFns } from "./SortBySection.vue?vue&type=template&id=08b53db8&scoped=true&"
import script from "./SortBySection.vue?vue&type=script&lang=js&"
export * from "./SortBySection.vue?vue&type=script&lang=js&"
import style0 from "./SortBySection.vue?vue&type=style&index=0&id=08b53db8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b53db8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupFilter: require('/usr/src/app/components/home/products/catalogue/PopupFilter.vue').default,PopupCategoryFilter: require('/usr/src/app/components/home/category/PopupCategoryFilter.vue').default})
