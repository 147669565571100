//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
  export default {
    name: 'CardBenefit',
    mixins: [panstoreMixin],
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    }
  }
