//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
    layout: 'account',
    middleware: ['user'],
    mixins: [mixins],
    data () {
        const breadcrumbs = [
            {
                path: '/'
            },
            {
                name: 'Beranda',
                path: '/account/bank-account'
            },
            {
                name: 'Akun Bank'
            }
        ];
        return {
            breadCrumb: breadcrumbs
        };
    }
}
