//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  computed: {
    myPoint () {
      return this.$auth.$state.user.paninti_point
    }
  }
}
