//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  layout: 'account',
  mixins: [panstoreMixin],
  middleware: ['user', 'affiliate'],
  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Affiliasi',
          path: '/account/affiliate/'
        },
        {
          name: 'Komisi'
        }
      ],
      commissionStats: [
        {
          id: 1,
          title: 'Pesanan Selesai',
          subtitle: 'Januari',
          amount: 0,
          percentage: '-',
          price: false,
          trendStatus: 'constant',
          general: false
        },
        {
          id: 2,
          title: 'Total Komisi',
          subtitle: 'Januari',
          amount: 0,
          percentage: '-',
          price: true,
          trendStatus: 'constant',
          general: false
        },
        {
          id: 3,
          title: 'Penarikan Diproses',
          subtitle: '',
          amount: 0,
          price: true,
          general: true
        }
      ],
      optionsRange: [
        {
          label: 'Sepanjang Waktu',
          value: 'all'
        },
        {
          label: 'Hari Ini',
          value: 'today'
        },
        {
          label: 'Minggu Lalu',
          value: 'last-week'
        },
        {
          label: '30 Hari yang Lalu',
          value: '30-days'
        },
        {
          label: '60 Hari yang Lalu',
          value: '60-days'
        },
        {
          label: '90 Hari yang Lalu',
          value: '90-days'
        }
      ],
      options: [
        {
          label: 'Semua Komisi',
          value: 'all'
        },
        {
          label: 'Komisi Masuk',
          value: 'in'
        },
        {
          label: 'Komisi Ditarik',
          value: 'out'
        }
      ],
      formFilter: {
        range: 'all',
        type: 'all'
      },
      getListBankAccounts: {
        paginated: false,
        type: 'Bank',
        created: 'desc',
        pagination: {
          total: 0,
          totalPage: 0
        },
        perPage: 3,
        page: 1,
        data: []
      }
    }
  },
  async mounted () {
    const getApis = [
      this.handleGetMasterBanks(),
      this.handleGetBankAccounts(),
      this.handleGetCommissionSummary()
    ]

    await Promise.all(getApis)
  },
  computed: {
    ...mapState('Affiliate', ['commissionSummaryCount'])
  },
  methods: {
    ...mapActions('Affiliate', ['getCommissionSummaryCount']),
    ...mapActions('BankAccount', ['getMasterBanks', 'getBankAccounts', 'getBankAccountsWithdrawal']),
    async handleGetMasterBanks () {
      try {
        const payload = {
          data: {
            paginated: false
          }
        }

        await this.getMasterBanks(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    },
    async handleGetBankAccounts (successCallback) {
      try {
        const resp = await this.getBankAccountsWithdrawal({

          params: {
            paginated: this.getListBankAccounts.paginated,
            type: this.getListBankAccounts.type,
            per_page: this.getListBankAccounts.perPage,
            page: this.getListBankAccounts.page,
            order_by: {
              created_at: this.getListBankAccounts.created
            }
          }
        })

        this.getListBankAccounts.pagination.totalPage = resp.data.data.accounts.last_page
        this.getListBankAccounts.pagination.total = resp.data.data.accounts.total
        this.getListBankAccounts.data = [
          ...this.getListBankAccounts.data,
          ...resp.data.data.accounts
        ]
        this.getListBankAccounts.page++
      } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error
          this.$message.error(errorMessage)
      }
    },
    openSendWithdraw () {
      setTimeout(() => {
        this.$refs.btnOpenSendWithdraw.$el.click()
      }, 500)
    },
    async handleBankSuccess () {
      this.getListBankAccounts.paginated = false
      this.getListBankAccounts.page = 1
      this.getListBankAccounts.data = []
      this.getListBankAccounts.pagination.totalPage = 0
      this.getListBankAccounts.pagination.total = 0

      await this.handleGetBankAccounts()
      this.openSendWithdraw()
    },
    setSummary () {
      if (this.checkObject(this.commissionSummaryCount)) {
        const {
          completed_orders: completedOrders,
          commission_earned: commissionEarned,
          withdrawals
        } = this.commissionSummaryCount
        // completed orders
        this.commissionStats[0].amount = completedOrders.completed_orders_this_month
        this.commissionStats[0].subtitle = completedOrders.month
        this.commissionStats[0].percentage = completedOrders.trend_percentage
        this.commissionStats[0].trendStatus = completedOrders.trend_status

        // commission
        this.commissionStats[1].amount = commissionEarned.commission_earned_this_month
        this.commissionStats[1].subtitle = commissionEarned.month
        this.commissionStats[1].percentage = commissionEarned.trend_percentage
        this.commissionStats[1].trendStatus = commissionEarned.trend_status

        // processed withdraw
        this.commissionStats[2].amount = withdrawals.withdrawal_nominal
        this.commissionStats[2].subtitle = `(${withdrawals.withdrawal_pending} Pengajuan)`
      }
    },
    async handleGetCommissionSummary () {
      try {
        await this.getCommissionSummaryCount()
        this.setSummary()
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    },
    openChooseWithdraw () {
      setTimeout(() => {
        this.$refs.btnOpenChooseWithdraw.$el.click()
      }, 500)
    },
    openModalBank () {
      setTimeout(() => {
        this.$refs.btnOpenModalBank.$el.click()
      }, 500)
    }
  }
}
