//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  async asyncData ({ store }) {
    let clearanceSale = []
    try {
      const resp = await store.dispatch('Contents/getOngoingEventSale', {
        data: {
          where_has: [
            {
              relation: 'event_sale',
              where: {
                type: 'clearance_sale'
              }
            }
          ]
        }
      })
      if (resp.status === 200) {
        clearanceSale = resp.data.data.event_sale_sessions
      }
    } catch (error) {
      console.log(error)
    }
    return {
      brand: null,
      clearanceSale
    }
  },
  computed: {
    ...mapState('Brand', ['brands', 'onLoaded'])
  },
  watch: {
    brands: {
      handler () {
        const length = this.brands.length
        const index = Math.floor(Math.random() * length)
        this.brand = this.brands[index]
      },
      deep: true
    }
  },
  head: () => {
    return {
      title: 'Promo Besar-Besaran Untuk Berbagai Produk | Paninti Store',
      meta: [{
        hid: 'description',
        name: 'description',
        content: 'Langsung serbu berbagai produk menarik dari Paninti dengan diskon yang bikin ngiler! Ayo, jangan sampai kelewatan ya!'
      }]
    }
  }
}
