//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    flashSales: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      activeFlashSaleTab: this.$dayjs(new Date()).format('DD-MM-YYYY')
    }
  },

  computed: {
    flashSaleWeeks () {
      const date = new Date()
      const weeks = []

      for (let i = 0; i < 7; i++) {
        const temp = {}

        if (i === 0) {
          Object.assign(temp, {
            label: 'HARI INI',
            name: this.$dayjs(date).format('DD-MM-YYYY')
          })
        } else {
          const nextDay = this.$dayjs().add(i, 'd')
          Object.assign(temp, {
            label: this.$dayjs(nextDay).format('MMMM') === 'Nopember'
              ? this.$dayjs(nextDay).format('DD') + ' November'
              : this.$dayjs(nextDay).format('DD MMMM'),
            name: this.$dayjs(nextDay).format('DD-MM-YYYY')
          })
        }

        weeks.push(temp)
      }

      return weeks
    }
  },
  methods: {
    handleTabClick () {
      this.flashSales.findIndex((flashSale, index) => {
        if (flashSale.date === this.activeFlashSaleTab) {
          this.$emit('on-flash-sale-tab-changed', index)
        }
      })
    }
  }
}
