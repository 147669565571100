//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Checkout', [
      'branchSelected',
      'listItem',
      'storeBranch',
      'storeBranchPAS',
      'selectedAddress',
      'selectedCourier',
      'pageTakeInStore',
      'usePoint'
    ]),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    haveNotAvailableProduct () {
      return (
        Object.keys(this.listItem).length &&
        this.listItem.unavailable_with_branch.length > 0
      )
    },
    haveNotAvailableProductStock () {
      return (
        Object.keys(this.listItem).length &&
        this.listItem.unavailable_with_stock.length > 0
      )
    },
    haveNotAvailableToPickUp () {
      return (
        Object.keys(this.listItem).length &&
        this.listItem.unavailable_with_pas.length > 0
      )
    },
    haveNotAvailableToShipping () {
      return (
        Object.keys(this.listItem).length &&
        this.listItem.unavailable_with_shipping.length > 0
      )
    },
    haveNotAvailableWithPreorder () {
      return (
        Object.keys(this.listItem).length &&
        this.listItem.unavailable_with_preorder.length > 0
      )
    }

  },
  methods: {
    ...mapActions('Cart', ['updateCart', 'deleteCart']),
    async handleUpdateCartProduct (idProduct, newQuantity) {
      const payload = {
        data: {
          carts: [
            {
              id: idProduct,
              quantity: newQuantity
            }
          ]
        }
      }
      try {
        const resp = await this.updateCart(payload)
        if (resp.status === 200) {
          this.$message({
            showClose: true,
            message: 'Keranjang berhasil diubah!',
            type: 'success'
          })
          const resp = this.checkAvailibilities(
            JSON.parse(localStorage.cartItem),
            this.storeBranch[this.branchSelected].id,
            this.selectedAddress.id,
            this.selectedCourier.code,
            this.selectedCourier.service,
            this.pageTakeInStore,
            this.voucherUsedId,
            this.usePoint
          )

          const vouchers = resp.data.data.checkout.vouchers
          this.checkActiveOnlyVoucher(vouchers)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleDeleteCartProduct (id) {
      this.onSubmit = true

      const tempCart = JSON.parse(localStorage.cartItem)
      tempCart.splice(tempCart.indexOf(id), 1)
      localStorage.cartItem = JSON.stringify(tempCart)
      this.$Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        text: 'Produk berhasil dihapus dari keranjang!',
        heightAuto: false
      })
      if (tempCart.length) {
        const resp = await this.checkAvailibilities(
          tempCart,
          this.storeBranch[this.branchSelected].id,
          this.selectedAddress.id,
          this.selectedCourier.code,
          this.selectedCourier.service,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.usePoint
        )

        const vouchers = resp.data.data.checkout.vouchers
        this.checkActiveOnlyVoucher(vouchers)
      } else {
        this.$router.push('/cart')
      }
    }
  }
}
