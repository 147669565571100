import { render, staticRenderFns } from "./ProductProfileSection.vue?vue&type=template&id=01d44f31&scoped=true&"
import script from "./ProductProfileSection.vue?vue&type=script&lang=js&"
export * from "./ProductProfileSection.vue?vue&type=script&lang=js&"
import style0 from "./ProductProfileSection.vue?vue&type=style&index=0&id=01d44f31&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d44f31",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SortBySection: require('/usr/src/app/components/home/products/catalogue/SortBySection.vue').default,FilterProfileMobile: require('/usr/src/app/components/home/profile/filterProfileMobile.vue').default,CardProduct: require('/usr/src/app/components/home/base/CardProduct.vue').default,CardProductFlashsale: require('/usr/src/app/components/home/base/CardProductFlashsale.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default})
