//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import panstoreMixin from '@/mixins/index'
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  props: {
    account: {
      type: Boolean,
      default () {
        return false
      }
    },
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      icon: {
        Pembayaran: 'payment'
      },
      search: '',
      searchEventSale: '',
      input4: '',
      input5: '',
      select: '',
      mobileSearch: false,
      formSearch: {
        query: ''
      },
      formSearchStore: {
        query: ''
      },
      onLoad: true,
      selectedCategory: 0,
      alphabet: [],
      partner_list: [],
      partner_by_city: [],
      listSearch: [],
      listSearchStore: [],
      loading: true,
      notifications: [],
      unreadNotification: 0,
      showSearch: false,
      showSuggest: false,
      currentLocation: 'Jakarta Utara',
      windowWidth: process.client ? window.innerWidth : 0
    }
  },
  computed: {
    // state
    ...mapState({
      cart: state => state.Cart.cart,
      productWishlist: state => state.Product.productWishlist,
      withCart: state => state.Cart.withCart,
      notif: state => state.User.notif
    }),
    ...mapState('User', ['profileUser']),
    ...mapState('User/address', ['location']),
    ...mapState('Contents', ['eventSale', 'searchSale']),
    ...mapGetters('Cart', ['getTotalPriceCartNavbar', 'getTotalQtyCart', 'getListCart']),
    ...mapGetters('Product', ['getTotalQtyWishlist']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  watch: {
    $route (to, from) {
      if (to.name === 'cart' || to.name === 'checkout') {
        this.onLoad = true
      }
      if (from.name === 'cart' || from.name === 'checkout') {
        this.actionWithCart(true)
        this.initComponent()
        this.search = this.$route.query.search
      }
    },
    '$route.query' () {
      this.search = this.$route.query.search
    },
    location () {
      this.getLocation()
    },
    searchSale () {
      this.searchEventSale = this.searchSale
    }
  },
  created () {
    this.initComponent()
    this.getLocation()
    this.search = this.$route.query.search
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    ...mapMutations('Sidebar', ['setMainSidebar']),
    ...mapMutations('User', ['setUser']),
    ...mapMutations('Cart', ['setCart']),
    ...mapMutations('Product', ['setProductWishlist']),
    ...mapMutations('User/address', ['setLocation']),
    ...mapMutations('Contents', ['setSearchSale']),
    ...mapActions('Cart', ['getCarts', 'getCartsNavbar', 'deleteCart', 'actionWithCart']),
    ...mapActions('User', [
      'showProfileUser',
      'getNotification',
      'showNotification',
      'readAllNotification'
    ]),
    ...mapActions('Product', ['getProductWishlist']),
    ...mapActions('GoogleMaps', ['getOptCity']),
    initComponent () {
      if (this.$route.name !== 'cart' && this.$route.name !== 'checkout') {
        this.handleGetCart()
      }
      if (this.$auth.loggedIn) {
        if (this.$route.name !== 'wishlist') {
          this.handleGetWishlist()
        }

        this.handleGetNotif()
      }
    },

    async handleReadAll () {
      try {
        const resp = await this.readAllNotification()
        if (resp.status === 200) {
          this.handleGetNotif()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetCart () {
      try {
        const resp = await this.getCartsNavbar({
          params: {
            session_id: this.$cookies.get('session_id')
          }
        })
        if (resp.status === 200) {
          this.onLoad = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleDeleteCart (id) {
      try {
        const resp = await this.deleteCart({
          data: {
            id: [id],
            session_id: this.$cookies.get('session_id')
          }
        })
        if (resp.status === 200) {
          this.handleGetCart()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetNotif () {
      try {
        const resp = await this.getNotification({
          data: {
            order_by: { created_at: 'desc' },
            where: {
              application: 'paninti-store'
            },
            page: 1,
            per_page: 15
          }
        })
        if (resp.status === 200) {
          this.unreadNotification = resp.data.data.unread_notification_count
          this.notifications = this.notifications.concat(
            resp.data.data.notifications.data
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
    async handleGetWishlist () {
      try {
        await this.getProductWishlist({
          data: { session_id: this.$cookies.get('session_id') }
        })
      } catch (error) {
        console.error(error)
      }
    },
    getIcon (name) {
      switch (name) {
        case 'Pembayaran':
          return 'payment'
        case 'Pesanan Dikemas':
          return 'order-packaging'
        case 'Pengiriman':
          return 'order-shipping'
        case 'Order Sampai':
          return 'order-done'
        case 'Order Selesai':
          return 'order-done'
        case 'Order Dibatalkan':
          return 'order-done'
        case 'Diskon':
          return 'discount'
        case 'Sale':
          return 'discount'
        case 'Event Sale':
          return 'event-sale'
        case 'Voucher':
          return 'voucher'
        case 'Poin':
          return 'voucher'
        case 'Afiliasi':
          return 'affiliate'
      }
    },
    async handleClickNotif ({ id, link, notification_type: notifType }) {
      let redirect = link
      await this.showNotification(id)

      if (notifType.name === 'Afiliasi' && this.checkObject(this.user) && this.checkObject(this.user.affiliate)) {
        redirect = '/account/affiliate'
      }

      window.location = redirect
    },
    handleSearch () {
      const value = this.search
      const input = value.charAt(value.length - 1)
      const letters = /^[A-Za-z|&./+,\d\-_\s]+$/i
      if (value === ' ') {
        this.search = ''
      }

      if (!input.match(letters)) {
        this.search = value.substr(0, value.length - 1)
      }
    },
    handleSearchSale (event) {
      const value = this.searchEventSale
      const input = value.charAt(value.length - 1)
      const letters = /^[A-Za-z|&./+,\d\-_\s]+$/i
      if (value === ' ') {
        this.searchEventSale = ''
      }

      if (!input.match(letters)) {
        this.searchEventSale = value.substr(0, value.length - 1)
      }
      if (event.keyCode === 13) {
        event.preventDefault()
        event.target.blur()
      }
    },
    onSearch () {
      if (this.search) {
        if (this.$refs.inputSearch) {
          this.$refs.inputSearch.blur()
        }

        let url = `/products/all?search=${this.search}`

        if (this.isAffiliate) {
          if (this.$route.path.includes('/affiliate/partner')) {
            url = `/affiliate/partner?search=${this.search}`
          } else if (!this.$route.params.section) {
            window.location.href = `/affiliate/products/${this.$route.params.section === 'all' || !this.$route.params.section ? 'best-selling-this-month' : this.$route.params.section}?search=${this.search}`
            return
          } else {
            url = `/affiliate/products/${this.$route.params.section === 'all' || !this.$route.params.section ? 'best-selling-this-month' : this.$route.params.section}?search=${this.search}`
          }
        }

        this.$router.push(url)
      }
    },
    onSearchSale () {
      this.$refs.inputSearchSale.blur()
      this.setSearchSale(this.searchEventSale)
    },
    async logout () {
      await this.$auth.logout('local')
      this.setUser([])
      this.setCart([])
      this.setProductWishlist([])
      this.$cookies.remove('session_id')
      const path = this.$route.path
      if (
        path === '/cart' ||
        path === '/checkout' ||
        this.$route.name === 'payment-detail-order_id' ||
        this.$route.name === 'payment-confirmation-order_id'
      ) {
        window.location.replace('/')
      } else {
        window.location.reload()
      }
    },
    showMobileSearch () {
      this.showSearch = !this.showSearch
    },
    getLocation () {
      if (process.browser) {
        let location = {}
        if (localStorage.getItem('location')) {
          location = JSON.parse(localStorage.getItem('location')) || {}
        }
        if (Object.keys(location).length) {
          this.currentLocation = location.value
        } else if (this.$auth.loggedIn) {
          if (Object.keys(this.$auth.user.main_address).length) {
            const mainAddress = this.$auth.user.main_address
            const temp = {
              value: mainAddress.village.city,
              name:
                mainAddress.village.province + ', ' + mainAddress.village.city,
              city_id: mainAddress.village.subdistrict.city.id,
              isAddress: mainAddress.id
            }
            localStorage.location = JSON.stringify(temp)
            this.currentLocation = temp.value
          } else {
            this.defaultLocation()
          }
        } else {
          this.defaultLocation()
        }
      }
    },
    async defaultLocation () {
      try {
        const resp = await this.getOptCity({
          data: {
            paginated: false,
            search: 'Kota Jakarta Utara'
          }
        })
        if (resp.status === 200) {
          const temp = {
            value:
              resp.data.data.cities[0].name.split(', ')[1] || 'Jakarta Utara',
            name: resp.data.data.cities[0].name,
            city_id: resp.data.data.cities[0].city_id,
            isAddress: null
          }
          localStorage.location = JSON.stringify(temp)
          this.currentLocation = temp.value
        }
      } catch (error) {
        console.log(error)
      }
    },
    getEventSaleName () {
      const temp = this.eventSale.length
        ? this.eventSale.filter((event) => {
          return event.slug === this.$route.params.id
        })
        : []
      if (temp.length) {
        return temp[0].name
      } else {
        return 'Event Sale'
      }
    },
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },
    handleBlur () {
      setTimeout(() => {
        this.showSuggest = false
      }, 250)
    }
  }
}
