//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapMutations, mapActions } from 'vuex'
export default {
  mixins: [panstoreMixin],
  props: {
    datas: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    ...mapMutations('Order', ['setOrder']),
    ...mapActions('Order', ['checkReorder']),
    toDetailOrder (id) {
      window.location = `/account/shop/my-order/detail/${id}`
    },
    async handleAddMore (item) {
      const id = []
      item.products.map((i) => {
        id.push(i.id)
      })
      try {
        const resp = await this.checkReorder({
          data: {
            order_product_id: id
          }
        })
        if (resp.status === 200) {
          this.setOrder({ products: resp.data.data.order_products })
          this.$refs.showPopupReorder.click()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
