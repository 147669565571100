import { render, staticRenderFns } from "./ModalChooseWithdrawCommission.vue?vue&type=template&id=66cf95f6&scoped=true&"
import script from "./ModalChooseWithdrawCommission.vue?vue&type=script&lang=js&"
export * from "./ModalChooseWithdrawCommission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66cf95f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,CommissionWithdrawItem: require('/usr/src/app/components/account/affiliate/commission/CommissionWithdrawItem.vue').default})
