//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'auth',
  middleware: ['guest'],
  head: () => {
    return {
      title: 'Reset Password - Paninti Store',
      meta: [{
        hid: 'description',
        name: 'description',
        content: 'Reset Password Page'
      }]
    }
  }
}
