import { render, staticRenderFns } from "./AccountPromotion.vue?vue&type=template&id=3a664cba&scoped=true&"
import script from "./AccountPromotion.vue?vue&type=script&lang=js&"
export * from "./AccountPromotion.vue?vue&type=script&lang=js&"
import style0 from "./AccountPromotion.vue?vue&type=style&index=0&id=3a664cba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a664cba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerPromotionExclusive: require('/usr/src/app/components/home/BannerPromotionExclusive.vue').default})
