import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c8829c70&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,CardShippingAddress: require('/usr/src/app/components/home/checkout/CardShippingAddress.vue').default,CardSelectStoreBranch: require('/usr/src/app/components/home/checkout/CardSelectStoreBranch.vue').default,CardSelectCourier: require('/usr/src/app/components/home/checkout/CardSelectCourier.vue').default,CartPaycheck: require('/usr/src/app/components/home/cart/CartPaycheck.vue').default,PopupVoucher: require('/usr/src/app/components/home/cart/PopupVoucher.vue').default,PopupDetailVoucher: require('/usr/src/app/components/home/cart/PopupDetailVoucher.vue').default,PopupPaymentMethod: require('/usr/src/app/components/home/checkout/PopupPaymentMethod.vue').default,PopupCreateCreditCard: require('/usr/src/app/components/home/checkout/PopupCreateCreditCard.vue').default,PopupListCreditCard: require('/usr/src/app/components/home/checkout/PopupListCreditCard.vue').default,PopupCVV: require('/usr/src/app/components/home/checkout/PopupCVV.vue').default,Modal3DS: require('/usr/src/app/components/account/payment/Modal3DS.vue').default,PopupOtherPayment: require('/usr/src/app/components/home/checkout/PopupOtherPayment.vue').default,PopupLoading: require('/usr/src/app/components/home/checkout/PopupLoading.vue').default})
