//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
    mixins: [panstoreMixin],
    data () {
        return {
          getListBankAccounts: {
            paginated: false,
            type: 'Bank',
            created: 'asc',
            pagination: {
              total: 0,
              totalPage: 0
            },
            perPage: 3,
            page: 1,
            data: []
          },

          isLoading: false,
          onSubmit: false
        }
    },

    async mounted () {
        const getApis = [
        this.handleGetMasterBanks(),
        this.fetchBankAccounts()
        ]

        await Promise.all(getApis)
    },
    computed: {
        ...mapState('BankAccount', ['bankAccounts'])
    },
    methods: {
        ...mapActions('BankAccount', ['getMasterBanks', 'getBankAccounts']),
        async handleGetMasterBanks () {
            try {
                const payload = {
                data: {
                    paginated: false
                }
                }

                await this.getMasterBanks(payload)
            } catch (error) {
                const errorMessage = error.response ? error.response.data.message : error
                console.log(errorMessage)
            }
            },
        async fetchBankAccounts () {
          this.isLoading = true

            try {
              const resp = await this.getBankAccounts({
                data: {
                  paginated: this.getListBankAccounts.paginated,
                  type: this.getListBankAccounts.type,
                  per_page: this.getListBankAccounts.perPage,
                  page: this.getListBankAccounts.page,
                  order_by: {
                    created_at: this.getListBankAccounts.created
                  }
                }
              })

              this.getListBankAccounts.pagination.totalPage = resp.data.data.accounts.last_page
              this.getListBankAccounts.pagination.total = resp.data.data.accounts.total
              this.getListBankAccounts.data = [
                ...this.getListBankAccounts.data,
                ...resp.data.data.accounts.data
              ]
              this.getListBankAccounts.page++
            } catch (error) {
                const errorMessage = error.response ? error.response.data.message : error
                console.log(errorMessage)
            }

            this.isLoading = false
        },
        showButton () {
            return this.bankAccounts.data ? this.bankAccounts.data.length < 3 : false
        },
        onSubmitChange (value) {
            this.onSubmit = value
        },
        async onBankUpdated () {
          this.getListBankAccounts.paginated = false
          this.getListBankAccounts.page = 1
          this.getListBankAccounts.data = []
          this.getListBankAccounts.pagination.totalPage = 0
          this.getListBankAccounts.pagination.total = 0
            await this.fetchBankAccounts()
        }
    }
}
