//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      onSubmit: false,
      formDeleteAccount: {
        password: ''
      },
      formDeleteAccountRules: {
        password: [
          {
            required: true,
            message: 'Masukan password',
            trigger: 'blur'
          },
          {
            min: 6,
            message: 'password berisi minimal 6 karakter',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('User', ['deleteAccount']),
    ...mapMutations('User', ['setUser']),
    ...mapMutations('Cart', ['setCart']),
    ...mapMutations('Product', ['setProductWishlist']),
    handleDeleteAccount (formName) {
      this.isSubmit = true
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const resp = await this.deleteAccount({
              data: this.formDeleteAccount
            })
            if (resp.status === 200) {
              this.$Swal
                .fire({
                  icon: 'success',
                  title: 'Berhasil',
                  text: 'Akun berhasil dihapus!',
                  heightAuto: false
                })
                .then((confirm) => {
                  if (confirm) {
                    this.logout()
                  }
                })
            }
          } catch (error) {
            this.$Swal.fire({
              icon: 'warning',
              title: 'Pemberitahuan',
              text: error.response.data.message,
              heightAuto: false
            })
          }
        } else {
          return false
        }
      })
      this.isSubmit = false
    },
    async logout () {
      await this.$auth.logout('local')
      this.setUser([])
      this.setCart([])
      this.setProductWishlist([])
      this.$cookies.remove('session_id')
      window.location.reload()
    }
  }
}
