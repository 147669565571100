//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'

export default {
  mixins: [mixins],

  props: {
    banner: {
      type: Array,
      default () {
        return []
      }
    },
    newBanner: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      width: process.client ? window ? window.innerWidth : 0 : 0,
      height: process.client ? window ? window.innerHeight : 0 : 0
    }
  },

  computed: {
    desktop () {
      return this.width > 426
    },

    phone () {
      return this.width > 0 && this.width <= 426
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize)
    })
  },

  beforeDestroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize () {
      this.width = window ? window.innerWidth : 0
      this.height = window ? window.innerHeight : 0
    }
  }
}
