//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'
import { mapActions } from 'vuex'
import _ from 'lodash'
import mixins from '@/mixins'
export default {
  components: {
    VClamp
  },
  mixins: [mixins],
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      datas: {},
      onLoad: true
    }
  },
  computed: {
    isEmptySuggest () {
      return !(Object.keys(this.datas).length && this.datas.search_suggestions.length)
    }
  },
  watch: {
    search () {
      this.handleGetData()
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('Contents', ['getSuggestionContent']),
    async getData () {
      this.onLoad = true
      try {
        const resp = await this.getSuggestionContent(this.search || '')
        if (resp.status === 200) {
          this.datas = resp.data.data
        } else {
          this.datas = {}
        }
      } catch (error) {
        this.datas = {}
        console.log(error)
      }
      this.onLoad = false
    },
    handleGetData: _.debounce(function () {
      this.getData()
    }, 700),
    handleRoute (url) {
      this.$router.push(url)
    }
  }
}
