//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/linktree/icon-chevron-left.png" class="img-logo"/>',
        right:
          '<img src="/img/icon/linktree/icon-chevron-right.png" class="img-logo"/>'
      },
      defaultBanner: '/img/icon/linktree/default-banner.svg'
    }
  },
  computed: {
    ...mapState('Linktree', ['brandList', 'brandLinkIcon'])
  },
  layout: 'linktree',
  watch: {
    '$route.params': {
      handler () {
        this.brandDetail()
      }
    }
  },
  created () {
    this.brandDetail()
  },
  methods: {
    openLink (link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    getDefaultBanner (event) {
      event.target.src = this.defaultBanner
    },
    getLinkImage (link) {
      const icon = this.brandLinkIcon.filter((result) => {
        return result.name.toLowerCase() === link.toLowerCase()
      })
      return icon[0].img
    },
    getYear () {
      const d = new Date()
      const year = d.getFullYear()
      return year
    },
    brandDetail () {
      if (this.$route.params.id) {
        const resp = this.brandList.filter((result) => {
          return result.slug === this.$route.params.id.toLowerCase()
        })
        if (resp.length) {
          return resp[0]
        } else {
          this.$router.push('/paninti-linktree')
        }
      } else {
        this.$router.push('/paninti-linktree')
      }
      return {}
    }
  }
}
