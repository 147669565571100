//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapMutations } from 'vuex'
export default {
  mixins: [mixins],
  props: {
    listPayments: {
      type: Array,
      default: null
    }
  },
  computed: {
    listBankTransfer () {
      return this.listPayments.filter((bank) => {
        return bank.type.code === 'bank_transfer'
      })
    },
    listVirtualAccount () {
      return this.listPayments.filter((bank) => {
        return bank.type.code === 'virtual_account'
      })
    },
    listOtherMethod () {
      return this.listPayments.filter((bank) => {
        return (
          bank.type.code !== 'virtual_account' &&
          bank.type.code !== 'bank_transfer'
        )
      })
    }
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBank']),
    handleLeavePayment () {
      this.$refs.closeListBank.click()
    },
    handleSelectBank (item) {
      if (item.available) {
        if (item.code !== 'credit_card') {
          this.setSelectedBank([item])
          this.$refs.leaveListBank.click()
        } else {
          // create CC
          // this.$refs.leaveListBankToCC.click()
          // this.$refs.buttonShowCreateCreditCard.click()
          // List CC
          this.$refs.leaveListBankToListCC.click()
          this.$refs.buttonShowListCreditCard.click()
        }
      }
    }
  }
}
