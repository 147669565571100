//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    dataStatus: {
      type: Object,
      default () {
        return {}
      }
    },
    date: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    shipping: {
      type: String,
      default: ''
    },
    method: {
      type: String,
      default: ''
    },
    schedules: {
      type: Array,
      default () {
        return []
      }
    },
    branchName: {
      type: String,
      default: ''
    },
    branchCity: {
      type: String,
      default: ''
    },
    branchNumber: {
      type: Array,
      default () {
        return []
      }
    },
    noOrder: {
      type: String,
      default: ''
    },
    recipientName: {
      type: String,
      default: ''
    },
    paymentId: {
      type: String,
      default: ''
    },
    isBankTransfer: {
      type: Boolean,
      default: false
    },
    cancellation: {
      type: Object,
      default () {
        return { by: null, reason: null }
      }
    }
  },
  data () {
    return {
      cancelBy: {
        system: 'Sistem',
        admin: 'Admin',
        customer: 'Anda',
        partner: 'Penjual'
      }
    }
  },
  computed: {
    ...mapState('Order', ['order', 'orderTracking']),

    status () {
      return this.getStatusPesanan(this.dataStatus.slug, this.method)
    },
    branchWA () {
      const number = this.branchNumber.filter((n) => {
        return n.name === 'whatsapp'
      })
      if (number.length) {
        return number[0].value
      }
      return '6282111308195'
    }
  },
  methods: {
    ...mapActions('Order', ['editStatus']),
    toPaymentDetail () {
      const orderId = this.paymentId
      this.$router.push(`/payment-detail/${orderId}`)
    },
    getTimeStore (schedules) {
      const dataSchedules = schedules
      const dateStatus = new Date(this.formatYMD(this.date))
      dateStatus.setHours(this.time.substr(0, 2))
      dateStatus.setMinutes(this.time.substr(3, 2))
      dateStatus.setSeconds(this.time.substr(6, 2))
      let indexDate = dateStatus.getDay()
      if (indexDate === 0) {
        indexDate = 7
      }
      indexDate--

      // search first open schedule
      const temp = dataSchedules
      const datas = dataSchedules
        .slice(indexDate, dataSchedules.length - indexDate + 2)
        .concat(temp)
      let i = 0
      let valid = false
      do {
        const jamTutup =
          dateStatus.getHours() + 2 < new Date(datas[i].close_time).getHours()
        if (datas[i].is_open && jamTutup) {
          valid = true
        } else {
          i++
        }
      } while (!valid && i < datas.length)
      if (i >= 7) {
        indexDate = i++ % 7
      } else {
        indexDate = i++
      }
      const firstDate = this.formatDate(
        (dateStatus.getDate() < 10
          ? '0' + dateStatus.getDate()
          : dateStatus.getDate()) +
          ':' +
          (dateStatus.getMonth() + 1 < 10
            ? '0' + (dateStatus.getMonth() + 1)
            : dateStatus.getMonth() + 1) +
          ':' +
          dateStatus.getFullYear()
      )

      // get max date
      const scheduleFree = dataSchedules.filter((sch) => {
        return !sch.is_open
      }).length
      const namaHari = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu'
      ]
      const maxDate = new Date(
        dateStatus.setDate(dateStatus.getDate() + (7 + scheduleFree))
      )
      const lastDate = this.formatDate(
        (maxDate.getDate() < 10 ? '0' + maxDate.getDate() : maxDate.getDate()) +
          ':' +
          (maxDate.getMonth() + 1 < 10
            ? '0' + (maxDate.getMonth() + 1)
            : maxDate.getMonth() + 1) +
          ':' +
          maxDate.getFullYear()
      )
      return `${dataSchedules[indexDate].day}, ${firstDate} - ${
        namaHari[maxDate.getDay()]
      }, ${lastDate}`
    },
    handleConfirmOrder () {
      this.$Swal
        .fire({
          title: 'Apakah Anda yakin?',
          text: '',
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ya, saya yakin',
          cancelButtonText: 'Batal'
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const resp = await this.editStatus({
                id: this.$route.params.order_id,
                payload: {
                  data: {
                    updated_status: 'transaksi-selesai'
                  }
                }
              })
              if (resp.status === 200) {
                this.$message({
                  showClose: true,
                  message: 'Status berhasil diperbaharui',
                  type: 'success'
                })
                window.location.reload()
              }
            } catch (error) {
              console.log(error)
            }
          }
        })
    },
    handleRedirectWhatsapp () {
      const string = `Halo%20selamat%20siang%2C%20untuk%20pesanan%20Paninti.com%20dengan%0ANo.%20Pesanan%3A%20*${this.noOrder}*%0ANama%3A%20*${this.recipientName}*%0ANo.%20Telp%3A%20%0A%0AAkan%20mengambil%20barang%20pada%0ATempat%20Pengambilan%3A%20*${this.branchName}*%0ATanggal%3A`
      window.open(`https://api.whatsapp.com/send?phone=${this.branchWA}?text=${string}`)
    }
  }
}
