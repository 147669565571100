//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'
import marketplaceMixin from '@/mixins'
export default {
  name: 'Index',
  mixins: [marketplaceMixin],
  layout: 'account',
  middleware: ['user', 'affiliate'],
  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Affiliasi',
          path: '/account/affiliate/'
        },
        {
          name: 'Komisi',
          path: '/account/affiliate/commission'
        },
        {
          name: 'Daftar Pengajuan'
        }
      ],
      optionsRange: [
        {
          label: 'Sepanjang Waktu',
          value: 'all'
        },
        {
          label: 'Hari Ini',
          value: 'today'
        },
        {
          label: 'Minggu Lalu',
          value: 'last-week'
        },
        {
          label: '30 Hari yang Lalu',
          value: '30-days'
        },
        {
          label: '60 Hari yang Lalu',
          value: '60-days'
        },
        {
          label: '90 Hari yang Lalu',
          value: '90-days'
        }
      ],
      optionsStatus: [
        {
          label: 'Semua Status',
          value: 'all'
        },
        {
          label: 'Menunggu Persetujuan',
          value: 'new'
        },
        {
          label: 'Diterima',
          value: 'approved'
        },
        {
          label: 'Ditolak',
          value: 'rejected'
        },
        {
          label: 'Selesai',
          value: 'done'
        }
      ],
      formFilter: {
        range: 'all',
        status: 'all'
      },
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapState('Affiliate', ['affiliateWithdrawals']),
    listAffiliateWithdrawals () {
      return this.affiliateWithdrawals.data
    }
  },
  watch: {
    formFilter: {
      handler () {
        this.resetAffiliateWithdrawals()
        this.infiniteId++
      },
      deep: true
    }
  },
  async mounted () {
    await this.handleGetAffiliateWithdrawal()
  },
  methods: {
    ...mapMutations('Affiliate', ['resetAffiliateWithdrawals']),
    ...mapActions('Affiliate', ['getAffiliateBalanceWithdrawal', 'getAffiliateBalanceWithdrawalDetail']),
    async handleInfiniteLoading ($state) {
      await this.handleGetAffiliateWithdrawal()
      if (this.affiliateWithdrawals.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async handleGetAffiliateWithdrawal () {
      const payload = {
        params: {
          paginated: true,
          per_page: 5,
          page: this.affiliateWithdrawals.pagination.nextPage || 1
        }
      }

      if (this.checkObject(this.formFilter)) {
        if (this.formFilter.range !== 'all') {
          payload.params.period_date = this.formFilter.range
        }

        if (this.formFilter.status !== 'all') {
          payload.params.status = this.formFilter.status
        }
      }

      try {
        await this.getAffiliateBalanceWithdrawal(payload)
      } catch (error) {
        console.log(error)
      }
    },
    async handleShowWithdraw (id) {
      try {
        const payload = {
          id
        }

        await this.getAffiliateBalanceWithdrawalDetail(payload)
        setTimeout(() => {
          this.$refs.btnOpenDetail.click()
        }, 500)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
    }
  }
}
