//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions } from 'vuex'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      formSocmed: {
        instagram: '',
        tiktok: '',
        facebook: '',
        twitter: '',
        youtube: ''
      },
      isSocmedFilledTwo: true
    }
  },
  computed: {
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    },
    rules () {
      return {
        instagram: [
          {
            required: true,
            message: 'Akun instagram wajib diisi',
            trigger: ['blur', 'change']
          },
          {
            required: this.isSocmedFilledTwo,
            message: 'Social media wajib diisi minimal 2',
            trigger: ['blur', 'change']
          },
          {
            validator: (...args) => this.socmedValidation('instagram', ...args)
          }
        ],
        tiktok: [
          {
            validator: (...args) => this.socmedValidation('tiktok', ...args)
          }
        ],
        facebook: [
          {
            validator: (...args) => this.socmedValidation('facebook', ...args)
          }
        ],
        twitter: [
          {
            validator: (...args) => this.socmedValidation('twitter', ...args)
          }
        ],
        youtube: [
          {
            validator: (...args) => this.socmedValidation('youtube', ...args)
          }
        ]
      }
    }
  },
  watch: {
    formSocmed: {
      handler () {
        this.checkSocmed()
      },
      deep: true
    }
  },
  mounted () {
    this.setSocmedIfExist()
  },
  methods: {
    ...mapActions('User', ['editUser']),
    socmedValidation (type, rule, value, callback) {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[a-zA-z._0-9]+$/
      const field = rule.field

      if (value === ' ') {
        this.formSocmed[field] = ''
        callback()
      }

      if (input.match(' ')) {
        this.formSocmed[field] = value.substr(0, value.length - 1)
      }
      if (input.match(' ') && input.match(inputBefore)) {
        this.formSocmed[field] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formSocmed[field] =
          value.substr(0, value.length - 1)
      }

      let errorMessage = ''
      if (field === type && !!this.formSocmed[field]) {
        if (!letters.test(value)) {
          errorMessage = `Username sosial media ${type} hanya boleh alfanumerik, underscore, titik saja.`
        }

        if (errorMessage) {
          callback(errorMessage)
        }
      }

      callback()
    },
    isSocialMediaUrl (url) {
      const socialMediaPlatforms = [
        'facebook.com',
        'twitter.com',
        'instagram.com',
        'youtube.com',
        'tiktok.com'
      ];

      if (url) {
        return socialMediaPlatforms.some((platform) => {
          return url.includes(platform)
        })
      }

      return false;
    },
    checkSocmed () {
      const filledSocmed = Object.entries(this.formSocmed).filter((key, value) => {
        return key[1] !== ''
      })

      this.isSocmedFilledTwo = filledSocmed.length >= 2
    },
    setSocmedIfExist () {
      if (this.checkObject(this.user) && this.checkArray(this.user.social_medias)) {
        Object.entries(this.formSocmed).forEach((key) => {
          const userSocmedIndex = this.user.social_medias.findIndex((socmedItem) => {
            return socmedItem.name === key[0]
          })

          if (userSocmedIndex > -1) {
            this.formSocmed[key[0]] = this.user.social_medias[userSocmedIndex].username
          }
        })

        this.checkSocmed()
      }
    },
    submitSocmed () {
      this.$refs.formSocmed.validate(async (valid) => {
        if (valid && this.isSocmedFilledTwo) {
          await this.handleUpdateSocmed()
        } else if (!valid) {
          this.$message.error('Akun instagram wajib diisi.')
        } else if (!this.isSocmedFilledTwo) {
          this.$message.error('Social media wajib diisi minimal 2.')
        }
      })
    },
    async handleUpdateSocmed () {
      try {
        const payload = {
          data: {
            social_medias: Object.entries(this.formSocmed).filter((item, value) => {
              return !!item[1]
            }).map((item) => {
              return {
                name: item[0],
                value: item[1]
              }
            })
          }
        }

        await this.editUser(payload)
        await this.$auth.fetchUser()
        this.$router.push({
          query: {
            page: 3
          }
        })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal Menambahkan Social Media!',
          text: errorMessage,
          heightAuto: false
        })
      }
    }
  }
}
