//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: {
    childs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      }
    }
  },
  methods: {
    handleRedirect (level, slug) {
      window.location = `/c${level}/${slug}`
    }
  }
}
