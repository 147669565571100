var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.onLoading || _vm.brands.length)?_c('div',{staticClass:"slider-brand-wrapper"},[(_vm.brands.length && !_vm.onLoading)?[_c('div',{staticClass:"text-title text-center mb-4"},[_vm._v("\n      Brand Di Toko Kami\n    ")]),_vm._v(" "),_c('client-only',[_c('carousel',{staticClass:"custom-carousel mx-auto",attrs:{"per-page":7,"navigation-enabled":true,"pagination-enabled":false,"navigation-prev-label":_vm.sliderArrow.left,"navigation-next-label":_vm.sliderArrow.right,"center-mode":true,"scroll-per-page":false,"per-page-custom":[
          [0, 2],
          [375, 3],
          [500, 4],
          [700, 5],
          [850, 6],
          [1024, 7] ]}},_vm._l((_vm.brands),function(item){return _c('slide',{key:'Slider-' + item.id},[_c('div',{staticClass:"brand-wrapper text-center",on:{"click":function($event){return _vm.handleClickImage(item.slug)}}},[_c('img',{staticClass:"img-fluid img-brand rounded-circle",attrs:{"src":_vm.getThumbnailImage(item.files, 'brand'),"alt":'Slider-' + item.id},on:{"error":function () {
                var args = [], len = arguments.length;
                while ( len-- ) args[ len ] = arguments[ len ];

                return _vm.getDefaultImgProfile.apply(void 0, args.concat( ['brand'] ));
        }}})])])}),1)],1)]:_vm._e(),_vm._v(" "),(_vm.onLoading)?[_c('client-only',[_c('el-skeleton',{staticStyle:{"height":"100%","width":"100%"},attrs:{"animated":""}},[_c('template',{slot:"template"},[_c('div',{staticClass:"d-flex w-100 flex-wrap text-center"},[_c('div',{staticClass:"col-12 mb-4"},[_c('el-skeleton-item',{staticStyle:{"width":"400px","height":"24px"},attrs:{"variant":"text"}})],1),_vm._v(" "),_c('div',{staticClass:"row mx-auto"},[_c('div',{staticClass:"d-flex"},_vm._l((5),function(i){return _c('el-skeleton-item',{key:'brand-' + i,staticClass:"mx-4",staticStyle:{"width":"104px","height":"104px","border-radius":"50%"},attrs:{"variant":"image"}})}),1)])])])],2)],1)]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }