//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

export default {
  data () {
    return {
      getListAccountAddress: {
        isLoading: false,
        perPage: 5,
        page: 1,
        total: 0,
        totalPage: 0,
        data: []
      },

      onSubmit: false
    }
  },

  async mounted () {
    await this.handleGetUserAddress()
  },

  methods: {
    ...mapActions('User/address', ['getUserAddress', 'editUserAddress']),

    handleInfiniteLoading () {
      this.handleGetUserAddress()
    },

    async handleGetUserAddress () {
      this.getListAccountAddress.isLoading = true

      try {
        const resp = await this.getUserAddress({
          data: {
            page: this.getListAccountAddress.page,
            per_page: this.getListAccountAddress.perPage,
            where: {
              addressable_id: this.$auth.$state.user.id
            }
          }
        })

        this.getListAccountAddress.totalPage = resp.data.data.addresses.last_page
        this.getListAccountAddress.total = resp.data.data.addresses.total
        this.getListAccountAddress.data = [
          ...this.getListAccountAddress.data,
          ...resp.data.data.addresses.data
        ]
        this.getListAccountAddress.page++

        if (this.getListAccountAddress.page > this.getListAccountAddress.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0) {
            this.$refs.InfiniteLoading[0].stateChanger.loaded()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.loaded()
          }
      } catch (error) {
        this.$message.error('Gagal mendapatkan data alamat pengguna')
      }

      this.getListAccountAddress.isLoading = false
    },

    onSubmitChange (value) {
      this.onSubmit = value
    },

    async onAddressUpdated () {
      this.getListAccountAddress.page = 1
      this.getListAccountAddress.data = []
      this.getListAccountAddress.totalPage = 0
      this.getListAccountAddress.total = 0

      await this.handleGetUserAddress()

      if (
        Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0
      ) {
        this.$refs.InfiniteLoading[0].stateChanger.reset()
      } else if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  }
}
