//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  computed: {
    ...mapState('Category', ['category']),
    categoryExist () {
      return this.category && Object.keys(this.category).length
    },
    imageBanner () {
      let image = []

      if (this.categoryExist && this.category.files) {
        image = this.category.files.filter((file) => {
          return file.name === 'cover_landscape' && file.variant === 'original'
        })
      }
      if (image.length) {
        return image[0].value
      } else {
        return ''
      }
    }
  },
  watch: {
    '$route.name' () {
      this.$forceUpdate()
    }
  },
  methods: {
    handleChangeCategory (slug, level) {
      window.location = `/c${level}/${slug}`
    },
    getImageSquare (files) {
      const value = files.filter((file) => {
        return file.name === 'cover_square' && file.variant === 'original'
      })
      if (value.length) {
        return value[0].value
      } else {
        return (
          files.filter((file) => {
            return file.name === 'logo' && file.variant === 'original'
          })[0].value || ''
        )
      }
    }
  }
}
