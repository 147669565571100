//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'

export default {
  mixins: [panstoreMixin],

  props: {
    flashSaleSessions: {
      type: Array,
      default: () => []
    },

    activeFlashSaleSessionIndex: {
      type: Number,
      default: 0
    },

    activeFlashSaleSession: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {}
  },

  computed: {
    countDownFlashsale () {
      if (this.getFlashTime(this.activeFlashSaleSession.date_start)) {
        return this.countdownTime(this.activeFlashSaleSession.date_end)
      }

      return this.countdownTime(this.activeFlashSaleSession.date_start)
    }
  },

  methods: {
    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },

    countdownEnd () {
      window.location = window.location.href
    },

    getFlashTime (start) {
      let startTime = new Date(this.formatYMD(start)).getTime()

      if (Number.isNaN(startTime)) {
        startTime = new Date(this.formatYMD(start).replace(' ', 'T')).getTime()
      }

      if (startTime < new Date().getTime()) {
        return true
      }

      return false
    },

    handleFlashSaleSessionTabChanged (index) {
      this.$emit('on-flash-sale-session-tab-changed', index)
    }
  }
}
