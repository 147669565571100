import { render, staticRenderFns } from "./WelcomePageAffiliate.vue?vue&type=template&id=6f37aba2&scoped=true&"
import script from "./WelcomePageAffiliate.vue?vue&type=script&lang=js&"
export * from "./WelcomePageAffiliate.vue?vue&type=script&lang=js&"
import style0 from "./WelcomePageAffiliate.vue?vue&type=style&index=0&id=6f37aba2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f37aba2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,ModalCtaJoinAffiliate: require('/usr/src/app/components/account/affiliate/ModalCtaJoinAffiliate.vue').default,ModalTermsCondition: require('/usr/src/app/components/account/affiliate/ModalTermsCondition.vue').default})
