//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    mobile: {
      type: Boolean,
      default: () => false
    },
    isSearched: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      update: true
    }
  },
  computed: {},
  watch: {
    datas () {
      this.update = false
      this.$nextTick(() => {
        this.update = true
        this.$forceUpdate()
      })
    }
  }
}
