//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getIdFromURL } from 'vue-youtube-embed'
import getYoutubeTitle from 'get-youtube-title'
export default {
  props: {
    videos: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      onRequest: false,
      dataVideo: [],
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      }
    }
  },
  async mounted () {
    if (this.videos.length) {
      await this.videos.map(async (video, index) => {
        this.dataVideo.push(Object.assign({
          ...video,
          title: ''
        }))
        await this.getYoutubeTitles(video.value, index)
      })
    }
    this.onRequest = true
  },
  methods: {
    getIdFromURL (url) {
      if (this.$youtube) {
        return this.$youtube.getIdFromURL(url)
      }
    },
    async getYoutubeTitles (value, index) {
      const _this = this
      if (this.$youtube) {
        // eslint-disable-next-line handle-callback-err
        await getYoutubeTitle(this.getIdFromURL(value), process.env.YOUTUBE_API_KEY, (error, title) => {
          _this.dataVideo[index].title = title
        })
      }
    }
  }
}
