//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  middleware: ['user'],
  async asyncData ({ store, params, error }) {
    try {
      const resp = await store.dispatch(
        'Order/getOrderProductDetail',
        params.id
      )
      if (resp.status === 200 && resp.data.data.order_product.is_reviewed) {
        error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
      }
    } catch (exerror) {
      console.log(exerror)
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
    try {
      await store.dispatch('Order/getReviewSuggestions')
    } catch (error) {
      console.log(error)
    }
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Pesanan Saya'
      },
      {
        name: 'Tulis Ulasan'
      }
    ]
    return {
      breadCrumb: breadcrumbs
    }
  },
  computed: {
    ...mapState('Order', ['productOrder'])
  }
}
