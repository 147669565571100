import { render, staticRenderFns } from "./CatalogListAffiliate.vue?vue&type=template&id=71326695&scoped=true&"
import script from "./CatalogListAffiliate.vue?vue&type=script&lang=js&"
export * from "./CatalogListAffiliate.vue?vue&type=script&lang=js&"
import style0 from "./CatalogListAffiliate.vue?vue&type=style&index=0&id=71326695&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71326695",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,CardProductAffiliate: require('/usr/src/app/components/account/CardProductAffiliate.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default,ModalLinkAffiliate: require('/usr/src/app/components/account/affiliate/ModalLinkAffiliate.vue').default})
