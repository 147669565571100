//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      loading: true,
      loadmore: false
    }
  },
  computed: {
    ...mapState('Product', ['productBestSellingMonthly'])
  },
  async mounted () {
    await this.handleGetProductBestSellingMonthly()
  },
  methods: {
    ...mapActions('Product', ['getProductBestSellingMonthly']),

    async handleGetProductBestSellingMonthly (paginated = true) {
      this.loading = true
      try {
        const payload = {
          data: {
            paginated: true,
            per_page: 5
          }
        }

        await this.getProductBestSellingMonthly(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    }
  }
}
