//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    datas: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapState('Linktree', ['brandLinkIcon'])
  },
  methods: {
    getImage (link) {
      let icon = this.brandLinkIcon.filter((result) => {
        return result.name.toLowerCase() === link.toLowerCase()
      })
      if (icon.length) {
        icon = icon[0].img
      } else {
        icon = ''
      }
      return icon
    },
    handleClick (i) {
      switch (i.name) {
        case 'whatsapp':
          window.open('https://api.whatsapp.com/send?phone=' + i.value, '_blank')
          break
        case 'line':
          window.open('https://line.me/R/ti/p/' + i.value, '_blank')
          break
        case 'twitter':
          window.open('https://twitter.com/' + i.value, '_blank')
          break
        case 'instagram':
          window.open('https://instagram.com/' + i.value, '_blank')
          break
        case 'tiktok':
          window.open('https://tiktok.com/' + i.value, '_blank')
          break
        case 'website':
          window.open(i.value, '_blank')
          break
      }
    }
  }
}
