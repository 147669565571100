var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('client-only',[_c('Breadcrumb',{staticClass:"container-section mt-4",attrs:{"datas":_vm.breadCrumb}}),_vm._v(" "),_c('div',{staticClass:"container-section"},[_c('BannerFlashsale',{attrs:{"flash-sale":_vm.activeFlashSale}}),_vm._v(" "),_c('TabFlashsale',{attrs:{"flash-sales":_vm.flashSales},on:{"on-flash-sale-tab-changed":_vm.handleFlashSaleTabChanged}}),_vm._v(" "),(_vm.activeFlashSaleWeek)?_c('TimeCountDownFlashsale',{attrs:{"flash-sale-sessions":_vm.activeFlashSaleWeek.sessions,"active-flash-sale-session-index":_vm.activeFlashSaleSessionIndex,"active-flash-sale-session":_vm.activeFlashSaleSession},on:{"on-flash-sale-session-tab-changed":_vm.handleFlashSaleSessionTabChanged}}):_vm._e(),_vm._v(" "),(_vm.activeFlashSaleWeek&& _vm.activeFlashSaleSession)?_c('ListProductFlashsale',{attrs:{"flash-sale-sessions":_vm.activeFlashSaleWeek.sessions,"active-flash-sale-week-index":_vm.activeFlashSaleWeekIndex,"active-flash-sale-week":_vm.activeFlashSaleWeek,"active-flash-sale-session-index":_vm.activeFlashSaleSessionIndex,"active-flash-sale-session":_vm.activeFlashSaleSession},on:{"flashsale-session-product-loading-change":_vm.handleFlashSaleSessionProductLoadingChange,"flashsale-session-product-updated":_vm.handleFlashSaleSessionProductUpdated}}):_vm._e(),_vm._v(" "),(
          (
            _vm.activeFlashSaleSession &&!_vm.activeFlashSaleSession.metaPagination.isLoading &&
            _vm.activeFlashSaleSession.event_sale_products.length <= 0 &&
            _vm.activeFlashSaleSession.metaPagination.page > 1
          ) ||
            !(_vm.activeFlashSaleWeek && _vm.activeFlashSaleSession)
        )?_c('div',{staticClass:"flashsale-product-empty"},[_c('img',{staticClass:"flashsale-product-empty-img",attrs:{"src":require("@/static/img/icon/flashsale/no-flashsale.svg"),"alt":"Flashsale Not Found"}}),_vm._v(" "),_c('div',{staticClass:"flashsale-product-empty-message"},[_vm._v("\n          Flash sale tidak tersedia\n        ")])]):_vm._e()],1),_vm._v(" "),_c('BannerBottomSection')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }