//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import mixins from '@/mixins'
import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  mixins: [mixins],

  props: {
    partner: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      rate: 0,
      width: process.client ? window.innerWidth : 0,
      height: process.client ? window.innerHeight : 0,
      share: false,
      expanded: false
    }
  },

  computed: {
    ...mapState('Partner', ['totalRelatedStores']),

    partnerType () {
      return this.partner.type || 'store_partner'
    },

    location () {
      const main =
        this.partner.branches.filter((branch) => {
          return branch.main
        })[0] || this.partner.branches[0]
      return main.main_address.village.city || 'Indonesia'
    },

    isPhone () {
      return this.width > 0 && this.width <= 426
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize)
    })
  },

  methods: {
    handleResize () {
      this.width = process.client ? window.innerWidth : 0
      this.height = process.client ? window.innerHeight : 0
    },

    getIcon (name) {
      return require(`@/static/img/icon/icon-${name}.svg`)
    },

    handleRoute (name, value) {
      let string = ''

      if (name === 'whatsapp') {
        string = 'https://api.whatsapp.com/send?phone='
      } else if (name === 'line') {
        string = 'https://line.me/ti/p/~'
      } else if (name === 'instagram') {
        string = 'https://instagram.com/'
      }

      window.open(string + value)
    },

    expandedToggle () {
      this.expanded = !this.expanded
    }
  }
}
