//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'maintenance',
  head () {
    return {
      title: 'Paninti.com Belanja Retail Harga Grosir, Ajak Teman Bisa Hemat Lebih Banyak',
      meta: [
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            'Belanja kebutuhan ibu dan bayi terlengkap hanya di Paninti.com'
        }
      ]
    }
  }
}
