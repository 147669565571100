//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      onSubmit: false,
      formLogin: {
        username: '',
        password: '',
        session_id: this.$cookies.get('session_id'),
        remember: false
      },
      errorUsername: '',
      errorPassword: '',
      email: '',
      type: 'password',
      toggleShowHide: true,
      iconEye: '/img/icon/eye-solid.svg',
      show: false
    }
  },
  methods: {
    showHidePassword () {
      if (this.type === 'password') {
        this.type = 'text'
        this.iconEye = '/img/icon/eye-solid.svg'
      } else {
        this.type = 'password'
        this.iconEye = '/img/icon/eye-slash-solid.svg'
      }
    },
    goToRegister () {
      this.$refs.modalLoginClose.click()
      this.$router.push('/register?redirect_from=/cart')
    },
    showForgotPassword () {
      this.$refs.modalLoginClose.click()
    },
    // Login Method
    handleSubmitLoginForm () {
      this.checkUsername()
      this.checkPassword()
      const valid = !this.errorUsername && !this.errorPassword
      if (valid) {
        this.submitLoginForm()
      } else {
        /* eslint-disable */
          if (!this.formLogin.password) {
            this.errorPassword = 'Password wajib diisi'
          }
        }
      },
      async submitLoginForm() {
        this.onSubmit = true
        try {
          await this.$auth.loginWith('local', {
            data: this.formLogin
          })

          this.$cookies.remove('session_id')
          this.$Swal.fire({
            icon: 'success',
            title: 'Berhasil Masuk',
            text: 'Selamat datang kembali di Paninti App'
          }).then(() => {
            window.location.reload()
          })
        } catch (error) {
          const errorMessage = error.response
            ? error.response.data.message
            : error
          this.$Swal.fire({
            icon: 'error',
            title: 'Gagal Login',
            text: errorMessage,
            heightAuto: false
          })
          this.onSubmit = false
        }
        this.onSubmit = false
      },

      handleChangeUsername() {
        this.show = true
        const value = this.formLogin.email
        const input = value.charAt(value.length - 1)
        const letters = /^[A-Za-z._0-9._+@]+$/
        if (value === ' ') {
          this.formLogin.email = ''
        }
        if (!input.match(letters)) {
          this.formLogin.email = value.substring(0, value.length - 1)
        }
      },

      handleChangePassword() {
        this.show = true
        const value = this.formLogin.password
        const input = value.charAt(value.length - 1)
        if (value === ' ') {
          this.formLogin.password = ''
        }
        if (input === ' ') {
          this.formLogin.password = value.substring(0, value.length - 1)
        }
      },

      checkUsername() {
        this.errorUsername = ''
        if (this.formLogin.email === '') {
          this.errorUsername = 'Username atau email wajib diisi'
        } else {
          this.errorUsername = ''
        }
      },
      checkPassword() {
        this.errorPassword = ''
        if (this.formLogin.password === '') {
          this.errorPassword = 'Password wajib diisi'
        }

        if (this.formLogin.password.length < 7) {
          this.errorPassword = 'Password minimal berisi 6 karakter'
        } else {
          this.errorPassword = ''
        }
      }
      // End Login Method
    }
  }
