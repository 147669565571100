//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    return {
      onSubmit: false,
      email: '',
      errorEmail: '',
      onShow: true
    }
  },
  computed: {
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    }
  },
  created () {
    if (Object.keys(this.user).length) {
      this.email = this.user.email
      this.checkEmail()
    }
  },
  methods: {
    ...mapActions('Auth', ['reqForgotPassword']),
    handleChangeEmail () {
      const value = this.email
      const input = value.charAt(value.length - 1)

      /* eslint-disable */
      const letters = /^[A-Za-z0-9._+@]+$/;
      if (value === " ") {
        this.email = "";
      }

      if (value.length > 100) {
        this.email = value.substring(0, value.length - 1);
      }

      if (!input.match(letters)) {
        this.email = value.substring(0, value.length - 1);
      }
    },

    checkEmail() {
      const value = this.email;
      /* eslint-disable */
      const format =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((-\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]{2,})+[a-zA-Z]{2,}))/;
      this.errorEmail = "";
      if (value === "") {
        this.errorEmail = "Email wajib diisi";
      } else if (!format.test(value)) {
        this.errorEmail = "Format email tidak valid";
      } else {
        this.errorEmail = "";
      }
      if (value.length > 100) {
        this.email = value.substring(0, 100);
      }
    },

    handleForgotPassword() {
      const _this = this;
      const valid = !this.errorEmail;
      if (valid) {
        _this.submitForgotPassword();
      } else if (!this.email) {
        this.email = "Password wajib diisi";
      }
    },

    async submitForgotPassword() {
      this.onSubmit = true;
      try {
        const resp = await this.reqForgotPassword({
          data: {
            email: this.email.trim(),
          },
        });
        if (resp.status === 200) {
          this.$Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Kami telah mengirimkan tautan ke email Anda, periksa email untuk mengatur ulang password!",
            heightAuto: false,
          });
          this.$refs.btnClose.click();
        }
      } catch (error) {
        this.$Swal.fire({
          icon: "error",
          title: "Gagal",
          text: error.response.data.message,
          heightAuto: false,
        });
        this.onSubmit = false;
      }
      this.onSubmit = false;
    },
  },
};
