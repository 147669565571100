//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  data () {
    return {
      onLoading: false,
      page: 1,
      perPage: 20,
      totalPage: 0,
      total: 0
    }
  },

  computed: {
    ...mapState('Product', ['mainProducts']),
    ...mapState('Category', ['category']),
    ...mapState('User/address', ['location'])
  },

  watch: {
    async location () {
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }

      this.page = 1
      this.totalPage = 0
      this.setTotalProducts(this.total)
      this.setMainProducts([])

      await this.handleGetProducts()
    },

    async '$route.query' () {
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }

      this.page = 1
      this.totalPage = 0
      this.setTotalProducts(this.total)
      this.setMainProducts([])

      await this.handleGetProducts()
    },

    total () {
      this.$emit('on-total-product-changed', this.total)
    }
  },

  methods: {
    ...mapMutations('Product', ['setTotalProducts', 'setMainProducts']),

    handleInfiniteLoading () {
      this.handleGetProducts()
    },

    setPayload () {
      const params = this.$route.params
      const query = this.$route.query

      // payload
      this.payload = {
        page: this.page,
        per_page: this.perPage,
        catalog_category: params.slug
      }

      // initial query
      let brands = []
      let colors = []
      let sizes = []
      let priceLimit = []
      let rating = []
      let search = []
      let sort = ''
      let location = []

      if (query.city) {
        location = query.city.split('::')
      }

      if (location.length) {
        this.payload = Object.assign({
          ...this.payload,
          city: location
        })
      }

      // let showAll = 'false'
      if (query.brands) {
        brands = query.brands.split('::')
      }

      if (query.colors) {
        colors = query.colors.split('::')
      }

      if (query.sizes) {
        sizes = query.sizes.split('::')
      }

      if (query.priceLimit) {
        priceLimit = query.priceLimit.split('::')
      }

      rating = query.rating ? query.rating.split('::').reverse() : []
      search = query.search ? query.search : ''
      sort = query.sort ? query.sort : ''

      const orWhereHas = []
      if (brands.length) {
        orWhereHas.push({
          relation: 'brand',
          where_in: [['slug', brands]]
        })
      }

      let variants = []
      if (colors.length && sizes.length) {
        variants = colors.concat(sizes)
      } else {
        if (colors.length) {
          variants = colors
        }
        if (sizes.length) {
          variants = sizes
        }
      }

      if (query.priceLimit && query.priceLimit !== '0::10000000') {
        this.payload = Object.assign({
          ...this.payload,
          where_greater_than: {
            price: priceLimit[0]
          },
          where_lower_than: {
            price: priceLimit[1]
          }
        })
      }

      if (rating.length) {
        this.payload = Object.assign({
          ...this.payload,
          where_rating: rating
        })
      }

      if (sort) {
        if (sort === 'most_expensive') {
          this.payload = Object.assign({
            ...this.payload,
            order_by: {
              price: 'desc'
            }
          })
        } else if (sort === 'cheapest') {
          this.payload = Object.assign({
            ...this.payload,
            order_by: {
              price: 'asc'
            }
          })
        } else if (sort === 'best_seller') {
          this.payload = Object.assign({
            ...this.payload,
            order_by: {
              total_sold_last_month: 'desc'
            }
          })
        } else if (sort === 'lastest') {
          this.payload = Object.assign({
            ...this.payload,
            order_by: {
              created_at: 'desc'
            }
          })
        }
      }

      if (search) {
        this.payload = Object.assign({
          ...this.payload,
          search
        })
      }

      if (variants.length) {
        orWhereHas.push({
          relation: 'details',
          where_has: [
            {
              relation: 'variants',
              where_in: [['variants.name', variants]]
            }
          ]
        })
      }

      if (orWhereHas.length) {
        this.payload = Object.assign({
          ...this.payload,
          or_where_has: orWhereHas
        })
      }
    },

    async handleGetProducts () {
      this.onLoading = true

      this.setPayload()
      let tempPayload = this.payload

      if (!this.$route.query.city) {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155

        tempPayload = Object.assign({
          ...tempPayload,
          city_id: cityId
        })
      }

      try {
        const resp = await this.$store.dispatch('Product/getProductCategory', {
          data: {
            ...tempPayload,
            page: this.page
          }
        })

        this.totalPage = resp.data.data.products.last_page
        this.total = resp.data.data.products.total
        this.setTotalProducts(this.total)
        this.page++

        if (this.page > this.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (Array.isArray(this.$refs.InfiniteLoading) && this.$refs.InfiniteLoading.length > 0) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (error) {
        this.$message.error('Gagal mendapatkan data catalog kategori produk')
      }

      this.onLoading = false
    },

    handleResetQuery () {
      this.$router.replace({})
    }
  }
}
