//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: {
    lorikeets: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      value: 1
    }
  },
  methods: {
    toggleShow () {
      document.getElementById('buttonExpand').blur()
      if (this.value === 1) {
        this.value = this.lorikeets.length
      } else {
        this.value = 1
      }
    }
  }
}
