//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'

export default {
  mixins: [mixins],

  props: {
    banners: {
      type: Array,
      default () {
        return []
      }
    },

    loading: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      bannerProfileCarouselSettings: {
        dots: false,
        edgeFriction: 0.35,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<button>Test</button>'
      },

      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },

  computed: {
    normalDesktop () {
      return this.windowWidth >= 992
    },

    dataBanner () {
      const type = this.normalDesktop ? 'normal desktop' : 'normal phone'
      const data = this.banners.filter(banner => banner.display_type === type)

      return data.sort(function (a, b) {
        return a.position - b.position
      })
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },

    handleRedirect (item) {
      if (item.link) {
        this.$router.push(`/${item.link}`)
      }
    }
  }
}
