//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Cart', ['cartItemId', 'deleteItem'])
  },
  methods: {
    ...mapActions('Cart', ['getCarts', 'getCartsNavbar', 'getEmptyCarts', 'deleteCart']),
    ...mapMutations('Cart', ['resetSelectedItem', 'setSelectedStore']),
    ...mapActions('User', ['toggleWishlist']),
    async handleDeleteCartProduct (isWishlisted) {
      this.onSubmit = true
      const payload = {
        data: {
          id: this.cartItemId,
          session_id: this.$cookies.get('session_id')
        }
      }
      try {
        const resp = await this.deleteCart(payload)
        if (resp.data.status_code === 200) {
          this.$refs.modalClose.click()
          this.$message({
            message: `Produk berhasil ${
              isWishlisted ? 'ditambahkan ke wishlist dan' : ''
            } dihapus dari keranjang.`,
            type: 'success'
          })
          try {
            const payloadCartNavbar = {
              params: {
                session_id: this.$cookies.get('session_id')
              }
            }
            const payloadAvailable = {
              data: {
                conditions: {
                  stock: 'available_stock'
                },
                session_id: this.$cookies.get('session_id')
              }
            }
            const payloadUnAvailable = {
              data: {
                conditions: {
                  stock: 'unavailable_stock'
                },
                order_by: {
                  'carts.updated_at': 'desc'
                },
                session_id: this.$cookies.get('session_id')
              }
            }
            this.resetSelectedItem()
            this.setSelectedStore('')
            await this.getCartsNavbar(payloadCartNavbar)
            await this.getCarts(payloadAvailable)
            await this.getEmptyCarts(payloadUnAvailable)
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.onSubmit = false
    },
    StoreProductPrice (variants) {
      return this.formatRupiah(
        variants.detail.branches[0].product_detail.price.toString(),
        'Rp. '
      )
    },
    async handleDeleteAndAddtoWishlist () {
      try {
        const resp = await this.toggleWishlist({
          data: {
            product_id: [this.deleteItem.product.id]
          }
        })
        if (resp.status === 200) {
          this.resetSelectedItem()
          this.setSelectedStore('')
          this.handleDeleteCartProduct(true)
        }
      } catch (error) {
        console.log(error)
        this.$message({
          message: 'Oops, Terjadi kesalahan.',
          type: 'error'
        })
      }
    }
  }
}
