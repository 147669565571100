//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    title: {
      type: String,
      default: ''
    },

    section: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      listProduct: {
        isLoading: true,
        data: []
      }
    }
  },

  computed: {
    ...mapState('Partner', ['partner'])
  },

  async mounted () {
    this.listProduct.isLoading = true

    switch (this.section) {
      case 'clearance_sale':
        await this.setProductClearance()
        break
      case 'event_sale':
        await this.setProductEventSale()
        break
      case 'sale':
        await this.setProductSale()
        break
      case 'lastseen':
        await this.setProductLastSeen()
        break
      case 'recommendation':
        await this.setProductRecommendation()
        break
      default:
        await this.setProductLastSeen()
        break
    }

    this.listProduct.isLoading = false
  },

  methods: {
    ...mapActions('Product', [
      'getProductsEventSale',
      'getProductsSale',
      'getProductSection'
    ]),

    async setProductEventSale () {
      try {
        const resp = await this.getProductsEventSale({
          data: {
            conditions: {
              period: 'ongoing'
            },
            where_has: [
              {
                relation: 'session.event_sale',
                where: {
                  type: 'event_sale'
                }
              },
              {
                relation: 'product_detail.product',
                where: {
                  store_id: this.partner.id
                }
              }
            ],
            per_page: 5
          },
          dontSave: true
        })

        this.listProduct.data = [].concat(resp.data.data.event_sale_products.data.map(esp => esp.product))
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section event sale partner')
      }
    },

    async setProductClearance () {
      try {
        const resp = await this.getProductsEventSale({
          data: {
            conditions: {
              period: 'ongoing'
            },
            where_has: [
              {
                relation: 'session.event_sale',
                where: {
                  type: 'clearance_sale'
                }
              },
              {
                relation: 'product_detail.product',
                where: {
                  store_id: this.partner.id
                }
              }
            ],
            per_page: 5
          },
          dontSave: true
        })

        this.listProduct.data = [].concat(resp.data.data.event_sale_products.data.map(esp => esp.product))
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section clearance sale partner')
      }
    },

    async setProductSale () {
      try {
        const resp = await this.getProductsSale({
          data: {
            where: {
              store_id: this.partner.id
            },
            per_page: 5
          }
        })

        this.listProduct.data = resp.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section sale partner')
      }
    },

    async setProductLastSeen () {
      try {
        const resp = await this.getProductSection({
          section: 'last-seen',
          payload: {
            data: {
              per_page: 5,
              page: 1,
              session_id: this.$cookies.get('session_id'),
              where_has: [
                {
                  relation: 'store',
                  where: {
                    slug: this.partner.slug
                  }
                }
              ]
            }
          }
        })

        this.listProduct.data = resp.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section terakhir dilihat partner')
      }
    },

    async setProductRecommendation () {
      try {
        const resp = await this.$store.dispatch(
          'Partner/getProductRecommendations',
          {
            id: this.$route.params.id,
            payload: {
              data: {
                per_page: 10,
                limit: 10
              }
            }
          }
        )

        this.listProduct.data = resp.data.data.products
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section rekomendasi partner')
      }
    },

    handleSeeAll () {
      const localSec = this.section === 'recommendation' ? 'all' : this.section

      if (this.$route.query.section !== localSec) {
        this.$router.push({
          query: {
            section: localSec
          }
        })
      }
    }
  }
}
