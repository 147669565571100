//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions } from 'vuex'
export default {
  mixins: [panstoreMixin],
  props: {
    dateRange: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      page: 1,
      totalPage: 0,
      loading: true,
      historyPoint: [],
      onLoad: true
    }
  },
  watch: {
    dateRange () {
      this.page = 1
      this.totalPage = 0
      this.loading = true
      this.getData()
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('User', ['getHistoryPoint']),
    initComponent () {},
    async getData () {
      try {
        let payload = {
          paginated: true,
          where: {
            type: 'paninti-point'
          },
          page: this.page,
          per_page: 10,
          order_by: {
            updated_at: 'desc'
          },
          group_by_date: true
        }
        if (this.dateRange) {
          payload = {
            ...payload,
            where_greater_than: {
              created_at: this.dateRange[0]
            },
            where_lower_than: {
              created_at: this.dateRange[1]
            }
          }
        }
        const resp = await this.getHistoryPoint({
          data: {
            ...payload
          }
        })
        if (resp.status === 200) {
          if (this.page === 1) {
            this.historyPoint = resp.data.data.balance_histories.data
            this.totalPage = resp.data.data.balance_histories.last_page
          } else {
            let temp = resp.data.data.balance_histories.data
            if (
              resp.data.data.balance_histories.data[0].date ===
              this.historyPoint[this.historyPoint.length - 1].date
            ) {
              this.historyPoint[
                this.historyPoint.length - 1
              ].balance_histories = this.historyPoint[
                this.historyPoint.length - 1
              ].balance_histories.concat(
                resp.data.data.balance_histories.data[0].balance_histories
              )
              temp = temp.slice(1, temp.length - 1)
            }
            this.historyPoint = this.historyPoint.concat(temp)
          }
          this.page++
        }
      } catch (error) {
        console.log(error)
      } finally {
        if (this.onLoad) {
          this.onLoad = false
        }
      }
    }
  }
}
