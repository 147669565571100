import { render, staticRenderFns } from "./HeaderProfileSection.vue?vue&type=template&id=3fc8a61c&scoped=true&"
import script from "./HeaderProfileSection.vue?vue&type=script&lang=js&"
export * from "./HeaderProfileSection.vue?vue&type=script&lang=js&"
import style0 from "./HeaderProfileSection.vue?vue&type=style&index=0&id=3fc8a61c&lang=scss&scoped=true&"
import style1 from "./HeaderProfileSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc8a61c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,ShareSection: require('/usr/src/app/components/base/ShareSection.vue').default,PopupListStore: require('/usr/src/app/components/home/profile/PopupListStore.vue').default,PopupListBranch: require('/usr/src/app/components/home/profile/PopupListBranch.vue').default,PopupListContact: require('/usr/src/app/components/base/PopupListContact.vue').default})
