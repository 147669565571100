//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
export default {
  name: 'CheckoutPage',
  middleware ({ $auth, redirect }) {
    if (!$auth.loggedIn) {
      return redirect('/cart')
    }
  },
  async asyncData ({ store }) {
    store.dispatch('Cart/actionWithCart', false)
    try {
      const resp = await store.dispatch('User/address/getUserAddress')
      if (resp.status === 200 && resp.data.data.addresses.data.length) {
        let mainAddress = resp.data.data.addresses.data.filter((address) => {
          return address.main
        })[0]
        if (!mainAddress) {
          mainAddress = resp.data.data.addresses.data[0]
          await store.dispatch('User/address/editUserAddress', {
            id: mainAddress.id,
            payload: {
              data: {
                main: true
              }
            }
          })
        }
        await store.commit('Checkout/setSelectedAddress', mainAddress)
        await store.commit('Checkout/setOrderPayload', {
          user_address_id: mainAddress.id
        })
      }
    } catch (error) {
      console.log(error)
    }
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Checkout'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      loading: true
    }
  },
  computed: {
    ...mapState('Checkout', [
      'selectedCourier',
      'listItem',
      'selectedAddress',
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'pageTakeInStore',
      'onCheck'
    ]),
    checkPayload () {
      if (
        !this.pageTakeInStore &&
        Object.keys(this.listItem).length &&
        this.listItem.available.length &&
        Object.keys(this.selectedCourier).length &&
        Object.keys(this.selectedAddress).length &&
        this.getDataBranch().length &&
        Object.keys(this.getDataBranch()[this.branchSelected]).length &&
        !this.onCheck
      ) {
        return false
      } else if (
        this.pageTakeInStore &&
        Object.keys(this.listItem).length &&
        this.listItem.available.length &&
        !this.onCheck
      ) {
        return false
      } else {
        return true
      }
    }
  },
  mounted () {
    if (!localStorage.cartItem || !localStorage.cartItem.length) {
      this.$alert('Anda belum memilih produk untuk checkout')
      return this.$router.push('/cart')
    } else {
      this.initComponent()
    }
  },
  methods: {
    ...mapMutations('Checkout', ['setOrderPayload']),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    async initComponent () {
      if (localStorage.voucher) {
        this.setVoucherUsed(JSON.parse(localStorage.voucher))
      }
      const payload = {
        user_address_id: this.selectedAddress.id
      }
      await this.setOrderPayload(payload)
      this.loading = false
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    },
    handleErrorCheckout (errorMessage) {
      setTimeout(() => {
        this.$refs.popupLoading.$refs.buttonClose.click()
      }, 500)

      setTimeout(() => {
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Tidak dapat memproses pesanan',
            text: errorMessage,
            heightAuto: false,
            confirmButtonText: 'Kembali'
          })
      }, 500)
    }
  }
}
