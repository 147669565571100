//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  layout: 'affiliate'
}
