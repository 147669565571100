//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  watchQuery: true,

  asyncData ({ store, params, query, error }) {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Katalog Produk'
      }
    ]

    const dataOtherSections = [
      { section: 'today-promotion', label: 'Promo Hari Ini' },
      {
        section: 'on-sale-store-partner',
        label: 'Lagi Diskon Dari Store Partner'
      },
      {
        section: 'on-sale-brand-partner',
        label: 'Lagi Diskon Dari Brand Partner'
      }
    ]

    const tempData = dataOtherSections.filter((os) => {
      return os.section === params.section
    })

    let section = ''

    if (params.section === 'all') {
      section = 'getHomeProducts'
    } else if (params.section === 'recommendations') {
      section = 'getRecomendationProduct'
    } else if (params.section === 'top-picks') {
      section = 'getTopPickProducts'
    } else if (params.section === 'most-reviewed') {
      section = 'getMostReviewedProducts'
    } else if (params.section === 'last-seen') {
      section = 'getLastSeenProducts'
    } else if (params.section === 'new-products') {
      section = 'getNewProducts'
    } else if (tempData.length || false) {
      section = 'getProductSection'
      breadcrumbs[1].name = tempData[0].label
    } else {
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }

    return {
      section,
      request: false,
      breadCrumb: breadcrumbs,
      showAll: query.show_all === 'true'
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.name !== from.name) {
      this.isLeave = true
    }
    next()
  },

  data () {
    return {
      isLeave: false,
      getPartnerDataLimitCount: 1,
      totalProduct: 0
    }
  },

  computed: {
    ...mapState('Partner', ['partners']),
    ...mapState('Product', ['loadingProduct']),

    paramSection () {
      return this.$route.params.section || ''
    },

    searchQuery () {
      return this.$route.query.search || ''
    },

    catalogSEOTitle () {
      let catalogSEOTitle = ''

      if (this.paramSection === 'all' && this.searchQuery.trim() !== '') {
        catalogSEOTitle = 'Jual Produk ' + this.searchQuery.trim() + ' Lengkap dan Berkualitas Dengan Harga Terbaik | Paninti Store'
      } else {
        catalogSEOTitle = 'Katalog Produk | Paninti Store'
      }

      return catalogSEOTitle.toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },

    catalogSEODescription () {
      let catalogSEODescription = ''

      if (this.paramSection === 'all' && this.searchQuery.trim() !== '') {
        catalogSEODescription = 'Jual produk ' + this.searchQuery.trim() + ' terlengkap dan berkualitas dengan harga terbaik hanya di paninti store. Belanja online di Paninti Store sekarang dan nikmati pengalaman berbelanja yang mudah dan menyenangkan.'
      } else {
        catalogSEODescription = 'Temukan berbagai macam produk berkualitas tinggi dengan harga terbaik di Paninti Store. Belanja online di Paninti Store sekarang dan nikmati pengalaman berbelanja yang mudah dan menyenangkan.'
      }

      return catalogSEODescription
    },

    catalogSEOKeyword () {
      let catalogSEOKeyword = ''

      if (this.paramSection === 'all' && this.searchQuery.trim() !== '') {
        catalogSEOKeyword = 'produk ' + this.searchQuery.trim() + ' terlengkap dan berkualitas dengan harga terbaik'

        const catalogSEOKeywordArray = catalogSEOKeyword.trim().split(' ')
        catalogSEOKeyword = ''
        for (let i = 1; i < catalogSEOKeywordArray.length; i++) {
          for (let j = 0; j <= i; j++) {
            catalogSEOKeyword += catalogSEOKeywordArray[j]
            if (j < i) { catalogSEOKeyword += ' ' }
          }

          if (i < catalogSEOKeywordArray.length - 1) { catalogSEOKeyword += ',' }
        }
      }

      return catalogSEOKeyword
    }
  },

  watch: {
    '$route.query': {
      async handler () {
        await this.handleGetFilter()
      },
      deep: true
    }
  },

  mounted () {
    this.handleGetFilter()
  },

  methods: {
    ...mapMutations('Partner', ['setPartnersLoading']),
    ...mapActions('Product/filter', ['getFilterPage']),
    ...mapActions('Partner', ['getPartners']),

    async handleGetFilter () {
      const query = this.$route.query
      const params = this.$route.params

      try {
        const queryFilter = []

        if (query.search) {
          queryFilter.push(`search=${query.search}`)
        }
        if (query.event_sale) {
          queryFilter.push(`event_sale=${query.event_sale}`)
        }
        if (params.section !== 'all') {
          queryFilter.push(`section=${params.section}`)
        }

        const payload = '?' + queryFilter.join('&')
        await this.getFilterPage(payload)
      } catch (error) {
        console.log(error)
      }
    },

    handleTotalProductChanged (value) {
      this.totalProduct = value
    }
  },

  head () {
    const metaData = [
      {
        hid: 'title',
        name: 'title',
        content: this.catalogSEOTitle
      },
      {
        hid: 'og:title',
        name: 'og:title',
        content: this.catalogSEOTitle
      },
      {
        hid: 'description',
        name: 'description',
        content: this.catalogSEODescription
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: this.catalogSEODescription
      },
      {
        hid: 'og:image',
        name: 'og:image',
        content: process.env.URL + '/default-og-image.jpg'
      }
    ]

    if (this.paramSection === 'all' && this.searchQuery.trim() !== '') {
      metaData.push({
        hid: 'keywords',
        name: 'keywords',
        content: this.catalogSEOKeyword
      })
    }

    return {
      title: this.catalogSEOTitle,
      meta: metaData
    }
  }
}
