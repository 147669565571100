//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'DashboardEmptyAffiliate',
  mixins: [panstoreMixin],
  props: {
    slider: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      affiliateStats: [
        {
            id: 1,
            name: 'Total Pesanan',
            waktu: 'Sepanjang Waktu',
            key: 'total_order',
            color: 'main'
        },
        {
            id: 2,
            name: 'Total Klik',
            waktu: 'Sepanjang Waktu',
            key: 'total_click',
            color: 'pink'
        },
        {
            id: 3,
            name: 'Total Produk',
            waktu: 'Produk yang aktif',
            key: 'total_product',
            color: 'main'
        }
      ]
    };
  },
  computed: {
    ...mapState('Affiliate', ['summaryCount', 'commissionHistory']),
    ...mapState('Base', ['csPhone']),
    alertCheckMessage () {
      let alertObj = {}

      const username = this.$auth.user.username
      let name = this.$auth.user.first_name
      name += this.$auth.user.last_name ? ` ${this.$auth.user.last_name}` : ''

      if (!this.$auth.user.affiliate.is_active) {
        alertObj = Object.assign({
          message: 'Program Affiliate by Paninti di' +
            ' akunmu sudah tidak aktif, untuk mengaktifkannya ' +
            'kembali mohon untuk menghubungi CS kami',
          redirect: `https://api.whatsapp.com/send?phone=${this.csPhone}&text=Saya ingin bertanya mengenai akun ${username}-${name} saya yang afiliasinya sudah tidak aktif`
        })
      } else if (this.$auth.user.affiliate.is_expired) {
        alertObj = Object.assign({
          message: 'Program Affiliate by Paninti di' +
            ' akunmu sudah kadaluarsa, untuk mengaktifkannya ' +
            'kembali mohon untuk menghubungi CS kami',
          redirect: `https://api.whatsapp.com/send?phone=${this.csPhone}&text=Saya ingin bertanya mengenai akun ${username}-${name} saya yang afiliasinya sudah kadaluarsa`
        })
      }

      return alertObj
    }
  },
  async created () {
    await this.fetchSummaryCount();
  },
  methods: {
    ...mapActions('Affiliate', ['getSummaryCount', 'getCommissionHistory']),
    async fetchSummaryCount () {
      const payload = {
          data: {}
      };
      try {
          await this.getSummaryCount(payload);
      } catch (error) {
          console.log(error);
      }
    }
  }
}
