//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import panstoreMixin from '@/mixins'

export default {
  mixins: [panstoreMixin],

  props: {
    partner: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      windowWidth: process.client ? window.innerWidth : 0,

      showcaseProfile: {
        isLoading: true,
        data: []
      }
    }
  },

  computed: {
    normalDesktop () {
      return this.windowWidth >= 992
    }
  },

  mounted () {
    this.getProfileShowcases()
  },

  methods: {
    ...mapActions('Partner', ['getShowcasePartner']),

    async getProfileShowcases () {
      this.showcaseProfile.isLoading = true

      try {
        const resp = await this.getShowcasePartner({
          data: {
            store_id: this.partner ? this.partner.id : ''
          }
        })

        this.showcaseProfile.data = resp.data.data.showcases
          .filter((sc) => {
            return (
              sc.files.length > 0 &&
              !['flash_sale', 'event_sale', 'sale', 'clearance_sale'].includes(
                sc.type
              ) && sc.event_sale_session_id === null
            )
          })

        this.$emit('onDataObtained', resp.data.data.showcases)
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section etalase partner')
      }

      this.showcaseProfile.isLoading = false
    },

    handleClick (section) {
      this.$router.push(
        `/partner/${this.$route.params.id}?section=${this.slugtify(section)}`
      )
    }
  }
}
