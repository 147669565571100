//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapGetters } from 'vuex'

import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      shippingOrTake: 'first',
      disabledTab: false
    }
  },
  computed: {
    ...mapState('User/address', ['userAddress']),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    ...mapState('Checkout', [
      'selectedAddress',
      'pageTakeInStore',
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'onCheck',
      'usePoint'
    ]),
    haveAddress () {
      return this.userAddress.length
    },
    haveStoreBranchPAS () {
      return this.storeBranchPAS.length
    },
    enableShipping () {
      return this.storeBranch.length
    }
  },
  watch: {
    onCheck () {
      if (!this.enableShipping && !this.onCheck) {
        this.setEnableShipping(false)
        this.shippingOrTake = 'second'
        this.setNoteShipping('')
        this.setTakeInStore(true)
        this.checkVoucherShipping()
      }
      if (!this.onCheck) {
        if (!this.enableShipping || !this.haveStoreBranchPAS) {
          this.disabledTab = true
        } else {
          this.disabledTab = false
        }
        if (!this.storeBranch.length) {
          this.setEnableShipping(false)
          this.shippingOrTake = 'second'
          this.setNoteShipping('')
          this.setTakeInStore(true)
          this.checkVoucherShipping()
        }
      }
    }
  },
  methods: {
    ...mapMutations('Voucher', ['setVoucherUsed']),
    ...mapMutations('Checkout', [
      'setTakeInStore',
      'setNoteShipping',
      'setEnableShipping',
      'setSelectedBranch'
    ]),
    async handleTabClick (tab) {
      this.setNoteShipping('')

      if (tab.name === 'first') {
        this.setSelectedBranch(0)
        this.setTakeInStore(false)
      }

      if (tab.name === 'second') {
        this.setSelectedBranch(0)
        this.setTakeInStore(true)
        this.checkVoucherShipping()
      }

      if (this.shippingOrTake !== tab.name) {
        const resp = await this.checkAvailibilities(
          JSON.parse(localStorage.cartItem),
          this.storeBranch[this.branchSelected].id,
          this.selectedAddress.id,
          null,
          null,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.userPoint
        )

        const vouchers = resp.data.data.checkout.vouchers
        this.checkActiveOnlyVoucher(vouchers)
      }
    },
    checkVoucherShipping () {
      const voucherExist = this.voucherUsed.some((used) => {
        return used.type === 'free_shipping_cost'
      })

      if (voucherExist) {
        const voucherDisc = this.voucherUsed.filter((used) => {
          return used.type === 'discount'
        })

        this.setVoucherUsed(voucherDisc)
        localStorage.setItem('voucher', JSON.stringify(voucherDisc))
      }
    }
  }
}
