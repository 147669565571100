//
//
//
//
//
//
//
//
//
//


export default {
  layout: 'account',
  middleware: ['user', 'affiliate'],
  data () {
    return {
      breadCrumb: [
        {
            path: '/'
        },
        {
            name: 'Affiliasi',
            path: '/account/affiliate/'
        },
        {
            name: 'Daftar Produk'
        }
      ]
    };
  }
}
