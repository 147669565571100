//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixin from '@/mixins'

export default {
  mixins: [panstoreMixin],

  data () {
    return {
      onload: false,
      page: 2,
      perPage: 15
    }
  },

  computed: {
    ...mapState('Flashsale', ['dataFlashsales', 'indexFlashsale', 'activeSession']),

    onFlashSale () {
      return this.getFlashTime(this.dataFlashsales[this.indexFlashsale].sessions[this.activeSession].date_start)
    },

    activeDataSession () {
      if (this.activeSession <= 0) {
        return null
      }

      return this.dataFlashsales[this.indexFlashsale].sessions[this.activeSession]
    }
  },

  mounted () {
    this.page = this.dataFlashsales[this.indexFlashsale].sessions[this.activeSession].event_sale_products.current_page
  },

  methods: {
    ...mapActions('Flashsale', ['getProductSession']),

    async handleInfiniteLoading ($state) {
      await this.handleGetProducts()

      if (
        this.dataFlashsales[this.indexFlashsale].sessions[this.activeSession].event_sale_products.current_page + 1 >
        this.dataFlashsales[this.indexFlashsale].sessions[this.activeSession].event_sale_products.last_page
      ) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },

    async handleGetProducts () {
      this.onload = true

      try {
        await this.getProductSession({
          payload: {
            data: {
              where: {
                event_sale_session_id: this.dataFlashsales[this.indexFlashsale].sessions[this.activeSession].id
              },
              per_page: this.perPage,
              page: this.page
            }
          },
          config: {
            indexFlashsale: this.indexFlashsale,
            indexActive: this.activeSession
          }
        })

        this.page++
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk flash sale')
      }

      this.onload = false
    },

    getFlashTime (start) {
      const startTime = new Date(this.formatYMD(start))

      if (startTime.getTime() < new Date().getTime()) {
        return true
      }

      return false
    }
  }
}
