//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins/index'
import cookiesMixin from '@/mixins/Cookies'

export default {
  mixins: [panstoreMixin, cookiesMixin],
  created () {
    this.setCookies()
  },
  computed: {
    ...mapState('Product', ['product']),

    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  }
}
