//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions } from 'vuex'
import Pusher from 'pusher-js'
export default {
  mixins: [panstoreMixin],
  layout: 'account',
  middleware: ['user', 'affiliate'],
  data () {
    return {
      selectedProducts: [],
      breadCrumb: [
        {
            path: '/'
        },
        {
            name: 'Affiliasi',
            path: '/account/affiliate/'
        },
        {
            name: 'Daftar Produk',
            path: '/account/affiliate/product-list/'
        },
        {
            name: 'Tambah Produk'
        }
      ],
      pusherListenerCreated: false
    };
  },
  created () {
    if (!this.pusherListenerCreated) {
      this.pusherListenerCreated = true
      const pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: 'ap1',
        forceTLS: true,
        authEndpoint: `${process.env.BASE_API}/v1/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `${this.$auth.getToken('local')}`
          }
        }
      })
      pusher.subscribe(`private-user.${this.$store.state.auth.user.id}.create-affiliate-product`)
      pusher.bind('affiliate-product-created', (data) => {
        this.handleSuccess(data)
      })
    }
  },
  methods: {
    ...mapActions('Product', ['addProductAffiliate']),
    handleSuccess (data) {
      this.$Swal.close()
      if (data.status_code === 200 || data.status_code === 201) {
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil Menambahkan Produk',
            text: `${this.selectedProducts.length} Produk berhasil ditambahkan ke daftar afiliasi. Promosikan tautan referalmu untuk mendapatkan komisi dari Paninti`,
            heightAuto: false
          }).then((confirm) => {
            if (confirm) {
              this.$router.push('/account/affiliate/product-list');
            }
          })
      }
    },
    async handleAddProduct () {
      const payload = Object.assign({
        data: {
          product_ids: [...this.selectedProducts]
        }
      })
      try {
        const resp = await this.addProductAffiliate(payload)
        if (resp.status === 201) {
          this.$Swal
            .fire({
              icon: 'info',
              title: 'Produk Sedang Ditambahkan',
              text: `Mohon tunggu, sistem sedang membuat link affiliate untuk ${this.selectedProducts.length} produk yang ditambahkan`,
              heightAuto: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: false,
              showConfirmButton: false
            })
        }
      } catch (error) {
        console.log(error)
        const errorMessage = error.response ? error.response.data.message : error
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Gagal Menambahkan Produk',
            text: errorMessage,
            heightAuto: false
          })
          .then((confirm) => {
            if (confirm) {
              this.$router.push('/account/affiliate/product-list');
            }
          })
        }
    },
    handleChangeCatalogAffiliate (products = []) {
      this.selectedProducts = products
    }
  }
}
