//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapGetters } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      courier: {}
    }
  },
  computed: {
    ...mapState('Checkout', [
      'shippingCourier',
      'listItem',
      'selectedCourier',
      'selectedAddress',
      'branchSelected',
      'storeBranch',
      'storeBranchPAS',
      'pageTakeInStore',
      'onCheck',
      'usePoint',
      'noteShipping'
    ]),
    ...mapState('Voucher', ['voucherUsed', 'voucher']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    noteShippingInput: {
      get () {
        return this.noteShipping
      },
      set (value) {
        this.setNoteShipping(value)
      }
    },
    haveShippingCourier () {
      return this.shippingCourier.length > 0
    },
    haveList () {
      return Object.keys(this.listItem).length
    },
    totalWeight () {
      let weight = 0
      const datas = this.listItem.available
      datas.map((data) => {
        weight += data.product.weight * data.quantity
      })
      return (weight / 1000).toFixed(1)
    },
    getTimeStore () {
      const schedule = this.getDataBranch()[this.branchSelected].schedules
      let day = '-'
      let dayMax = '-'
      let indexToday = new Date().getDay()
      const nowHour = new Date().getHours()
      if (indexToday === 0) {
        indexToday = 7
      }
      indexToday--
      if (schedule.length) {
        const temp = schedule
        let datas = schedule
          .slice(indexToday, schedule.length - indexToday + 2)
          .concat(temp)
        const jamTutup = new Date(datas[0].close_time).getHours()
        if (!datas[0].is_open && nowHour + 2 > jamTutup) {
          datas = datas.filter((data, index) => {
            return !data.is_close && index !== 0
          })
        }
        day = datas[0]
        dayMax = datas[datas.length - 1]
        return `${day.day}, ${this.formatDate(this.formatDMY(day.date))} - ${
          dayMax.day
        }, ${this.formatDate(this.formatDMY(dayMax.date))}`
      } else {
        return null
      }
    }
  },
  watch: {
    branchSelected () {
      this.courier = {}
      this.setSelectedCourier({})
    },
    selectedAddress () {
      this.courier = {}
      this.setSelectedCourier({})
    },
    pageTakeInStore () {
      this.courier = {}
      this.setSelectedCourier({})
    }
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedCourier', 'setNoteShipping']),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    getLabel (courier) {
      if (courier.etd.value === '0 HARI' || courier.etd.value === '0' || courier.etd.value === '0-0') {
        return 'Same Day'
      } else if (
        courier.etd.value === '1' ||
        courier.etd.value === '1 HARI' ||
        courier.etd.value === '1-1'
      ) {
        return 'Next Day'
      } else {
        return `Pengiriman ${
          courier.etd.value.substring(0, 1) === '?'
            ? 'Lainnya'
            : this.getETD(courier.etd.value)
        }`
      }
    },
    getETD (etd) {
      if (etd === '1-1' || etd === '1' || etd === '1 HARI') {
        return '1 Hari'
      } else if (etd.trim() === '') {
        return ''
      } else {
        if (
          etd.substring(etd.length - 4, etd.length).toLowerCase() === 'hari'
        ) {
          return etd.substring(0, etd.length - 4) + ' Hari'
        }
        if (etd.substring(etd.length - 3, etd.length).toLowerCase() === 'jam') {
          return etd.substring(0, etd.length - 4) + 'Jam'
        }
        return etd + ' Hari'
      }
    },
    handleChangeCourier () {
      this.setSelectedCourier(this.courier)
      this.checkAvailibility()
    },
    async checkAvailibility () {
      try {
        const resp = await this.checkAvailibilities(
          JSON.parse(localStorage.cartItem),
          this.getDataBranch()[this.branchSelected].id,
          this.selectedAddress.id,
          this.courier.code,
          this.courier.service,
          false,
          this.voucherUsedId,
          this.usePoint
        )

        const vouchers = resp.data.data.checkout.vouchers
        this.checkActiveOnlyVoucher(vouchers)
      } catch (error) {
        console.log(error)
      }
    },
    handleNoteShipping (e) {
      this.setNoteShipping(e.target.value)
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    }
  }
}
