//
//
//
//

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      observer: null,
      minId: null,
      maxId: null
    }
  },

  mounted () {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting && this.disabled === false) {
          this.$emit('intersect')
        }
      },
      {
        rootMargin: '500px',
        threshold: 0.1
      }
    );

    this.observer.observe(this.$el);
  },

  destroyed () {
    this.observer.disconnect();
  }
};
