//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('Order', ['order', 'orderTracking'])
  },
  created () {
    this.initComponent()
  },
  methods: {
    getFormatDate (date, time) {
      const newDate = new Date(date)
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'Nopember',
        'Desember'
      ]
      const dayNames = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu'
      ]
      return `${dayNames[newDate.getDay()]}, ${newDate.getDate()} ${
        monthNames[newDate.getMonth()]
      } ${newDate.getFullYear()} (${time})`
    },
    async initComponent () {
      try {
        await this.$store.dispatch('Order/getTrackingOrder', {
          data: { order_id: this.$route.params.order_id }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
