//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'AllCommissionHistory',
  mixins: [panstoreMixin],
  props: {
    formFilter: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapState('Affiliate', ['commissionHistory']),
    listHistory () {
      const tempMonths = []
      this.commissionHistory.data.forEach((dt, index) => {
        const titleDate = this.getMonthAndYear(dt.created_at, '-', true)

        const checkMonth = tempMonths.some((tmp) => {
          return tmp.titleDate === titleDate
        })

        if (!checkMonth) {
          tempMonths.push({
            titleDate,
            dateRaw: dt.created_at,
            data: []
          })
        }

        const indexMonth = tempMonths.findIndex((tmp) => {
          return tmp.titleDate === this.getMonthAndYear(dt.created_at, '-', true)
        })

        if (indexMonth > -1) {
          tempMonths[indexMonth].data.push(dt)
        }
      })

      return tempMonths || []
    }
  },
  watch: {
    formFilter: {
      handler () {
        this.resetCommissionHistory()
        this.infiniteId++
      },
      deep: true
    }
  },
  async mounted () {
    await this.fetchAffiliateCommission()
  },
  methods: {
    ...mapMutations('Affiliate', ['resetCommissionHistory']),
    ...mapActions('Affiliate', ['getCommissionHistory', 'getCommissionHistoryDetail']),
    async handleInfiniteLoading ($state) {
      await this.fetchAffiliateCommission()
      if (this.commissionHistory.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async fetchAffiliateCommission () {
      const payload = {
        params: {
          paginated: true,
          per_page: 5,
          page: this.commissionHistory.pagination.nextPage || 1
        }
      }

      if (this.checkObject(this.formFilter)) {
        if (this.formFilter.range !== 'all') {
          payload.params.period_date = this.formFilter.range
        }

        if (this.formFilter.type !== 'all') {
          payload.params.balance_type = this.formFilter.type
        }
      }

      try {
        await this.getCommissionHistory(payload)
      } catch (error) {
        console.log(error)
      }
    },
    async openDetailHistory (id) {
      try {
        const payload = {
          id
        }

        await this.getCommissionHistoryDetail(payload)
        setTimeout(() => { this.$refs.btnOpenDetail.click() })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    }
  }
}
