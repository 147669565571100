//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins/index'
import VClamp from 'vue-clamp'
import { mapMutations, mapActions } from 'vuex'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    showShare: {
      type: Boolean,
      default: true
    },
    affiliateId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dropdownVisible: false,
      productAffiliateDeleted: false
    }
  },
  computed: {
    realPrice () {
      return this.item.product.discounted_price.toString()
    },
    productPrice () {
      // check range price
      if (this.realPrice && this.realPrice.includes('-')) {
        const cheaperPrice = this.realPrice.split('-')

        if (this.checkArray(cheaperPrice)) {
          return this.formatRupiah(cheaperPrice[0], 'Rp')
        }
      }
      return this.formatRupiah(this.realPrice, 'Rp')
    }
  },
  methods: {
    ...mapMutations('Product', ['setShareUrl']),
    ...mapActions('Product', ['deleteAffiliateProduct']),
    handleSetShareUrl (url) {
      this.setShareUrl(url)
    },
    handleGotoDetail (slug) {
      this.$router.push(`/product/${slug}`)
    },
    toggleDropdown () {
      this.dropdownVisible = !this.dropdownVisible
    },
    handleCommand (command) {
      const { type, id } = command

      if (type === 'delete') {
        this.handleDeleteAffiliateProduct(id)
      }
    },
    handleDeleteAffiliateProduct (id) {
       this.$Swal
        .fire({
          title: 'Hapus produk afiliasi',
          text: 'Apakah anda yakin ingin menghapus produk dari afiliasi?',
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Hapus',
          cancelButtonText: 'Batal'
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.deleteAffiliateProduct({
                data: {
                  id: [id]
                }
              })

              this.productAffiliateDeleted = true
              this.$emit('delete-affiliate-item', id)
            } catch (error) {
              console.log(error)
            }
          }
        })
    }
  }
}
