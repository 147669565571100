//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState } from 'vuex'
export default {
  mixins: [mixins],
  props: {
    flashSale: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },
  computed: {
    ...mapState('Flashsale', [
      'dataFlashsales',
      'indexFlashsale',
      'activeSession'
    ]),
    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    normalDesktop () {
      return this.windowWidth >= 992
    },
    flashSaleBanner () {
      const type = this.normalPhone ? 'banner_mobile' : 'banner'

      if (!this.flashSale || !this.flashSale.files.length) {
        return null
      }

      const data = this.flashSale.files.filter((file) => {
        return file.name === type
      })

      return data && data.length ? data[0] : null
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = process.client ? window ? window.innerWidth : 0 : 0
    }
  }
}
