//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixins from '@/mixins'
export default {
  mixins: [panstoreMixins],
  props: {
    brand: {
      type: String,
      default () {
        return ''
      }
    },
    isPartner: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      value: 4,
      onLoading: true
    }
  },
  computed: {
    ...mapState('Category', ['productCategory'])
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Category', ['getCategories']),
    async initComponent () {
      try {
        let payload = {}
        if (this.isPartner) {
          payload = {
            data: {
              where_has: [
                {
                  relation: 'products.details.store_branches.store',
                  where: {
                    'stores.id': this.$route.params.id
                  }
                }
              ],
              paginated: false
            }
          }
        } else {
          payload = {
            data: {
              where_has: [
                {
                  relation: 'brands',
                  where: {
                    'brands.id': this.$route.params.id
                  }
                }
              ],
              paginated: false
            }
          }
        }
        const resp = await this.getCategories(payload)
        if (resp.status === 200) {
          this.onLoading = false
        }
      } catch (err) {
        this.onLoading = false
        console.log(err)
      }
    },
    toggleShow () {
      document.getElementById('buttonExpand').blur()
      if (this.value === 4) {
        this.value = this.productCategory.length
      } else {
        this.value = 4
      }
    },
    handleClick (item) {
      this.$router.push(
        `/products/all?categories=${item.slug}${
          this.brand ? '&brands=' + this.brand : ''
        }`
      )
    }
  }
}
