//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions, mapState } from 'vuex'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  mixins: [mixins],

  props: {
    type: {
      type: String,
      default: 'brand'
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      windowWidth: process.client && window ? window.innerWidth : 0,
      windowHeight: process.client && window ? window.innerHeight : 0,
      todayProducts: [],
      banners: [],
      isLoading: true
    }
  },

  computed: {
    ...mapState('Product', ['product']),
    ...mapState('User/address', ['location']),

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 769
    },

    bannerPromo () {
      return (
        this.banners.filter((banner) => {
          return banner.display_type ===
          (this.windowWidth > 0 && this.windowWidth < 769)
            ? 'normal phone'
            : 'normal desktop'
        })[0] || []
      )
    }
  },

  watch: {
    location () {
      this.isLoading = true
      this.getPromotionProducts()
      this.isLoading = false
    }
  },

  async mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    await this.getPromotionSectionData()
  },

  methods: {
    ...mapActions('Contents', ['getBannerTodayPromotion']),
    ...mapActions('Product', ['getProductTodaySection']),

    onResize () {
      this.windowWidth = process.client && window ? window.innerWidth : 0
    },

    async getPromotionSectionData () {
      this.isLoading = true

      await this.getPromotionProducts()
      await this.getBannerPromotion()

      this.isLoading = false
    },

    async getPromotionProducts () {
      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155

        const respProduct = await this.getProductTodaySection({
          section: 'today-promotion',
          data: {
            city_id: cityId,
            per_page: 10
          }
        })

        this.todayProducts = respProduct.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk promo hari ini')
      }
    },

    async getBannerPromotion () {
      try {
        const resp = await this.getBannerTodayPromotion()

        this.banners = resp.data.data.contents[0].contentable.banners || []
      } catch (error) {
        this.$message.error('Gagal mendapatkan data banner promo hari ini')
      }
    },

    getImageBanner (files) {
      let img = '/img/default/default_product_image.png'

      if (files !== undefined && files.length) {
        const temp = files.filter((file) => {
          return file.variant === 'original'
        })

        if (temp.length) {
          img = temp[0].value + '.webp'
        }
      }

      return img
    }
  }
}
