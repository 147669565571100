var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container bg-white"},[_c('div',{staticClass:"container-section my-4"},[(_vm.clearanceSale.length)?_c('div',[_c('BannerEventSaleSection',{attrs:{"data-banner":[_vm.clearanceSale[0].event_sale]}}),_vm._v(" "),_c('BrandSaleSection',{attrs:{"data-event":_vm.clearanceSale[0].event_sale}}),_vm._v(" "),(_vm.brands.length)?_c('SectionTemplate',{attrs:{"title":_vm.clearanceSale[0].event_sale.name,"action":"getProductsEventSale","payload":{
          data: {
            conditions: {
              period: 'ongoing'
            },
            where_has: [
              {
                relation: 'session.event_sale',
                where: {
                  type: 'clearance_sale'
                }
              }
            ],
            per_page: 10
          }
        }}}):_vm._e()],1):[_vm._m(0)]],2),_vm._v(" "),_c('BannerBottomSection')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center p-5"},[_c('img',{staticClass:"empty-sale mb-4",attrs:{"src":require("@/static/img/default/no-clearance-sale.svg"),"alt":"Empty Clearance Sale"}}),_vm._v(" "),_c('div',{staticClass:"font-heading-2"},[_vm._v("\n          Tidak ada clearance sale yang sedang berlangsung\n        ")])])}]

export { render, staticRenderFns }