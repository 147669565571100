import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4d31ddfb&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=4d31ddfb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d31ddfb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,BannerFlashsale: require('/usr/src/app/components/home/flashsale/BannerFlashsale.vue').default,TabFlashsale: require('/usr/src/app/components/home/flashsale/TabFlashsale.vue').default,TimeCountDownFlashsale: require('/usr/src/app/components/home/flashsale/TimeCountDownFlashsale.vue').default,ListProductFlashsale: require('/usr/src/app/components/home/flashsale/ListProductFlashsale.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
