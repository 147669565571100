//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'account',
  middleware: ['user'],
  asyncData ({ error }) {
    error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Akun',
        path: '/account/home'
      },
      {
        name: 'Profil'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      filter: {
        basedOn: '',
        type: ''
      }
    }
  }
}
