//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapState, mapMutations, mapActions } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],
  data () {
    return {
      inputSearch: '',
      onLoading: false,
      perPage: 8,
      infiniteId: +new Date(),
      storeType: '',
      selectedProducts: []
    }
  },
  computed: {
    ...mapState('Product', ['productUnAffiliated']),
    ...mapState('Product', ['popularUnAffiliated']),
    listProduct () {
      return this.productUnAffiliated.data;
    },
    listProductPopular () {
      return this.popularUnAffiliated;
    }
  },
  async mounted () {
    await this.getUnAffiliated();
    await this.fetchPopularUnAffiliated();
  },
  methods: {
    ...mapMutations('Product', ['resetProductUnAffiliated']),
    ...mapActions('Product', ['getProductUnAffiliated']),
    ...mapActions('Product', ['getPopularUnAffiliated']),
    handleAddProductAffiliate (value) {
      const { id, checked } = value

      if (checked) {
        this.selectedProducts.push(id)
      } else {
        const deletedIdx = this.selectedProducts.findIndex((selected) => {
          return selected === id
        })

        if (deletedIdx > -1) {
          this.selectedProducts.splice(deletedIdx, 1)
        }
      }

      this.$emit('change', this.selectedProducts)
    },
    async handleInfiniteLoading ($state) {
      await this.getUnAffiliated()

      if (this.productUnAffiliated.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async getUnAffiliated () {
      this.onLoading = true;
      const payload = Object.assign({
          data: {
              paginate: true,
              per_page: this.perPage

          },
          params: {
              page: this.productUnAffiliated.pagination.nextPage || 1
          }
      });

      if (this.inputSearch || this.storeType) {
        payload.data = Object.assign(payload.data, {
          search: this.inputSearch,
          store_type: this.storeType
        })
      }
      try {
        await this.getProductUnAffiliated(payload);
      } catch (error) {
          console.log(error);
      } finally {
          this.onLoading = false;
      }
    },
    async fetchPopularUnAffiliated () {
      this.onLoading = true;
      try {
        const payload = {
            data: {
                paginated: false,
                limit: 4,
                search: this.search,
                for_recommendation: true

            }
        };
        await this.getPopularUnAffiliated(payload);
      } catch (error) {
          console.log(error);
      } finally {
          this.onLoading = false;
      }
    },
    handleSearch () {
      const value = this.inputSearch
      const input = value.charAt(value.length - 1)
      const letters = /^[A-Za-z\d\-_\s]+$/i
      if (value === ' ') {
        this.inputSearch = ''
      }

      if (!input.match(letters)) {
        this.inputSearch = value.substring(0, value.length - 1)
      }
    },
    handleChangeFilter: _.debounce(function () {
      this.infiniteId++
      this.resetProductUnAffiliated()
      this.getUnAffiliated()
    }, 500),
    changeFilter (filter) {
      this.storeType = filter;
      this.infiniteId++
      this.resetProductUnAffiliated()
      this.getUnAffiliated()
    }
  }
}
