//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'account',
  middleware: ['user'],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Beranda',
        path: '/account/home'
      },
      {
        name: 'Akun Saya',
        path: '/account/home'
      },
      {
        name: 'Ubah Password'
      }
    ]
    return {
      breadCrumb: breadcrumbs
    }
  }
}
