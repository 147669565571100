//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],
  props: {
    sessionId: {
      type: String,
      default: ''
    },
    dataEvent: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },

      brands: []
    }
  },

  computed: {
    ...mapState('Contents', ['eventSale']),

    haveBrands () {
      return this.brands.length > 0
    },

    dataEventExist () {
      return this.dataEvent && Object.keys(this.dataEvent).length
    }
  },

  async mounted () {
    await this.getOnSaleBrands()
  },

  methods: {
    ...mapActions('Brand', ['getListBrandsSale']),

    async getOnSaleBrands () {
      try {
        const resp = await this.getListBrandsSale({
          type:
            this.$route.name === 'sale-id' ? 'event-sale' : this.$route.name,
          payload: {
            data: {
              event_sale_session_id: this.sessionId,
              paginated: false
            }
          }
        })

        this.brands = resp.data.data.brands
      } catch (error) {
        this.$message.error('Gagal mendapatkan data brand sedang diskon')
      }
    },

    handleGoToBrandPage (brand) {
      const eventSlug = this.dataEventExist ? this.dataEvent.slug : this.$route.params.id
      let urlBrandPage = '/products/all?brands=' + brand

      if (eventSlug) {
        urlBrandPage += '&event_sale=' + eventSlug
      }

      window.location = urlBrandPage
    }
  }
}
