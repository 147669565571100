//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: {
    brand: {
      type: Object,
      default () {
        return {}
      }
    },
    brandPartner: {
      type: Boolean,
      default () {
        return false
      }
    },
    storeType: {
      type: String,
      default: ''
    },
    productType: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleGoto (id) {
      this.$router.push('/partner/' + id)
    }
  }
}
