//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    histories: {
      type: Array,
      default () {
        return []
      }
    },
    method: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      orderTimeList: [
        {
          name: 'Waktu Pemesanan',
          time: '-'
        },
        {
          name: 'Waktu Pembayaran',
          time: '-'
        },
        {
          name: 'Waktu Pengiriman',
          time: '-'
        },
        {
          name: 'Waktu Pesanan Selesai',
          time: '-'
        }
      ]
    }
  },
  mounted () {
    this.initComponent()
  },
  methods: {
    initComponent () {
      this.histories.length &&
        this.histories.map((history) => {
          const tempDate =
            this.formatDate(history.date) + ' ' + history.time.substr(0, 5)
          switch (history.slug) {
            case 'menunggu-pembayaran':
              this.orderTimeList[0].time = tempDate
              break
            case 'pembayaran-berhasil':
              this.orderTimeList[1].time = tempDate
              break
            case 'pesanan-telah-dikirim':
              if (this.method === 'shipping') {
                this.orderTimeList[2].time = tempDate
              } else {
                this.orderTimeList[2].name = 'Waktu Pengambilan'
              }
              break
            case 'transaksi-selesai':
              this.orderTimeList[3] = Object.assign({
                time: tempDate,
                name: 'Waktu Pesanan Selesai'
              })
              if (this.method === 'pickup') {
                this.orderTimeList[2].time = tempDate
              }
              break
            case 'transaksi-dibatalkan':
              this.orderTimeList[3] = Object.assign({
                time: tempDate,
                name: 'Waktu Pesanan Dibatalkan'
              })
              break
          }
        })
    }
  }
}
