//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

export default {
  props: {
    address: {
      type: Object,
      default: () => {
      }
    },

    onSubmit: {
      type: Boolean,
      default: () => {
      }
    }
  },

  data () {
    return {
      defaultPosition: { lat: -2.548926, lng: 118.0148634 },
      provinces: [],
      cities: [],
      subDistricts: [],
      villages: [],

      gMapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },

      inputAddress: [
        {
          name: 'title',
          placeholder: 'Label Alamat',
          label: 'Label Alamat',
          type: 'input',
          note: '*Contoh : Alamat Rumah, Alamat Kantor',
          action: e => this.handleChangeName(e)
        },
        {
          name: 'recipient_name',
          placeholder: 'Nama Lengkap',
          label: 'Nama Lengkap',
          type: 'input',
          action: e => this.handleChangeName(e)
        },
        {
          name: 'phone_number',
          placeholder: 'No. telepon',
          label: 'No. Telepon',
          type: 'input',
          action: e => this.handleChangePhoneNumber(e)
        },
        {
          name: 'province',
          placeholder: 'Province',
          label: 'Province',
          type: 'select',
          action: e => this.handleChange(e)
        },
        {
          name: 'city',
          placeholder: 'Kota / Kabupaten',
          label: 'Kota / Kabupaten',
          type: 'select',
          action: e => this.handleChange(e)
        },
        {
          name: 'subdistrict',
          placeholder: 'Kecamatan',
          label: 'Kecamatan',
          type: 'select',
          action: e => this.handleChange(e)
        },
        {
          name: 'village_id',
          placeholder: 'Kelurahan / Desa',
          label: 'Kelurahan / Desa',
          type: 'select',
          action: e => this.handleChange(e)
        },
        {
          name: 'detail_address',
          placeholder: 'Alamat',
          label: 'Alamat',
          type: 'textarea',
          action: e => this.handleChangeInputAddress(e)
        },
        {
          name: 'note',
          placeholder: 'Catatan',
          label: 'Catatan',
          type: 'textarea',
          action: e => this.handleChangeNote(e)
        }
      ],

      formData: {
        title: '',
        recipient_name: '',
        phone_number: '',
        province: '',
        city: '',
        subdistrict: '',
        village_id: '',
        postal_code: '',
        detail_address: '',
        note: '',
        main: false,
        latitude: '',
        longitude: ''
      },

      error: {
        title: '',
        recipient_name: '',
        phone_number: '',
        province: '',
        city: '',
        subdistrict: '',
        village_id: '',
        postal_code: '',
        detail_address: ''
      }
    }
  },

  computed: {
    ...mapState('GoogleMaps', ['location']),

    selectedAddressProvince () {
      if (
        this.address.id &&
        this.address.village &&
        this.address.village.subdistrict &&
        this.address.village.subdistrict.city &&
        this.address.village.subdistrict.city.province
      ) {
        return this.address.village.subdistrict.city.province
      }

      return null
    },

    selectedAddressCity () {
      if (
        this.address.id &&
        this.address.village &&
        this.address.village.subdistrict &&
        this.address.village.subdistrict.city
      ) {
        return this.address.village.subdistrict.city
      }

      return null
    },

    selectedAddressSubDistrict () {
      if (this.address.id && this.address.village && this.address.village.subdistrict) {
        return this.address.village.subdistrict
      }

      return null
    },

    selectedAddressVillage () {
      if (this.address.id && this.address.village) {
        return this.address.village
      }

      return null
    }
  },

  mounted () {
    this.initComponent()
  },

  methods: {
    ...mapActions('Region', [
      'getProvinces',
      'getCities',
      'getSubdistricts',
      'getVillages'
    ]),
    ...mapActions('GoogleMaps', ['getLocation']),
    ...mapActions('User/address', ['editUserAddress']),

    async initComponent () {
      this.$emit('on-submit-changed', true)

      await this.initFormData()
      await this.initProvince()
      await this.initCity()
      await this.initDistrict()
      await this.initVillage()
      await this.getDefaultLocation()

      this.$emit('on-submit-changed', false)
    },

    initFormData () {
      this.formData = Object.assign({
        title: this.address.title,
        recipient_name: this.address.recipient_name,
        phone_number: this.address.phone_number,
        province: this.selectedAddressProvince ? this.selectedAddressProvince.id : '',
        city: this.selectedAddressCity ? this.selectedAddressCity.id : '',
        subdistrict: this.selectedAddressSubDistrict ? this.selectedAddressSubDistrict.id : '',
        village_id: this.selectedAddressVillage ? this.selectedAddressVillage.id : '',
        postal_code: this.selectedAddressVillage ? this.selectedAddressVillage.postal_code : '',
        detail_address: this.address.detail_address,
        note: this.address.note,
        main: this.address.main,
        latitude: this.address.latitude,
        longitude: this.address.longitude
      })
    },

    async initProvince () {
      try {
        const resp = await this.getProvinces()

        this.provinces = resp.data.data.region_provinces
      } catch (error) {
        this.$message.error('Gagal mendapatkan data provinsi')
      }
    },

    async initCity () {
      try {
        const resp = await this.getCities({
          data: {
            where: {
              province_id: this.formData.province
            }
          }
        })

        this.cities = resp.data.data.region_cities
      } catch (error) {
        this.$message.error('Gagal mendapatkan data kota/kabupaten')
      }
    },

    async initDistrict () {
      try {
        const resp = await this.getSubdistricts({
          data: {
            where: {
              city_id: this.formData.city
            }
          }
        })

        this.subDistricts = resp.data.data.region_subdistricts
      } catch (error) {
        this.$message.error('Gagal mendapatkan data kecamatan')
      }
    },

    async initVillage () {
      try {
        const resp = await this.getVillages({
          data: {
            where: {
              subdistrict_id: this.formData.subdistrict
            }
          }
        })

        this.villages = resp.data.data.region_villages
      } catch (error) {
        this.$message.error('Gagal mendapatkan data desa/kelurahan')
      }
    },

    handleChangeName (e) {
      const input = this.formData[e.target.id].charAt(
        this.formData[e.target.id].length - 1
      )
      const inputBefore = this.formData[e.target.id].charAt(
        this.formData[e.target.id].length - 2
      )
      const lettersLabel = /^[A-Za-z. 0-9]+$/
      const alphabet = /^[A-Za-z. ]+$/

      if (this.formData[e.target.id].length === 1) {
        if (input === ' ') {
          this.formData[e.target.id] = ''
        } else {
          this.formData[e.target.id] = input.toUpperCase()
        }
      }
      if (!input.match(lettersLabel) && e.target.id === 'title') {
        // hanya huruf, . dan spasi (tidak bisa double spasi)
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      } else if (!input.match(alphabet) && e.target.id === 'recipient_name') {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      } else if (inputBefore.match(' ')) {
        this.formData[e.target.id] =
          this.formData[e.target.id].substr(
            0,
            this.formData[e.target.id].length - 1
          ) + input.toUpperCase()
      }

      if (
        this.formData[e.target.id].split('').length > 15 &&
        e.target.id === 'title'
      ) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }

      if (
        this.formData[e.target.id].split('').length > 30 &&
        e.target.id === 'recipient_name'
      ) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }

      if (this.formData[e.target.id].split('').length < 5) {
        this.error[e.target.id] = `${
          e.target.id === 'title' ? 'Label alamat' : 'Nama penerima'
        } minimal 5 karakter`
      } else {
        this.error[e.target.id] = ''
      }

      this.getDefaultMap()
    },

    handleChangePhoneNumber (e) {
      const input = this.formData[e.target.id].charAt(
        this.formData[e.target.id].length - 1
      )
      const letters = /[^0-9]/g

      if (letters.test(input)) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }

      if (this.formData[e.target.id].split('').length >= 13) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }

      if (this.formData[e.target.id].split('').length < 11) {
        this.error[e.target.id] = 'Nomor telepon minimal 11 karakter'
      } else {
        this.error[e.target.id] = ''
      }
    },

    handleChangeInputAddress (e) {
      this.formData[e.target.id] = e.target.value

      if (this.formData[e.target.id].split('').length >= 300) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }

      if (this.formData[e.target.id].split('').length < 10) {
        this.error[e.target.id] = 'Alamat minimal 10 karakter'
      } else {
        this.error[e.target.id] = ''
        const _this = this
        this.timer = setTimeout(function () {
          _this.getDefaultMap()
        }, 2000)
      }
    },

    handleChangeNote (e) {
      if (this.formData[e.target.id].split('').length >= 300) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }
    },

    async handleChange (e) {
      const target = e.target.id
      this.formData[target] = e.target.value

      if (target === 'province') {
        await this.initCity()

        this.subDistricts = []
        this.villages = []

        this.formData.city = ''
        this.formData.subdistrict = ''
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'city') {
        await this.initDistrict()

        this.villages = []

        this.formData.subdistrict = ''
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'subdistrict') {
        await this.initVillage()

        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'village_id') {
        this.villages.map((village) => {
          if (village.id === Number(this.formData.village_id)) {
            this.formData.postal_code = village.postal_code
          }
        })
      }

      this.error[e.target.id] = ''
      this.getDefaultMap()
    },

    async getDefaultLocation () {
      const province = this.provinces.filter((province) => {
        return province.id === Number(this.formData.province)
      })[0].name
      const city = this.cities.filter((city) => {
        return city.id === Number(this.formData.city)
      })[0]
      const subdistrict = this.subdistricts.filter((subdistrict) => {
        return subdistrict.id === Number(this.formData.subdistrict)
      })[0].name
      const village = this.villages.filter((village) => {
        return village.id === Number(this.formData.village_id)
      })[0].name
      const string =
        this.formData.detail_address +
        ', ' +
        village +
        ', ' +
        subdistrict +
        ', ' +
        city.type +
        ' ' +
        city.name +
        ', ' +
        province +
        ', ' +
        this.formData.postal_code

      try {
        const resp = await this.getLocation({
          data: {
            address: string
          }
        })

        this.formData.latitude = resp.data.data.results[0].geometry.location.lat
        this.formData.longitude = resp.data.data.results[0].geometry.location.lng
        this.defaultPosition.lat = resp.data.data.results[0].geometry.location.lat
        this.defaultPosition.lng = resp.data.data.results[0].geometry.location.lng

        this.handleChangePosition({
          latLng: {
            lat: this.formData.latitude,
            lng: this.formData.longitude
          }
        })
      } catch (error) {
        this.$message.error('Gagal mendapatkan data latitude dan longitude lokasi pengguna')
      }
    },

    checkValidation () {
      let valid = true

      if (!this.formData.title) {
        this.error.title = 'Label alamat wajib diisi'
        valid = false
      } else {
        this.error.title = ''
      }

      if (!this.formData.recipient_name) {
        this.error.recipient_name = 'Nama penerima wajib diisi'
        valid = false
      } else {
        this.error.recipient_name = ''
      }

      if (!this.formData.phone_number) {
        this.error.phone_number = 'Nomor Telepon wajib diisi'
        valid = false
      } else {
        this.error.phone_number = ''
      }

      if (!this.formData.province) {
        this.error.province = 'Provinsi wajib diisi'
        valid = false
      } else {
        this.error.province = ''
      }

      if (!this.formData.city) {
        this.error.city = 'Kota/kabupaten wajib diisi'
        valid = false
      } else {
        this.error.city = ''
      }

      if (!this.formData.subdistrict) {
        this.error.subdistrict = 'Kecamatan wajib diisi'
        valid = false
      } else {
        this.error.subdistrict = ''
      }

      if (!this.formData.village_id) {
        this.error.village_id = 'Kelurahan wajib diisi'
        valid = false
      } else {
        this.error.village_id = ''
      }

      if (!this.formData.detail_address) {
        this.error.detail_address = 'Detail Alamat wajib diisi'
        valid = false
      } else {
        this.error.detail_address = ''
      }

      return valid
    },

    resetForm () {
      this.formData.title = ''
      this.formData.recipient_name = ''
      this.formData.phone_number = ''
      this.formData.province = ''
      this.formData.city = ''
      this.formData.subdistrict = ''
      this.formData.village_id = ''
      this.formData.postal_code = ''
      this.formData.detail_address = ''
      this.formData.note = ''
      this.formData.main = false
      this.formData.latitude = ''
      this.formData.longitude = ''
    },

    async handleSubmit () {
      this.$emit('on-submit-changed', true)
      const valid = this.checkValidation()

      if (valid && this.formData.latitude && this.formData.longitude) {
        try {
          await this.editUserAddress({
            id: this.address.id,
            payload: { data: this.formData }
          })

          this.resetForm()
          this.$refs.closeButton.click()
          this.$Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Alamat berhasil ditambahkan !',
            heightAuto: false
          })
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error.message

          this.$Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: errorMessage,
            heightAuto: false
          })
        }
      } else {
        this.$Swal.fire({
          icon: 'warning',
          title: 'Pemberitahuan',
          text: 'Lengkapi data dengan benar!',
          heightAuto: false
        })
      }

      this.$emit('on-address-edited')
      this.$emit('on-submit-changed', false)
    },

    handleChangePosition (event) {
      this.$refs.markerLocation.$markerObject.setPosition(event.latLng)
    },

    getDefaultMap () {
      if (
        this.formData.province &&
        this.formData.city &&
        this.formData.subdistrict &&
        this.formData.village_id &&
        this.formData.postal_code &&
        this.formData.detail_address
      ) {
        this.getDefaultLocation()
      }
    }
  }
}
