//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  props: {
    account: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      activeNames: ['1'],
      menus: [
        {
          link: 'javascript:void(0)',
          label: 'Kategori',
          isChild: true,
          actives: [],
          child: []
        },
        {
          link: 'javascript:void(0)',
          label: 'Brand',
          isChild: true,
          actives: [],
          child: []
        },
        // {
        //   link: 'javascript:void(0)',
        //   label: 'Shop By Store',
        //   isChild: false
        // },
        {
          link: 'javascript:void(0)',
          label: 'Sale',
          isChild: true,
          actives: [],
          child: [
            {
              link: '/sale',
              label: 'Sale'
            },
            {
              link: '/clearance-sale',
              label: 'Clearance Sale'
            }
          ]
        },
        {
          link: '/account/shop/my-order',
          label: 'Konfirmasi Pembayaran',
          isChild: false
        },
        {
          link: '/page/call-center',
          label: 'Customer Service',
          isChild: false,
          separator: true
        }
      ],
      platformMenus: [
        {
          link: process.env.PARENTINGHUB_URL,
          img: '/img/logo/logo-parenting-hub.svg',
          style: 'width: 48%'
        },
        {
          link: process.env.PANINIPARTNER_URL,
          img: '/img/logo/logo-partner-center.svg'
        },
        {
          link: '/affiliate',
          img: '/img/logo/logo-paninti-affiliate.svg'
        }
      ]
    }
  },
  computed: {
    ...mapState('Sidebar', ['mainSidebar']),
    ...mapState('Contents', [
      'productCategories',
      'productBrands',
      'eventSale'
    ]),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  watch: {
    eventSale () {
      if (this.eventSale.length) {
        const temp = []
        this.eventSale.map((item) => {
          temp.push({
            link: `/sale/${item.slug}`,
            label: item.name,
            isChild: false
          })
        })
        this.menus.splice(5, 0, ...temp)
      }
    },
    deep: true
  },
  methods: {
    ...mapMutations('Sidebar', ['setMainSidebar']),
    ...mapMutations('User', ['setUser']),
    ...mapMutations('Cart', ['setCart']),
    ...mapMutations('Product', ['setProductWishlist']),
    async logout () {
      await this.$auth.logout('local')
      this.setUser([])
      this.setCart([])
      this.setProductWishlist([])
      this.$cookies.remove('session_id')
      const path = this.$route.path
      if (
        path === '/cart' ||
        path === '/checkout' ||
        this.$route.name === 'payment-detail-order_id' ||
        this.$route.name === 'payment-confirmation-order_id'
      ) {
        window.location.replace('/')
      } else {
        window.location.reload()
      }
    }
  }
}
