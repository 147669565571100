//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  async asyncData ({ store, error }) {
    let flashSales = []

    try {
      const resp = await store.dispatch('Flashsale/getFlashsale', {
        data: {
          conditions: {
            group_by: 'date'
          },
          where_has: [
            {
              relation: 'event_sale',
              where: {
                type: 'flash_sale'
              }
            }
          ]
        }
      })

      flashSales = resp.data.data.event_sale_sessions
      flashSales = flashSales.map((flashSale) => {
        flashSale.sessions = flashSale.sessions.map((session) => {
          session.event_sale_products = []
          session.metaPagination = {
            isLoading: false,
            page: 1,
            perPage: 10,
            total: 0,
            totalPage: 0
          }

          return session
        })

        return flashSale
      })
    } catch (e) {
      error({
        statusCode: 500,
        image: require('@/static/img/error/maintenance.svg'),
        message: 'Terjadi kesalahan saat mengambil data flash sale',
        subMessage: 'Mohon maaf atas ketidaknyamanannya, Cobalah beberapa saat lagi',
        type: 'flashsale-internal-server-error'
      })
    }

    return {
      flashSales
    }
  },

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Flashsale'
        }
      ],

      activeFlashSaleWeekIndex: 0,
      activeFlashSaleSessionIndex: 0
    }
  },

  computed: {
    activeFlashSaleWeek () {
      if (
        !this.flashSales.length ||
        !this.flashSales[this.activeFlashSaleWeekIndex]
      ) {
        return null
      }

      return this.flashSales[this.activeFlashSaleWeekIndex]
    },

    activeFlashSaleSession () {
      if (
        !this.activeFlashSaleWeek ||
        !this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
      ) {
        return null
      }

      return this.activeFlashSaleWeek.sessions[this.activeFlashSaleSessionIndex]
    },

    activeFlashSale () {
      if (!this.activeFlashSaleSession) {
        return null
      }

      return this.activeFlashSaleSession.event_sale
    }
  },

  methods: {
    handleFlashSaleTabChanged (activeFlashSaleWeekIndex) {
      this.activeFlashSaleSessionIndex = 0
      this.activeFlashSaleWeekIndex = activeFlashSaleWeekIndex
    },

    handleFlashSaleSessionTabChanged (activeFlashSaleSessionIndex) {
      this.activeFlashSaleSessionIndex = activeFlashSaleSessionIndex
    },

    handleFlashSaleSessionProductLoadingChange (isLoading) {
      this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
        .metaPagination.isLoading = isLoading
    },

    handleFlashSaleSessionProductUpdated (eventSaleProducts) {
      this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
        .metaPagination.total = eventSaleProducts.total
      this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
        .metaPagination.totalPage = eventSaleProducts.last_page
      this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
        .event_sale_products = [
        ...this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
          .event_sale_products,
        ...eventSaleProducts.data
      ]
      this.flashSales[this.activeFlashSaleWeekIndex].sessions[this.activeFlashSaleSessionIndex]
        .metaPagination.page += 1
    }
  }
}
