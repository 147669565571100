import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=832e38dc&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=js&"
export * from "./_slug.vue?vue&type=script&lang=js&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=832e38dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832e38dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,ChildCategory: require('/usr/src/app/components/home/category/ChildCategory.vue').default,FilterCategorySection: require('/usr/src/app/components/home/category/FilterCategorySection.vue').default,SortBySection: require('/usr/src/app/components/home/products/catalogue/SortBySection.vue').default,CatalogListProductCategory: require('/usr/src/app/components/home/category/CatalogListProductCategory.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
