//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { Arrow } from '@egjs/flicking-plugins'
import { mapActions } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    return {
      plugins: [new Arrow()],
      slider: [],
      brands: [],
      partners: [],
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },
  computed: {
    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    normalDesktop () {
      return this.windowWidth >= 992
    },
    dataBanner () {
      const type = this.normalDesktop ? 'normal desktop' : 'normal phone'
      const data = this.slider.filter((slid) => {
        return slid.display_type === type
      })
      return data.sort(function (a, b) {
        return a.position - b.position
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Contents', ['getFeatured']),
    async initComponent () {
      try {
        const slider = await this.getFeatured('featured-partner-slider')
        if (slider.status === 200) {
          this.slider = slider.data.data.contents[0].contentable.banners
        }
      } catch (error) {
        console.log(error)
      }
      try {
        const brand = await this.getFeatured('featured-brand')
        if (brand.status === 200) {
          this.brands = brand.data.data.contents[0].contentable.brands
        }
      } catch (error) {
        console.log(error)
      }
      try {
        const partner = await this.getFeatured('featured-store')
        if (partner.status === 200) {
          this.partners = partner.data.data.contents[0].contentable.stores
        }
      } catch (error) {
        console.log(error)
      }
    },
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    }
  }
}
