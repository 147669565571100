//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions } from 'vuex'

export default {
  mixins: [panstoreMixin],
  layout: 'affiliatePages',
  data () {
    return {
      showAll: this.$route.query.show_all_partner,
      sectionAlias: [
        {
          slug: 'best-selling-this-month',
          name: 'Produk Terlaris Bulan Ini'
        },
        {
          slug: 'affiliate-popular-this-month',
          name: 'Produk Afiliasi Terpopuler Bulan Ini'
        }
      ]
    }
  },
  computed: {
    breadCrumb () {
      const rawBreadcrumb = [
        {
          path: '/affiliate/home'
        },
        {
          name: 'Katalog Afiliasi Produk'
        }
      ]

      if (this.$route.params.section) {
        const selectAlias = this.sectionAlias.find((alias) => {
          return alias.slug === this.$route.params.section
        })

        rawBreadcrumb[1].name = this.checkObject(selectAlias) ? selectAlias.name : 'Katalog Afiliasi Produk'
      }

      return rawBreadcrumb
    }
  },
  watch: {
    '$route.query': {
      async handler () {
        this.showAll = this.$route.query.show_all_partner || false
        await this.handleGetFilter()
      },
      deep: true
    }
  },
  mounted () {
    this.handleGetFilter()
  },
  methods: {
    ...mapActions('Product/filter', ['getFilterPage']),
    async handleGetFilter () {
      const query = this.$route.query
      const params = this.$route.params

      try {
        const queryFilter = []

        if (query.search) {
          queryFilter.push(`search=${query.search}`)
        }
        if (query.event_sale) {
          queryFilter.push(`event_sale=${query.event_sale}`)
        }
        if (params.section !== 'all') {
          queryFilter.push(`section=${params.section}`)
        }

        const payload = '?' + queryFilter.join('&')
        await this.getFilterPage(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    }
  }
}
