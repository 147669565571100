//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  async asyncData ({ store }) {
    let eventSale = []

    try {
      const resp = await store.dispatch('Contents/getOngoingEventSale', {
        data: {
          where_has: [
            {
              relation: 'event_sale',
              where: {
                type: 'event_sale'
              }
            }
          ]
        }
      })

      eventSale = resp.data.data.event_sale_sessions
    } catch (error) {
      console.log(error)
    }

    return {
      eventSale
    }
  }
}
