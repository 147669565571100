//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions } from 'vuex'
import { Arrow, AutoPlay } from '@egjs/flicking-plugins'
export default {
  mixins: [mixins],
  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-white.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-white.svg" alt=""/>'
      },
      plugins: [new Arrow()],
      windowWidth: process.client ? window && Object.keys(window).length ? window.innerWidth : 0 : 0,
      loading: true
    }
  },
  computed: {
    ...mapState('Contents', ['slider']),
    windowExist () {
      return !!window && Object.keys(window).length
    },
    normalDesktop () {
      return this.windowWidth >= 992
    },
    dataBanner () {
      const type = this.normalDesktop ? 'normal desktop' : 'normal phone'
      const data = this.slider.filter((slid) => {
        return slid.display_type === type
      })
      return data.sort(function (a, b) {
        return a.position - b.position
      })
    },
    handleGetOS () {
      if (this.windowExist) {
        return this.getOS(window) === 'ios'
      }

      return true
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Contents', ['getSlider']),
    async initComponent () {
      try {
        const resp = await this.getSlider()
        if (resp.status === 200) {
          if (this.dataBanner.length > 2) {
            this.plugins.push(
              new AutoPlay({
                duration: 4500,
                direction: 'NEXT',
                stopOnHover: false
              })
            )
          }
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    onResize () {
      this.windowWidth = process.client && window ? window.innerWidth : 0
    }
  }
}
