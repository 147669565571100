//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    dataStatus: {
      type: String,
      default: ''
    },
    dataMethod: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataSteps: [
        {
          id: 0,
          iconOff: '/img/icon/account/shop/my-order/icon-bayar-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-bayar-putih.svg',
          name: 'Belum Bayar',
          active: false
        },
        {
          id: 1,
          iconOff: '/img/icon/account/shop/my-order/icon-diproses-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-diproses-putih.svg',
          name: 'Diproses',
          active: false
        },
        {
          id: 2,
          iconOff: '/img/icon/account/shop/my-order/icon-dikirim-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-dikirim-putih.svg',
          name: 'Dikirim',
          active: false
        },
        {
          id: 3,
          iconOff: '/img/icon/account/shop/my-order/icon-selesai-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-selesai-putih.svg',
          name: 'Selesai',
          active: false
        }
      ],
      dataStepsCancelled: [
        {
          id: 0,
          iconOff: '/img/icon/account/shop/my-order/icon-bayar-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-bayar-putih.svg',
          name: 'Belum Bayar',
          active: true
        },
        {
          id: 1,
          iconOff: '/img/icon/account/shop/my-order/icon-dibatalkan.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-dibatalkan.svg',
          name: 'Dibatalkan',
          active: true
        }
      ]
    }
  },
  watch: {
    dataStatus () {
      this.reset()
    }
  },
  methods: {
    getMakeData () {
      const status = this.getStatusPesanan(this.dataStatus, this.dataMethod)[0]
      let valid = false
      if (status !== 'Dibatalkan') {
        if (this.dataMethod === 'pickup') {
          this.dataSteps[2].iconOff =
            '/img/icon/account/shop/my-order/icon-pas-grey.svg'
          this.dataSteps[2].iconOn =
            '/img/icon/account/shop/my-order/icon-pas-putih.svg'
          this.dataSteps[2].name = `${
            status === 'Siap Diambil' ? 'Siap' : ''
          } Diambil`
        }
        this.dataSteps.map((data, index) => {
          if (!valid) {
            this.dataSteps[index].active = true
            if (data.name === status) {
              valid = true
            }
          }
        })
      } else {
        this.dataSteps = this.dataStepsCancelled
      }
      return this.dataSteps
    },
    reset () {
      this.dataSteps = [
        {
          id: 0,
          iconOff: '/img/icon/account/shop/my-order/icon-pas-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-pas-putih.svg',
          name: 'Belum Bayar',
          active: false
        },
        {
          id: 1,
          iconOff: '/img/icon/account/shop/my-order/icon-diproses-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-diproses-putih.svg',
          name: 'Diproses',
          active: false
        },
        {
          id: 2,
          iconOff: '/img/icon/account/shop/my-order/icon-dikirim-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-dikirim-putih.svg',
          name: 'Dikirim',
          active: false
        },
        {
          id: 3,
          iconOff: '/img/icon/account/shop/my-order/icon-selesai-grey.svg',
          iconOn: '/img/icon/account/shop/my-order/icon-selesai-putih.svg',
          name: 'Selesai',
          active: false
        }
      ]
    }
  }
}
