//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions } from 'vuex'

export default {
  mixins: [mixins],

  data () {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState('Contents', ['bannerPromotionBrand']),

    bannerPromotion () {
      if (this.isPhone) {
        return this.bannerPhone
      } else if (this.isTablet) {
        return this.bannerPhone
      } else {
        let banners = this.bannerDesktop

        if (this.bannerDesktop.length > 1) {
          banners = [this.bannerDesktop[0], this.bannerPhone[1]]
        }
        return banners
      }
    },

    bannerDesktop () {
      return this.bannerPromotionBrand.filter((banner) => {
        return banner.display_type === 'normal desktop'
      })
    },

    bannerPhone () {
      return this.bannerPromotionBrand.filter((banner) => {
        return banner.display_type === 'normal phone'
      })
    },

    isPhone () {
      return this.$mq === 'xs' || this.$mq === 'xxs'
    },

    isTablet () {
      return this.$mq === 'md' || this.$mq === 'sm'
    }
  },

  mounted () {
    this.getHomeBannerBrandPromotions()
  },

  methods: {
    ...mapActions('Contents', ['getBannerPromotionBrand']),

    async getHomeBannerBrandPromotions () {
      try {
        await this.getBannerPromotionBrand()
      } catch (error) {
        this.$message.error('Gagal mendapatkan data home banner brand promotion')
      }
    }
  }
}
