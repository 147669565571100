import { render, staticRenderFns } from "./PopupCreateCreditCard.vue?vue&type=template&id=0b8fdbd0&scoped=true&"
import script from "./PopupCreateCreditCard.vue?vue&type=script&lang=js&"
export * from "./PopupCreateCreditCard.vue?vue&type=script&lang=js&"
import style0 from "./PopupCreateCreditCard.vue?vue&type=style&index=0&id=0b8fdbd0&lang=scss&scoped=true&"
import style1 from "./PopupCreateCreditCard.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8fdbd0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default})
