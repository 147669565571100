//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  name: 'CommissionHistoryItem',
  mixins: [panstoreMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    allHistory: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', this.item.id)
    }
  }
}
