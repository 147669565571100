//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    action: {
      type: String,
      default: ''
    },

    payload: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      dataProduct: [],
      totalPage: 0,
      page: 1,
      firstLoad: true,
      onload: true
    }
  },

  computed: {
    haveProduct () {
      return this.dataProduct.length > 0
    },

    key () {
      return this.action === 'getProductsEventSale'
        ? 'event_sale_products'
        : 'products'
    },

    perPage () {
      return this.payload.per_page || 10
    }
  },

  mounted () {
    this.handleGetProducts()
  },

  methods: {
    ...mapActions('Product', ['getProductsSale', 'getProductsEventSale']),

    async handleInfiniteLoading ($state) {
      await this.handleGetProducts()

      if (this.page > this.totalPage) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },

    async handleGetProducts () {
      this.onload = true

      try {
        const resp = await this[this.action]({
          data: {
            ...this.payload.data,
            page: this.page
          }
        })

        this.dataProduct = this.dataProduct.concat(resp.data.data[this.key].data)
        this.totalPage = resp.data.data[this.key].last_page
        this.page++
      } catch (error) {
        this.$message.error('Gagal mendapatkan data clearance produk')
      }

      if (this.page > 1) {
        this.firstLoad = false
      }

      this.onload = false
    }
  }
}
