//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      collapseItem: ['1', '2'],
      close: false,
      onSubmit: false,
      valueInstallment: 1,
      installmentSelected: {}
    }
  },
  computed: {
    ...mapState('Checkout', [
      'listTotalPayment',
      'listPaymentMethods',
      'selectedBank',
      'listItem',
      'selectedCourier',
      'paymentDetail',
      'selectedAddress',
      'noteShipping',
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'pageTakeInStore',
      'usePoint',
      'installments',
      'orderPayload',
      'vouchers'
    ]),
    ...mapState('Voucher', ['voucherUsed']),
    haveSelectedBank () {
      return this.selectedBank.length > 0
    },
    paninti_point () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.paninti_point
      } else {
        return 0
      }
    },
    voucherDiscount () {
      const vouchers = this.vouchers
      if (vouchers) {
        const voucherDisc = vouchers.filter((used) => {
          return used.type === 'discount'
        })

        if (voucherDisc && voucherDisc.length) {
          return voucherDisc[0]
        }
      }
      return {}
    }
  },
  watch: {
    installments () {
      this.handleChangeInstallment(1)
    },
    selectedBank () {
      this.handleChangeInstallment(1)
    }
  },
  methods: {
    ...mapActions('Order', ['addOrder']),
    ...mapMutations('Order', ['setOnSuccess']),
    ...mapActions('User', ['createTokenCC']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    ...mapMutations('Checkout', ['setOrderPayload']),
    handleChangeInstallment (item) {
      this.installmentSelected =
        this.installments.filter((installment) => {
          return installment.count === item
        })[0] || this.installments[0]
    },
    handleModalPayment () {
      this.$refs.closeModalPayment.click()
    },
    countProduct (product) {
      let qty = 0
      product.map((p) => {
        qty += p.quantity
      })
      return qty
    },
    async handleCreateOrder () {
      if (
        this.listItem.unavailable_with_branch.length ||
        this.listItem.unavailable_with_courier.length ||
        this.listItem.unavailable_with_pas.length ||
        this.listItem.unavailable_with_stock.length
      ) {
        await this.$Swal
          .fire({
            title: 'Apakah Anda yakin?',
            text: 'Terdapat Produk yang tidak dapat di checkout!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Lanjutkan pembayaran!',
            cancelButtonText: 'Batal'
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.orderProcessed()
            }
          })
      } else {
        this.orderProcessed()
      }
    },
    async orderProcessed () {
      this.onSubmit = true
      const listId = []
      this.listItem.available.map((list) => {
        listId.push(list.id)
      })
      const gift = localStorage.note ? JSON.parse(localStorage.note) : null
      const payload = Object.assign({
        data: {
          items: [
            {
              cart_id: listId,
              store_branch_id: this.getDataBranch()[this.branchSelected].id
            }
          ],
          user_address_id: this.selectedAddress.id,
          payment_method_id: this.selectedBank[0].id,
          send_as_gift: gift && gift.switchPrize ? gift.switchPrize : false,
          use_paninti_point: this.usePoint
        }
      })
      if (!this.pageTakeInStore) {
        payload.data = Object.assign({
          ...payload.data,
          shipping_courier: {
            code: this.selectedCourier.code,
            service: this.selectedCourier.service
          }
        })
      }
      if (gift && gift.switchPrize) {
        payload.data = Object.assign({
          ...payload.data,
          gift_note: gift.note
        })
      }
      if (this.noteShipping) {
        payload.data = Object.assign({
          ...payload.data,
          note: this.noteShipping ? this.noteShipping : ''
        })
      }
      if (localStorage.voucher && Object.keys(localStorage.voucher).length) {
        const rawVoucher = JSON.parse(localStorage.getItem('voucher'))
        const voucherId = rawVoucher.map((voucher) => {
          return voucher.id
        })
        payload.data = Object.assign({
          ...payload.data,
          voucher_id: voucherId
        })
      }

      if (this.selectedBank[0].type.code === 'credit_card') {
        payload.data = Object.assign({
          ...payload.data,
          account_id: this.selectedBank[0].accounts[0].id
        })
        payload.data.credit_card = Object.assign({
          installment_count: this.valueInstallment
        })
        this.setOrderPayload(payload)
        if (!this.selectedBank[0].create_new) {
          this.$refs.buttonShowCVV.click()
        } else {
          // create new
          const payload = {
            ...this.selectedBank[0].accounts[0].credit_card,
            transaction_amount: this.paymentDetail.total_net_price,
            amount: this.paymentDetail.total_net_price
          }
          try {
            const resp = await this.createTokenCC(payload)
            if (resp.status === 'IN_REVIEW') {
              this.$refs.closeModalPayment.click()
              this.setDataAccount({
                account_number: this.selectedBank[0].accounts[0].credit_card.card_number,
                card_token_id: resp.id,
                card_authentication_id:
                  resp.authentication_id || null,
                card_exp_month: this.selectedBank[0].accounts[0].credit_card.card_exp_month,
                card_exp_year: this.selectedBank[0].accounts[0].credit_card.card_exp_year,
                card_cvv: this.selectedBank[0].accounts[0].credit_card.cvv
              })
              this.setLink3DS(
                resp.payer_authentication_url
              )
              if (resp.status === 'IN_REVIEW') {
                const payload = JSON.parse(JSON.stringify(this.orderPayload))
                payload.data.credit_card = Object.assign({
                  ...payload.data.credit_card,
                  card_token_id: resp.id,
                  card_authentication_id:
                    resp.authentication_id || null,
                  card_number: this.selectedBank[0].accounts[0].credit_card.card_number,
                  card_exp_month: this.selectedBank[0].accounts[0].credit_card.card_exp_month,
                  card_exp_year: this.selectedBank[0].accounts[0].credit_card.card_exp_year,
                  card_cvv:
                    this.selectedBank[0].accounts[0].credit_card.card_cvv,
                  save_card: this.selectedBank[0].save,
                  installment_interval: 'month'
                })
                this.setOrderPayload(payload)
                this.$refs.showModal3DS.click()
              } else if (
                resp.status === 'VERIFIED'
              ) {
                this.$refs.buttonShowLoading.click()
                this.handleSubmit(resp.data.data)
              } else {
                this.$Swal.fire({
                  icon: 'error',
                  title: 'Pemberitahuan',
                  text: 'Kartu Kredit tidak dapat digunakan.',
                  heightAuto: false
                })
              }
            }
          } catch (error) {
            this.$Swal.fire({
              icon: 'error',
              title: 'Tidak dapat memproses pesanan',
              text: error.response.data.message,
              heightAuto: false,
              confirmButtonText: 'Kembali'
            })
            console.log(error)
          }
        }
      } else {
        this.$refs.closeModalPayment.click()
        this.$refs.buttonShowLoading.click()
        try {
          const resp = await this.addOrder(payload)
          if (resp.status === 201) {
            this.close = true
            // await this.$refs.popupLoading.$refs.buttonClose.click()
            // if (
            //   this.selectedBank[0].type.code === 'bank_transfer' ||
            //   this.selectedBank[0].type.code === 'virtual_account'
            // ) {
            //   window.location = `/payment-detail/${resp.data.data.order_payment.id}`
            // } else {
            //   this.$refs.popupOtherPayment.order_id =
            //     resp.data.data.order_payment.id
            //   this.$refs.buttonShowPopupOtherPayment.click()
            // }
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error
          this.$emit('error', errorMessage)
        }
      }

      this.onSubmit = false
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    }
  }
}
