//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'

export default {
  mixins: [mixins],
  props: {
    lorikeets: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
