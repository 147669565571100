//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      onSubmit: false,
      formLogin: {
        email: '',
        password: '',
        remember: false,
        session_id: this.$cookies.get('session_id')
      },
      errorUsername: '',
      errorPassword: '',
      toggleShowHide: true,
      iconEye: '/img/icon/eye-solid.svg'
    }
  },
  computed: {
    urlRegister () {
      return this.$route.query.redirect_from ? `/register?redirect_from=${this.$route.query.redirect_from}` : '/register'
    }
  },
  methods: {
    showHidePassword () {
      this.toggleShowHide = !this.toggleShowHide
      if (this.toggleShowHide === true) {
        this.iconEye = '/img/icon/eye-solid.svg'
        document.getElementById('inputPassword').type = 'password'
      } else {
        this.iconEye = '/img/icon/eye-slash-solid.svg'
        document.getElementById('inputPassword').type = 'text'
      }
    },
    handleSubmitLoginForm () {
      const _this = this
      const valid = !(this.errorUsername || this.errorPassword)
      if (valid) {
        _this.submitLoginForm()
      } else {
        /* eslint-disable */
          if (!this.formLogin.password) {
            this.errorPassword = 'Password wajib diisi'
          }
        }
      },
      async submitLoginForm() {
        this.onSubmit = true
        try {
          const resp = await this.$auth.loginWith('local', {
            data: this.formLogin
          })
          if (resp.status === 200) {
            window.location.href = this.$route.query.redirect_from ? this.$route.query.redirect_from :  "/"
            this.$cookies.remove('session_id')
          }
        } catch (error) {
          const errorMessage = error.response
            ? error.response.data.message
            : error
          this.$Swal.fire({
            icon: 'error',
            title: 'Gagal Login',
            text: errorMessage,
            heightAuto: false
          })
          this.onSubmit = false
        }
      },

      handleChangeUsername() {
        const value = this.formLogin.email
        const input = value.charAt(value.length - 1)
        const letters = /^[A-Za-z._0-9._+@]+$/
        if (value === ' ') {
          this.formLogin.email = ''
        }
        if (!input.match(letters)) {
          this.formLogin.email = value.substring(0, value.length - 1)
        }
      },

      handleChangePassword() {
        const value = this.formLogin.password
        const input = value.charAt(value.length - 1)
        if (value === ' ') {
          this.formLogin.password = ''
        }
        if (input === ' ') {
          this.formLogin.password = value.substring(0, value.length - 1)
        }
        if (value.length >= 100) {
          this.errorPassword = 'Maksimal 100 karakter'
          this.formLogin.password = value.substring(0, value.length - 1)
        } else {
          this.errorPassword = ''
        }
      },
      checkUsername() {
        this.errorUsername = ''
        if (this.formLogin.email === '') {
          this.errorUsername = 'Username atau email wajib diisi'
        }
      },
      checkPassword() {
        this.errorPassword = ''
        if (this.formLogin.password === '') {
          this.errorPassword = 'Password wajib diisi'
        }
        if (this.formLogin.password.length >= 100) {
          this.errorPassword = 'Maksimal 100 karakter'
          this.formLogin.password = this.formLogin.password.substring(0, 100)
        } else {
          this.errorPassword = ''
        }
      }
    }
  }
