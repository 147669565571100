//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
export default {
  name: 'ModalTermsCondition',
  data () {
    return {
    checked: false,
    loading: false
    }
  },
  computed: {
    ...mapState('Contents', ['privacyPolicy'])
  },
  async mounted () {
    await this.fetchPrivacyPolicy()
  },
  methods: {
    ...mapActions('Contents', ['getPrivacyPolicy']),

    async fetchPrivacyPolicy () {
      try {
        const payload = {
          data: {}
        }

        await this.getPrivacyPolicy(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
    },
    modalClose () {
      this.$emit('modal-close')
    }
  }
}
