//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixins from '@/mixins'
export default {
  mixins: [panstoreMixins],
  layout: 'invoice',
  middleware: ['user'],
  async asyncData ({ store, params, error }) {
    try {
      await store.dispatch('Order/getDetailOrder', params.order_id)
    } catch (exerror) {
      console.log(exerror)
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
  },
  computed: {
    ...mapState('Order', ['order']),
    ...mapState('Base', ['csPhone']),
    haveNumber () {
      return this.order.store.branch.social_medias.findIndex((sosmed) => {
        return sosmed.name.toLowerCase() === 'whatsapp'
      })
    },
    subTotal () {
      let result = 0
      this.order.products.map((product) => {
        result += product.total_price
      })
      return result
    }
  },
  methods: {
    ...mapActions('Order', ['downloadInvoice']),
    async handleDownload () {
      try {
        const resp = await this.downloadInvoice(this.$route.params.order_id)
        const url = window.URL.createObjectURL(
          new Blob([resp.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Invoice - ${this.order.reference}.pdf`)
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.log(error)
      }
    },
    handlePrint () {
      window.print()
    }
  }
}
