//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'AllProduct',
  mixins: [panstoreMixin],
  data () {
    return {
      loading: true,
      loadmore: false,
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapState('Product', ['affiliateUserAllProduct'])
  },
  async mounted () {
    await this.handleGetAffiliateAllProduct()
  },
  methods: {
    ...mapActions('Product', ['getAffiliateAllProduct']),
    async handleInfiniteLoading ($state) {
      await this.handleGetAffiliateAllProduct()

      if (this.affiliateUserAllProduct.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async handleGetAffiliateAllProduct (paginated = true) {
      this.loading = true
      try {
        const payload = {
          username: this.$route.params.slug,
          params: {
            paginated: true,
            per_page: 8,
            page: this.affiliateUserAllProduct.pagination.nextPage || 1
          }
        }

        await this.getAffiliateAllProduct(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    }
  }
}
