import { render, staticRenderFns } from "./FormEditProfile.vue?vue&type=template&id=dd366f1a&scoped=true&"
import script from "./FormEditProfile.vue?vue&type=script&lang=js&"
export * from "./FormEditProfile.vue?vue&type=script&lang=js&"
import style0 from "./FormEditProfile.vue?vue&type=style&index=0&id=dd366f1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd366f1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default})
