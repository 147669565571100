//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import mixins from '@/mixins'

export default {
  mixins: [mixins],

  data () {
    return {
      search: '',
      sort: '',

      sections: [],
      options: [
        {
          value: 'lastest',
          label: 'Terbaru'
        },
        {
          value: 'most_expensive',
          label: 'Termahal'
        },
        {
          value: 'cheapest',
          label: 'Termurah'
        }
      ]
    }
  },

  computed: {
    ...mapState('Partner', ['showcase'])
  },

  watch: {
    showcase () {
      this.handleSetShowcase()
    }
  },

  mounted () {
    this.sort = this.$route.query.sort || ''
    this.handleSetShowcase()
  },

  methods: {
    setFilter () {
      this.$router.push({
        query: {
          ...this.modelPayload(
            null,
            this.search,
            null,
            null,
            null,
            '',
            null,
            this.sort
          ),
          section: this.$route.query.section
        }
      })
    },

    handleSetShowcase () {
      let showcases = [
        {
          name: 'Semua Produk',
          value: 'all',
          visible: true,
          product: {
            isLoading: true,
            perPage: 12,
            page: 1,
            totalPage: 0,
            total: 0,
            data: []
          }
        },
        {
          name: 'Terakhir Dilihat',
          value: 'lastseen',
          visible: false,
          product: {
            isLoading: true,
            perPage: 12,
            page: 1,
            totalPage: 0,
            total: 0,
            data: []
          }
        }
      ]

      if (this.showcase.length) {
        this.showcase.map((sc) => {
          showcases.push({
            name: sc.name,
            value: sc.type === 'product' ? this.slugtify(sc.name) : sc.type,
            id: sc.type === 'product' ? sc.id : sc.event_sale_session_id,
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          })
        })
      } else {
        showcases = showcases.concat([
          {
            name: 'Eventsale',
            value: 'event_sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          },
          {
            name: 'Flashsale',
            value: 'flash_sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          },
          {
            name: 'Lagi Diskon',
            value: 'sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          },
          {
            name: 'CLEARANCE SALE',
            value: 'clearance_sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          }
        ])
      }

      this.sections = showcases
    }
  }
}
