//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins/index'
import { mapMutations } from 'vuex'
export default {
  name: 'ModalShareLink',
  mixins: [panstoreMixin],

  props: {
    modalId: {
        type: String,
        default: 'modalShareLink'
    },
    link: {
        type: String,
        default: ''
    }
  },

  data () {
    return {
      showClipboardSuccess: false
    }
  },

  methods: {
    ...mapMutations('Product', ['setShareUrl']),
    showNotif () {
      this.showClipboardSuccess = true;
      setTimeout(() => {
        this.showClipboardSuccess = false;
      }, 2000);
    }
  }
}
