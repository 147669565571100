var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-afiliasi"},[_c('div',{staticClass:"navbar-afiliasi container-section"},[_c('div',{staticClass:"logo-afiliasi"},[_c('nuxt-link',{attrs:{"to":"/affiliate"}},[_c('img',{attrs:{"src":require("@/static/img/logo/logo-paninti-affiliate-2.svg"),"alt":"Logo Paninti Affiliate"}})])],1),_vm._v(" "),(!_vm.checkObject(_vm.user))?_c('div',{staticClass:"btn-create-afiliasi"},[_c('nuxt-link',{attrs:{"to":"/affiliate/create-affiliate"}},[_c('Button',{ref:"btnCreateAffiliate",staticClass:"btn btn-main btn-cta flex-fill",attrs:{"text":"Bergabung Sekarang"}})],1)],1):_c('nuxt-link',{attrs:{"to":_vm.checkObject(_vm.user.affiliate) ? '/account/affiliate' : '/affiliate/create-affiliate'}},[_c('div',{staticClass:"profil-user d-flex",attrs:{"title":_vm.user.first_name + ' ' + _vm.user.last_name}},[_c('div',{staticClass:"image-profile"},[_c('div',{staticClass:"col-1 text-center my-auto me-3"},[_c('img',{staticClass:"nav-avatarImg",attrs:{"src":_vm.user.files.length
                    ? _vm.getImageOriginal(_vm.user.files, 'avatar')
                    : _vm.getDefaultAvatar(
                      _vm.user.detail ? _vm.user.detail.gender : 'male'
                    ),"alt":"avatar dropdown"},on:{"error":function () {
                                    var args = [], len = arguments.length;
                                    while ( len-- ) args[ len ] = arguments[ len ];

                                    return _vm.getDefaultImgAvatar.apply(
                      void 0, args.concat( [_vm.user.detail ? _vm.user.detail.gender : 'male'] )
                    );
}}})])]),_vm._v(" "),_c('div',{staticClass:"font-username col-9 my-auto ms-1"},[_c('client-only',[_c('v-clamp',{staticClass:"font-website-title black-bold",attrs:{"autoresize":"","max-lines":1}},[_vm._v("\n                "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"font-website-small"},[_vm._v("\n                "+_vm._s(_vm.checkObject(_vm.user) && _vm.checkObject(_vm.user.affiliate) ? 'Sudah jadi affiliates' : 'Daftar jadi affiliates')+"\n              ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"arrow-down col-2 text-center ps-2"},[_c('i',{staticClass:"el-icon-arrow-down"})])])])],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"main-afiliasi container-section"},[_c('div',{staticClass:"afiliasi-benefit"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"card-benefit-wrapper row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-9"},[_c('client-only',[_c('carousel',{staticClass:"custom-carousel mx-auto",attrs:{"per-page":1,"navigation-enabled":false,"scroll-per-page":false,"pagination-enabled":true,"navigation-prev-label":_vm.sliderArrow.left,"navigation-next-label":_vm.sliderArrow.right,"autoplay":false,"loop":true,"per-page-custom":[
                [425, 1],
                [1024, 3]]}},_vm._l((_vm.benefitAfiliasi),function(data){return _c('slide',{key:("benefit-" + (data.id))},[_c('CardBenefit',{attrs:{"item":data}})],1)}),1)],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"afiliasi-guideline"},[_c('GuidelineAffiliate')],1),_vm._v(" "),_c('div',{staticClass:"afiliasi-question col-12 col-lg-9 m-auto"},[_c('QuestionAffiliate')],1)]),_vm._v(" "),_c('FooterJoinAffiliate')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-section"},[_c('div',{staticClass:"top-badge"},[_c('section',{staticClass:"element-1"}),_vm._v(" "),_c('section',{staticClass:"element-2"})]),_vm._v(" "),_c('div',{staticClass:"middle-badge"},[_c('div',{staticClass:"main-element d-none d-lg-block overflow-hidden"},[_c('img',{staticClass:"w-100",attrs:{"src":"/img/home/affiliate/hero-visual.svg","alt":"hero-visual"}})]),_vm._v(" "),_c('div',{staticClass:"sub-element"})]),_vm._v(" "),_c('div',{staticClass:"bottom-badge"},[_c('div',{staticClass:"element-3"}),_vm._v(" "),_c('div',{staticClass:"element-4"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-content container-section"},[_c('div',{staticClass:"text-section col-12 col-md-7 col-lg-5 mt-5 pt-5"},[_c('h1',[_vm._v("Promosikan Produk Favoritmu dan Dapatkan Tambahan Penghasilan")]),_vm._v(" "),_c('h3',[_vm._v("Pilih produk dari Paninti dan bagikan link. Dapatkan komisi dari setiap penjualan yang selesai!")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-benefit"},[_c('h2',[_vm._v("Keuntungan Mengikuti Afiliasi Paninti")])])}]

export { render, staticRenderFns }