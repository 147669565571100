//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formCancel: {
        cancellation_reason: ''
      },
      formCancelRules: {
        cancellation_reason: [{
          required: true,
          message: 'Masukan alasan pembatalan',
          trigger: 'blur'
        }, {
          min: 6,
          message: 'Alasan pembatalan berisi minimal 6 karakter',
          trigger: 'blur'
        }]
      },
      isSubmit: false
    }
  },
  methods: {
    ...mapActions('Order', ['cancelOrder']),
    resetField () {
      this.$refs.formCancel.resetFields()
    },
    handleSubmit (formName) {
      this.isSubmit = true
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const resp = await this.cancelOrder({
              payload: {
                data: this.formCancel
              },
              id: this.data.id
            })
            if (resp.status === 201) {
              this.$Swal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: 'Pengajuan pembatalan pesanan berhasil dibuat!',
                heightAuto: false
              }).then((confirm) => {
                if (confirm) {
                  this.$refs.modalClose.click()
                  window.location.reload()
                }
              })
            }
          } catch (error) {
            this.$Swal.fire({
              icon: 'error',
              title: 'Pemberitahuan',
              text: error.response.data.message,
              heightAuto: false
            })
          }
        } else {
          return false
        }
      })
      this.isSubmit = false
    }
  }
}
