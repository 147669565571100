//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  name: 'CardStatsAffiliate',
  mixins: [panstoreMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    summaryCount: {
      type: Object,
      default: () => {}
    }
  }
}
