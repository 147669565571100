//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'

import mixins from '@/mixins'
import productCardSkeletonLoadingMixin from '@/mixins/product-card-skeleton-loading'

export default {
  mixins: [mixins, productCardSkeletonLoadingMixin],

  props: {
    brand: {
      type: Object,
      default: () => ({})
    },
    storeType: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      dataProducts: [],
      showAll: false,
      page: 1,
      totalPage: 0
    }
  },

  computed: {
    ...mapState('Product', ['product']),
    ...mapState('User/address', ['location', 'userAddress', 'isCreated'])
  },

  watch: {
    location () {
      this.page = 1
      this.totalPage = 0
      this.getDataProduct()
    }
  },

  created () {
    this.page = 1
    this.totalPage = 0
    this.getDataProduct()
  },

  methods: {
    ...mapActions('Product', ['getProductNearest']),
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant', 'setIsBuyNow']),
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),

    async handleInfiniteLoading () {
      if (this.page <= this.totalPage) {
        await this.getDataProduct()
        this.page++
        this.$refs.InfiniteLoadingNearestProduct.stateChanger.loaded()
      } else {
        this.$refs.InfiniteLoadingNearestProduct.stateChanger.complete()
      }
    },

    async getDataProduct () {
      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155
        const resp = await this.getProductNearest({
          data: {
            product_id: this.product.id,
            page: this.page,
            per_page: 2,
            city_id: cityId
          }
        })

        if (resp.status === 200) {
          this.totalPage = resp.data.data.products.last_page || 1
          if (resp.data.data.products.current_page === 1) {
            this.dataProducts = resp.data.data.products.data || []
          } else {
            this.dataProducts = this.dataProducts.concat(
              resp.data.data.products.data
            )
          }
        }

        if (this.dataProducts.length === 0 || this.storeType === 'brand_partner') {
          this.$emit('show-brand', true)
        }
      } catch (error) {
        this.$emit('show-brand', true)
        console.log(error)
      }
    },

    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }

      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })

        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }

      try {
        await this.getCartsNavbar({ params: payload })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
