//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data () {
    const validatorName = (rule, value, callback) => {
      const name = rule.field
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)

      const letters = /^[A-Za-z. ]+$/
      if (value.length === 1) {
        if (input === ' ') {
          this.formData[name] = ''
        } else {
          this.formData[name] = input.toUpperCase()
        }
      }

      if (!input.match(letters)) {
        // hanya huruf, . dan spasi (tidak bisa double spasi)
        this.formData[name] = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formData[name] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formData[name] = value.substr(0, value.length - 1) + input.toUpperCase()
      }

      if (value.length > 100) {
        this.formData[name] = value.substr(0, value.length - 1)
      }

      callback()
    }

    const validatorEmail = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)

      /* eslint-disable */
      const letters = /^[A-Za-z0-9._+@]+$/
      if (value === ' ') {
        this.formData.email = ''
      }

      if (value.length > 100) {
        this.formData.email = value.substring(0, value.length - 1)
      }

      if (!input.match(letters)) {
        this.formData.email = value.substring(0, value.length - 1)
      }

      callback()
    }
    return {
      onSubmit: false,
      formData: this.initFormData(),

      rules: {
        fullName: [
          {
            required: true,
            message: 'Nama lengkap wajib diisi',
            trigger: 'blur'
          },
          {
            min: 2,
            message: 'Nama lengkap minimal 2 karakter',
            trigger: 'blur'
          },
          {
            validator: validatorName
          }
        ],

        email: [
          {
            required: true,
            message: 'Email wajib diisi',
            trigger: 'blur'
          },
          {
            validator: validatorEmail
          }
        ]
      }
    }
  },

  computed: {
    ...mapState('Base', ['dataBreadCrumbs'])
  },

  methods: {
    ...mapActions('User', ['editUser']),
    ...mapMutations('Account', ['setIsEdit']),
    ...mapMutations('Base', ['setBreadCrumbs']),

    initFormData() {
      const dataUser = this.$auth.$state.user
      let socmed = {}

      dataUser.social_medias.map(sm => {
        socmed = Object.assign({
          ...socmed,
          [sm.name]: sm.username
        })
      })

      let fullname = dataUser.first_name

      if (dataUser.last_name) {
        fullname += ` ${dataUser.last_name}`
      }

      return Object.assign({
        username: dataUser.username,
        fullName: fullname,
        email: dataUser.email,
        phoneNumber: dataUser.detail ? dataUser.detail.phone_number : null,
        city: dataUser.addresses ? dataUser.addresses[0].village.city : null,
        bio: dataUser.detail ? dataUser.detail.bio : null,
        date_birth: dataUser.detail ? dataUser.detail.date_birth : null,
        gender: dataUser.detail ? dataUser.detail.gender : 'male',
        ...socmed
      })
    },

    handleChangePhoneNumber(e) {
      const input = this.formData[e.target.id].charAt(
        this.formData[e.target.id].length - 1
      )
      const letters = /[^0-9]/g

      if (letters.test(input)) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }

      if (this.formData[e.target.id].split('').length >= 13) {
        this.formData[e.target.id] = this.formData[e.target.id].substr(
          0,
          this.formData[e.target.id].length - 1
        )
      }
    },

    handleSubmit() {
      this.onSubmit = true

      this.$refs.editProfilForm.validate(async valid => {
        if (valid) {
          let firstName, lastName

          if (this.formData.fullName.includes(' ')) {
            firstName = this.formData.fullName.substr(
              0,
              this.formData.fullName.indexOf(' ')
            )
            lastName = this.formData.fullName.substr(
              this.formData.fullName.indexOf(' ') + 1,
              this.formData.fullName.length
            )
            lastName = lastName === ' ' || lastName === '' ? null : lastName
          } else {
            firstName = this.formData.fullName
            lastName = null
          }

          const payload = {
            data: {
              first_name: firstName,
              last_name: lastName,
              username: this.formData.username,
              email: this.formData.email,
              detail: {
                date_birth: this.formData.date_birth,
                phone_number: this.formData.phone_number,
                bio: this.formData.bio,
                gender: this.formData.gender
              },
              social_medias: []
            }
          }

          if (this.formData.instagram) {
            const socmedObj = {
              name: 'instagram',
              value: this.formData.instagram
            }
            payload.data.social_medias.push(socmedObj)
          }

          if (this.formData.twitter) {
            const socmedObj = {
              name: 'twitter',
              value: this.formData.twitter
            }
            payload.data.social_medias.push(socmedObj)
          }

          if (this.formData.tiktok) {
            const socmedObj = {
              name: 'tiktok',
              value: this.formData.tiktok
            }
            payload.data.social_medias.push(socmedObj)
          }

          if (this.formData.facebook) {
            const socmedObj = {
              name: 'facebook',
              value: this.formData.facebook
            }
            payload.data.social_medias.push(socmedObj)
          }

          if (this.formData.youtube) {
            const socmedObj = {
              name: 'youtube',
              value: this.formData.youtube
            }
            payload.data.social_medias.push(socmedObj)
          }

          if (this.formData.blog) {
            const socmedObj = {
              name: 'blog',
              value: this.formData.blog
            }
            payload.data.social_medias.push(socmedObj)
          }

          try {
            const resp = await this.editUser(payload)

            if (resp.data.status_code === 200) {
              this.$Swal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: 'Data user berhasil diubah !',
                heightAuto: false
              })
              window.location.href = '/account/profile'
            }
          } catch (error) {
            console.log(error)
          }
        }
      })

      this.onSubmit = false
    },

    handleCancel() {
      this.setBreadCrumbs(
        this.dataBreadCrumbs.slice(0, this.dataBreadCrumbs.length - 1)
      )
      this.setIsEdit()
    }
  }
}
