//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  props: {
    dataUser: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
