//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions } from 'vuex'

export default {
  mixins: [panstoreMixin],

  data () {
    return {
      accountRecommendationProducts: {
        isLoading: true,
        page: 1,
        perPage: 9,
        totalPage: 0,
        total: 0,
        data: []
      }
    }
  },

  mounted () {
    this.getAccountRecommendationProducts()
  },

  methods: {
    ...mapActions('Product', ['getRecomendationProduct']),

    async getAccountRecommendationProducts () {
      this.accountRecommendationProducts.isLoading = true

      try {
        const resp = await this.getRecomendationProduct({
          data: {
            page: this.accountRecommendationProducts.page,
            per_page: this.accountRecommendationProducts.perPage
          }
        })

        this.accountRecommendationProducts.totalPage = resp.data.data.products.last_page
        this.accountRecommendationProducts.total = resp.data.data.products.total
        this.accountRecommendationProducts.data = resp.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk rekomendasi untuk kamu')
      }

      this.accountRecommendationProducts.isLoading = false
    }
  }
}
