//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions } from 'vuex';

export default {
  mixins: [mixins],

  props: {
    eventSaleSessionId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      eventSaleSections: {
        isLoading: false,
        page: 1,
        perPage: 2,
        totalPage: 0,
        total: 0,
        data: []
      }
    }
  },

  methods: {
    ...mapActions('Contents', ['getEventSaleSections']),

    handleInfiniteLoading () {
      this.getEventSaleSectionData()
    },

    async getEventSaleSectionData () {
      this.eventSaleSections.isLoading = true

      try {
        const resp = await this.getEventSaleSections({
          data: {
            where: {
              event_sale_session_id: this.eventSaleSessionId
            },
            paginated: true,
            page: this.eventSaleSections.page,
            per_page: this.eventSaleSections.perPage
          }
        })

        this.eventSaleSections.data = this.eventSaleSections.data.concat(
          resp.data.data.event_sale_sections.data
        )
        this.eventSaleSections.totalPage = resp.data.data.event_sale_sections.last_page
        this.eventSaleSections.total = resp.data.data.event_sale_sections.total
        this.eventSaleSections.page++

        if (this.eventSaleSections.page > this.eventSaleSections.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) &&
            this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (
          Array.isArray(this.$refs.InfiniteLoading) &&
          this.$refs.InfiniteLoading.length > 0
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section event sale')
      }

      this.eventSaleSections.isLoading = false
    }
  }
}
