//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BrandCardSkeleton',
  props: {
    count: {
      type: Number,
      default: 5
    },
    isAnimated: {
      type: Boolean,
      default: false
    }
  }
}
