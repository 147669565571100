//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Order', ['orderDetailPayment']),
    countDown () {
      const date = this.orderDetailPayment.expired_at.substr(
        0,
        this.orderDetailPayment.expired_at.length - 9
      )
      const time = this.orderDetailPayment.expired_at.substr(
        this.orderDetailPayment.expired_at.length - 8,
        this.orderDetailPayment.expired_at.length
      )
      const formatDate = date.split('-')
      let newDate = new Date(
        `${formatDate[1]}-${formatDate[0]}-${formatDate[2]} ${time}`
      )
      if (Number.isNaN(newDate.getTime())) {
        newDate = new Date(formatDate.reverse().join('-') + 'T' + time)
      }
      return newDate.getTime() - new Date().getTime()
    }
  },
  methods: {
    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  }
}
