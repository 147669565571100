var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-product"},[(_vm.pageTakeInStore && _vm.haveNotAvailableToPickUp)?[_c('div',{staticClass:"alert alert-red text-left font-16 mb-4"},[_vm._v("\n      "+_vm._s(_vm.listItem.unavailable_with_pas.length)+" produk\n      "),_c('strong',[_vm._v("tidak dapat Ambil di Toko")])]),_vm._v(" "),_c('div',{staticClass:"cant-take"},_vm._l((_vm.listItem.unavailable_with_pas),function(itemPickup,index){return _c('div',{key:itemPickup.id,staticClass:"product-item mb-5"},[_c('div',{staticClass:"product-item__container"},[_c('div',{staticClass:"product-item__cover"},[_c('img',{staticClass:"w-100",attrs:{"src":itemPickup.product.files[0].value + '.webp',"alt":("itemPickup" + index)},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),_c('div',{staticClass:"product-item__info"},[_c('div',{staticClass:"item-brand"},[_vm._v("\n              "+_vm._s(itemPickup.product.brand.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v("\n              "+_vm._s(itemPickup.product.name)+"\n            ")]),_vm._v(" "),(itemPickup.product.type !== 'simple')?_c('div',{staticClass:"item-variant"},[_vm._v("\n              Varian:\n              "+_vm._s(itemPickup.product.detail.name
                  ? itemPickup.product.detail.name
                  : "-")+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"product-item__price"},[_c('div',{staticClass:"price-original"},[_vm._v("\n              "+_vm._s(_vm.formatRupiah(itemPickup.product.price.toString(), "Rp"))+"\n            ")])])])])}),0)]:_vm._e(),_vm._v(" "),(_vm.haveNotAvailableToShipping)?[_c('div',{staticClass:"alert alert-red text-left font-16 mb-4"},[_vm._v("\n      "+_vm._s(_vm.listItem.unavailable_with_shipping.length)+" produk\n      "),_c('strong',[_vm._v("tidak dapat dikirim.")])]),_vm._v(" "),_c('div',{staticClass:"cant-take"},_vm._l((_vm.listItem.unavailable_with_shipping),function(itemPickup,index){return _c('div',{key:itemPickup.id,staticClass:"product-item mb-5"},[_c('div',{staticClass:"product-item__container"},[_c('div',{staticClass:"product-item__cover"},[_c('img',{staticClass:"w-100",attrs:{"src":itemPickup.product.files[0].value + '.webp',"alt":("itemPickup" + index)},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),_c('div',{staticClass:"product-item__info"},[_c('div',{staticClass:"item-brand"},[_vm._v("\n              "+_vm._s(itemPickup.product.brand.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v("\n              "+_vm._s(itemPickup.product.name)+"\n            ")]),_vm._v(" "),(itemPickup.product.type !== 'simple')?_c('div',{staticClass:"item-variant"},[_vm._v("\n              Varian:\n              "+_vm._s(itemPickup.product.detail.name
                  ? itemPickup.product.detail.name
                  : "-")+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"product-item__price"},[_c('div',{staticClass:"price-original"},[_vm._v("\n              "+_vm._s(_vm.formatRupiah(itemPickup.product.price.toString(), "Rp"))+"\n            ")])])])])}),0)]:_vm._e(),_vm._v(" "),(_vm.haveNotAvailableProductStock || _vm.haveNotAvailableProduct)?[_c('div',{staticClass:"alert alert-red text-left font-16 mb-4"},[_vm._v("\n      Terdapat produk yang\n      "),_c('strong',[_vm._v(_vm._s(_vm.haveNotAvailableProductStock ? "tidak memenuhi stok" : ""))]),_vm._v("\n      "+_vm._s(_vm.haveNotAvailableProductStock && _vm.haveNotAvailableProduct
          ? "atau"
          : null)+"\n      "),_c('strong',[_vm._v(_vm._s(_vm.haveNotAvailableProduct ? "tidak tersedia " : ""))]),_vm._v("\n      di cabang yang dipilih\n    ")]),_vm._v(" "),_vm._l((_vm.listItem.unavailable_with_stock.concat(
        _vm.listItem.unavailable_with_branch
      )),function(productItem,index){return _c('div',{key:productItem.id,staticClass:"product-item mb-2"},[_c('div',{staticClass:"product-item__container"},[_c('div',{staticClass:"product-item__cover"},[_c('img',{staticClass:"w-100",attrs:{"src":productItem.product.files[0].value + '.webp',"alt":("productItem" + index)},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),_c('div',{staticClass:"product-item__info"},[(
              index + 1 <= _vm.listItem.unavailable_with_stock.length &&
                productItem.product.detail[
                  _vm.pageTakeInStore ? 'offline_stock' : 'online_stock'
                ]
            )?_c('div',{staticClass:"item-stock mt-2"},[_vm._v("\n            Stok yang tersedia hanya\n            "+_vm._s(productItem.product.detail[
                _vm.pageTakeInStore ? "offline_stock" : "online_stock"
              ])+"\n          ")]):_c('div',{staticClass:"item-stock mt-2"},[_vm._v("\n            Stok Habis\n          ")]),_vm._v(" "),(productItem.product.detail.is_preorder)?_c('Badge',{staticClass:"mb-2",attrs:{"variant":"pink"}},[_vm._v("\n            Pre-Order | "+_vm._s(productItem.product.detail.preorder_period)+" hari\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"item-brand"},[_vm._v("\n            "+_vm._s(productItem.product.brand.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v("\n            "+_vm._s(productItem.product.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"item-variant"},[_vm._v("\n            Varian :\n            "+_vm._s(productItem.product.detail.name
                ? productItem.product.detail.name
                : "-")+"\n          ")]),_vm._v(" "),(index + 1 > _vm.listItem.unavailable_with_stock.length)?_c('div',{staticClass:"item-stock mt-2"},[_vm._v("\n            Tidak Tersedia\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"product-item__price"},[_c('div',{staticClass:"price-original"},[_vm._v("\n            "+_vm._s(_vm.formatRupiah(productItem.product.price.toString(), "Rp"))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"product-item__action"},[_c('Button',{staticClass:"btn btn-outline-main font-weight-600",attrs:{"text":'Hapus dari Checkout'},nativeOn:{"click":function($event){return _vm.handleDeleteCartProduct(productItem.id)}}}),_vm._v(" "),(
            productItem.product.detail[
              _vm.pageTakeInStore ? 'offline_stock' : 'online_stock'
            ] && !(index + 1 > _vm.listItem.unavailable_with_stock.length)
          )?_c('Button',{staticClass:"btn btn-primary font-weight-600 ms-2",attrs:{"text":("Beli hanya " + (productItem.product.detail[
              _vm.pageTakeInStore ? 'offline_stock' : 'online_stock'
            ] || 0) + " stok")},nativeOn:{"click":function($event){return _vm.handleUpdateCartProduct(
              productItem.id,
              productItem.product.detail[
                _vm.pageTakeInStore ? 'offline_stock' : 'online_stock'
              ]
            )}}}):_vm._e()],1)])})]:_vm._e(),_vm._v(" "),(_vm.haveNotAvailableWithPreorder)?[_c('div',{staticClass:"alert alert-red text-left font-16 mb-4"},[_vm._v("\n      Terdapat produk yang memiliki periode Pre-Order yang berbeda\n    ")]),_vm._v(" "),_vm._l((_vm.listItem.unavailable_with_preorder.concat(
        _vm.listItem.unavailable_with_branch
      )),function(productItem,index){return _c('div',{key:productItem.id,staticClass:"product-item mb-2"},[_c('div',{staticClass:"product-item__container"},[_c('div',{staticClass:"product-item__cover"},[_c('img',{staticClass:"w-100",attrs:{"src":productItem.product.files[0].value + '.webp',"alt":("productItem" + index)},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),_c('div',{staticClass:"product-item__info"},[(productItem.product.detail.is_preorder)?_c('Badge',{staticClass:"mb-2",attrs:{"variant":"pink"}},[_vm._v("\n            Pre-Order | "+_vm._s(productItem.product.detail.preorder_period)+" hari\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"item-brand"},[_vm._v("\n            "+_vm._s(productItem.product.brand.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v("\n            "+_vm._s(productItem.product.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"item-variant"},[_vm._v("\n            Varian :\n            "+_vm._s(productItem.product.detail.name
                ? productItem.product.detail.name
                : "-")+"\n          ")]),_vm._v(" "),(index + 1 > _vm.listItem.unavailable_with_preorder.length)?_c('div',{staticClass:"item-stock mt-2"},[_vm._v("\n            Tidak Tersedia\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"product-item__price"},[_c('div',{staticClass:"price-original"},[_vm._v("\n            "+_vm._s(_vm.formatRupiah(productItem.product.price.toString(), "Rp"))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"product-item__action"},[_c('Button',{staticClass:"btn btn-outline-main font-weight-600",attrs:{"text":'Hapus dari Checkout'},nativeOn:{"click":function($event){return _vm.handleDeleteCartProduct(productItem.id)}}})],1)])})]:_vm._e(),_vm._v(" "),(
      _vm.haveNotAvailableProductStock ||
        _vm.haveNotAvailableProduct ||
        _vm.haveNotAvailableToPickUp ||
        _vm.haveNotAvailableToShipping ||
        _vm.haveNotAvailableWithPreorder
    )?[_c('hr',{staticClass:"divider"}),_vm._v(" "),_c('div',{staticClass:"font-16 font-weight-600"},[_vm._v("\n      Produk yang tidak bisa diproses akan tetap ada di keranjang Anda\n    ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }