//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins/index'

export default {
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Order', ['orderDetailPayment'])
  },
  methods: {
    handleShowDetail () {
      this.$router.push(
        `/account/shop/my-order/detail/${this.orderDetailPayment.orders[0].id}`
      )
    },
    handleConfirm () {
      this.$router.push(
        `/payment-confirmation/${this.orderDetailPayment.orders[0].id}`
      )
    },
    showNotif () {
      this.$message({
        message: 'Teks berhasil disalin ke clipboard',
        type: 'success'
      })
    }
  }
}
