//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'
import panstoreMixin from '@/mixins'
  export default {
    name: 'DashboardJoinAffiliate',
    components: {
      VClamp
    },
    mixins: [panstoreMixin],
    data () {
      return {
        sliderArrow: {
          left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
          right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
        },
        benefitAfiliasi: [
          {
            id: 1,
            title: 'Side Hustle Tanpa Modal',
            img: '/img/home/affiliate/benefit-1-visual.svg',
            description: 'Dapatkan penghasilan tambahanmu di Afiliasi by Paninti dengan mudah, cepat dan tanpa modal.',
            style: 'left'
          },
          {
            id: 2,
            title: 'Dapatkan Produk Gratis',
            img: '/img/home/affiliate/benefit-2-visual.svg',
            description: 'Dapatkan kesempatan menarik untuk dapatkan sampel produk atau voucher gratis dari Paninti.',
            style: 'center'
          },
          {
            id: 3,
            title: 'Promosi yang Fleksibel',
            img: '/img/home/affiliate/benefit-3-visual.svg',
            description: 'Promosi ga perlu ribet, bisa kapanpun dan dimanapun. Fleksibel menyesuaikan keinginanmu  .',
            style: 'right'
          }
      ]
      }
    },
    computed: {
      user () {
        if (this.$store.state.auth.user) {
          return this.$store.state.auth.user
        } else {
          return []
        }
      }
    }
  }
