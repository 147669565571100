//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  name: 'CommissionWithdrawItem',
  mixins: [panstoreMixin],
  props: {
    checkedDatas: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    checked () {
      return this.checkedDatas.some((dt) => {
        return dt.id === this.value.id
      })
    }
  },
  methods: {
    clickItem () {
      this.$emit('click', {
        id: this.value.id,
        amount: this.value.amount,
        type: this.value.withdrawable_type,
        checked: !this.checked
      })
    }
  }
}
