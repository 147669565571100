//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'CatalogAffiliateProduct',
  mixins: [panstoreMixin],
  data () {
    return {
      perPage: 20,
      onLoading: false,
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapState('Product', ['catalogAffiliate']),
    listProduct () {
      return this.catalogAffiliate.data
    }
  },
  watch: {
    '$route.query': {
      async handler () {
        this.infiniteId += 1
        this.resetCatalogAffiliate()
        await this.fetchCatalogtAffiliate()
      },
      deep: true
    }
  },
  async mounted () {
    await this.fetchCatalogtAffiliate()
  },
  methods: {
    ...mapMutations('Product', ['resetCatalogAffiliate']),
    ...mapActions('Product', ['getCatalogAffiliate']),
    getPayload () {
      // initial query
      let brands = []
      let category = []
      let colors = []
      let sizes = []
      let priceLimit = []
      let rating = []
      let search = []
      let partner = ''
      let sort = ''
      let location = []

      if (this.$route.query.brands) {
        brands = this.$route.query.brands.split('::')
      }

      if (this.$route.query.categories) {
        category = this.$route.query.categories.split('::')
      }

      if (this.$route.query.colors) {
        colors = this.$route.query.colors.split('::')
      }

      if (this.$route.query.sizes) {
        sizes = this.$route.query.sizes.split('::')
      }

      if (this.$route.query.priceLimit) {
        priceLimit = this.$route.query.priceLimit.split('::')
      }

      if (this.$route.query.city) {
        location = this.$route.query.city.split('::')
      }

      rating = this.$route.query.rating
        ? this.$route.query.rating.split('::').reverse()
        : []
      search = this.$route.query.search ? this.$route.query.search : ''
      partner = this.$route.query.partner ? this.$route.query.partner : ''
      sort = this.$route.query.sort ? this.$route.query.sort : ''

      // payload
      let payload = {
        page: this.page,
        per_page: this.perPage
      }

      const orWhereHas = []
      if (brands.length) {
        orWhereHas.push({
          relation: 'brand',
          where_in: [['slug', brands]]
        })
      }

      if (category.length) {
        payload = Object.assign({
          ...payload,
          category_slug: category
        })
      }

      let variants = []
      if (colors.length && sizes.length) {
        variants = colors.concat(sizes)
      } else {
        if (colors.length) {
          variants = colors
        }
        if (sizes.length) {
          variants = sizes
        }
      }

      if (
        this.$route.query.priceLimit &&
        this.$route.query.priceLimit !== '0::10000000'
      ) {
        payload = Object.assign({
          ...payload,
          where_greater_than: {
            price: priceLimit[0]
          },
          where_lower_than: {
            price: priceLimit[1]
          }
        })
      }

      if (location.length) {
        payload = Object.assign({
          ...payload,
          city: location
        })
      }

      if (rating.length) {
        payload = Object.assign({
          ...payload,
          where_rating: rating
        })
      }

      if (partner.length) {
        payload = Object.assign({
          ...payload,
          where_has: [
            {
              relation: 'store',
              where: {
                'stores.slug': partner
              }
            }
          ]
        })
      }

      if (sort) {
        if (sort === 'most_expensive') {
          payload = Object.assign({
            ...payload,
            order_by: {
              price: 'desc'
            }
          })
        } else if (sort === 'cheapest') {
          payload = Object.assign({
            ...payload,
            order_by: {
              price: 'asc'
            }
          })
        } else if (sort === 'best_seller') {
          payload = Object.assign({
            ...payload,
            order_by: {
              total_sold_last_month: 'desc'
            }
          })
        } else if (sort === 'lastest') {
          payload = Object.assign({
            ...payload,
            order_by: {
              created_at: 'desc'
            }
          })
        }
      }

      if (search) {
        payload = Object.assign({
          ...payload,
          search
        })
      }

      if (variants.length) {
        orWhereHas.push({
          relation: 'details',
          where_has: [
            {
              relation: 'variants',
              where_in: [['variants.name', variants]]
            }
          ]
        })
      }

      if (orWhereHas.length) {
        payload = Object.assign({
          ...payload,
          or_where_has: orWhereHas
        })
      }

      if (this.$route.query.event_sale) {
        payload = Object.assign({
          ...payload,
          event_sale: this.$route.query.event_sale
        })
      }

      return payload
    },
    async handleInfiniteLoading ($state) {
      await this.fetchCatalogtAffiliate()

      if (this.catalogAffiliate.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async fetchCatalogtAffiliate () {
      this.onLoading = true
      const tempPayload = this.getPayload()

      const payload = Object.assign({
        section: this.$route.params.section !== 'all' ? this.$route.params.section : 'best-selling-this-month',
        data: {
          ...tempPayload,
          paginated: true,
          per_page: this.perPage
        },
        params: {
          page: this.catalogAffiliate.pagination.nextPage || 1
        }
      });

      try {
        await this.getCatalogAffiliate(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      } finally {
        this.onLoading = false
      }
    }
  }
}
