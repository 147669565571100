//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    image () {
      return require('@/static/img/error/empty-review.svg')
    }
  }
}
