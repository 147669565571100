//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    return {}
  },
  methods: {
    openTermsCondition () {
      setTimeout(() => {
        this.$refs.btnOpenTermsCondition.$el.click()
      }, 500)
    },
    openJoinAffiliate () {
      setTimeout(() => {
        this.$refs.btnOpenJoinAffiliate.$el.click()
      }, 500)
    }
  }
}
