//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapActions, mapState } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  layout: 'affiliatePages',
  computed: {
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  }
}
