//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  watchQuery: true,

  beforeRouteLeave (to, from, next) {
    if (to.name !== from.name) {
      this.isLeave = true
    }
    next()
  },

  data () {
    return {
      isLeave: false,

      breadCrumb: [
        {
          path: '/'
        },
        {
          name:
            this.$route.query.type === 'brand'
              ? 'Brand Disekitarmu'
              : 'Toko Disekitarmu'
        }
      ]
    }
  },

  head () {
    return {
      title: 'Partner Terdekat | Paninti Store'
    }
  }
}
