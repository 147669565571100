//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    const dataUser = this.$auth.$state.user
    return {
      profileData: [
        {
          icon: '/img/icon/account/profile/icon-bio.svg',
          title: 'Bio :',
          text:
            dataUser.detail && dataUser.detail.bio ? dataUser.detail.bio : '-'
        },
        {
          icon: '/img/icon/account/profile/icon-lokasi.svg',
          title: 'Asal Kota :',
          text: dataUser.main_address ? dataUser.main_address.village.city : '-'
        },
        {
          icon: '/img/icon/account/profile/icon-username.svg',
          title: 'Username :',
          text: dataUser.username
        },
        {
          icon: '/img/icon/account/profile/icon-email.svg',
          title: 'Email :',
          text: dataUser.email
        },
        {
          icon: '/img/icon/account/profile/icon-tl.svg',
          title: 'Tanggal Lahir :',
          text:
            dataUser.detail && dataUser.detail.date_birth
              ? this.formatDMY(new Date(dataUser.detail.date_birth))
              : '-'
        },
        {
          icon: '/img/icon/account/profile/icon-jk.svg',
          title: 'Jenis Kelamin :',
          text:
            dataUser.detail && dataUser.detail.gender === 'female'
              ? 'Perempuan'
              : 'Laki-laki'
        }
      ],
      socmedData: this.initDataSocmed()
    }
  },
  methods: {
    initDataSocmed () {
      const userSosmed = this.$auth.$state.user.social_medias
      const temp = []
      userSosmed.map((sm) => {
        let title = ''
        let icon = ''
        switch (sm.name) {
          case 'facebook':
            title = 'Facebook'
            icon = 'icon-facebook'
            break
          case 'twitter':
            title = 'Twitter'
            icon = 'icon-twitter'
            break
          case 'instagram':
            title = 'Instagram'
            icon = 'icon-instagram'
            break
          case 'youtube':
            title = 'Youtube Channel'
            icon = 'icon-youtube'
            break
          case 'blog':
            title = 'Blog'
            icon = 'icon-blog'
            break
          case 'tiktok':
            title = 'Tiktok'
            icon = 'icon-tiktok'
            break
        }
        temp.push({
          icon: `/img/icon/account/profile/${icon}.svg`,
          title,
          text: sm.value
        })
      })
      return temp
    }
  }
}
