import { render, staticRenderFns } from "./TimeCountDownFlashsale.vue?vue&type=template&id=044b3d80&scoped=true&"
import script from "./TimeCountDownFlashsale.vue?vue&type=script&lang=js&"
export * from "./TimeCountDownFlashsale.vue?vue&type=script&lang=js&"
import style0 from "./TimeCountDownFlashsale.vue?vue&type=style&index=0&id=044b3d80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044b3d80",
  null
  
)

export default component.exports