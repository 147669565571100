//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    return {
      isSubmit: false,
      cardMetaData: {}
    }
  },
  computed: {
    ...mapState('User', ['link3DS', 'dataAccount']),
    ...mapState('Checkout', ['orderPayload']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  mounted () {
    this.isSubmit = false
  },
  methods: {
    ...mapMutations('User', ['setDataAccount']),
    ...mapMutations('Checkout', ['setOrderPayload']),
    ...mapActions('User', ['createPayment', 'getPayment']),
    ...mapActions('Order', ['addOrder']),
    handleLoad () {
      const _this = this
      if (this.link3DS) {
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
        const eventer = window[eventMethod]
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
        const handleCreateData = this.handleCreateData
        // Listen to message from child window
        eventer(messageEvent, function (e) {
          const key = e.message ? 'message' : 'data'
          const data = e[key]
          if (data && typeof data === 'string') {
            const resp = JSON.parse(data)
            if (resp.status === 'VERIFIED') {
              _this.setDataAccount({
                ..._this.dataAccount,
                card_authentication_id: resp.authentication_id
              })

              if (_this.checkObject(_this.orderPayload)) {
                _this.setOrderPayload({
                  data: {
                    ..._this.orderPayload.data,
                    credit_card: {
                      ..._this.orderPayload.data.credit_card,
                      card_authentication_id: resp.authentication_id,
                      card_meta_data: resp.card_info
                    }
                  }
                })
              }

              _this.cardMetaData = resp.card_info
              handleCreateData()
            }
          }
        }, false)
      }
    },
    async handleCreateData () {
      if (!this.isSubmit) {
        this.isSubmit = true
        if (Object.keys(this.orderPayload).length) {
          this.$refs.buttonShowLoading.click()
          this.handleSubmit()
        } else {
          try {
            const resp = await this.createPayment({
              data: {
                name: '',
                owner_name: '',
                value: this.dataAccount,
                card_meta_data: this.cardMetaData,
                type: 'Credit Card',
                active: true,
                main: true
              }
            })
            if (resp.status === 201) {
              this.$Swal
                .fire({
                  icon: 'success',
                  title: 'Berhasil',
                  text: 'Kartu Kredit berhasil ditambahkan !',
                  heightAuto: false
                }).then(() => {
                  this.isSubmit = false
                  this.getDataAccounts()
                  this.$refs.closeModal.click()
                  window.location.reload()
                })
            }
          } catch (error) {
            this.isSubmit = false
            this.$Swal.fire({
              icon: 'warning',
              title: 'Pemberitahuan',
              text: error.response.data.message,
              heightAuto: false
            }).then(() => {
              this.$refs.closeModal.click()
            })
            console.log(error)
          }
        }
      }
    },
    async handleSubmit () {
      try {
        const resp = await this.addOrder(this.orderPayload)
        if (resp.status === 201) {
          this.$refs.buttonShowLoading.click()
        }
      } catch (error) {
        this.$refs.buttonShowLoading.click()
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Tidak dapat memproses pesanan',
            text: error.response.data.message,
            heightAuto: false,
            confirmButtonText: 'Kembali'
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location = '/cart'
            }
          })
      }
    },
    async getDataAccounts () {
      try {
        await this.getPayment({
          data: {
            paginated: true,
            per_page: 10,
            page: 1,
            where: {
              type: 'Credit Card'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
