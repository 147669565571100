//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  mixins: [panstoreMixin],

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },

    progress: {
      type: Number,
      default () {
        return 0
      }
    },

    onFlashsale: {
      type: Boolean,
      default () {
        return false
      }
    },

    totalSold: {
      type: Number,
      default () {
        return 0
      }
    }
  },

  data () {
    return {
      value: 4.5,
      colors: ['#FFCF44', '#FFCF44', '#FFCF44'],
      iconReview: '/img/icon/card-product/icon-review.svg',
      iconWishlist: '/img/icon/card-product/icon-wishlist.svg',
      iconRegistry: '/img/icon/card-product/icon-registry.svg',
      iconBuy: '/img/icon/card-product/icon-buy.svg',
      is_wishlisted: this.item.is_wishlisted
    }
  },

  computed: {
    showDisc () {
      if (this.item.type === 'simple') {
        return this.item.price !== this.item.discounted_price
      } else {
        return (
          this.item.discounted_price.split('-').length === 1 &&
          this.item.price !== this.item.discounted_price
        )
      }
    },
    remainingStatus () {
      if (this.progress === 100) {
        return 'Sudah Habis';
      } else if (this.progress >= 80) {
        return 'Hampir habis';
      } else if (this.progress < 80) {
          return 'Masih tersedia';
        } else {
          return 'error';
        }
    },
    backgroundBarStatus () {
      if (this.progress >= 80) {
        return 'bgBar-second';
      } else {
        return '';
      }
    },
    colorBarStatusText () {
      if (this.progress >= 80) {
        return 'textBar-second';
      } else {
        return '';
      }
    }
  },

  methods: {
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapActions('Product', ['getProductWishlist']),

    async handleAddCart (item) {
      await this.setSelectedProduct(item)
      const payload = Object.assign({
        data: {
          product_detail_id: item.details[0].id,
          quantity: 1,
          session_id: this.$cookies.get('session_id')
        }
      })

      try {
        const resp = await this.addCart(payload)

        if (resp.data.status_code === 201) {
          await this.setSelectedVariant(null)
          await this.handleGetCart()
          this.$refs.showAlert.click()
        } else {
          this.$message({
            showClose: true,
            message: resp.data.message,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
        const errorMessage = error.response
          ? error.response.data.message
          : error
        this.$message({
          showClose: true,
          message: errorMessage,
          type: 'error'
        })
      }
    },

    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }

      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })

        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }

      try {
        await this.getCartsNavbar({
          params: {
            session_id: payload.session_id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    hoverIconReview (hover) {
      if (hover) {
        this.iconReview = '/img/icon/card-product/icon-review-filled.svg'
      } else {
        this.iconReview = '/img/icon/card-product/icon-review.svg'
      }
    },

    hoverIconWishlist (hover) {
      if (!this.is_wishlisted) {
        if (hover) {
          this.iconWishlist = '/img/icon/card-product/icon-wishlist-filled.svg'
        } else {
          this.iconWishlist = '/img/icon/card-product/icon-wishlist.svg'
        }
      }
    },

    hoverIconRegistry (hover) {
      if (hover) {
        this.iconRegistry = '/img/icon/card-product/icon-registry-filled.svg'
      } else {
        this.iconRegistry = '/img/icon/card-product/icon-registry.svg'
      }
    },

    hoverIconBuy (hover) {
      if (hover) {
        this.iconBuy = '/img/icon/card-product/icon-buy-filled.svg'
      } else {
        this.iconBuy = '/img/icon/card-product/icon-buy.svg'
      }
    },

    handleGotoDetail (slug) {
      this.$router.push(`/product/${slug}`)
    },

    async handleToggleWishlist () {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_slug: [this.item.slug]
            }
          })

          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })

            this.iconWishlist =
              this.iconWishlist ===
              '/img/icon/card-product/icon-wishlist-filled.svg'
                ? '/img/icon/card-product/icon-wishlist.svg'
                : '/img/icon/card-product/icon-wishlist-filled.svg'

            this.is_wishlisted = !this.is_wishlisted

            this.$message({
              message: `Produk berhasil ${
                this.is_wishlisted ? 'dihapus dari ' : 'ditambahkan ke'
              } Wishlist.`,
              type: 'success'
            })
          }
        } catch (error) {
          console.error(error)
          this.$message({
            message: 'Oops, Terjadi kesalahan.',
            type: 'error'
          })
        }
      }
    }
  }
}
