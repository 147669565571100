//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      onLoading: false,
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapState('Brand', ['bestSellingBrandAffiliate']),
    listBrand () {
      return this.bestSellingBrandAffiliate.data || []
    }
  },
  watch: {
    '$route.query': {
      handler  () {
        this.resetBestSellingBrandAffiliate()
        this.infiniteId++
      },
      deep: true
    }
  },
  async mounted () {
    this.resetBestSellingBrandAffiliate()
    await this.handleTopBrandAffiliate()
  },
  methods: {
    ...mapActions('Brand', ['getBestSellingBrandAffiliate']),
    ...mapMutations('Brand', ['resetBestSellingBrandAffiliate']),
    async handleInfiniteLoading ($state) {
      await this.handleTopBrandAffiliate()

      if (this.bestSellingBrandAffiliate.pagination.nextPage === null) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    async handleTopBrandAffiliate () {
      this.loading = true
      try {
        const payload = {
          params: {
            paginated: true,
            per_page: 4,
            page: this.bestSellingBrandAffiliate.pagination.nextPage || 1,
            search: this.$route.query.search || ''
          }
        }

        await this.getBestSellingBrandAffiliate(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      } finally {
        this.loading = false
      }
    }
  }
}
