//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions } from 'vuex'

export default {
  name: 'ModalCreateAccount',
  props: {
    modalId: {
      type: String,
      default: 'modalCreateAccount'
    }
  },
  data () {
    const validateNumeric = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[0-9]+$/
      const field = rule.field

      if (value.length === 1 && (input === '0' || input === 0)) {
        this.formCreateAccount[field] = '62'
      }

      if (value.length === 1) {
        if (value === ' ') {
          this.formCreateAccount[field] = ''
        }
      }

      if (value === ' ') {
        this.formCreateAccount[field] = ''
      }
      if (value.length > 15) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      }

      if (!input.match(letters)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formCreateAccount[field] =
          value.substr(0, value.length - 1) + input.toUpperCase()
      }

      callback()
    }

    const validateAlphaCapitalize = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[A-Za-z'. ]+$/
      const firstLetter = /^['\\. ]+$/
      const field = rule.field

      if (value.length === 1) {
        if (value === ' ') {
          this.formCreateAccount[field] = ''
        } else if (value.match(firstLetter)) {
          this.formCreateAccount[field] = ''
        } else {
          this.formCreateAccount[field] = value.substr(0, 1).toUpperCase()
        }
      }

      if (value === ' ') {
        this.formCreateAccount[field] = ''
      }

      if (!input.match(letters)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      } else if (input.match(firstLetter) && input.match(inputBefore)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formCreateAccount[field] =
          value.substr(0, value.length - 1) + input.toUpperCase()
      }

      callback()
    }

    const validateUsername = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const letters = /^[a-zA-z._0-9]+$/
      const field = rule.field

      if (input === ' ') {
        this.formCreateAccount[field] = ''
      }
      if (!input.match(letters)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      }
      if (value.length > 20) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      }
      if (!input.match(letters)) {
        this.formCreateAccount[field] = value.substr(0, value.length - 1)
      }

      callback()
    }

    const validatePass = (rule, value, callback) => {
      /* eslint-disable */
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
      const field = rule.field;

      if (!value.match(regex)) {
        callback(
          new Error("Password minimal berisi 1 huruf kapital, 1 huruf non kapital, dan 1 angka")
        );
      }

      if (value.length > 255) {
        this.formCreateAccount[field] = value.substring(0, value.length - 1);
      }

      callback();
    }

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Masukan Konfirmasi Password'));
      }

      if (value !== this.formCreateAccount.password) {
        callback(new Error('Konfirmasi password tidak sama'));
      }

      callback();
    }

    return {
      options: [{
        value: 'male',
        label: 'Laki - Laki'
      }, {
        value: 'female',
        label: 'Perempuan'
      }],
      formCreateAccount: {
        fullname: '',
        username: '',
        birth_date: '',
        gender: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: ''
      },
      formCreateAccountRules: {
        fullname: [
          {
            required: true,
            message: 'Nama lengkap wajib diisi',
            trigger: ['change', 'blur']
          },
          {
            validator: validateAlphaCapitalize
          }
        ],
        username: [
          {
            required: true,
            message: 'Username wajib diisi',
            trigger: ['change', 'blur']
          },
          {
            min: 6,
            max: 20,
            message: 'Username minimal 6 karakter',
            trigger: 'blur'
          },
          {
            validator: validateUsername
          }
        ],
        birth_date: [
          {
            required: true,
            message: 'Tanggal lahir wajib diisi',
            trigger: ['change', 'blur']
          }
        ],
        gender: [
          {
            required: true,
            message: 'Jenis kelamin wajib diisi',
            trigger: ['change', 'blur']
          }
        ],
        phone: [
          {
            required: true,
            message: 'Nomor rekening wajib diisi',
            trigger: ['change', 'blur']
          },
          {
            validator: validateNumeric
          }
        ],
        email: [
          {
            required: true,
            message: 'Email wajib diisi',
            trigger: ['change', 'blur']
          },
          {
            type: 'email',
            message: 'Format email tidak valid',
            trigger: ['change', 'blur']
          }
        ],
        password: [
          {
            validator: validatePass,
            trigger: ['change', 'blur']
          }
        ],
        passwordConfirmation: [
          {
            validator: validatePass2,
            trigger: ['change', 'blur']
          }

        ]
      },
      onSubmit: false
    }
  },
  methods: {
    ...mapActions('Auth', ['register', 'sendOtp']),
    resetForm (clearCart) {
      this.$refs[clearCart].resetFields();
    },
    async submitForm (formCreate) {
      let result = false
      this.$refs[formCreate].validate((valid) => {
        result = valid
      });
      if (!result) {
        return
      }
      this.onSubmit = true

      // break full name into first and last name
      const arrayName = this.formCreateAccount.fullname.split(' ')
      const firstName = arrayName[0]
      let lastName = ''
      if (arrayName.length > 1) {
        arrayName.shift()
        lastName = arrayName.join(' ')
      }

      try {
        const payload = {
          data: {
            first_name: firstName,
            last_name: lastName,
            username: this.formCreateAccount.username,
            birth_date: this.formCreateAccount.birth_date,
            gender: this.formCreateAccount.gender,
            email: this.formCreateAccount.email,
            phone_number: this.formCreateAccount.phone,
            password: this.formCreateAccount.password,
            password_confirmation: this.formCreateAccount.passwordConfirmation,
            remember: false
          }
        }

        await this.register(payload)

        await this.handleLogin()
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Gagal Register',
            text: errorMessage
          })
      }
      this.onSubmit = false
    },
    async handleLogin (username, email, password) {
      await this.$auth.loginWith('local', {
        data: {
          email: this.formCreateAccount.email,
          username: this.formCreateAccount.username,
          password: this.formCreateAccount.password,
          session_id: this.$cookies.get('session_id')
        }
      })

      await this.handleSendOtp()
    },
    async handleSendOtp () {
      try {
        const payload = {
          data: {
            purpose: 'registration'
          }
        }

        await this.sendOtp(payload)
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil Mengirimkan Kode OTP',
            text: 'Berhasil mengirimkan kode OTP, silahkan cek email anda!'
          })
          .then((confirm) => {
            this.resetForm('formCreateAccount')
            this.$refs.btnClose.click()
            this.$emit('register-success')
          })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
    },
    modalClose () {
      this.$emit('modal-close')
    }
  }
}
