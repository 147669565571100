//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  data () {
    return {
      datas: [],
      dataLimit: 5,
      loading: true
    }
  },

  computed: {
    ...mapState('User/address', ['location'])
  },

  watch: {
    location () {
      this.getTrendingNowProducts()
    }
  },

  async mounted () {
    await this.getTrendingNowProducts()
  },

  methods: {
    ...mapActions('Product', ['getProductSection']),

    async getTrendingNowProducts () {
      this.loading = true

      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155

        const resp = await this.getProductSection({
          section: 'trending-now',
          payload: {
            data: {
              session_id: this.$cookies.get('session_id'),
              city_id: cityId,
              paginated: false,
              limit: this.dataLimit
            }
          }
        })

        this.datas = resp.data.data.products
      } catch (error) {
        this.$message.error('Gagal mendapatkan data lagi trending produk')
      }

      this.loading = false
    }
  }
}
