import { render, staticRenderFns } from "./index.vue?vue&type=template&id=54adb708&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54adb708",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardUserProfile: require('/usr/src/app/components/home/users/CardUserProfile.vue').default,UserProfileBanner: require('/usr/src/app/components/home/users/UserProfileBanner.vue').default,TopProducts: require('/usr/src/app/components/home/users/TopProducts.vue').default,TopBrands: require('/usr/src/app/components/home/users/TopBrands.vue').default,AllProduct: require('/usr/src/app/components/home/users/AllProduct.vue').default})
