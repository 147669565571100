//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  layout: 'account',
  middleware: ['user'],
  mixins: [mixins],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Beranda',
        path: '/account/payment'
      },
      {
        name: 'Pembayaran Instan'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      selectedItem: {},
      onCreate: false,
      loading: true
    }
  },
  computed: {
    ...mapState('User', ['accounts'])
  },
  async mounted () {
    await this.handleGetPayment()
  },
  methods: {
    ...mapMutations('User', ['setCreateCC']),
    ...mapActions('User', ['getPayment']),
    async handleGetPayment () {
      try {
        this.loading = true
        const payload = {
          data: {
            paginated: true,
            per_page: 10,
            page: 1,
            where: {
              type: 'Credit Card'
            }
          }
        }

        await this.getPayment(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    },
    showLabel (value) {
      let str = ''
      const count = value.length
      if (value) {
        str = '**** ' + value.substring(count - 4, count)
      } else {
        str = value
      }
      return str
    },
    handleShowItem (item) {
      this.selectedItem = item
    },
    handleAddNew () {
      this.setCreateCC(true)
      setTimeout(() => this.setCreateCC(false), 300)
    }
  }
}
