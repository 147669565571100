//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    dataBanner: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },

  computed: {
    banner_desktop () {
      return this.dataBanner.length ? this.dataBanner.filter((file) => {
        return file.display_type === 'normal desktop'
      })[0] : {}
    },
    banner_phone () {
      return this.dataBanner.length ? this.dataBanner.filter((file) => {
        return file.display_type === 'normal phone'
      })[0] : {}
    },

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    normalDesktop () {
      return this.windowWidth >= 992
    },
    loading () {
      return Object.keys(this.banner_desktop).length || Object.keys(this.banner_phone).length
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },
    handleAlert () {
      this.$message({
        message: 'Kode voucher berhasil disalin ke clipboard',
        type: 'success'
      })
    }
  }
}
