//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      page: 0,
      totalPage: 1
    }
  },
  computed: {
    ...mapState('Partner', ['relatedStores'])
  },
  created () {
    this.handleGetBranch()
  },
  methods: {
    ...mapActions('Partner', ['getRelatedPartners']),
    async handleGetBranch () {
      this.page++
      try {
        const resp = await this.getRelatedPartners({
          id: this.$route.params.id,
          payload: {
            data: {
              page: this.page,
              paginated: true
            }
          }
        })
        if (resp.status === 200) {
          this.totalPage = resp.data.data.stores.last_page || 1
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
