import { render, staticRenderFns } from "./_order_id.vue?vue&type=template&id=59ab3458&scoped=true&"
import script from "./_order_id.vue?vue&type=script&lang=js&"
export * from "./_order_id.vue?vue&type=script&lang=js&"
import style0 from "./_order_id.vue?vue&type=style&index=0&id=59ab3458&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ab3458",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CheckoutCounterSuccess: require('/usr/src/app/components/home/payment-detail/CheckoutCounterSuccess.vue').default,DetailCheckout: require('/usr/src/app/components/home/payment-detail/DetailCheckout.vue').default,PaymentGuide: require('/usr/src/app/components/home/payment-detail/PaymentGuide.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
