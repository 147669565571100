//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      windowWidth: process.client ? window.innerWidth : 0,
      loading: false
    }
  },
  computed: {
    ...mapState('Contents', ['productCategories', 'isLoadingCategory']),
    ...mapState('Category', ['parentCategories']),
    normalDesktop () {
      return this.windowWidth >= 992
    }
  },
  async mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    await this.handleGetParentCategories()
  },
  methods: {
    ...mapActions('Category', ['getParentCategories']),
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },
    async handleGetParentCategories () {
      this.loading = true
      try {
        await this.getParentCategories()
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
    }
  }
}
