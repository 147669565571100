//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    return {
      search: '',
      showAll: false,
      links: [
        {
          name: 'Gunakan lokasi saya saat ini',
          value: '',
          icon: '/img/icon/icon-current-location.svg',
          city_id: -1
        }
      ]
    }
  },
  computed: {
    ...mapState('User/address', ['location', 'userAddress']),
    user () {
      return this.$auth.user
    },
    isHaveAddress () {
      return (
        (this.$auth.loggedIn &&
          this.user.main_address &&
          !!Object.keys(this.user.main_address).length) ||
        false
      )
    },
    isPhone () {
      return (
        this.$mq === 'md' ||
            this.$mq === 'sm' ||
            this.$mq === 'xs' ||
            this.$mq === 'xxs'
      )
    },
    selectedAddress () {
      if (this.$auth.loggedIn && this.isHaveAddress) {
        return (
          this.userAddress.filter((address) => {
            return address.id === this.location.isAddress
          })[0] || this.$auth.user.main_address
        )
      } else {
        return []
      }
    }
  },
  async created () {
    if (process.browser && localStorage.location) {
      this.setLocation(JSON.parse(localStorage.location))

      this.search = JSON.parse(localStorage.location).isAddress
        ? ''
        : JSON.parse(localStorage.location).name
    }
    if (this.$auth.loggedIn) {
      try {
        await this.getUserAddress()
      } catch (error) {
        console.log(error)
      }
    }
  },
  methods: {
    ...mapActions('GoogleMaps', ['getOptCity', 'getCurrentCity']),
    ...mapMutations('User/address', ['setLocation']),
    ...mapActions('User/address', ['getUserAddress']),
    async handleGetData () {
      try {
        const resp = await this.getOptCity({
          data: {
            paginated: false,
            search: this.search
          }
        })
        if (resp.status === 200) {
          this.links = [
            {
              name: 'Gunakan lokasi saya saat ini',
              icon: '/img/icon/icon-current-location.svg',
              city_id: -1
            }
          ]
          this.links = this.links.concat(resp.data.data.cities)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async querySearch (queryString, cb) {
      if (this.search) {
        await this.handleGetData()
      } else {
        this.links = [
          {
            name: 'Gunakan lokasi saya saat ini',
            value: '',
            icon: '/img/icon/icon-current-location.svg',
            city_id: -1,
            isAddress: null
          }
        ]
      }
      cb(this.links)
    },
    onSearch (item) {
      if (item.icon) {
        this.locatorButtonPressed()
      } else {
        const temp = {
          value: item.name.split(', ')[1] || 'Jakarta Utara',
          name: item.name,
          city_id: item.city_id,
          isAddress: null
        }
        this.setLocation(temp)
        localStorage.location = JSON.stringify(temp)
        this.$message({
          message: 'Lokasi berhasil diubah!',
          type: 'success'
        })
        this.$refs.closeButton.click()
      }
    },
    locatorButtonPressed () {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.handleGetCurrentCity({
            data: {
              latlng:
                position.coords.latitude + ',' + position.coords.longitude
            }
          })
        },
        (error) => {
          console.log(error.message)
        }
      )
    },
    async handleGetCurrentCity (payload) {
      try {
        const resp = await this.getCurrentCity(payload)
        if (resp.status === 200) {
          this.onSearch({
            name: resp.data.data.city.name,
            city_id: resp.data.data.city.city_id
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    seeAllAddress () {
      this.showAll = true
    },
    handleClickAddress (address) {
      this.search = ''
      const temp = {
        value: address.village.city,
        name: address.village.province + ', ' + address.village.city,
        city_id: address.village.subdistrict.city.id,
        isAddress: address.id
      }
      this.setLocation(temp)
      localStorage.location = JSON.stringify(temp)
      this.$message({
        message: 'Lokasi berhasil diubah!',
        type: 'success'
      })
      this.$refs.closeButton.click()
    },
    handleOnSearch () {
      this.handleGetData()
      this.$refs.inputSearch.focus()
    }
  }
}
