//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    eventSaleSection: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      }
    }
  },

  computed: {
    bannerHeader () {
      if (!this.eventSaleSection || !this.eventSaleSection.files) {
        return null
      }

      const bannerHeaders = this.eventSaleSection.files.filter((file) => {
        return file.name === 'header'
      })

      if (bannerHeaders.length > 0) {
        return bannerHeaders[0].value
      }

      return null
    },

    eventSaleSectionProducts () {
      if (!this.eventSaleSection || !this.eventSaleSection.event_sale_products) {
        return []
      }

      return this.eventSaleSection.event_sale_products.data
    },
    settings () {
      return {
        dots: false,
        arrows: !!(this.eventSaleSectionProducts && this.eventSaleSectionProducts.length),
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          }
        ]
      }
    },
    moreThanTenProducts () {
      return this.eventSaleSection.event_sale_products.total > 10
    }
  }
}
