//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      idStore: '',
      firstLoad: true
    }
  },
  computed: {
    ...mapState('Checkout', [
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'selectedAddress',
      'pageTakeInStore',
      'usePoint',
      'isLoading',
      'selectedCourier'
    ]),
    ...mapState('User/address', ['userAddress']),
    ...mapState('Voucher', ['voucherUsed', 'voucher']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    haveStoreBranch () {
      return this.getDataBranch()
    }
  },
  watch: {
    async pageTakeInStore () {
      if (this.pageTakeInStore && !this.firstLoad) {
        await this.checkAvailibility(
          this.selectedAddress.id,
          this.getDataBranch()[this.branchSelected].id
        )
      }
    },
    async selectedAddress () {
      await this.handleGetStoreBranch()
    }
  },
  async mounted () {
    await this.initComponent()
  },
  methods: {
    ...mapActions('Checkout', [
      'getStoreBranch',
      'getStoreBranchPAS'
    ]),
    ...mapMutations('Checkout', ['setSelectedBranch', 'setTakeInStore']),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    async handleBranchSelected (index) {
      if (this.branchSelected !== index) {
        this.setSelectedBranch(index)
        await this.checkAvailibility(
          this.selectedAddress.id,
          this.getDataBranch()[this.branchSelected].id
        )
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    },
    async handleGetStoreBranch () {
      try {
        const payload = {
          data: {
            cart_id: JSON.parse(localStorage.cartItem),
            user_address_id: this.selectedAddress.id,
            pickup_at_store: this.pageTakeInStore
          }
        }
        const resp = await this.getStoreBranch({
          payload
        })
        if (resp.status === 200) {
          this.idStore = resp.data.data.store_branches.length
            ? resp.data.data.store_branches[0].id
            : ''
        }
      } catch (error) {
        const cart = localStorage.cartItem
          ? JSON.parse(localStorage.cartItem)
          : []
        if (cart.length) {
          this.$Swal
            .fire({
              icon: 'error',
              title: 'Oops, terjadi kesalahan',
              text: 'Mohon maaf atas ketidaknyamanan ini',
              heightAuto: false
            })
            .then((confirm) => {
              if (confirm) {
                this.$router.go(-1)
              }
            })
        }
        console.log(error)
      }
    },
    async handleGetStoreBranchPAS () {
      try {
        const payload = {
          data: {
            cart_id: JSON.parse(localStorage.cartItem),
            user_address_id: this.selectedAddress.id,
            pickup_at_store: true
          }
        }
        const resp = await this.getStoreBranchPAS({
          payload
        })
        if (resp.status === 200 && !this.storeBranch.length) {
          this.idStore = resp.data.data.store_branches.length
            ? resp.data.data.store_branches[0].id
            : ''
          this.setTakeInStore(true)
        }
      } catch (error) {
        const cart = localStorage.cartItem
          ? JSON.parse(localStorage.cartItem)
          : []
        if (cart.length) {
          this.$Swal
            .fire({
              icon: 'error',
              title: 'Oops, terjadi kesalahan',
              text: 'Mohon maaf atas ketidaknyamanan ini',
              heightAuto: false
            })
            .then((confirm) => {
              if (confirm) {
                this.$router.go(-1)
              }
            })
        }
        console.log(error)
      }
    },
    async checkAvailibility (userAddressId, storeId) {
      try {
        const courierCode = Object.keys(this.selectedCourier).length
          ? this.selectedCourier.code
          : null
        const courierService = Object.keys(this.selectedCourier).length
          ? this.selectedCourier.service
          : null

        const resp = await this.checkAvailibilities(
          JSON.parse(localStorage.cartItem),
          storeId,
          userAddressId,
          courierCode,
          courierService,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.usePoint
        )

        const vouchers = resp.data.data.checkout.vouchers
        this.checkActiveOnlyVoucher(vouchers)
      } catch (error) {
        console.log(error)
      }
    },
    async initComponent () {
      await this.handleGetStoreBranch()
      await this.handleGetStoreBranchPAS()
      await this.checkAvailibility(this.selectedAddress.id, this.idStore)

      this.firstLoad = false
    }
  }
}
