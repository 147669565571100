import { render, staticRenderFns } from "./wishlist.vue?vue&type=template&id=507bc354&scoped=true&"
import script from "./wishlist.vue?vue&type=script&lang=js&"
export * from "./wishlist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507bc354",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,WishlistSection: require('/usr/src/app/components/account/shop/wishlist/WishlistSection.vue').default})
