import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5af6a9d7&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af6a9d7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBanner: require('/usr/src/app/components/home/HomeBanner.vue').default,CategorySection: require('/usr/src/app/components/home/CategorySection.vue').default,PopularMonthly: require('/usr/src/app/components/affiliate/home/PopularMonthly.vue').default,TopBrandAffiliate: require('/usr/src/app/components/affiliate/home/TopBrandAffiliate.vue').default,ProductAffiliatePopular: require('/usr/src/app/components/affiliate/home/ProductAffiliatePopular.vue').default})
