//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapState } from 'vuex'
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  data () {
    return {
      visible: false,
      indexImg: 0,
      indexData: 0
    }
  },
  computed: {
    ...mapState('Order', ['orderReviews']),
    imgReview () {
      const img = []
      this.orderReviews.data.map((datas, i) => {
        if (i === this.indexData) {
          datas.files.filter((file) => {
            file.variant === 'original' && img.push(file.value)
          })
        }
      })

      return img
    }
  },
  methods: {
    showImage (index, indexData) {
      this.indexImg = index
      this.indexData = indexData
      this.visible = true
    },
    expandedToggle (index) {
      this.$refs['desc-' + index][0]._props.expanded =
        !this.$refs['desc-' + index][0]._props.expanded
    },
    getAvatar (files, gender) {
      if (files.length) {
        return (
          files.filter((file) => {
            return file.name === 'avatar' && file.variant === 'thumbnail'
          })[0].value + '.webp'
        )
      }
      return require(`@/static/img/account/profile/default_avatar${
        gender === 'female' ? '_female' : ''
      }.png`)
    },
    getImagesReview (files, type) {
      return files.filter((file) => {
        return file.variant === type
      })
    }
  }
}
