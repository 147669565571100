//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins/index'
export default {
    name: 'CardStatsBank',
    mixins: [panstoreMixin],
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        onSubmit: {
          type: Boolean,
          default: false
        }
    },
    methods: {
      onSubmitChange (value) {
        this.$emit('on-submit-changed', value)
      },

      onBankUpdated () {
        this.$emit('on-bank-updated')
      }
    }
}
