//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions } from 'vuex'
export default {
  layout: 'account',
  middleware: ['user'],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Beranda',
        path: '/account/home'
      },
      {
        name: '<b>Belanja Saya</b>'
      },
      {
        name: 'Penilaian Saya'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      payload: {
        per_page: 10,
        page: 1
      },
      rate: '',
      sort: '',
      search: '',
      isAvailable: false,
      totalPage: 1,
      firstLoad: true
    }
  },
  watch: {
    '$route.query': {
      handler () {
        setTimeout(async () => {
          this.payload.page = 0
          await this.getDataReviews()
        }, 500)
      },
      deep: true
    }
  },
  async mounted () {
    await this.getDataReviews()
  },
  methods: {
    ...mapActions('Order', ['getMyOrderReviews']),
    handleChangeRating (value) {
      if (value === Number(this.rate)) {
        this.rate = null
      } else {
        this.rate = Number(value)
      }
      this.applyQuery()
    },
    handleSearch: _.debounce(function () {
      this.applyQuery()
    }, 2000),
    applyQuery () {
      const payload = {}
      const search = this.search
      const sort = this.sort
      const rating = this.rate

      if (search) {
        payload.searchReview = search
      }
      if (sort) {
        payload.sort = sort
      }
      if (rating) {
        payload.rating = rating
      }
      this.$router.push({
        query: payload
      })
    },
    async getDataReviews () {
      if (!this.firstLoad) {
        this.payload.page++
      }

      const query = this.$route.query

      if (query.searchReview) {
        this.payload.search = query.searchReview
      } else {
        delete this.payload.search
      }

      if (query.rating) {
        this.payload.where = Object.assign({
          rating: query.rating
        })
      }

      try {
        const resp = await this.getMyOrderReviews({
          data: this.payload
        })

        if (resp.status === 200 && resp.data.data.reviews.data.length) {
          this.totalPage = resp.data.data.reviews.last_page
          this.isAvailable = true
        } else {
          this.isAvailable = false
        }
      } catch (error) {
        console.log(error)
      } finally {
        if (this.firstLoad) { this.firstLoad = false }
      }
    }
  }
}
