//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import marketplaceMixin from '@/mixins'
export default {
  name: 'WithdrawCommissionItem',
  mixins: [marketplaceMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      badgeStatus: {
        rejected: {
          color: 'red',
          text: 'Ditolak'
        },
        new: {
          color: 'pink',
          text: 'Menunggu Persetujuan'
        },
        done: {
          color: 'green',
          text: 'Selesai'
        },
        approved: {
          color: 'blue',
          text: 'Diterima'
        }
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', this.item.id)
    }
  }
}
