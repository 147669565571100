//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  async asyncData ({ store, params, error }) {
    try {
      const resp = await store.dispatch('Order/getDetailOrder', params.order_id)
      if (resp.status === 200) {
        if (
          resp.data.data.order.status.sequence > 1 ||
          resp.data.data.order.payment.is_payment_proof_uploaded
        ) {
          error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
        }
      }
    } catch (exerror) {
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Pesanan Saya',
        path: '/account/shop/my-order'
      },
      {
        name: 'Konfirmasi Pembayaran'
      }
    ]
    return {
      breadCrumb: breadcrumbs
    }
  },
  head: () => {
    return {
      title: 'Konfirmasi Pembayaran | Paninti Store'
    }
  }
}
