//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
import _ from 'lodash'

export default {
  mixins: [panstoreMixin],
  data () {
    return {
      minCountItem: 1,
      maxCountItem: 2,
      alertStore: false,
      deleteAll: false,
      onIncrease: false,
      selectedItemCheck: [],
      maxQuantity: false,
      mappingStore: false,
      auth: [],
      currentValue: 0
    }
  },
  computed: {
    ...mapState('Cart', ['cart', 'selectItem', 'store', 'isLoading']),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapState('Checkout', ['usePoint'])
  },
  mounted () {
    this.auth = this.$auth
    if (
      localStorage.cartItem ||
        localStorage.cartPrice ||
        localStorage.cartBranchId
    ) {
      localStorage.removeItem('cartItem')
      localStorage.removeItem('cartPrice')
      localStorage.removeItem('cartBranchId')
      this.setSelectedStore('')
      this.resetItem([])
    }
  },
  methods: {
    ...mapActions('Cart', ['getCarts', 'getCartsNavbar', 'updateCart', 'selectedItem']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapMutations('Cart', [
      'setCartItemId',
      'setCartItemQuantity',
      'setDeleteItem',
      'setSelectedItem',
      'resetSelectedItem',
      'setCheckoutItem',
      'resetItem',
      'setSelectedStore'
    ]),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    ...mapActions('Product', ['getProductWishlist']),
    async handleToggleWishlist (id, isWishlisted) {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_id: [id]
            }
          })
          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })
            this.$message({
              message: `Produk berhasil ${
                  isWishlisted ? 'dihapus dari ' : 'ditambahkan ke'
                } Wishlist.`,
              type: 'success'
            })
            window.location.reload()
          }
        } catch (error) {
          console.log(error)
          this.$message({
            message: 'Oops, Terjadi kesalahan.',
            type: 'error'
          })
        }
      }
      // else {
      //   this.$alert('Silahkan login terlebih dahulu!')
      // }
    },
    async handleUpdateCartProduct (idProduct, newQuantity, type) {
      this.onIncrease = true
      const payload = {
        data: {
          carts: [
            {
              id: idProduct,
              quantity: newQuantity
            }
          ],
          session_id: this.$cookies.get('session_id')
        }
      }
      try {
        const resp = await this.updateCart(payload)
        if (resp.status === 200) {
          if (type === 'increase' || type === 'decrease') {
            this.$message({
              showClose: true,
              message: `Berhasil ${
                  type === 'increase' ? 'menambahkan' : 'mengurangi'
                } item ${type === 'increase' ? 'ke' : 'di'} Keranjang`,
              type: 'success'
            })
          } else {
            this.$message({
              showClose: true,
              message: 'Berhasil merubah item di keranjang',
              type: 'success'
            })
          }
        }
      } catch (error) {
        console.log(error)
        this.$message.error(error.response.data.message)
      }
      try {
        const payloadCartNavbar = {
          params: {
            session_id: this.$cookies.get('session_id')
          }
        }
        const payloadAvailable = {
          data: {
            conditions: {
              stock: 'available_stock'
            },
            order_by: {
              'carts.updated_at': 'desc'
            },
            session_id: this.$cookies.get('session_id')
          }
        }
        await this.getCartsNavbar(payloadCartNavbar)
        await this.getCarts(payloadAvailable)
      } catch (error) {
        console.log(error)
        this.$message.error(error.response.data.message)
      }
      this.onIncrease = false
    },
    debounceUpdateCart: _.debounce(async function (id, currentVal, type) {
      await this.handleUpdateCartProduct(id, currentVal, type)
      await this.handleCheck()
    }, 700),
    increaseCounter (currentVal, id, increaseLimit) {
      // Increase
      this.setCartItemQuantity({ id, currentVal, type: 'increase' })
      if (currentVal < increaseLimit) {
        currentVal++
        this.maxQuantity = false
      } else {
        this.maxQuantity = true
      }
      this.debounceUpdateCart(id, currentVal, 'increase')
    },
    decreaseCounter (currentVal, id, decreaseLimit) {
      // Decrease
      this.setCartItemQuantity({ id, currentVal, type: 'decrease' })
      if (currentVal > decreaseLimit) {
        currentVal--
        this.maxQuantity = false
      }
      this.debounceUpdateCart(id, currentVal, 'decrease')
    },
    async handleCheck () {
      const oldVoucher = this.voucherUsed
      if (
        this.selectItem.length &&
          Object.keys(oldVoucher).length &&
          !this.mappingStore
      ) {
        const voucherId = oldVoucher.id
        const response = await this.checkAvailibilities(
          this.selectItem,
          null,
          null,
          null,
          null,
          false,
          voucherId,
          this.usePoint
        )
        const newVoucher = response.data.data.checkout.voucher
        if (newVoucher) {
          const payload = {
            code: newVoucher.code || null,
            reduction_type: newVoucher.reduction_type,
            reduction_value: newVoucher.reduction_value,
            total_voucher_discount: newVoucher.total_voucher_discount,
            type: newVoucher.type
          }
          const finalVoucher = Object.assign(
            JSON.parse(JSON.stringify(oldVoucher)),
            payload
          )
          this.setVoucherUsed(finalVoucher)
        }
      }
    },
    flashAlert (msg = '') {
      this.$message({
        showClose: true,
        message: msg || 'Hanya bisa pesan dari 1 toko, dalam 1 kali Checkout ',
        type: 'warning'
      })
    },
    checkItem (id, storeId) {
      const dataCart = this.selectItem
      this.alertStore = false
      localStorage.cartBranchId = storeId

      if (!this.store || this.store === storeId) {
        const checkPreOrder = this.handleCheckPreorderPerItem(id, storeId, dataCart)

        if (checkPreOrder) {
          return
        }

        if (this.$refs['check-' + id][0].checked) {
          this.setSelectedStore(storeId)
          const payload = {
            cart_id: id
          }
          this.setCheckoutItem(payload.cart_id)
          this.setSelectedItem(id)
        } else if (dataCart.includes(id)) {
          this.setSelectedStore('')
          const payload = {
            cart_id: id
          }
          this.setCheckoutItem(payload.cart_id)
          this.setSelectedItem(id)
        }

        const indexStore = this.cart.findIndex((c) => {
          return c.id === storeId
        })

        if (
          this.selectItem.length === this.cart[indexStore].cart_items.length
        ) {
          this.deleteAll = true
          this.$refs['store-' + storeId][0].checked = true
        } else {
          this.deleteAll = false
          this.$refs['store-' + storeId][0].checked = false
          localStorage.removeItem('cartBranchId')
        }
        this.handleCheck()
      } else {
        this.alertStore = true
        this.flashAlert()
        this.$refs['check-' + id][0].checked = false
      }
    },
    handleCheckPreorderPerItem (id, storeId, dataCart) {
      const choosenCart = this.cart.find((listCart) => {
        return listCart.id === storeId
      })

      if (this.checkObject(choosenCart) && dataCart.length) {
        const cartItems = choosenCart.cart_items

        const oldSelectedCart = cartItems.filter((item) => {
          return dataCart.includes(item.id)
        })

        // check old condition
        const oldIsPreorder = oldSelectedCart.some((select) => {
          return !!(select.product.detail.is_preorder)
        })

        const newSelected = cartItems.find((item) => {
          return item.id === id
        })

        if (oldIsPreorder !== newSelected.product.detail.is_preorder) {
          this.$refs['check-' + id][0].checked = false
          this.flashAlert('Tidak bisa checkout produk Pre-Order dan tidak Pre-Order bersamaan')
          return true
        }

        if (oldIsPreorder) {
          let oldArrPeriode = []

          oldSelectedCart.forEach((select) => {
            oldArrPeriode = [
              ...oldArrPeriode,
              ...select.product.detail.preorder_periods
            ]
          })

          const difference = !this.checkCommonValueArrays(oldArrPeriode, newSelected.product.detail.preorder_periods)

          if (difference) {
            this.$refs['check-' + id][0].checked = false
            this.flashAlert('Tidak bisa checkout produk yang memiliki periode Pre-Order yang berbeda')
            return true
          }
        }
      }

      return false
    },
    checkAllStore (data) {
      this.mappingStore = true
      this.alertStore = false
      const checked = this.$refs['store-' + data.id][0].checked

      if (!this.store || this.store === data.id) {
        const checkPreOrder = this.handleCheckPreorderPerStore(data)

        if (checkPreOrder) {
          return
        }

        this.setSelectedStore(data.id)
        data.cart_items.map((cart, i) => {
          if (checked !== this.$refs['check-' + cart.id][0].checked) {
            if (i === data.cart_items.length - 1) {
              this.mappingStore = false
            }
            this.$refs['check-' + cart.id][0].click()
          } else if (i === data.cart_items.length - 1) {
            this.mappingStore = false
            this.handleCheck()
          }
        })
        this.deleteAll = checked
        this.alertStore = false
      } else {
        this.alertStore = true
        this.flashAlert()
        this.$refs['store-' + data.id][0].checked = false
      }
    },
    handleCheckPreorderPerStore (storeData) {
      const checkPreOrder = storeData.cart_items.some((cart) => {
        return !!cart.product.detail.is_preorder
      })

      const checkOrdinaryProduct = storeData.cart_items.some((cart) => {
        return !cart.product.detail.is_preorder
      })

      if (checkPreOrder && checkOrdinaryProduct) {
        this.$refs['store-' + storeData.id][0].checked = false
        this.flashAlert('Tidak bisa checkout karena terdapat produk Pre-Order dan tidak Pre-Order')
        return true
      }

      if (checkPreOrder) {
        const preOrderCarts = storeData.cart_items.filter((cart) => {
          return !!cart.product.detail.is_preorder
        })

        // new valid
        const cartItemsPreOrderPeriodes = preOrderCarts.map((cart) => {
          return cart.product.detail.preorder_periods
        })

        let currentValue = []
        const resCheckValue = []
        cartItemsPreOrderPeriodes.forEach((periode, index) => {
          currentValue = periode

          if (index < (cartItemsPreOrderPeriodes.length - 1)) {
            resCheckValue.push(this.checkCommonValueArrays(currentValue, cartItemsPreOrderPeriodes[index + 1]))
            currentValue = this.findCommonValues(currentValue, cartItemsPreOrderPeriodes[index + 1])
          }
        })

        const checkFalseCommon = resCheckValue.includes(false)

        if (checkFalseCommon) {
          this.$refs['store-' + storeData.id][0].checked = false
          this.flashAlert('Tidak bisa checkout karena terdapat produk yang memiliki periode Pre-Order yang berbeda')
          return true
        }
      }

      return false
    },
    getChecked (id) {
      this.selectItem.map((item) => {
        if (item === id) {
          return true
        }
      })
      return false
    },
    deleteModal (item, id) {
      this.setCartItemId(id)
      this.setDeleteItem(item)
    },
    inputQty (e, id, stock) {
      let qty = Number(e.target.value)
      if (qty <= 0) {
        qty = 1
      }
      if (qty > stock) {
        qty = stock
      }
      this.handleUpdateCartProduct(id, qty)
    }
  }
}
