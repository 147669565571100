//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins/index'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'ModalLinkAffiliate',
  mixins: [panstoreMixin],

  data () {
    return {
      showClipboardSuccess: false
    }
  },

  computed: {
    ...mapState('Product', ['shareUrlAffiliate']),
    link () {
      return this.shareUrlAffiliate
    }
  },

  methods: {
    ...mapMutations('Product', ['setShareUrl']),
    showNotif () {
      this.showClipboardSuccess = true;
      setTimeout(() => {
        this.showClipboardSuccess = false;
      }, 2000);
    }
  }
}
