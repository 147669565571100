//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'ModalSendWithdraw',
  mixins: [panstoreMixin],
  props: {
    modalId: {
      type: String,
      default: 'modalSendWithdraw'
    }
  },
  data () {
    return {
      formWithdraw: {
        bankAccount: ''
      },
      getListBankAccounts: {
        paginated: false,
        type: 'Bank',
        created: 'desc',
        pagination: {
          total: 0,
          totalPage: 0
        },
        perPage: 3,
        page: 1,
        data: []
      }
    }
  },
  async mounted () {
      const getApis = [
      this.fetchBankAccounts()
      ]

      await Promise.all(getApis)
  },
  computed: {
    ...mapState('Affiliate', ['selectedWithdrawables']),
    ...mapState('BankAccount', ['bankAccounts', 'bankAccountsWithdrawal']),
    ...mapGetters('Affiliate', ['getSelectedWithdrawablesAmount']),
    transferFee () {
      if (this.formWithdraw.bankAccount) {
        const bankAccount = this.bankAccountsWithdrawal.data.find(item => item.id === this.formWithdraw.bankAccount)
        return this.checkObject(bankAccount) ? bankAccount.transfer_fee : 0
      }

      return 0
    },
    transferFeeMessage () {
      if (this.formWithdraw.bankAccount) {
        const bankAccount = this.bankAccountsWithdrawal.data.find(item => item.id === this.formWithdraw.bankAccount)
        return this.checkObject(bankAccount) ? bankAccount.transfer_fee_message : 0
      }

      return 0
    },
    totalWithdraw () {
      return this.getSelectedWithdrawablesAmount - this.transferFee
    }
  },
  methods: {
    ...mapActions('BankAccount', ['getBankAccountsWithdrawal']),
    ...mapActions('Affiliate', ['createWithdrawalAffiliate']),
    async fetchBankAccounts () {
      try {
        const resp = await this.getBankAccountsWithdrawal({

          params: {
            paginated: this.getListBankAccounts.paginated,
            type: this.getListBankAccounts.type,
            per_page: this.getListBankAccounts.perPage,
            page: this.getListBankAccounts.page,
            order_by: {
              created_at: this.getListBankAccounts.created
            }
          }
        })

        this.getListBankAccounts.pagination.totalPage = resp.data.data.accounts.last_page
        this.getListBankAccounts.pagination.total = resp.data.data.accounts.total
        this.getListBankAccounts.data = [
          ...this.getListBankAccounts.data,
          ...resp.data.data.accounts
        ]
        this.getListBankAccounts.page++
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    },
    async drawAffiliate () {
      try {
        const payload = {
          data: {
            account_id: this.formWithdraw.bankAccount,
            withdrawables: this.selectedWithdrawables.map((selected) => {
              return {
                id: selected.id,
                type: selected.type
              }
            }),
            amount: this.getSelectedWithdrawablesAmount
          }
        }

        await this.createWithdrawalAffiliate(payload)
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Pengajuan Penarikan komisi afiliasi telah berhasil. Pengajuan akan diproses oleh admin, perubahan status dapat dilihat pada Daftar Pengajuan Penarikan Komisi'
          })
          .then((confirm) => {
            window.location.href = '/account/affiliate/commission/withdrawal'
          })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error

        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal Mengajukan Penarikan',
          text: errorMessage,
          heightAuto: false
        })
      }
    },
    modalClose () {
      this.$emit('modal-close')
    },
    handleAddAccount () {
      if (this.bankAccountsWithdrawal.data.length < 3) {
        this.$emit('open-bank')
        this.$refs.hideModal.$el.click()
      }
    },
    emptyAccountBank () {
      return this.bankAccountsWithdrawal.data ? this.bankAccountsWithdrawal.data.length < 1 : false
    },
    showButton () {
      return this.bankAccountsWithdrawal.data ? this.bankAccountsWithdrawal.data.length < 3 : false
    }
  }
}
