//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    withBrand: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false
    }
  }
}
