//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    classContainer: {
      type: String,
      default: ''
    },

    onCart: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState('Product', ['productsRecomendation']),
    ...mapGetters('Cart', ['haveCart']),

    countRecommendation () {
      return this.haveCart && this.onCart ? 4 : 5
    }
  },

  mounted () {
    this.handleGetData()
  },

  methods: {
    ...mapActions('Product', ['getRecomendationProduct']),

    async handleGetData () {
      this.loading = true

      try {
        await this.getRecomendationProduct({
          data: {
            limit: this.countRecommendation
          }
        })
      } catch (error) {
        this.$message.error('Gagal mendapatkan data rekomendasi produk')
      }

      this.loading = false
    }
  }
}
