//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegisterForm from '@/components/auth/RegisterForm'

export default {
  layout: 'auth',
  middleware: ['guest'],
  components: {
    'register-form': RegisterForm
  },
  computed: {},
  head: () => {
    return {
      title: 'Pendaftaran Akun Baru | Paninti Store'
    }
  }
}
