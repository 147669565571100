//
//
//
//
//
//
//
//

export default {
  layout: 'account',
  middleware: ['user'],

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Beranda',
          path: '/account/home'
        },
        {
          name: 'Alamat Saya'
        }
      ]
    }
  }
}
