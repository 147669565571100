//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

export default {
  name: 'ModalCtaJoinAffiliate',
  data () {
    return {
      checked: false,
      loading: false
    }
  },
  mounted () {
    const modal = this.$refs.modalCtaJoinAffiliate
    modal.addEventListener('hide.bs.modal', (event) => {})
  },
  methods: {
    ...mapActions('Affiliate', ['acceptPrivacyPolicy']),
    handleButtonClick () {
      this.$emit('buttonClick');
    },
    handleChange (value) {
      this.checked = value
    },
    openTnc () {
      this.$refs.hideModal.$el.click()
      this.$emit('terms-condition')
    },
    async joinAffiliate () {
      this.loading = true
      try {
        await this.acceptPrivacyPolicy()
        window.location.reload()
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage);
      } finally {
        this.loading = false
      }
    }
  }
}
