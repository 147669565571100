//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import _ from 'lodash'

export default {
  mixins: [panstoreMixin],

  props: {
    partner: {
      type: Object,
      default () {
        return {}
      }
    },

    isPartner: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      navActive: 1,
      mobileSearch: false,
      search: '',

      window: {
        width: 0,
        height: 0
      },

      showcases: []
    }
  },

  watch: {
    '$route.query' () {
      if (this.$route.query.section) {
        this.navActive = 2
      } else {
        this.navActive = 1
      }

      this.search = this.$route.query.partnerSearch
    }
  },

  beforeMount () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeDestroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  mounted () {
    if (Object.keys(this.$route.query).length && this.$route.query.section) {
      this.navActive = 2
    }

    this.search = this.$route.query.partnerSearch || ''
  },

  methods: {
    setShowcases (showcases) {
      this.showcases = showcases
    },

    changeTab (index) {
      if (index === 1) {
        this.$router.replace({
          query: {
           ...this.$route.query,
            section: undefined
          }
        })
      } else if (index === 2 && !this.$route.query.section) {
        this.$router.push({
          query: {
            ...this.$route.query,
            section: 'all'
          }
        })
      }

      this.navActive = index
    },

    onDisplaySearch () {
      return (this.mobileSearch = !this.mobileSearch)
    },

    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },

    handleSearch: _.debounce(function () {
      this.onSearch()
    }, 1000),

    onSearch () {
      if (this.navActive !== 2) {
        this.$refs.navAllproducts.click()
        this.navActive = 2
      }

      const dataQuery = this.initFilter(
        this.$route.query.brands,
        this.$route.query.partnerSearch,
        this.$route.query.categories,
        this.$route.query.colors,
        this.$route.query.sizes,
        this.$route.query.priceLimit,
        this.$route.query.rating,
        this.$route.query.sort
      )
      const selectedBrand = dataQuery.brands
      const selectedCategory = dataQuery.category

      if (selectedCategory === {}) {
        this.$refs.categoryTree.setCheckedKeys([])
      }

      const selectedColor = dataQuery.colors
      const selectedSize = dataQuery.sizes
      let valueRange = [0, 10000000]

      if (dataQuery.priceLimit) {
        valueRange = dataQuery.priceLimit
      }

      const sort = dataQuery.sort
      const brand = selectedBrand.join('::')
      const priceLimit = valueRange.join('::')
      const category = selectedCategory.join('::')
      const color = selectedColor.join('::')
      const size = selectedSize.join('::')
      const rating = dataQuery.rating.length > 1 ? dataQuery.rating.join('::') : null

      this.$router.push({
        query: {
          ...this.modelPayload(brand, '', category, color, size, priceLimit, rating, sort),
          partnerSearch: this.search,
          section: this.$route.query.section || 'all'
        }
      })
    }
  }
}
