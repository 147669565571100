//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  data () {
    return {
      datas: [],
      dataLimit: 5,
      loading: true
    }
  },

  computed: {
    ...mapState('User/address', ['location'])
  },

  watch: {
    location () {
      this.getNewestProducts()
    }
  },

  mounted () {
    this.getNewestProducts()
  },

  methods: {
    ...mapActions('Product', ['getNewProducts']),

    async getNewestProducts () {
      this.loading = true

      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155

        const resp = await this.getNewProducts({
          payload: {
            data: {
              session_id: this.$cookies.get('session_id'),
              city_id: cityId,
              per_page: this.dataLimit
            }
          }
        })

        this.datas = resp.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data dari produk terbaru')
      }

      this.loading = false
    }
  }
}
