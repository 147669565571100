//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  props: {
    totalPayment: {
      type: Number,
      default: 0
    },
    shippingCost: {
      type: Number,
      default: 0
    },
    totalShippingCost: {
      type: Number,
      default: 0
    },
    sentGift: {
      type: Boolean,
      default: false
    },
    giftNote: {
      type: String,
      default: ''
    },
    store: {
      type: Object,
      default () {
        return {}
      }
    },
    preorder: {
      type: Object,
      default: () => ({})
    },
    products: {
      type: Array,
      default () {
        return []
      }
    },
    reference: {
      type: String,
      default: ''
    },
    courier: {
      type: Object,
      default () {
        return {}
      }
    },
    discount: {
      type: Number,
      default: 0
    },
    isShipping: {
      type: Boolean,
      default: false
    },
    dataStatus: {
      type: Object,
      default () {
        return {}
      }
    },
    method: {
      type: String,
      default: ''
    },
    invoice: {
      type: Boolean,
      default: false
    },
    pointUsed: {
      type: Number,
      default: 0
    },
    paymentMethod: {
      type: String,
      default: ''
    }
  },
  computed: {
    status () {
      return this.getStatusPesanan(this.dataStatus.slug, this.method)
    }
  },
  methods: {
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant']),
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),
    getSubtotal () {
      let result = 0
      this.products.map((product) => {
        result += product.total_price
      })
      return result
    },
    async handleAddCart (item) {
      const payload = Object.assign({
        data: {
          product_detail_id: item.detail_id,
          quantity: 1,
          session_id: this.$cookies.get('session_id')
        }
      })
      try {
        const resp = await this.addCart(payload)
        if (resp.data.status_code === 201) {
          await this.setSelectedVariant(null)
          await this.handleGetCart()
          await this.setSelectedProduct({
            ...item,
            type: 'simple',
            name: item.product_name + ' - ' + item.detail_name
          })
          this.$refs.showAlert.click()
        } else {
          this.$message({
            showClose: true,
            message: resp.data.message,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
        const errorMessage = error.response
          ? error.response.data.message
          : error
        this.$message({
          showClose: true,
          message: errorMessage,
          type: 'error'
        })
      }
    },
    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }
      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }
      try {
        await this.getCartsNavbar({
          params: {
            session_id: payload.session_id
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
