import { render, staticRenderFns } from "./index.vue?vue&type=template&id=522406ea&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522406ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,WithdrawCommissionItem: require('/usr/src/app/components/account/affiliate/commission/WithdrawCommissionItem.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,ModalDetailWithdrawCommission: require('/usr/src/app/components/account/affiliate/commission/ModalDetailWithdrawCommission.vue').default})
