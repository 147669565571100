//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Alert',
  props: {
    variant: {
      type: String,
      default: 'blue'
    },
    textScheme: {
      type: String,
      default: ''
    },
    bordered: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes () {
      const classes = []

      if (this.variant) {
        classes.push(`alert-${this.variant}`)
      }

      if (this.textScheme === 'dark') {
        classes.push('text-dark')
      }

      if (this.bordered) {
        classes.push('bordered')
      }

      return classes
    },
    icon () {
      let icon = ''
      switch (this.variant) {
        case 'blue':
          icon = '/img/icon/icon-info-blue.svg'
          break
      }

      return icon
    }
  }
}
