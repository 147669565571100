//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'CardUserProfile',
  mixins: [panstoreMixin],
  data () {
    return { }
  },
  computed: {
    ...mapState('User', ['profileUser']),
    socmed () {
      const locSocmed = [
        {
          link: '#',
          icon: '/img/icon/icon-instagram-dark.svg',
          name: 'instagram'
        },
        {
          link: '#',
          icon: '/img/icon/icon-tiktok-dark.svg',
          name: 'tiktok'
        },
        {
          link: '#',
          icon: '/img/icon/icon-twitter-x-dark.svg',
          name: 'twitter'
        },
        {
          link: '#',
          icon: '/img/icon/icon-youtube-dark.svg',
          name: 'youtube'
        }
      ]

      if (this.checkObject(this.profileUser)) {
        this.profileUser.social_medias.forEach((item) => {
          const locSocmedIdx = locSocmed.findIndex((itemLoc) => {
            return itemLoc.name === item.name
          })

          if (locSocmedIdx > -1) {
            locSocmed[locSocmedIdx].link = item.value
          }
        })
      }

      // check link
      const deletedSocmedIndex = []
      locSocmed.forEach((item, index) => {
        if (item.link.includes('#')) {
          deletedSocmedIndex.push(item.name)
        }
      })

      if (this.checkArray(deletedSocmedIndex)) {
        deletedSocmedIndex.forEach((item) => {
          const locSocmedIdx = locSocmed.findIndex((sm) => {
            return sm.name === item
          })

          if (locSocmedIdx > -1) {
            locSocmed.splice(locSocmedIdx, 1)
          }
        })
      }

      return locSocmed
    }
  }
}
