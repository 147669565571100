//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      loading: true,
      notifTransaksi: [],
      notifPromo: [],
      page: {
        transaksi: 1,
        promo: 1
      },
      unread: {
        transaksi: 0,
        promo: 0
      },
      totalPage: {
        transaksi: 0,
        promo: 0
      },
      tabs: 'transaksi'
    }
  },
  computed: {
    dataItem () {
      if (this.tabs === 'transaksi') {
        return this.notifTransaksi
      } else {
        return this.notifPromo
      }
    },
    ...mapState('Base', ['dataBreadCrumbs']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  created () {
    this.getDataTransaksi()
    this.getDataPromo()
    if (
      Object.keys(this.$route.query).length &&
      this.$route.query.type === 'promotion'
    ) {
      this.tabs = 'promo'
    }
  },
  methods: {
    ...mapActions('User', [
      'getNotification',
      'showNotification',
      'readAllNotification'
    ]),

    ...mapMutations('Base', ['setBreadCrumbs']),
    async getDataTransaksi () {
      this.loading = true
      try {
        const resp = await this.getNotification({
          data: {
            order_by: { created_at: 'desc' },
            where_has: [
              {
                relation: 'notification_type.parent',
                where: {
                  name: 'Transaksi'
                }
              }
            ],
            where: {
              application: 'paninti-store'
            },
            page: this.page.transaksi,
            per_page: 10
          }
        })
        if (resp.status === 200) {
          this.page.transaksi++
          this.unread.transaksi = resp.data.data.unread_notification_count || 0
          if (resp.data.data.notifications.current_page === 1) {
            this.totalPage.transaksi = resp.data.data.notifications.last_page
            this.notifTransaksi = resp.data.data.notifications.data
          } else {
            this.notifTransaksi = this.notifTransaksi.concat(
              resp.data.data.notifications.data
            )
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async getDataPromo () {
      this.loading = true
      try {
        const resp = await this.getNotification({
          data: {
            order_by: { created_at: 'desc' },
            where_has: [
              {
                relation: 'notification_type.parent',
                where: {
                  name: 'Promo'
                }
              }
            ],
            where: {
              application: 'paninti-store'
            },
            page: this.page.promo,
            per_page: 10
          }
        })
        if (resp.status === 200) {
          this.page.promo++
          this.unread.promo = resp.data.data.unread_notification_count || 0
          if (resp.data.data.notifications.current_page === 1) {
            this.totalPage.promo = resp.data.data.notifications.last_page
            this.notifPromo = resp.data.data.notifications.data
          } else {
            this.notifPromo = this.notifPromo.concat(
              resp.data.data.notifications.data
            )
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getIcon (name) {
      switch (name) {
        case 'Pembayaran':
          return 'payment'
        case 'Pesanan Dikemas':
          return 'order-packaging'
        case 'Pengiriman':
          return 'order-shipping'
        case 'Order Sampai':
          return 'order-done'
        case 'Order Selesai':
          return 'order-done'
        case 'Order Dibatalkan':
          return 'order-done'
        case 'Diskon':
          return 'discount'
        case 'Sale':
          return 'discount'
        case 'Event Sale':
          return 'event-sale'
        case 'Voucher':
          return 'voucher'
        case 'Poin':
          return 'voucher'
        case 'Afiliasi':
          return 'affiliate'
      }
    },
    handleGetData () {
      if (this.tabs === 'transaksi') {
        this.getDataTransaksi()
      } else {
        this.getDataPromo()
      }
    },
    changeTab (tab) {
      this.tabs = tab
      const temp = JSON.parse(JSON.stringify(this.dataBreadCrumbs))
      temp[temp.length - 1].name =
        tab === 'transaksi' ? 'Transaksi' : 'Promosi'
      this.setBreadCrumbs(temp)
    },
    async handleClickNotif ({ id, link, notification_type: notifType }) {
      let redirect = link
      await this.showNotification(id)

      if (notifType.name === 'Afiliasi' && this.checkObject(this.user) && this.checkObject(this.user.affiliate)) {
        redirect = '/account/affiliate'
      }
      window.location = redirect
    },
    async handleReadAll () {
      try {
        const resp = await this.readAllNotification({
          data: {
            type: this.tabs === 'transaksi' ? 'transaction' : 'promotion'
          }
        })
        if (resp.status === 200) {
          this.page = {
            transaksi: 1,
            promo: 1
          }
          this.totalPage = {
            transaksi: 1,
            promo: 1
          }
          this.getDataTransaksi()
          this.getDataPromo()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
